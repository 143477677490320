import { IAta } from 'entities';
import {
  IAtaReportListInputModel,
  IAtaInputModel,
  IDossierFieldInputModel,
} from 'inputModels';
import { IGetAtasV2 } from 'props';
import { IAtaListViewModel, IAtaReportListViewModel } from 'viewModels';
import api from '../services/api/axios';

const create = (formData: FormData, token: string) => {
  return api.post('ata', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};

/*const duplicate = (input: IDuplicateAtaInputModel) =>
new Promise<string>((resolve, reject) =>
    api
      .post('ata/duplicate', input, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject)
  );*/

const readPdf = async (formData: FormData, token: string) => {
  return api.post('ata/read-pdf', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};

const getReportList = (params?: IAtaReportListInputModel) =>
  new Promise<IAtaReportListViewModel[]>((resolve, reject) => {
    api
      .get('ata/get-report-list', {
        params,
      })
      .then(({ data }) => resolve(data || []))
      .catch(reject);
  });

const getById = (id: string) =>
  new Promise<IAtaListViewModel>((resolve, reject) => {
    api
      .get(`ata/get-by-id/${id}`)
      .then(({ data }) => resolve(data || []))
      .catch(reject);
  });

const findById = (id: string) =>
  new Promise<IAta>((resolve, reject) => {
    api
      .get(`ata/find-by-id/${id}`)
      .then(({ data }) => resolve(data.data))
      .catch(reject);
  });

/*const getForwardAtaInfosById = (id: string) =>
  new Promise<IAtaForwardInfoViewModel>((resolve, reject) => {
    api
      .get(`ata/get-forward-ata-infos/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });*/

const getAllInfosById = (id: string) =>
  new Promise<IAta>((resolve, reject) => {
    api
      .get(`ata/get-all-infos/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const get = (params?: IGetAtasV2) =>
  new Promise<IAtaListViewModel[]>((resolve, reject) => {
    api
      .get(`ata`, { params })
      .then(({ data }) => resolve(data || []))
      .catch(reject);
  });

/*const getByContactId = (contactId: string, params?: IGetAtas) =>
  new Promise<IAtaListViewModel[]>((resolve, reject) => {
    api
      .get(`ata/find-by-contact-id/${contactId}`, { params })
      .then(({ data }) => resolve(data || []))
      .catch(reject);
  });*/

const getDossier = (fields: IDossierFieldInputModel[]) =>
  new Promise<IAtaListViewModel[]>((resolve, reject) => {
    api
      .post('ata/dossier', fields)
      .then(({ data }) => resolve(data || []))
      .catch(reject);
  });

export const reportDownload = (atasIds: string[]) =>
  new Promise<any>((resolve, reject) => {
    api
      .post('ata/dossier/export', atasIds, {
        responseType: 'blob',
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const exportDownload = (atasIds: string[], allInOneFile: boolean) =>
  new Promise<any>((resolve, reject) => {
    api
      .post('ata/export', atasIds, {
        responseType: 'blob',
        params: {
          allInOneFile,
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const attach = (formData: FormData) =>
  new Promise<{ id: string }>((resolve, reject) => {
    api
      .post('ata/attach-to-ata', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => resolve(data.data))
      .catch(reject);
  });

const linkTo = (ataId: string, userId: string) =>
  new Promise<void>((resolve, reject) => {
    api
      .put(`ata/link-to/${userId}`, undefined, {
        params: {
          ataId,
        },
      })
      .then(() => resolve())
      .catch(reject);
  });

const moveToTrash = (id: string) =>
  new Promise<void>((resolve, reject) => {
    api
      .put(`ata/move-to-trash/${id}`)
      .then(() => resolve())
      .catch(reject);
  });

const restore = (id: string) =>
  new Promise<void>((resolve, reject) => {
    api
      .put(`ata/restore/${id}`)
      .then(() => resolve())
      .catch(reject);
  });

const update = (data: IAtaInputModel) =>
  new Promise<void>((resolve, reject) => {
    api
      .put('ata', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => resolve())
      .catch(reject);
  });

const remove = (id: string) =>
  new Promise<void>((resolve, reject) => {
    api
      .delete(`ata/${id}`)
      .then(() => resolve())
      .catch(reject);
  });

const getByUserAndOffice = (userId: string, officeId: string) =>
  new Promise<IAtaListViewModel[]>((resolve, reject) => {
    api
      .get(`ata/get-by-user-and-office/${userId}/${officeId}`)
      .then(({ data }) => resolve(data || []))
      .catch(reject);
  });

export const AtaController = {
  get,
  attach,
  create,
  remove,
  update,
  linkTo,
  readPdf,
  getById,
  restore,
  findById,
  getDossier,
  moveToTrash,
  getReportList,
  exportDownload,
  reportDownload,
  getAllInfosById,
  getByUserAndOffice,
};
