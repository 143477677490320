import styled from 'styled-components';

export const NotificationsStyle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.colors.background};

  #icon {
    width: 25px;
    height: 25px;

    cursor: pointer;

    &.disabled {
      cursor: default;
    }
  }

  #counter {
    background: ${({ theme }) => theme.colors.accent};
    font-size: 12px;
    border-radius: 30px;
    font-weight: 300;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -7px;
    right: -7px;
    cursor: default;
  }
`;

export const NotificationStyle = styled.div`
  display: flex !important;
  max-width: 250px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.f2};
  padding: 10px;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }

  & > svg {
    width: 20px;
    height: 20px;
  }

  p {
    font-weight: 200;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  button {
    margin-left: auto;

    svg {
      fill: ${({ theme }) => theme.colors.accent};
    }
  }

  :last-child {
    border-bottom: none;
  }
`;

export const NotificationsList = styled.div`
  display: flex;
  flex-direction: column;

  .list-header {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0px 5px;
  }

  #items {
    max-height: 320px;
    overflow-y: auto;
  }
`;
