import React, { useEffect, useRef } from 'react';

const useHorizontalScroll = (classesToIgnore?: string[]) => {
  const elRef = useRef<any>();
  useEffect(() => {
    const el = elRef.current;
    let onWheel: any;
    if (el) {
      onWheel = (e: React.WheelEvent) => {
        const target = e.target as HTMLElement;
        if (
          e.deltaY === 0 ||
          !classesToIgnore?.some((c) => target.classList.contains(c)) ||
          target.scrollHeight > target.clientHeight
        )
          return;

        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: 'smooth',
        });
      };
      el.addEventListener('wheel', onWheel);
    }
    return () => onWheel && el.removeEventListener('wheel', onWheel);
  }, [classesToIgnore]);
  return elRef;
};

export default useHorizontalScroll;
