/* eslint-disable no-param-reassign */
import { CheckCircle, DeleteOutline, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@mui/material';
import { IContact } from 'entities';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert, useApp, useAuth, useContact } from '../../context';
import { ContactController } from '../../controllers';
import { ContactDeleteAction, ContactStatus } from '../../types/enums';
import { Container } from './styles';

interface ContactProps extends IContact {
  focus?: boolean;
  //showRules?: boolean;
  officeId?: string;
  showSeeAtas?: boolean;
  isDraggingOver?: boolean;
  isOwnerContact?: boolean;
  showEmptyMessage?: boolean;
  children?: React.ReactNode;
  showNameFrom?: 'user' | 'owner';
  onDelete?: (id: string) => void;
  onOpen?: (isOpen: boolean) => void;
  deleteButton: boolean;
}

const Contact = React.memo(
  ({
    children,
    onOpen,
    onDelete,
    //hasShares,
    //showRules,
    //shareCount,
    officeId,
    showNameFrom,
    focus = false,
    isDraggingOver,
    isOwnerContact,
    showSeeAtas = true,
    showEmptyMessage = false,
    deleteButton,
    ...contact
  }: ContactProps) => {
    const { id, user, owner, status, isAmOwner } = contact;
    const auth = useAuth();
    const appAlert = useAlert();
    const navigate = useNavigate();
    const accordionRef = useRef<HTMLDivElement>(null);
    const { online, loading, setLoading } = useApp();
    const [isFirstOpen, setIsFirstOpen] = useState(true);
    const [isOpen, setIsOpen] = useState<boolean>();
    const { getContactName } = useContact();
    const [openedBy, setOpenedBy] = useState<'click' | 'event'>();
    const [showChildren, setShowChildren] = useState<boolean>();
    //const [hasChanges, setHasChanges] = useState(false);
    //const ruleSlugs = useMemo(() => rules.map(({ slug }) => slug), [rules]);
    //const [currentRules, setCurrentRules] = useState<string[]>(ruleSlugs);
    const statusName =
      status === 0 ? 'Bloqueado' : status === 1 ? 'Liberado' : 'Em aprovação';

    /*const defaultRules = [
      { id: 'share-atas', label: 'Compartilhar atas' },
      { id: 'receive-atas', label: 'Receber atas' },
      { id: 'edit-atas', label: 'Editar atas' },
    ];*/

    useEffect(() => {
      if (focus && !isOpen) {
        accordionRef?.current?.click();
        setOpenedBy('click');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focus]);

    useEffect(() => {
      setIsFirstOpen(false);
      let timer: any;
      if (isOpen) {
        setShowChildren(true);
      } else if (isOpen === false) {
        setOpenedBy('event');
        timer = setTimeout(() => {
          setShowChildren(false);
        }, 1000);
      }

      return () => timer && clearTimeout(timer);
    }, [isOpen, onOpen]);

    useEffect(() => {
      let timer: any;
      if (!isFirstOpen && openedBy !== 'click') {
        timer = setTimeout(() => {
          if (isOpen ? !isDraggingOver : isDraggingOver)
            accordionRef?.current?.click();
        }, 1000);
      }
      return () => timer && clearTimeout(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDraggingOver]);

    /*const setRules = useCallback(() => {
      if (loading || !hasChanges) return;
      setLoading(true);
      ContactController.setRules(id, currentRules)
        .then(() => {
          setHasChanges(false);
          setLoading(false);
        })
        .catch(async () => {
          setHasChanges(false);
          //setCurrentRules(ruleSlugs);
          setLoading(false);

          await appAlert.show({
            type: 'error',
            errors: ['Erro ao atualizar os permissões do usuário'],
          });
        });
    }, [
      appAlert,
      currentRules,
      hasChanges,
      id,
      loading,
      ruleSlugs,
      setLoading,
    ]);*/

    /*useEffect(() => {
      setHasChanges(false);
      setCurrentRules(ruleSlugs);
    }, [ruleSlugs]);*/

    /*useEffect(() => {
      const timer = setTimeout(() => {
        if (hasChanges) setRules();
      }, 1000);

      return () => clearTimeout(timer);
    }, [hasChanges, setRules]);*/

    const handleUpdateStatus = (
      newStatus: ContactStatus,
      action?: ContactDeleteAction
    ) => {
      if (loading) return;
      setLoading(true);
      ContactController.update(id, newStatus, action, auth?.token || '')
        .then(() => {
          appAlert.show({
            type: 'info',
            message: 'Status atualizado com sucesso',
          });
        })
        .catch(() => {
          appAlert.show({
            type: 'error',
            errors: ['Não foi possível atualizar o status do contato'],
          });
        })
        .finally(() => setLoading(false));
    };

    const handleDelete = (action?: ContactDeleteAction) => {
      if (loading) return;
      setLoading(true);
      ContactController.remove(id, action, auth?.token || '')
        .then(() => onDelete?.(id))
        .catch(() => {
          appAlert.show({
            type: 'error',
            errors: ['Não foi possível deletar o contato'],
          });
        })
        .finally(() => setLoading(false));
    };

    const handleSeeAtas = () => {
      navigate('/atas', {
        state: {
          contactId: id,
          companyId: isOwnerContact ? owner.id : undefined,
        },
      });
    };

    /*const blockAlert = () => {
      let option: number = ContactDeleteAction.TRANSFER;

      return (
        <BlockAlert>
          <p>
            <span>Atenção:</span> Ao bloquear, você irá tirar a permissão dele
            de vincular atas a sua empresa.
          </p>
          <RadioGroup
            aria-labelledby="rbd-block"
            defaultValue={option}
            name="rbd-block"
            onChange={(e) => {
              option = Number(e.target.value);
            }}
          >
            <FormControlLabel
              value={ContactDeleteAction.TRANSFER}
              control={<Radio />}
              label="Bloquear usuário e passar atas para empresa"
            />
            <FormControlLabel
              value={ContactDeleteAction.DELETE}
              control={<Radio />}
              label="Bloquear o usuário e deletar todas as atas"
            />
            <FormControlLabel
              value={ContactDeleteAction.NONE}
              control={<Radio />}
              label="Somente bloquear o usuário"
            />
          </RadioGroup>
          <Button
            color="error"
            variant="contained"
            disabled={!online}
            title="Confirmar bloqueio do usuário"
            onClick={() => handleUpdateStatus(ContactStatus.BLOCKED, option)}
          >
            <p>Bloquear</p>
          </Button>
        </BlockAlert>
      );
    };*/

    /*const deleteAlert = () => {
      let option: number = ContactDeleteAction.TRANSFER;

      return (
        <DeleteAlert>
          <p>
            <span>Atenção:</span> Este usuário ainda pode ter{/*tem*\} atas
            compartilhadas com você. Você deseja:.
          </p>
          <RadioGroup
            aria-labelledby="rbd-block"
            defaultValue={option}
            name="rbd-block"
            onChange={(e) => {
              option = Number(e.target.value);
            }}
          >
            <FormControlLabel
              value={ContactDeleteAction.TRANSFER}
              control={<Radio />}
              label="Passar as atas para empresa."
            />
            <FormControlLabel
              value={ContactDeleteAction.NONE}
              control={<Radio />}
              label="Apenas excluir o usuário."
            />
            <FormControlLabel
              value={ContactDeleteAction.DELETE}
              control={<Radio />}
              label="Excluir tudo."
            />
          </RadioGroup>
          <Button
            color="error"
            variant="contained"
            disabled={!online}
            title="Confirmar bloqueio do usuário"
            onClick={() => handleDelete(option)}
          >
            <p>Excluir</p>
          </Button>
        </DeleteAlert>
      );
    };*/

    return (
      <Container>
        <Accordion
          expanded={isOpen}
          onChange={() => {
            setIsOpen(!isOpen);
            onOpen?.(!isOpen);
          }}
        >
          <AccordionSummary
            id="user-summary"
            ref={accordionRef}
            onClick={(e) => {
              const isUserClick = (e.target as any)?.className === 'user-info';
              setOpenedBy(isUserClick ? 'click' : 'event');
            }}
            expandIcon={<ExpandMore color="primary" />}
          >
            <div className="user-info">
              <p id="name">{getContactName(contact, showNameFrom)}</p>
              <p
                id="status"
                className={`${
                  status === 0 ? 'blocked' : status === 2 ? 'pending' : ''
                }`}
              >
                {statusName}
              </p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="user-actions">
              {
                /* {status === 0 ? (
                <>
                  <Button
                    color="success"
                    disabled={!isAmOwner || !online}
                    onClick={() => handleUpdateStatus(ContactStatus.RELEASED)}
                    title="Desbloquear acesso para este usuário"
                  >
                    <CheckCircle color="success" />
                    <p>Desbloquear</p>
                  </Button>
                  {hasShares || !!shareCount ? (
                    <Popover
                      Element={deleteAlert}
                      disabled={!isAmOwner || !online}
                    >
                      <Button
                        color="error"
                        disabled={!isAmOwner || !online}
                        title="Excluir vínculo com este usuário"
                      >
                        <DeleteOutline color="error" />
                        <p>Excluir</p>
                      </Button>
                    </Popover>
                  ) : (
                    <Button
                      color="error"
                      disabled={!isAmOwner || !online}
                      title="Excluir vínculo com este usuário"
                      onClick={() => handleDelete()}
                    >
                      <DeleteOutline color="error" />
                      <p>Excluir</p>
                    </Button>
                  )}
                </>
              ) : */ status === 1 ? (
                  <>
                    {showSeeAtas &&
                    (user.id !== auth?.user?.id ||
                      owner.profileSlug !== 'office') ? (
                      <Button
                        onClick={() => handleSeeAtas()}
                        disabled={!online}
                      >
                        <p>Ver atas</p>
                      </Button>
                    ) : !showEmptyMessage ||
                      isAmOwner ||
                      owner.id === officeId ? (
                      <div />
                    ) : (
                      <p className="empty-action-text">
                        Nenhuma ação disponível...
                      </p>
                    )}
                    {/* {isAmOwner &&
                      (hasShares || !!shareCount ? (
                        <Popover
                          Element={deleteAlert}
                          disabled={!isAmOwner || !online}
                        >
                          <Button
                            color="error"
                            disabled={!isAmOwner || !online}
                            title="Excluir vínculo com este usuário"
                          >
                            <DeleteOutline color="error" />
                            <p>Excluir</p>
                          </Button>
                        </Popover>
                      ) : ( */}
                    {deleteButton && (isAmOwner || owner.id === officeId) && (
                      <Button
                        color="error"
                        disabled={!online}
                        title="Excluir vínculo com este usuário"
                        onClick={() => handleDelete()}
                      >
                        <DeleteOutline color="error" />
                        <p>Excluir</p>
                      </Button>
                    )}
                    {/* ))} */}
                    {/* {!(
                    user.profileSlug === 'common' &&
                    owner?.profileSlug === 'common'
                  ) ? (
                    <MenuPopup
                      options={defaultRules}
                      multiple
                      disabled={!isAmOwner || loading || !online}
                      onSelect={(selected) => {
                        setCurrentRules(selected);
                        setHasChanges(true);
                      }}
                      value={currentRules}
                    >
                      <Button disabled={!isAmOwner}>
                        <p>Permissões</p>
                      </Button>
                    </MenuPopup>
                  ) : (
                    <div />
                  )} */}
                    {/* {shareCount ? (
                    <Popover
                      Element={blockAlert}
                      disabled={!isAmOwner || !online}
                    >
                      <Button
                        color="error"
                        disabled={!isAmOwner || !online}
                        title="Bloquear acesso para este usuário"
                      >
                        <Block color="error" />
                        <p>Bloquear</p>
                      </Button>
                    </Popover>
                  ) : (
                    <Button
                      color="error"
                      disabled={!isAmOwner || !online}
                      title="Bloquear acesso para este usuário"
                      onClick={() => handleUpdateStatus(ContactStatus.BLOCKED)}
                    >
                      <Block color="error" />
                      <p>Bloquear</p>
                    </Button>
                  )} */}
                  </>
                ) : (
                  <>
                    {isAmOwner || owner.id === officeId ? (
                      <Button
                        color="success"
                        disabled={
                          (!isAmOwner && owner.id !== officeId) || !online
                        }
                        title="Liberar acesso para este usuário"
                        onClick={() =>
                          handleUpdateStatus(ContactStatus.RELEASED)
                        }
                      >
                        <CheckCircle color="success" />
                        <p>Liberar</p>
                      </Button>
                    ) : user.id === auth?.user?.id ? (
                      <div />
                    ) : (
                      <p className="empty-action-text">
                        Nenhuma ação disponível...
                      </p>
                    )}
                    {(isAmOwner ||
                      user.id === auth?.user?.id ||
                      owner.id === officeId) && (
                      <Button
                        color="error"
                        disabled={!online}
                        title="Excluir vínculo com este usuário"
                        onClick={() => handleDelete()}
                      >
                        <DeleteOutline color="error" />
                        <p>Excluir</p>
                      </Button>
                    )}
                    {/* <Button
                    color="error"
                    disabled={!isAmOwner || !online}
                    title="Bloquear acesso para este usuário"
                    onClick={() => handleUpdateStatus(ContactStatus.BLOCKED)}
                  >
                    <Block color="error" />
                    <p>Bloquear</p>
                  </Button> */}
                  </>
                )
              }
            </div>
            {/* {showRules && status === 1 && (
              <p className="contact-rules">
                {rules?.length > 0
                  ? `Este usuário pode ${rules
                      .map(
                        (rule) =>
                          defaultRules.find((r) => r.id === rule.slug)?.label
                      )
                      .join(', ')}`
                  : 'Este usuário não tem permissões habilitadas'}
              </p>
            )} */}
            {showChildren && children}
          </AccordionDetails>
        </Accordion>
      </Container>
    );
  }
);

export default Contact;
