import {
  AttachFileOutlined,
  InsertDriveFileOutlined,
} from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { ISearchResult } from 'entities';
import React, { useCallback, useEffect, useState } from 'react';
import Masks from '../../utils/masks';
import { SearchItemContainer } from './styles';

interface ISearchItemProps {
  result: ISearchResult;
  term: string;
  onClick?: () => Promise<void>;
}

const SearchItem: React.FC<ISearchItemProps> = ({ result, term, onClick }) => {
  const [text, setText] = useState(result?.text);
  const [loading, setLoading] = useState(false);

  const getMatch = useCallback(
    (pattern: string, input: string) => {
      if (!input.trim()) return input;
      const rgx = new RegExp(pattern, 'gi');
      let match = rgx.exec(input);
      const matches = [];
      while (match !== null) {
        matches.push(match);
        match = rgx.exec(input);
      }

      let newText = result.text;

      matches.forEach((m) => {
        const val = m[0];
        const { index } = m;
        const sub = result?.text?.substring(index, index + val.length) ?? '';
        const txt = `<strong>${sub}</strong>`;

        newText = newText?.replace(sub, txt);
      });

      return newText;
    },
    [result.text]
  );

  const handleClick = useCallback(async () => {
    if (onClick) {
      setLoading(true);
      onClick().finally(() => setLoading(false));
    }
  }, [onClick]);

  useEffect(() => {
    if (term.trim()) {
      const words = term.split(' ');
      const rgx = words.map((word) => {
        const txt = word.split('');
        const res = word.split('').map(() => {
          const str = [...txt];
          txt.pop();
          return str.join('');
        });
        return res;
      });

      const pattern = Array.from(new Set(rgx.flat()))
        .sort((a, b) => b.length - a.length)
        .join('|');

      setText(
        getMatch(
          `(?:^|\\s|-|\\.|_)(?:${Masks.Unaccent(pattern)})`,
          Masks.Unaccent(result?.text || '')
        ) || result?.text
      );
    }
  }, [getMatch, result?.text, term]);

  const comeFrom = [
    'Número do processo',
    'Local da audiência',
    'Juiz da audiência',
    'Reclamante',
    'Reclamado',
    'Advogado',
    'Nome do depoente',
    'Apelido do depoente',
    'Depoimento',
    'Nome do anexo',
    'Descrição do anexo',
  ];

  return (
    <SearchItemContainer onClick={handleClick}>
      {loading ? (
        <CircularProgress className="item-search-loading" size="small" />
      ) : result.type === 0 ? (
        <InsertDriveFileOutlined />
      ) : (
        <AttachFileOutlined />
      )}
      <p
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: text || '',
        }}
      />
      <span
        className="come-from"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `&nbsp;- ${comeFrom[result.comeFrom] || 'Outro'}`,
        }}
      />
    </SearchItemContainer>
  );
};

export default SearchItem;
