import styled from 'styled-components';

export const Screen = styled.div`
  width: 100vw;
  display: flex;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
`;
