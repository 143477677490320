import { Key } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import mime from 'mime';
import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
  IAtaListViewModel,
  IAtaMoveToTrashViewModel,
  IAtaRestoreViewModel,
} from 'viewModels';
import { IUserContact } from 'entities';
import { ReactComponent as OfficeIcon } from '../../assets/svg/icon-office.svg';
import { ReactComponent as IconSearchPlus } from '../../assets/svg/icon-search-plus.svg';
import { ReactComponent as IconWriteAta } from '../../assets/svg/icon-write-ata.svg';
import { Ata, Button, ContactWithAtas, Header, Input } from '../../components';
import {
  useActions,
  useAlert,
  useApp,
  useAta,
  useAuth,
  useContact,
  useOffice,
} from '../../context';
import { ContactController } from '../../controllers';
import useHorizontalScroll from '../../hooks/useHorizontalScroll';
import { useReadTextFile } from '../../hooks/useReadTextFile';
import { Container } from './styles';

interface AtasProps {
  contactId: string;
  companyId?: string;
}

const Atas: React.FC = () => {
  const { atas, setAta, atasOffline } = useAta();

  const appAlert = useAlert();
  const navigate = useNavigate();
  const { offices } = useOffice();
  const { subscribe } = useActions();
  const { online, setLoading } = useApp();
  const { profile, user, activeOffice, ownerAccount } = useAuth();
  const { readTextImage, readTextPdf } = useReadTextFile();
  const state = useLocation().state as AtasProps | undefined;
  const [newContactCode, setNewContactCode] = useState<string>('');
  const [cardsViewType, setCardsViewType] =
    useState<string>('colunm-atas-cards');
  const {
    contacts,
    ownerContacts,
    officeContacts,
    organizedContacts,
    linkedContact,
    getOfficeContacts,
  } = useContact();

  const [contactsOrganized, setContactsOrganized] = useState<any>();

  const allContacts = useMemo(
    () =>
      contacts
        .concat(Object.values(officeContacts).flat())
        .concat(ownerContacts)
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        ),
    [contacts, ownerContacts, officeContacts]
  );

  const [contactAtas, setContactAtas] = useState<{
    [key: string]: IAtaListViewModel[];
  }>({});
  const officesRef = useHorizontalScroll(['office-atas', 'user-info']);

  useEffect(() => {
    const unsubscribeAtaUpdate = subscribe(
      'ATA_UPDATED',
      (ataUpdated: IAtaListViewModel) => {
        Object.entries(contactAtas).forEach(([key, atasContact]) => {
          const ataContactIndex = atasContact.findIndex(
            (a) => a.id === ataUpdated.id
          );

          if (ataContactIndex > -1) {
            setContactAtas((prev) => ({
              ...prev,
              [key]: [
                ...atasContact.slice(0, ataContactIndex),
                ataUpdated,
                ...atasContact.slice(ataContactIndex + 1),
              ],
            }));
          }
        });
      }
    );

    const unsubscribeAtaDelete = subscribe(
      'ATA_DISABLED',
      (ataDeletedId: string) => {
        setContactAtas((prev) =>
          Object.entries(prev).reduce(
            (acc, [key, atasContact]) => ({
              ...acc,
              [key]: atasContact.filter((a) => a.id !== ataDeletedId),
            }),
            {}
          )
        );
      }
    );

    const unsubscribeAtaLinkTo = subscribe('ATA_LINK_TO', (info) => {
      Object.entries(contactAtas).forEach(([key, atasContact]) => {
        const ataContactIndex = atasContact.findIndex(
          (a) => a.id === info.ataId
        );

        if (ataContactIndex > -1) {
          setContactAtas((prev) => ({
            ...prev,
            [key]: [
              ...atasContact.slice(0, ataContactIndex),
              {
                ...atasContact[ataContactIndex],
                linkedTo: info.linkedTo,
              },
              ...atasContact.slice(ataContactIndex + 1),
            ],
          }));
        }
      });
    });

    const unsubscribeAtaTrashed = subscribe(
      'TRASHED_ATA',
      ({ id }: IAtaListViewModel & IAtaMoveToTrashViewModel) => {
        setContactAtas((prev) =>
          Object.entries(prev).reduce(
            (acc, [key, atasContact]) => ({
              ...acc,
              [key]: atasContact.filter((a) => a.id !== id),
            }),
            {}
          )
        );
      }
    );

    const unsubscribeAtaRestore = subscribe(
      'ATA_RESTORED',
      ({
        companyId,
        contactId,
        ...restoredAta
      }: IAtaRestoreViewModel & IAtaListViewModel) => {
        const contact = allContacts.find((c) =>
          contactId ? c.id === contactId : c.companyId === companyId
        );

        if (contact) {
          const idToUpdate = contact.companyId ?? contact.id;
          setContactAtas((prev) => ({
            ...prev,
            [idToUpdate]: [restoredAta, ...(prev[idToUpdate] || [])],
          }));
        }
      }
    );

    const unsubscribeAtaShare = subscribe(
      'NEW_CONTACT_ATA',
      ({
        contactId,
        companyId,
        ...addedAta
      }: { contactId: string; companyId?: string } & IAtaListViewModel) => {
        setContactAtas((prev) => ({
          ...prev,
          [companyId ?? contactId]: [
            addedAta,
            ...(prev[companyId ?? contactId] || []),
          ],
        }));
      }
    );

    return () => {
      unsubscribeAtaShare();
      unsubscribeAtaUpdate();
      unsubscribeAtaDelete();
      unsubscribeAtaLinkTo();
      unsubscribeAtaTrashed();
      unsubscribeAtaRestore();
    };
  }, [allContacts, contactAtas, subscribe, user?.id]);

  const handleAddContact = () => {
    setLoading(true);

    const isEmail = newContactCode.includes('@');
    ContactController.add({
      code: isEmail ? undefined : newContactCode,
      email: isEmail ? newContactCode : undefined,
    })
      .then(() => setNewContactCode(''))
      .catch(() => {
        appAlert.show({
          type: 'error',
          errors: ['Não foi possível vincular usuário'],
        });
      })
      .finally(() => setLoading(false));
  };

  const handleOnFilesChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    setLoading(true);
    const files = Array.from(e.target?.files!);

    if (!files || files?.length === 0) {
      appAlert.show({
        type: 'error',
        errors: ['É necessário selecionar um arquivo para continuar'],
      });
      setLoading(false);
      return;
    }

    const isPdf = mime.getExtension(files[0].type) === 'pdf';

    const promise = isPdf ? readTextPdf(files[0]) : readTextImage(files[0]);

    promise.then((text) => {
      setAta((prevState) => ({
        ...prevState,
        files: files.map((f, index) => ({
          file: Object.assign(f, {
            id: uuid(),
          }),
          text: index === 0 && !!text ? text : '',
        })),
      }));
      setLoading(false);
      navigate('/atas/register/ocr');
    });
  };

  const companyAtas = useMemo(
    () => (online ? atas : atasOffline.filter((a) => !a.trashed)),
    [atas, atasOffline, online]
  );

  useEffect(() => {
    if (profile === 'common' && contacts) {
      organizedContacts.forEach((o, index) => {
        if (o.contacts.length > 1) {
          setContactsOrganized((prev: any) => [...(prev || []), o]);
        } else {
          setContactsOrganized((prev: any) => [
            ...(prev || []),
            {
              office: linkedContact
                ? contacts[index].owner
                : ({} as IUserContact),
              contacts: o.contacts || [],
            },
          ]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizedContacts, profile, contacts]);

  useEffect(() => {
    getOfficeContacts();
  }, [getOfficeContacts]);

  useEffect(() => {
    if (offices.length > 0 || contactsOrganized) {
      setCardsViewType('colunm-atas-cards');
    } else {
      setCardsViewType('row-atas-cards');
    }
  }, [offices, contactsOrganized]);

  return (
    <Container>
      <Header showOptions />
      <h1>Atas</h1>
      <DragDropContext onDragEnd={() => {}}>
        <div className="content">
          {!atas.length &&
          !atasOffline.filter((a) => !a.trashed).length &&
          !contacts.length &&
          !offices.length ? (
            <div className="empty">
              <h2>
                {profile === 'common'
                  ? 'Você não possui Atas'
                  : user?.name ||
                    activeOffice?.name ||
                    ownerAccount?.user?.name ||
                    'Empresa'}
              </h2>
              <div className="empty-container">
                <div className="empty-title">
                  <p>
                    {profile === 'common'
                      ? 'Selecione uma das opções e crie uma nova ata'
                      : `${
                          profile !== 'office' ? 'Você não possui Atas.\n' : ''
                        }Adicione colaboradores${
                          profile !== 'office' ? ' ou cria sua Ata' : ''
                        }.`}
                  </p>
                  {profile !== 'common' && (
                    <Input
                      id="add-contact"
                      label="Chave"
                      placeholder="Insira a chave ou email para vincular"
                      variant="outlined"
                      size="small"
                      value={newContactCode}
                      onChange={(e) => setNewContactCode(e.target.value)}
                      endAdornment={
                        <IconButton onClick={handleAddContact} color="primary">
                          <Key />
                        </IconButton>
                      }
                    />
                  )}
                </div>
                {profile !== 'office' && (
                  <>
                    <div className="empty-option">
                      <div>
                        <IconSearchPlus />
                        <p>
                          Busque um documento base para o preenchimento da Ata
                        </p>
                      </div>
                      <Button
                        shape="outline"
                        size="large"
                        type="file"
                        disabled={!online}
                        onFilesChange={handleOnFilesChange}
                      >
                        Começar
                      </Button>
                    </div>

                    <div className="empty-option">
                      <div>
                        <IconWriteAta />
                        <p>
                          Redija ou use o comando de voz para preencher a Ata
                        </p>
                      </div>
                      <Button
                        shape="outline"
                        size="large"
                        onClick={() => navigate('/atas/register')}
                      >
                        Começar
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              <div style={{ display: 'flex' }}>
                <div>
                  <h2 className="header-name">
                    {user?.name ||
                      activeOffice?.name ||
                      ownerAccount?.user?.name ||
                      'Empresa'}
                  </h2>
                  {profile !== 'office' && (
                    <div className={cardsViewType}>
                      {!companyAtas.length ? (
                        <p className="list-empty">Nenhuma ata encontrada...</p>
                      ) : (
                        companyAtas.map((item) => (
                          <div key={item.id} className="ata-card">
                            <Ata {...item} />
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
                <div id="contacts">
                  {profile === 'office' &&
                    ownerContacts.map((contact) => (
                      <ContactWithAtas
                        refId="contact"
                        key={contact.id}
                        {...contact}
                        trashed={false}
                        showNameFrom="user"
                        focus={
                          contact.id === state?.contactId ||
                          (contact.owner.id === state?.companyId &&
                            contact.user.id === user?.id)
                        }
                        atas={contactAtas[contact?.companyId ?? contact.id]}
                        onGetAtas={(atasRes) => {
                          setContactAtas((prev) => ({
                            ...prev,
                            [contact?.companyId ?? contact.id]: atasRes,
                          }));
                        }}
                        officeId={contacts[0].owner.id}
                      />
                    ))}
                </div>
                {profile === 'common' && (
                  <div ref={officesRef} className="offices">
                    {contactsOrganized?.map((o: any) => (
                      <div className="office" key={o.office.id}>
                        <div className="header">
                          <OfficeIcon />
                          <h2>{o.office.name}</h2>
                        </div>
                        <div className="office-atas">
                          {!o.contacts.length ? (
                            <p className="list-empty">
                              Este escritório não possui contatos...
                            </p>
                          ) : (
                            o.contacts.map((c: any) =>
                              c.user.id !== user?.id ? (
                                <ContactWithAtas
                                  refId={`office/${c.owner.id}`}
                                  {...c}
                                  focus={c.id === state?.contactId}
                                  atas={contactAtas[c.id]}
                                  trashed={false}
                                  officeId={c.owner.id}
                                  onGetAtas={(atasRes) => {
                                    setContactAtas((prev) => ({
                                      ...prev,
                                      [c.id]: atasRes,
                                    }));
                                  }}
                                />
                              ) : (
                                <ContactWithAtas
                                  refId={`office/${c.owner.id}`}
                                  {...c}
                                  focus={c.id === state?.contactId}
                                  atas={contactAtas[c.owner.id]}
                                  trashed={false}
                                  officeId={o.office.id}
                                  onGetAtas={(atasRes) => {
                                    setContactAtas((prev) => ({
                                      ...prev,
                                      [c.id]: atasRes,
                                    }));
                                  }}
                                />
                              )
                            )
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {profile === 'company' && (
                  <div ref={officesRef} className="offices">
                    {offices?.map((office) => (
                      <div className="office" key={office.id}>
                        <div className="header">
                          <OfficeIcon />
                          <h2>{office.name}</h2>
                        </div>
                        <div className="office-atas">
                          {!officeContacts[office.id]?.length ? (
                            <p className="list-empty">
                              Este escritório não possui contatos...
                            </p>
                          ) : (
                            officeContacts[office.id]?.map((c) => (
                              <ContactWithAtas
                                refId={`office/${office.id}`}
                                {...c}
                                focus={c.id === state?.contactId}
                                atas={contactAtas[c.id]}
                                trashed={false}
                                officeId={office.id}
                                onGetAtas={(atasRes) => {
                                  setContactAtas((prev) => ({
                                    ...prev,
                                    [c.id]: atasRes,
                                  }));
                                }}
                              />
                            ))
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </DragDropContext>
    </Container>
  );
};

export default Atas;
