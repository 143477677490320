import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  min-height: 200px;

  .footer-content {
    height: 150px;
    display: flex;
    padding: 30px 40px;
    justify-content: space-between;
    align-items: flex-start;
    color: ${({ theme }) => theme.colors.background};
    background-color: ${({ theme }) => theme.colors.primary};

    & > svg {
      max-width: 20vw;
      max-height: 75px;
    }

    .footer-info {
      display: flex;
      align-items: center;
      width: fit-content;

      svg {
        width: 50px;
        height: auto;
        margin-right: 15px;
      }

      strong {
        font-size: 26px;
      }

      p {
        font-size: 20px;
      }

      svg + strong {
        margin-right: 10px;
      }

      a {
        color: ${({ theme }) => theme.colors.background};
        text-decoration: none;

        &:hover,
        &:focus,
        &:active,
        &:visited {
          color: ${({ theme }) => theme.colors.background};
        }

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .all-rights {
    height: 50px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.background};

    p {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  @media (max-width: 720px) {
    .footer-content {
      & > svg {
        min-width: 150px;
      }

      .footer-info {
        svg {
          width: 25px;
          margin-right: 5px;
        }
        strong {
          font-size: 15px;
        }

        p {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 530px) {
    .footer-content {
      flex-direction: column;
    }
  }

  @media (max-width: 370px) {
    .footer-content {
      & > svg {
        min-width: 100px;
      }

      .footer-info {
        svg {
          width: 15px;
        }
        strong {
          font-size: 10px;
        }

        p {
          font-size: 8px;
        }
      }
    }

    .all-rights {
      p {
        font-size: 10px;
      }
    }
  }
`;
