import styled, { css } from 'styled-components';

export const Group = styled.div`
  padding: 16px;
  ${({ theme: { colors, shadows } }) => css`
    background-color: ${colors.background};
    box-shadow: ${shadows[2]};
    border: 0.5px solid ${colors.border};
  `};
  border-radius: 17px;
  margin: 16px 0;

  .icon-delete {
    height: 21px;
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const Line = styled.div`
  display: flex;
  gap: 0 16px;
`;

export const ContainerLawyers = styled.div`
  min-width: 50%;
`;

export const Lawyers = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .btn-add-attorney {
    margin-right: -10px;
  }
`;

export const ButtonAdd = styled.button`
  background-color: transparent;
  display: flex;
  //flex: 1;
  padding: 5px 15px;
  border-radius: 50px;
  font-family: 'Futura PT';
  font-size: 16px;
  font-weight: 100;
  color: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  transition: all linear 300ms;

  &:hover {
    filter: brightness(80%);
  }

  svg {
    margin-right: 8px;
  }
`;

export const ButtonPlus = styled.button`
  background-color: transparent;
  border: none;
  padding-top: 10px;
  display: flex;
  margin-left: auto;
  font-family: 'Futura PT';
  font-size: 16px;
  font-weight: 100;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  transition: all linear 300ms;

  &:hover {
    filter: brightness(80%);
  }

  svg {
    margin-right: 8px;
  }
`;

export const WrapperFiles = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 8px;
`;

export const ButtonAttachFile = styled.button`
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  font-family: 'Futura PT';
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  text-decoration: underline;
  width: max-content;
  margin-top: 16px;
  display: flex;
  align-items: center;
  transition: all linear 0.3s;

  svg {
    margin-right: 8px;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    transition: all linear 0.3s;
  }
`;

export const WrapperDeposition = styled.div`
  display: flex;
  flex-direction: column;

  .btn-delete-deposition {
    align-self: flex-end;
  }
`;

export const WrapperHearing = styled.div`
  .title {
    min-width: calc(100% - 250px);
    display: flex;
    align-items: center;

    h3 {
      margin-right: 10px;
      font-size: 28px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.accent};
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.accent};
  }
  .hearing-items {
    margin-left: 16px;
  }
`;

export const WrapperContradicted = styled.div`
  display: flex;
  align-items: center;

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-right: 16px;
    p {
      margin-left: 8px;
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
    }

    span,
    svg {
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }
`;
