const Cpf = (cpf_: any): boolean => {
  let Soma;
  let Resto;
  Soma = 0;

  const cpf = cpf_.replace(/\D/g, '');

  if (new Set(cpf).size === 1) return false;

  for (let i: number = 1; i <= 9; i++)
    Soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(cpf.substring(9, 10))) return false;

  Soma = 0;
  for (let i: number = 1; i <= 10; i++)
    Soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

const Email = (email: any) => {
  const reg = /^[\w.\\+]+@\w+.\w{2,}(?:.\w{2})?$/;
  return reg.test(email);
};

const Phone = (phone_: any) => {
  const phone = phone_.replace(/\D/g, '');
  return phone.length === 10 || phone.length === 11;
};

export default {
  Cpf,
  Email,
  Phone,
};
