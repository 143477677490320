import React, { createContext, useState, useContext } from 'react';
import useOnlineStatus from '../hooks/useOnlineStatus';
import { Loading, Offline } from '../components';
import LoadingAtaProcessing from '../components/LoadingAtaProcessing';

type AppContextProps = {
  loading: boolean;
  online: boolean;
  setLoading: (props: any) => void;
  processingAta: boolean;
  setProcessingAta: React.Dispatch<React.SetStateAction<boolean>>;
  showPhrases: boolean;
  setShowPhrases: React.Dispatch<React.SetStateAction<boolean>>;
};

const AppContext = createContext<AppContextProps>({} as AppContextProps);

const App: React.FC = ({ children }) => {
  const online = useOnlineStatus();
  const [loading, setLoading] = useState(false);
  const [processingAta, setProcessingAta] = useState(false);
  const [showPhrases, setShowPhrases] = useState(false);
  return (
    <AppContext.Provider
      value={{
        online,
        loading,
        setLoading,
        setShowPhrases,
        showPhrases,
        processingAta,
        setProcessingAta,
      }}
    >
      <Offline isOffline={!online} />
      {children}
      <Loading shown={loading} />
      <LoadingAtaProcessing
        shown={processingAta}
        showPhrases={showPhrases} phrases={
          [
            "Carregando recursos necessários, isso não deve demorar muito...",
            "Estamos trabalhando nos bastidores para trazer as informações que você precisa...",
            "Coletando dados importantes para apresentação...",
            "Estamos quase lá, obrigado pela paciência!",
            "Analisando dados, por favor, aguarde...",
          ]}
      />
    </AppContext.Provider>
  );
};

export const useApp: () => AppContextProps = () => useContext(AppContext);

export default App;
