import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 135px;
  ${({ theme: { colors } }) => css`
    background: ${colors.background};
    border: 0.5px dashed ${colors.text};
  `}

  border-radius: 17px;
  margin-top: 16px;
  padding: 16px 16px 0;
`;

export const TextDefault = styled.p`
  ${({ theme: { colors } }) => css`
    color: ${colors.text};
    margin-bottom: 16px;
  `}
`;

export const ListFiles = styled.div`
  width: 100%;
  padding-top: 16px;
  overflow-y: scroll;
`;
