import styled from 'styled-components';

interface IContainer {
  disabled: boolean;
  isClickable: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  width: 100%;
  max-height: 80px;
  padding: 10px 20px;
  border-radius: 20px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  background-color: ${({ theme: { colors }, disabled }) =>
    disabled ? colors.f1 : colors.background};
  border: 1px solid ${({ theme }) => `${theme.colors.text}11`};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  font-weight: 400;

  p {
    font-weight: 200;
    color: ${({ theme }) => `${theme.colors.text}AA`};
    font-size: 14px;
  }

  .ata-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;

    span {
      text-transform: capitalize;
    }
  }

  span {
    color: ${({ theme }) => theme.colors.text2};
    font-size: 16px;
  }

  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};

    &.MuiCircularProgress-svg {
      cursor: default;
    }
  }

  button {
    margin-right: -10px;
  }
`;

export const WrapperIconTrashed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: ${({ theme }) => theme.colors.error};
    font-weight: 100;
    font-size: 14px;
  }
`;
