import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  margin-right: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => `${theme.colors.text}88`};

  #arrow {
    width: 25px;
    height: 25px;
  }

  #office-summary {
    max-height: 66px;
    min-height: 66px;
  }

  .office-info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    #icon {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      fill: ${({ theme }) => theme.colors.primary};
    }

    h3 {
      font-size: 22px;
      white-space: nowrap;
      width: 200px;
      text-overflow: ellipsis;
      word-break: break-all;
      overflow: hidden;
      color: ${({ theme }) => theme.colors.primary};
      font-family: 'Futura Condensed PT' !important;
    }

    .code {
      width: 150px;
      margin: 0 10px 0 20px;
    }

    .manager {
      margin-right: auto !important;
      .MuiTextField-root {
        width: 250px;
      }

      .manager-container {
        display: flex;
        align-items: center;
        #name {
          margin-right: 10px;
          color: ${({ theme }) => theme.colors.text};

          p {
            font-weight: 200;
          }

          p:first-of-type {
            font-size: 10px;
            opacity: 0.5;
          }

          p:last-of-type {
            max-width: 150px;
            font-size: 18px;
            font-style: italic;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
            overflow: hidden;
          }
        }

        #edit-manager {
          padding: 5px 10px;
          p {
            font-weight: 200;
            text-decoration: underline;
            margin-right: 10px;
          }
        }
      }
    }

    &.main {
      h3 {
        display: none;
      }

      .code {
        margin-right: auto;
        margin-left: 10px;
      }

      .manager {
        margin-right: 0 !important;

        #edit-manager {
          display: none;
        }
      }
    }
  }

  .office-contacts {
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    border-top: 1px solid ${({ theme }) => `${theme.colors.text}33`};

    #inputs {
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      align-items: flex-end;
      justify-content: space-between;
      > div {
        width: 49% !important;
      }
    }

    #contacts {
      display: flex;
      justify-content: space-between;
      & > div {
        width: 49%;
      }

      #empty {
        color: ${({ theme }) => theme.colors.text};
      }
    }
  }
`;

export const OfficeManagerPopover = styled.div`
  padding: 10px 10px 5px 15px;

  p {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
    font-weight: 200;
  }

  #remove-manager {
    font-weight: 200;
    padding: 5px 10px;
    margin-left: -10px;
    text-decoration: underline;
  }
`;

export const DeleteAlert = styled.div`
  max-width: 400px;
  padding: 5px 15px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;

  .MuiFormGroup-root {
    width: 100%;
  }
  &,
  .MuiTypography-root {
    font-weight: 200 !important;
    color: ${({ theme }) => theme.colors.text} !important;
  }
  & > p {
    margin-bottom: 10px;
    cursor: default;
    span {
      color: ${({ theme }) => theme.colors.error};
    }
  }

  button {
    border-radius: 60px;
    padding: 0px 30px;
    margin-top: 10px;

    p {
      font-weight: 200;
      white-space: nowrap;
      text-transform: none !important;
    }
  }
`;
