import React, { useState } from 'react';
import Input from '../../Input';
import Button from '../../Button';
import { ContactForm } from './styles';
import { EmailController } from '../../../controllers';
import { useApp, useAlert } from '../../../context';
import Validators from '../../../utils/validators';
import Masks from '../../../utils/masks';

const ContactUs: React.FC = () => {
  const { online } = useApp();
  const appAlert = useAlert();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const schema = {
    name: !name,
    message: !message,
    email: !email || !Validators.Email(email),
    phone: !phone || !Validators.Phone(phone),
  };

  const validate = !Object.values(schema).some((v) => !!v);

  const handleSend = () => {
    if (isLoading) return;
    setIsSubmit(true);
    if (validate) {
      setIsLoading(true);

      EmailController.sendContactEmail({
        email: process.env.REACT_APP_CONTACT_EMAIL || '',
        subject: 'Novo contato - Atas Control',
        title: 'Novo contato - Atas Control',
        description: `${name} entrou em contato com a seguinte mensagem: ${message}`,
        titleCode: 'Contatos',
        code: `${Masks.Phone(phone)}<br/>${email}`,
      })
        .then(() => {
          appAlert.show({
            type: 'sucess',
            message: 'Mensagem enviada com sucesso!',
            timer: 2000,
          });

          setIsSubmit(false);
          setName('');
          setEmail('');
          setPhone('');
          setMessage('');
        })
        .catch(() => {
          appAlert.show({
            type: 'error',
            title: 'Falha ao enviar mensagem!',
            errors: ['Algo deu errado, tente novamente mais tarde.'],
          });
          setIsSubmit(false);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <ContactForm>
      <Input
        label="Nome"
        placeholder="Informe seu nome"
        value={name}
        error={isSubmit && schema.name}
        helperText={isSubmit && schema.name && 'Nome obrigatório'}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        label="E-mail"
        value={email}
        placeholder="Informe seu e-mail"
        error={isSubmit && schema.email}
        helperText={isSubmit && schema.email && 'E-mail inválido'}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        label="Telefone"
        value={Masks.Phone(phone)}
        mask="(99) 99999-9999"
        placeholder="Informe seu telefone"
        error={isSubmit && schema.phone}
        helperText={isSubmit && schema.phone && 'Telefone inválido'}
        onChange={(e) => setPhone(e.target.value)}
      />
      <Input
        id="msg"
        label="Mensagem"
        placeholder="Digite aqui sua mensagem"
        variant="outlined"
        value={message}
        error={isSubmit && schema.message}
        helperText={isSubmit && schema.message && 'Mensagem obrigatória'}
        onChange={(e) => setMessage(e.target.value)}
        multiline
      />
      <Button
        id="send-form"
        shape="outline"
        disabled={!online}
        onClick={handleSend}
        isLoading={isLoading}
      >
        ENVIAR
      </Button>
    </ContactForm>
  );
};

export default ContactUs;
