import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 80vw;
  height: 70vh;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon-close {
    ${({ theme: { colors } }) => css`
      color: ${colors.blue};
    `};
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  ${({ theme: { colors } }) => css`
    color: ${colors.primary};
  `};

  p {
    font-size: 20px;
    font-weight: 400;
    padding-left: 4px;
  }

  svg {
    width: 28px;
    height: 28px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  #search {
    width: 250px;
  }

  .loading-atas {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.text};

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }

  .empty-atas {
    color: ${({ theme }) => theme.colors.text};
  }

  .user-atas {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 145px;
    margin: 0 -8px;
    margin-top: 10px;
    justify-content: space-between;

    & > div {
      width: calc(100% / 4 - 8px);
    }
  }
  .contact-atas {
    margin-top: 10px;
    max-height: 42vh;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;

    #accordion-summary {
      color: ${({ theme }) => theme.colors.accent};
      &,
      &.Mui-expande {
        min-height: 35px;
        max-height: 35px;
        margin: 0;
        padding: 0;
      }

      svg {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
    .MuiAccordion-root {
      width: calc(75vw / 3 - 8px);
      box-shadow: none;
      margin: 0 8px 8px 0 !important;
    }

    .MuiAccordion-root.Mui-expanded {
      margin: 0 8px 8px 0 !important;
    }

    .MuiAccordion-root::before {
      background-color: transparent;
    }

    .MuiAccordion-region {
      overflow: scroll;
      max-height: 175px;
    }

    .Mui-expanded {
      margin: 0;
    }
  }
`;

export const ContainerAccordion = styled.div`
  width: calc(100% / 3 - 8px);
`;
