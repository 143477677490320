import { IAta } from 'entities';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IAtaListViewModel,
  IAtaMoveToTrashViewModel,
  IAtaRestoreViewModel,
} from 'viewModels';
import { Forms, Header } from '../../../components';
import {
  useActions,
  useAlert,
  useApp,
  useAta,
  useAuth,
} from '../../../context';
import { AtaController, AttachmentController } from '../../../controllers';
import useWebSocket from '../../../hooks/useWebSocket';
import { Container, Content } from './styles';

interface EditAtaProps {
  ataId: string;
}

const EditAta: React.FC = () => {
  const appAlert = useAlert();
  const { ata, setAta } = useAta();
  const navigate = useNavigate();
  const { subscribe } = useActions();
  const { tokenIsValid } = useAuth();
  const { setLoading, loading } = useApp();
  const { ataId } = useLocation().state as EditAtaProps;

  useEffect(() => {
    if (tokenIsValid && ataId !== ata.id) {
      setLoading(true);
      AtaController.getAllInfosById(ataId)
        .then(setAta)
        .catch(() => navigate(-1))
        .finally(() => setLoading(false));
    }
  }, [ata.id, ataId, navigate, setAta, setLoading, tokenIsValid]);

  useEffect(() => {
    const unsubscribeAtaUpdate = subscribe(
      'ATA_UPDATED',
      async (ataUpdated: IAtaListViewModel) => {
        if (ataId === ataUpdated.id) {
          const res = await appAlert.show({
            type: 'confirm',
            message: 'Deseja atualizar a página?',
            title: 'Ata alterada.',
          });

          if (res) {
            setLoading(true);
            AtaController.getAllInfosById(ataId)
              .then(setAta)
              .catch(() => navigate(-1))
              .finally(() => setLoading(false));
          }
        }
      }
    );

    const unsubscribeAtaDelete = subscribe(
      'ATA_DISABLED',
      async (ataDeletedId: string) => {
        if (ataId === ataDeletedId) {
          await appAlert.show({
            type: 'info',
            message: 'Esta ata foi excluída.',
          });

          navigate(-1);
        }
      }
    );

    const unsubscribeAtaTrashed = subscribe(
      'TRASHED_ATA',
      ({ id }: IAtaMoveToTrashViewModel) => {
        if (ataId === id) {
          setAta((prev) => ({ ...prev, trashed: true }));
        }
      }
    );

    const unsubscribeAtaRestore = subscribe(
      'ATA_RESTORED',
      ({ id }: IAtaRestoreViewModel) => {
        if (id === ataId) {
          setAta((prev) => ({ ...prev, trashed: false }));
        }
      }
    );

    return () => {
      unsubscribeAtaUpdate();
      unsubscribeAtaDelete();
      unsubscribeAtaTrashed();
      unsubscribeAtaRestore();
    };
  }, [subscribe, ataId, setAta, navigate, setLoading, appAlert]);

  useEffect(() => {
    if (ataId === ata.id && ata.trashed) {
      (async () => {
        await appAlert.show({
          type: 'info',
          message: 'Esta ata foi movida para a lixeira.',
        });

        setAta({} as IAta);
        navigate('/atas', { replace: true });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ata, navigate]);

  useWebSocket<string>({
    url: `attachment/${ataId}/attach-to-ata`,
    onMessage: async (attId) => {
      try {
        const att = await AttachmentController.getById(attId);
        setAta((prev) => ({
          ...prev,
          attachments: [
            ...(prev.attachments || []).filter((a) => a.id !== attId),
            att,
          ],
        }));
      } catch {
        //...
      }
    },
  });

  useWebSocket<string>({
    url: `attachment/${ataId}/detach`,
    onMessage: async (attId) => {
      try {
        setAta((prev) => ({
          ...prev,
          attachments: [
            ...(prev.attachments || []).filter((a) => a.id !== attId),
          ],
        }));
      } catch {
        //...
      }
    },
  });

  return (
    <Container id="container-register-ata-ocr">
      <Header showOptions />
      <h1>Editar Ata</h1>
      <Content>
        {!loading && (
          <>
            <Forms.RegisterAtaOcr />
            <Forms.RegisterAta isEdit />
          </>
        )}
      </Content>
    </Container>
  );
};

export default EditAta;
