import React from 'react';
import { CloudOff } from '@mui/icons-material';
import { Container } from './styles';

interface OfflineProps {
  isOffline: boolean;
}
const Offline: React.FC<OfflineProps> = ({ isOffline }) => {
  return (
    <Container isOffline={isOffline}>
      <CloudOff />
      <strong>Você está trabalhando offline</strong>
      <p>
        Os dados serão salvos em cache e serão enviados quando você estiver
        conectado novamente.
      </p>
    </Container>
  );
};

export default Offline;
