import React from 'react';
import ActionsProvider, { useActions } from './actions';
import AppProvider, { useApp } from './app';
import ContactProvider, { useContact } from './contact';
import { AlertProvider, useAlert } from './alert';
import { AtaProvider, useAta } from './ata';
import { AuthProvider, useAuth } from './auth';
import NotificationProvider, { useNotification } from './notification';
import OfficeProvider, { useOffice } from './office';
import { AttachmentsProvider, useAttachments } from './attachments';
import { SignUpProvider, useUserRegister } from './signUp';
import { FormDirtyProvider } from './formDirty';

export {
  useActions,
  useApp,
  useAlert,
  useAta,
  useAuth,
  useNotification,
  useAttachments,
  useContact,
  useUserRegister,
  useOffice,
};

const GlobalContext: React.FC = ({ children }) => {
  return (
    <ActionsProvider>
      <AppProvider>
        <AlertProvider>
          <AuthProvider>
            <OfficeProvider>
              <ContactProvider>
                <AtaProvider>
                  <AttachmentsProvider>
                    <NotificationProvider>
                      <FormDirtyProvider>
                        <SignUpProvider>{children}</SignUpProvider>
                      </FormDirtyProvider>
                    </NotificationProvider>
                  </AttachmentsProvider>
                </AtaProvider>
              </ContactProvider>
            </OfficeProvider>
          </AuthProvider>
        </AlertProvider>
      </AppProvider>
    </ActionsProvider>
  );
};

export default GlobalContext;
