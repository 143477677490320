import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import ErrorBoundary from './components/ErrorBoundary';
import GlobalContext from './context';
import Routes from './pages/Routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import GlobalStyle from './styles';
import light from './styles/themes/light';
import reportWebVitals from './utils/reportWebVitals';

const theme = createTheme({
  palette: {
    primary: {
      main: light.colors.primary,
    },
  },
});

const App: React.FC = () => {
  return <Routes />;
};

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={light}>
          <GlobalStyle />
          <GlobalContext>
            <App />
          </GlobalContext>
        </ThemeProvider>
      </MuiThemeProvider>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

reportWebVitals();
