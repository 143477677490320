import React from 'react';
import Input, { InputProps } from '../Input';

interface InputCpfCnpjProps extends Omit<InputProps, 'onChange'> {
  type?: 'cpf' | 'cnpj';
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    mask: string
  ) => void;
}

const InputCpfCnpj: React.FC<InputCpfCnpjProps> = ({
  type = 'cpf',
  value: initialValue,
  onChange,
  ...props
}) => {
  const TYPES = {
    CPF: '999.999.999-999',
    CNPJ: '99.999.999/9999-99',
  };
  const clear = (value: string) => value && value.replace(/[^0-9]/g, '');
  const getMask = (value: string) => (value.length > 11 ? 'CNPJ' : 'CPF');

  const applyMask = (value: string, mask: string) => {
    let result = '';

    let inc = 0;
    Array.from(value).forEach((letter, index) => {
      if (!mask[index + inc].match(/[0-9]/)) {
        result += mask[index + inc];
        inc++;
      }
      result += letter;
    });

    return result;
  };

  const MAX_LENGTH = clear(TYPES.CNPJ).length;

  let value = clear(initialValue as string);

  if (value) {
    value = applyMask(value, TYPES[getMask(value)]);
  }

  const onLocalChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let val = clear(ev.target.value);
    const mask = getMask(val);

    const nextLength = val.length;

    if (nextLength > MAX_LENGTH) return;

    val = applyMask(val, TYPES[mask]);

    // eslint-disable-next-line no-param-reassign
    ev.target.value = val;

    onChange?.(ev, mask);
  };

  return (
    <Input {...props} type={type} value={value} onChange={onLocalChange} />
  );
};

export default InputCpfCnpj;
