// Componente para o Chat futuante de ajuda do Legal Control Help
import React, { useState, useEffect, useRef } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { ThreeDots } from 'react-loader-spinner';
import { InputAdornment, TextField, Tooltip } from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { IMessageAssistantModel } from 'entities';
import { useAuth } from '../../context';

import LegalHelpIcon from '../../assets/img/LegalHelpIcon.png';
import { Role } from '../../types/enums';

import ConversationController from '../../controllers/ConversationController';

import {
  ChatContainer,
  InputContainer,
  MessageContainer,
  Avatar,
  Button,
} from './styles';

export function FloatingChat() {
  const { user } = useAuth();
  const [messageHistory, setMessageHistory] = useState<
    IMessageAssistantModel[]
  >([]);
  const cardContentRef = useRef<HTMLDivElement>(null);
  const [showModalChat, setShowModalChat] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [userMessage, setUserMessage] = useState<string>('');
  const [typingInProgress, setTypingInProgress] = useState<boolean>(false);

  const localStorageKey = `assistantMessages_Atas_${user?.id}`;

  const saveMessagesToLocalStorage = (messages: IMessageAssistantModel[]) => {
    localStorage.setItem(localStorageKey, JSON.stringify(messages));
  };

  const loadMessagesFromLocalStorage = () => {
    const storedMessages = localStorage.getItem(localStorageKey);
    if (storedMessages) {
      return JSON.parse(storedMessages) as IMessageAssistantModel[];
    }
    return [];
  };

  const clearMessagesFromLocalStorage = () => {
    setIsTyping(false);
    setIsDisable(false);
    setMessageHistory([]);
    localStorage.removeItem(localStorageKey);
  };

  const handleSendMessage = async () => {
    if (!userMessage) return;
    setIsDisable(true);
    setUserMessage('');

    const userActualMessage: IMessageAssistantModel = {
      text: userMessage,
      userName: user?.name,
      userId: user?.id ?? '',
      createdAt: new Date(),
      role: Role.USER,
    };

    setMessageHistory((prevHistory) => [...prevHistory, userActualMessage]);
    setIsTyping(true);
    const assistantMessagePromise =
      ConversationController.sendAssistantMessage(userActualMessage);

    const assistantMessage: IMessageAssistantModel = {
      text: 'Houve um problema ao gerar sua resposta, por favor tente novamente mais tarde!',
      userName: user?.name,
      userId: user?.id ?? '',
      createdAt: new Date(),
      role: Role.SYSTEM,
    };

    const timeoutMilliseconds = 15 * 1000;
    const timeoutPromise = new Promise<IMessageAssistantModel>((resolve) => {
      setTimeout(() => {
        resolve(assistantMessage);
      }, timeoutMilliseconds);
    });

    Promise.race([assistantMessagePromise, timeoutPromise])
      .then((resolvedPromise) => {
        setMessageHistory((prevHistory) => [...prevHistory, resolvedPromise]);
        setIsTyping(false);
        setIsDisable(false);
      })
      .catch(() => {
        setIsTyping(false);
        setIsDisable(false);
        setMessageHistory((prevHistory) => [...prevHistory, assistantMessage]);
      });
  };

  const handleScroll = () => {
    if (cardContentRef.current) {
      cardContentRef.current.scrollTop = cardContentRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const storedMessages = loadMessagesFromLocalStorage();
    setMessageHistory(storedMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalChat]);

  useEffect(() => {
    if (messageHistory.length > 0) saveMessagesToLocalStorage(messageHistory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageHistory]);

  useEffect(() => {
    handleScroll();
  }, [showModalChat, typingInProgress, messageHistory]);

  return (
    <ChatContainer isOpen={showModalChat}>
      {!showModalChat && (
        <Button
          onClick={() => {
            setShowModalChat(true);
          }}
        >
          <ContactSupportOutlinedIcon className="iconChatButton" />
        </Button>
      )}
      <div className="containerHeaderChat">
        <div className="avatarContainer">
          <Avatar src={LegalHelpIcon} />
        </div>
        <div className="titleChat">
          <h3>Legal Control Help</h3>
        </div>
        <Tooltip title="Limpar conversa">
          <DeleteForeverTwoToneIcon
            className="deleteButton"
            onClick={clearMessagesFromLocalStorage}
          />
        </Tooltip>
        <CloseIcon
          className="closeButton"
          onClick={() => setShowModalChat(false)}
        />
      </div>

      <MessageContainer ref={cardContentRef}>
        {messageHistory.length === 0 ? (
          <div className="emptyContent">
            <Avatar src={LegalHelpIcon} alt="Logo" className="emptyAvatar" />
            <h2 className="emptyTitle">Legal Control Help</h2>
            <p className="emptySubTitle">Chat de ajuda da Legal Control</p>
          </div>
        ) : (
          messageHistory.map((message, index) => (
            <React.Fragment key={message.createdAt.toString()}>
              {index === 0 ||
              new Date(message.createdAt).toDateString() !==
                new Date(messageHistory[index - 1].createdAt).toDateString() ? (
                <div className="dayMarker">
                  {new Date(message.createdAt).toLocaleDateString('pt-BR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </div>
              ) : null}
              {message.role === Role.USER ? (
                <>
                  <span className="userTitle">Você </span>
                  <div className="userMessage">
                    <div className="messageText">
                      <span className="messageText">{message.text} </span>
                      <span className="timestamp">
                        {new Date(message?.createdAt).toLocaleString('pt-BR', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <span className="assistantTitle">Legal Help </span>
                  <div className="assistantMessage">
                    <div className="messageText">
                      <span className="messageText">
                        {index === messageHistory.length - 1 ? (
                          <TypeAnimation
                            sequence={[
                              () => {
                                setTypingInProgress(true);
                              },
                              message.text ? message.text : '',
                              () => {
                                setTypingInProgress(false);
                              },
                            ]}
                            speed={90}
                            cursor={false}
                          />
                        ) : (
                          message.text
                        )}
                      </span>
                      <span className="timestamp">
                        {new Date(message?.createdAt).toLocaleString('pt-BR', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </React.Fragment>
          ))
        )}
        {isTyping && (
          <>
            <span className="assistantTitle">Legal Help </span>
            <div className="assistantMessage">
              <ThreeDots height="30" width="30" color="#1d6fcc" />
            </div>
          </>
        )}
      </MessageContainer>
      <InputContainer>
        <TextField
          disabled={isDisable}
          autoComplete="off"
          placeholder="Digite sua dúvida"
          variant="outlined"
          fullWidth
          value={userMessage}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setUserMessage(e.target.value)
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSendMessage();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SendIcon
                  onClick={handleSendMessage}
                  style={{ cursor: 'pointer' }}
                />
              </InputAdornment>
            ),
          }}
        />
      </InputContainer>
    </ChatContainer>
  );
}
