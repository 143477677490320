import {
  Cancel,
  DoneAll,
  InsertDriveFile,
  Notifications as NotificationsIcon,
  Person,
} from '@mui/icons-material';
import { CircularProgress, IconButton, Menu } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useMemo, useState } from 'react';
import { FaBuilding } from 'react-icons/fa';
import { ReactComponent as IconPaperClip } from '../../assets/svg/icon-paper-clip.svg';
import { useApp, useAuth, useNotification } from '../../context';
import { NotificationController } from '../../controllers';
import { NotificationType } from '../../types/enums';
import {
  NotificationsList,
  NotificationsStyle,
  NotificationStyle,
} from './styles';

const Notifications: React.FC = () => {
  const { activeOffice, user } = useAuth();
  const { online } = useApp();
  const [loadingReadAll, setLoadingReadAll] = useState(false);
  const [loadingReadType, setLoadingReadTypeArray] = useState<
    NotificationType[]
  >([]);
  const { notifications } = useNotification();

  const setLoadingReadType = (type: NotificationType) =>
    setLoadingReadTypeArray((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
    );

  const icons = [
    {
      icon: <Person />,
      types: [
        NotificationType.ADD_CONTACT,
        NotificationType.REMOVE_CONTACT,
        NotificationType.CHANGE_CONTACT_STATUS,
        NotificationType.CHANGE_CONTACT_PERMISSIONS,
        NotificationType.PENDING_CHANGE_CONTACT_STATUS,
      ],
    },
    {
      icon: <FaBuilding />,
      types: [
        NotificationType.ADD_OFFICE,
        NotificationType.REMOVE_OFFICE,
        NotificationType.ADD_OFFICE_MANAGER,
        NotificationType.CHANGE_OFFICE_MANAGER,
        NotificationType.REMOVE_OFFICE_MANAGER,
        NotificationType.PENDING_ADD_OFFICE,
        NotificationType.PENDING_ADD_OFFICE_MANAGER,
      ],
    },
    {
      icon: <InsertDriveFile />,
      types: [
        NotificationType.ADD_ATA,
        NotificationType.CHANGE_ATA,
        NotificationType.REMOVE_ATA,
        NotificationType.SHARE_ATA,
        NotificationType.TRASHED_ATA,
        NotificationType.RESTORE_ATA,
        NotificationType.DUPLICATE_ATA,
        NotificationType.LINK_ATA_TO_USER,
      ],
    },
    {
      icon: <IconPaperClip />,
      types: [
        NotificationType.ADD_ATTACHMENT,
        NotificationType.REMOVE_ATTACHMENT,
        NotificationType.CHANGE_ATTACHMENT,
        NotificationType.LINK_ATTACHMENT,
        NotificationType.PENDING_LINK_ATTACHMENT,
        NotificationType.REMOVE_LINK_ATTACHMENT,
      ],
    },
  ];

  const handleReadAll = () => {
    if (!online || loadingReadAll) return;
    setLoadingReadAll(true);
    NotificationController.markAllAsRead(user?.id || activeOffice?.id).finally(
      () => setLoadingReadAll(false)
    );
  };

  const handleRead = (type: NotificationType) => {
    setLoadingReadType(type);

    NotificationController.markAsReadByType(
      user?.id || activeOffice?.id,
      type
    ).finally(() => setLoadingReadType(type));
  };

  const notificationCounter = useMemo(
    () => notifications.reduce((acc, curr) => acc + curr.count, 0),
    [notifications]
  );

  return (
    <PopupState variant="popover" popupId="popup-menu">
      {(popupState) => (
        <NotificationsStyle>
          <NotificationsIcon
            id={`icon${notificationCounter === 0 ? ' disabled' : ''}`}
            {...bindTrigger(popupState)}
          />
          {notifications.length > 0 && (
            <>
              <span id="counter">
                {notificationCounter > 99 ? '99+' : notificationCounter}
              </span>
              <Menu {...bindMenu(popupState)}>
                <NotificationsList>
                  <div className="list-header">
                    <IconButton
                      color="primary"
                      onClick={handleReadAll}
                      disabled={!online || loadingReadAll}
                    >
                      {loadingReadAll ? (
                        <CircularProgress size={25} />
                      ) : (
                        <DoneAll />
                      )}
                    </IconButton>
                  </div>
                  <div id="items">
                    {notifications
                      .filter((notification) => notification.count > 0)
                      .map((notification) => (
                        <NotificationStyle key={notification.title}>
                          {
                            icons.find((icon) =>
                              icon.types.includes(notification.type)
                            )?.icon
                          }
                          <p>
                            <strong>{notification.count}</strong>{' '}
                            {notification.title || 'notificações'}
                          </p>
                          {online && (
                            <IconButton
                              size="small"
                              onClick={() => handleRead(notification.type)}
                              disabled={
                                !online ||
                                loadingReadType.includes(notification.type)
                              }
                            >
                              {loadingReadType.includes(notification.type) ? (
                                <CircularProgress size={20} />
                              ) : (
                                <Cancel />
                              )}
                            </IconButton>
                          )}
                        </NotificationStyle>
                      ))}
                  </div>
                </NotificationsList>
              </Menu>
            </>
          )}
        </NotificationsStyle>
      )}
    </PopupState>
  );
};

export default Notifications;
