import { IContact, IUserContact } from 'entities';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ContactController } from '../controllers';
import useWebSocket from '../hooks/useWebSocket';
import { ContactStatus } from '../types/enums';
import { useActions, useApp, useAuth, useOffice } from './index';

interface IOrganizeContact {
  office: IUserContact;
  contacts: IContact[];
}

type ContactContextProps = {
  contacts: IContact[];
  ownerContacts: IContact[];
  organizedContacts: IOrganizeContact[];
  officeContacts: { [key: string]: IContact[] };
  getContactName: (
    contact: IContact,
    showNameFrom?: 'user' | 'owner'
  ) => string;
  getContactFullName: (
    contact: IContact,
    officeId?: string
  ) => [string, string | undefined];
  linkedContact: IContact[] | undefined;
  getOfficeContacts: () => void;
};

const ContactContext = createContext<ContactContextProps>(
  {} as ContactContextProps
);

const Contact: React.FC = ({ children }) => {
  const { user, profile, activeOffice, tokenIsValid } = useAuth();
  const { subscribe, dispatch } = useActions();
  const { offices, managedOffices } = useOffice();
  const { setLoading } = useApp();
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [ownerContacts, setOwnerContacts] = useState<IContact[]>([]);
  const [officeContacts, setOfficeContacts] = useState<{
    [key: string]: IContact[];
  }>({} as { [key: string]: IContact[] });
  const [organizedContacts, setOrganizedContacts] = useState<
    IOrganizeContact[]
  >([]);

  const linkedContact = useMemo(() => {
    return [...(contacts || [])];
  }, [contacts]);

  const getContactName = (
    { isAmOwner, user: contactUser, owner, ...c }: IContact,
    showNameFrom?: 'user' | 'owner'
  ) =>
    ((showNameFrom === 'user' && c.companyId === undefined) ||
    isAmOwner ||
    (!showNameFrom &&
      profile === 'common' &&
      owner?.profileSlug !== 'common' &&
      contactUser.id !== user?.id)
      ? `${contactUser?.name}   - Escritório: ${owner?.name}`
      : owner?.name) || 'Usuário sem nome';

  const getContactFullName = (contact: IContact) => {
    const firstName = contact?.companyId
      ? contact?.owner?.name
      : contact?.user?.name;

    const lastName =
      contact?.companyId ||
      contact?.owner?.id === user?.id ||
      contact?.owner?.id === activeOffice?.id
        ? undefined
        : contact?.owner?.name ?? 'Proprietário desconhecido';

    const fullName: [string, string | undefined] = [firstName, lastName];

    return fullName;
  };

  const getContacts = useCallback(() => {
    if (tokenIsValid) {
      setLoading(true);
      ContactController.get(user?.id || activeOffice?.id)
        .then(setContacts)
        .finally(() => setLoading(false));
    }
  }, [activeOffice?.id, setLoading, tokenIsValid, user?.id]);

  const getOfficeContacts = useCallback(() => {
    if (tokenIsValid) {
      setLoading(true);
      const promises = offices.map(({ id }) =>
        ContactController.get(id).then((officeContactsRes) => ({
          officeContactsRes,
          id,
        }))
      );

      Promise.all(promises)
        .then((res) => {
          const newOfficeContacts = res.reduce(
            (acc, { officeContactsRes, id }) => ({
              ...acc,
              [id]: officeContactsRes,
            }),
            {} as { [key: string]: IContact[] }
          );
          setOfficeContacts(newOfficeContacts);
        })
        .finally(() => setLoading(false));
    }
  }, [offices, setLoading, tokenIsValid]);

  const getOwnerContacts = useCallback(() => {
    if (profile === 'office' && activeOffice) {
      ContactController.getOwnerContacts(activeOffice.id).then(
        setOwnerContacts
      );
    } else if (profile === 'common' && linkedContact) {
      linkedContact.forEach((contact: IContact) => {
        ContactController.getOwnerContacts(contact.id).then((ownerC) => {
          setOwnerContacts((prev) => {
            return [...prev, ...ownerC];
          });
        });
      });
    } else setOwnerContacts([]);
  }, [activeOffice, linkedContact, profile]);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  useEffect(() => {
    getOfficeContacts();
  }, [getOfficeContacts]);

  useEffect(() => {
    getOwnerContacts();
  }, [getOwnerContacts]);

  useEffect(() => {
    if (tokenIsValid) {
      setLoading(true);
      const promises = managedOffices.map(({ id }) =>
        ContactController.get(id).then((officeContactsRes) => ({
          officeContactsRes,
          id,
        }))
      );

      Promise.all(promises)
        .then((res) => {
          const newOfficeContacts = res.reduce(
            (acc, { officeContactsRes, id }) => ({
              ...acc,
              [id]: officeContactsRes,
            }),
            {} as { [key: string]: IContact[] }
          );
          setOfficeContacts(newOfficeContacts);
        })
        .finally(() => setLoading(false));
    }
  }, [managedOffices, setLoading, tokenIsValid]);

  useEffect(() => {
    const unsubscribeDisableAccount = subscribe('DISABLE_ACCOUNT', (uid) => {
      setContacts((prev) => [
        ...(prev || []).filter(
          (c) =>
            c?.companyId === uid && !(c.owner.id === uid || c.user.id === uid)
        ),
      ]);

      setOfficeContacts((prev) =>
        Object.keys(prev).reduce(
          (acc, officeId) => ({
            ...acc,
            [officeId]: (prev[officeId] || []).filter(
              (c) =>
                c?.companyId === uid &&
                !(c.owner.id === uid || c.user.id === uid)
            ),
          }),
          {}
        )
      );

      setOwnerContacts((prev) => [
        ...(prev || []).filter(
          (c) =>
            c?.companyId === uid && !(c.owner.id === uid || c.user.id === uid)
        ),
      ]);
    });

    const unsubscribeRestoreAccount = subscribe('RESTORE_ACCOUNT', () => {
      getContacts();
      getOfficeContacts();
      getOwnerContacts();
    });

    return () => {
      unsubscribeDisableAccount();
      unsubscribeRestoreAccount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribe, getContacts, getOfficeContacts, getOwnerContacts]);

  useEffect(() => {
    if (profile === 'common' && linkedContact) {
      let ofcContacts: IContact[] = [];
      const officess: {
        office: IUserContact;
        contacts: IContact[];
      }[] = [];
      ownerContacts.forEach((contact) => {
        if (!contact.companyId) {
          ofcContacts.push(contact);
        } else {
          ofcContacts.push(contact);
          officess.push({
            office: ofcContacts[0].owner,
            contacts: ofcContacts,
          });
          ofcContacts = [] as IContact[];
        }
      });
      setOrganizedContacts(officess);
    }
  }, [ownerContacts, linkedContact, profile]);

  useEffect(() => {
    if (!tokenIsValid) {
      setContacts([]);
      setOwnerContacts([]);
      setOfficeContacts({});
      setOrganizedContacts([]);
    }
  }, [tokenIsValid]);

  useWebSocket<{ id: string }>({
    url: `contact/${user?.id || activeOffice?.id}`,
    onMessage: async ({ id }) => {
      try {
        const newContact = await ContactController.getById(id);
        if (
          newContact.user?.id === user?.id ||
          newContact.owner?.id === user?.id
        )
          setContacts((prev) => [
            ...(prev || []).filter((c) => c.id !== id),
            newContact,
          ]);
        else if (newContact.owner?.id !== undefined)
          setOfficeContacts((prev) => ({
            ...(prev || []),
            [newContact.owner?.id!]: [
              ...(prev[newContact.owner?.id!] || [])?.filter(
                (c) => c.id !== newContact.id
              ),
              newContact,
            ],
          }));
      } catch {
        //...
      }
    },
  });

  useWebSocket<string>({
    url: `contact/${user?.id || activeOffice?.id}/delete`,
    onMessage: async (id) => {
      try {
        setContacts((prev) =>
          (prev || []).filter((contact) => contact.id !== id)
        );
        setOwnerContacts((prev) =>
          (prev || []).filter((contact) => contact.id !== id)
        );
        setOfficeContacts((prev) =>
          Object.keys(prev).reduce(
            (acc, officeId) => ({
              ...acc,
              [officeId]: (prev[officeId] || []).filter(
                (contact) => contact.id !== id
              ),
            }),
            {}
          )
        );
      } catch {
        //...
      }
    },
  });

  useWebSocket<{ id: string; status: ContactStatus }>({
    url: `contact/${user?.id || activeOffice?.id}/update`,
    onMessage: async ({ id, status }) => {
      try {
        setContacts((prev) =>
          prev.map((contact) =>
            contact.id === id ? { ...contact, status } : contact
          )
        );
        setOfficeContacts((prev) =>
          Object.keys(prev).reduce(
            (acc, officeId) => ({
              ...acc,
              [officeId]: prev[officeId].map((contact) =>
                contact.id === id ? { ...contact, status } : contact
              ),
            }),
            {}
          )
        );

        setOwnerContacts((prev) =>
          prev.map((contact) =>
            contact.id === id ? { ...contact, status } : contact
          )
        );

        if (
          contacts.findIndex((c) => c.id === id) === -1 &&
          Object.values(officeContacts)
            .flat()
            .findIndex((c) => c.id === id) === -1 &&
          ownerContacts.findIndex((c) => c.id === id) === -1
        ) {
          const updated = await ContactController.getById(id);

          if (updated.user?.id === user?.id || updated.owner?.id === user?.id) {
            setContacts((prev) => [
              ...(prev || []).filter((c) => c.id !== id),
              updated,
            ]);
          } else {
            setOwnerContacts((prev) => [
              ...(prev || []).filter((c) => c.id !== id),
              updated,
            ]);
          }
        }
      } catch {
        //...
      }
    },
  });

  useWebSocket<{
    newOwnerId: string;
  }>({
    url: `contact/${user?.id || activeOffice?.id}/change-owner`,
    onMessage: ({ newOwnerId }) => {
      ContactController.get(user?.id || activeOffice?.id).then(setContacts);

      if (offices.find((c) => c.id === newOwnerId)) {
        ContactController.get(newOwnerId).then((newOfficeContacts) => {
          setOfficeContacts((prev) => ({
            ...prev,
            [newOwnerId]: newOfficeContacts,
          }));
        });
      }

      getOwnerContacts();

      dispatch('CONTACT_NEW_OWNER', { newOwnerId });
    },
  });

  return (
    <ContactContext.Provider
      value={{
        organizedContacts,
        contacts,
        linkedContact,
        ownerContacts,
        officeContacts,
        getContactName,
        getContactFullName,
        getOfficeContacts,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export const useContact: () => ContactContextProps = () =>
  useContext(ContactContext);

export default Contact;
