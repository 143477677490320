import { IconButton, ModalProps } from '@mui/material';
import mime from 'mime';
import { ElementType } from 'react';
import { FaTimesCircle } from 'react-icons/fa';

import { ReactComponent as IconExcel } from '../../../assets/svg/icon-excel.svg';
import { ReactComponent as IconImage } from '../../../assets/svg/icon-image.svg';
import { ReactComponent as IconPdf } from '../../../assets/svg/icon-pdf.svg';
import { ReactComponent as IconWord } from '../../../assets/svg/icon-word.svg';

import Button from '../../Button';
import Modal from '../Modal';
import { Attachment, Container, Footer, Title } from './styles';

interface IDataAttachment {
  icon?: ElementType;
  originalName: string;
  contentType: string;
  createdAt: Date;
}

interface Props extends Omit<ModalProps, 'children'> {
  close: () => void;
  data: IDataAttachment;
  onDelete: () => void;
}

const ModalDeleteAttachment = ({
  close,
  data: { icon: Icon, createdAt, originalName, contentType },
  onDelete,
  ...rest
}: Props) => {
  const extension = mime.getExtension(contentType);
  const getIcon = () => {
    if (extension === 'pdf') {
      return <IconPdf />;
    }
    if (extension === 'doc' || extension === 'docx') {
      return <IconWord />;
    }
    if (extension === 'csv' || extension === 'xls' || extension === 'xlsx') {
      return <IconExcel />;
    }
    if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
      return <IconImage />;
    }
    return null;
  };

  return (
    <Modal onClose={close} {...rest}>
      <Container>
        <IconButton onClick={close} className="btn-close">
          <FaTimesCircle size={20} className="icon-close" />
        </IconButton>
        <Title>Você tem certeza que quer excluir o arquivo?</Title>
        <Attachment>
          {Icon ? <Icon /> : getIcon()}
          <div className="content-attachment">
            <h1
              title={`Nome: ${`${originalName}.${extension}`}`}
            >{`${originalName}.${extension}`}</h1>
            <p>
              {new Intl.DateTimeFormat('pt-BR').format(new Date(createdAt))}
            </p>
          </div>
        </Attachment>
        <Footer>
          <Button shape="outline" onClick={close}>
            Voltar
          </Button>
          <div style={{ width: 16 }} />
          <Button shape="outline" color="red" onClick={onDelete}>
            Excluir
          </Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default ModalDeleteAttachment;
