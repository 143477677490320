import { ICodeEmailInputModel, IEmailInputModel } from 'inputModels';
import api from '../services/api/axios';

const sendContactEmail = async (email: IEmailInputModel) =>
  new Promise<void>((resolve, reject) => {
    api
      .post('/email/send-contact-email', email)
      .then(() => resolve())
      .catch((err) => reject(err));
  });

// const sendCode = async (email: IEmailInputModel) =>
//   new Promise<void>((resolve, reject) => {
//     api
//       .post('/user/send-email', email)
//       .then(() => resolve())
//       .catch((err) => reject(err));
//   });

export const sendCodeEmail = async ({
  email,
  subject,
  description,
  title,
  titleCode,
}: ICodeEmailInputModel) =>
  new Promise<string>((resolve, reject) => {
    api
      .post('user/send-code-email', {
        email,
        subject,
        description,
        title,
        titleCode,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error: any) => reject(error));
  });

export const EmailController = { sendCodeEmail, sendContactEmail };
