import { useRef, MutableRefObject, useCallback } from 'react';

export default function useStateSync<T>(initialValue: T) {
  const stateRef = useRef<T>(initialValue);

  const set = useCallback((newState: T) => {
    stateRef.current = newState;
  }, []);

  const res: [MutableRefObject<T>, (newState: T) => void] = [stateRef, set];
  return res;
}
