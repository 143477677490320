import { IUserAuth } from 'entities';
import api from '../services/api/axios';

const create = (refreshToken: string) =>
  new Promise<IUserAuth>((resolve, reject) => {
    api
      .post('user/refresh-token', JSON.stringify(refreshToken), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err?.response?.data));
  });

export const RefreshTokenController = { create };
