import { IAta, IAttachment, IClaimed, IComplainant, IHearing } from 'entities';
import { IFile } from 'utils';
import BaseModel from './BaseModel';

export default class Ata extends BaseModel implements IAta {
  id!: string;

  userId!: string;

  nickName?: string;

  //sharedBy?: string;

  //isShared?: boolean;

  createdAt!: string;

  linkedToId?: string;

  trashed?: boolean;

  //isFavorite?: boolean;

  claimed!: IClaimed[];

  hearingJudge!: string;

  hearings!: IHearing[];

  processNumber!: string;

  attachmentsId?: string[];

  isNew?: true | undefined;

  claimants!: IComplainant[];

  attachments?: IAttachment[];

  hearingsLocations!: string[];

  //shares!: IShareListViewModel[];

  //duplications!: IAtaDuplicationViewModel[];

  files?: { text?: string; file: IFile }[];
}
