import React, { useCallback, useEffect, useState } from 'react';

type Awaited<T> = T extends null | undefined
  ? T
  : T extends object & {
      then(onfulfilled: infer F): any;
    }
  ? F extends (value: infer V, ...args: any) => any
    ? Awaited<V>
    : never
  : T;

type ParamsType<T extends (params: any) => ReturnType<T>> = Parameters<T>[0];

type Params = {
  page: number;
  take: number;
};

interface Props<T extends (params: ParamsType<T> & Params) => ReturnType<T>> {
  query: T;
  onError?: (error: any) => void;
  ref: React.RefObject<HTMLElement>;
  onReFetch?: () => void;
  onFetch?: (data: Awaited<ReturnType<T>>) => void;
  params: Omit<ParamsType<T>, keyof Params>;
  initialData?: Awaited<ReturnType<T>>;
  take?: number;
  minTake?: number;
  enabled?: boolean;
  delay?: number;
}

const useInfiniteScroll = <
  T extends (params: ParamsType<T> & Params) => ReturnType<T>
>({
  ref,
  query,
  params,
  onFetch,
  onError,
  onReFetch,
  take = 10,
  delay = 0,
  initialData,
  minTake = 10,
  enabled = true,
}: Props<T>) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);

  const [page, setPage] = React.useState<number>(
    Math.floor(((initialData ?? []) as []).length / (take + minTake ?? 1)) + 1
  );
  const [data, setData] = React.useState<Awaited<ReturnType<T>> | null>(null);
  const [error, setError] = React.useState<any>(null);

  const [currentParams, setCurrentParams] = useState(params);

  const fetchData = useCallback(async () => {
    if (!hasMore || isFetching || !enabled || !take) return;

    setIsFetching(true);
    try {
      const response = (await query({
        ...currentParams,
        page,
        take: take + minTake,
      } as ParamsType<T> & Params)) as Awaited<ReturnType<T>>;
      setData(response);
      onFetch?.(response);
      setIsFetching(false);
      setHasMore(JSON.stringify(response).length > 2);
    } catch (err) {
      setError(err);
      onError?.(err);
      setIsFetching(false);
    }
  }, [
    hasMore,
    page,
    currentParams,
    isFetching,
    take,
    enabled,
    query,
    minTake,
    onFetch,
    onError,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData();
    }, delay);
    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentParams, take, delay]);

  const invalidate = useCallback(() => {
    setCurrentParams(params);
    setPage(1);
    setData(null);
    setError(null);
    setIsFetching(false);
    setHasMore(true);
    onReFetch?.();
  }, [onReFetch, params]);

  useEffect(() => {
    if (JSON.stringify(currentParams) !== JSON.stringify(params)) invalidate();
  }, [currentParams, onReFetch, params, invalidate]);

  useEffect(() => {
    const current = ref?.current;
    const handleScroll = () => {
      if (current) {
        const scrollPercent =
          (current.scrollTop / (current.scrollHeight - current.offsetHeight)) *
          100;

        if (!isFetching && hasMore && scrollPercent > 99 && enabled) {
          setPage((prev) => prev + 1);
        }
      }
    };
    current?.addEventListener('scroll', handleScroll);

    return () => {
      current?.removeEventListener('scroll', handleScroll);
    };
  }, [enabled, hasMore, isFetching, ref]);

  return { isFetching, hasMore, data, error, invalidate };
};

export default useInfiniteScroll;
