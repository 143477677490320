import { Audiotrack } from '@mui/icons-material';
import { Checkbox, IconButton } from '@mui/material';
import { IAttachment } from 'entities';
import mime from 'mime';
import React, { ElementType, useState } from 'react';
import { FaEye, FaTrashAlt } from 'react-icons/fa';
import { IFile } from 'utils';
import { useAlert, useApp, useAta, useAttachments } from '../../context';
import { AttachmentController } from '../../controllers';
import ModalDeleteAttachment from '../Modals/ModalDeleteAttachment';
import { Container, Filename, Line } from './styles';

import { ReactComponent as IconExcel } from '../../assets/svg/icon-excel.svg';
import { ReactComponent as IconImage } from '../../assets/svg/icon-image.svg';
import { ReactComponent as IconPdf } from '../../assets/svg/icon-pdf.svg';
import { ReactComponent as IconWord } from '../../assets/svg/icon-word.svg';

interface Props {
  att?: IAttachment;
  file?: IFile;
  //filename: string;
  icon?: ElementType;
  //contentType: string;
  onDelete?: () => void;
  onChecked?: (checked: boolean) => void;
  isFirstElement?: boolean;
  selectedFilesIds?: string[];
}

const ItemFile = ({
  //filename,
  icon: Icon,
  onDelete,
  //contentType,
  onChecked,
  isFirstElement = false,
  selectedFilesIds,
  file,
  att,
}: Props) => {
  const { ata, setAta } = useAta();
  const appAlert = useAlert();
  const { online } = useApp();
  const [isChecked, setIsChecked] = useState(isFirstElement || false);
  const extension = mime.getExtension(att?.contentType || file?.type || '');
  const [displayModalDeleteAttachment, setDisplayModalDeleteAttachment] =
    useState(false);

  const { setCurrentAttachment, download, currentAttachment } =
    useAttachments();

  const getIcon = () => {
    if (extension === 'pdf') {
      return <IconPdf />;
    }
    if (extension === 'doc' || extension === 'docx') {
      return <IconWord />;
    }
    if (extension === 'csv' || extension === 'xls' || extension === 'xlsx') {
      return <IconExcel />;
    }
    if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
      return <IconImage />;
    }
    if (
      extension === 'mp3' ||
      extension === 'wav' ||
      extension === 'ogg' ||
      extension === 'aac' ||
      extension === 'flac' ||
      extension === 'wma' ||
      extension === 'mpga'
    ) {
      return <Audiotrack color="info" />;
    }
    return null;
  };
  const filenameWithoutExtension = (
    att?.originalName ||
    file?.name ||
    ''
  ).substring(
    0,
    (att?.originalName || file?.name || '').includes('.')
      ? (att?.originalName || file?.name || '').lastIndexOf('.')
      : (att?.originalName || file?.name || '').length
  );

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (!checked && selectedFilesIds?.length === 1) {
      appAlert.show({
        type: 'info',
        message: 'É necessário ter no mínimo um arquivo selecionado.',
      });
      return;
    }
    setIsChecked(checked);
    onChecked!(checked);
  };

  const onDetach = () => {
    setDisplayModalDeleteAttachment(false);
    setAta((prevState) => ({
      ...prevState,
      attachments: prevState.attachments?.filter(
        (attachment) => attachment.id !== att?.id
      ),
      attachmentsId: prevState.attachmentsId?.filter(
        (attachmentId) => attachmentId !== att?.id
      ),
    }));
    onDelete?.();
  };

  const handleDeleteAttachment = async () => {
    if (!ata.id) {
      onDetach();
      return;
    }
    AttachmentController.detach({
      attachmentId: att?.id || '',
      ataId: ata.id,
    })
      .then(onDetach)
      .catch(() => {
        appAlert.show({
          type: 'error',
          errors: [
            'Não foi possível remover o arquivo. Tente novamente mais tarde.',
          ],
        });
      });
  };

  return (
    <Container>
      {onChecked && <Checkbox checked={isChecked} onChange={handleChecked} />}
      {Icon ? <Icon className="icon-file" /> : getIcon()}
      <Filename>{filenameWithoutExtension}</Filename>
      <Line />
      <IconButton
        disabled={!online}
        onClick={(e) => {
          e.stopPropagation();
          if (att) {
            setCurrentAttachment(att);
            download(false);
          } else if (file) window.open(URL.createObjectURL(file));
        }}
      >
        <FaEye className="icon-view" />
      </IconButton>
      <IconButton
        disabled={!online}
        onClick={(e) => {
          e.stopPropagation();
          if (att) {
            setCurrentAttachment(att);
            setDisplayModalDeleteAttachment(true);
          } else onDelete?.();
        }}
      >
        <FaTrashAlt className="icon-delete" />
      </IconButton>
      {displayModalDeleteAttachment &&
        Object.keys(currentAttachment.current).length > 0 && (
          <ModalDeleteAttachment
            data={{
              createdAt: currentAttachment.current.createdAt,
              contentType: currentAttachment.current.contentType,
              originalName: currentAttachment.current.originalName,
            }}
            open={displayModalDeleteAttachment}
            close={() => setDisplayModalDeleteAttachment(false)}
            onDelete={() => handleDeleteAttachment()}
          />
        )}
    </Container>
  );
};

export default ItemFile;
