import styled from 'styled-components';

export const Container = styled.div`
  max-width: 250px;
  .btn-close {
    color: ${({ theme }) => theme.colors.blue};
    position: absolute;
    top: 5px;
    right: 5px;
  }

  strong {
    color: ${({ theme }) => theme.colors.accent};
    font-size: 22px;
    font-weight: 400;
  }

  .actions {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      min-width: 49%;
      max-width: 49%;
    }
  }
`;
