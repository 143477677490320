import {
  AccountCircle,
  KeyboardArrowDown,
  Logout,
  SyncAlt,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { FaCogs } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { PopupProps } from 'utils';
import { ReactComponent as Logo } from '../../assets/svg/logo-color.svg';
import { useActions, useAlert, useAuth, useOffice } from '../../context';
import { OfficeController } from '../../controllers';
import Notifications from '../Notifications';
import Popover from '../Popover';
import { HeaderStyle, UserMenuPopup } from './styles';
import { useFormDirty } from '../../context/formDirty';

interface HeaderProps {
  children?: React.ReactNode;
  showOptions?: boolean;
}

const Header: React.FC<HeaderProps> = ({ children, showOptions }) => {
  const { subscribe } = useActions();
  const appAlert = useAlert();
  const { managedOffices } = useOffice();
  const {
    tokenIsValid,
    logout,
    user,
    changeAccount,
    ownerAccount,
    activeOffice,
    profile,
  } = useAuth();
  const navigate = useNavigate();
  const { isFormDirty, setIsFormDirty } = useFormDirty();
  const [leave, setLeave] = useState('');
  const [open, setOpen] = useState(false);

  const handleNavigation = (event: any, path: string) => {
    event.preventDefault();
    if (isFormDirty) {
      if (
        window.confirm('Você tem mudanças não salvas. Deseja sair mesmo assim?')
      ) {
        setIsFormDirty(false);
        navigate(path);
      }
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    const unsubscribeDisableAccount = subscribe('DISABLE_ACCOUNT', (uid) => {
      if (
        uid !== user?.id &&
        (ownerAccount === undefined || ownerAccount?.user?.id !== uid)
      )
        return;

      logout();
      navigate('/');

      appAlert.show({
        type: 'info',
        title: 'Conta desativada',
        message:
          'A conta logada neste dispositivo foi desativada.\nVocê foi redirecionado para a tela inicial.',
      });
    });

    return () => unsubscribeDisableAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribe]);

  const menuOptions = [
    {
      label: 'Atas',
      path: '/atas',
      enabled: true,
      options: [
        {
          label: 'Nova Ata',
          path: '/atas/new',
          enabled: profile !== 'office',
        },
        {
          label: 'Ver todas as atas',
          path: '/atas',
          enabled: true,
        },
        {
          label: 'Listagem de atas',
          path: '/atas/list',
          enabled: true,
        },
        {
          label: 'Lixeira',
          path: '/atas/trash',
          enabled: true,
        },
      ],
    },
    {
      label: 'Anexos',
      path: '/attachments',
      enabled: profile !== 'office',
    },
    {
      label: 'Administrativo',
      enabled: profile !== 'common',
      path: profile === 'office' ? '/administrative/office' : '/administrative',
    },
    {
      label: 'Relatório',
      path: '/dossier',
      enabled: true,
    },
  ];

  const UserMenu = ({ isOpen }: PopupProps) => {
    useEffect(() => {
      setOpen(isOpen);
    }, [isOpen]);

    const handleChangeAccount = (event: any, activeId?: string) => {
      event.preventDefault();
      if (
        isFormDirty &&
        !window.confirm(
          'Você tem mudanças não salvas. Deseja sair mesmo assim?'
        )
      ) {
        return;
      }
      setIsFormDirty(false);
      if (activeId) {
        OfficeController.login(activeId).then((authData) => {
          changeAccount(authData);
          navigate('/administrative/office');
        });
      } else {
        changeAccount(undefined);
      }
    };

    const handleLogout = (event: any) => {
      event.preventDefault();
      if (
        isFormDirty &&
        !window.confirm(
          'Você tem mudanças não salvas. Deseja sair mesmo assim?'
        )
      ) {
        return;
      }
      setIsFormDirty(false);
      logout();
      navigate('/');
    };

    return (
      <UserMenuPopup>
        <div id="offices-list">
          {activeOffice && (
            <Link
              to="/dashboard"
              onClick={(e) => handleChangeAccount(e, undefined)}
            >
              <SyncAlt />
              <p>{user?.name || ownerAccount?.user?.name || 'Sua conta'}</p>
            </Link>
          )}
          {managedOffices
            .filter((office) => activeOffice?.id !== office.id)
            .map(({ id, name }) => (
              <Link key={id} to="." onClick={(e) => handleChangeAccount(e, id)}>
                <SyncAlt />
                <p>{name}</p>
              </Link>
            ))}
        </div>
        <div id={managedOffices.length ? 'with-divider' : ''}>
          <Link to="/profile" onClick={(e) => handleNavigation(e, '/profile')}>
            <FaCogs />
            <p>Conta</p>
          </Link>
          <button type="button" onClick={(e) => handleLogout(e)}>
            <Logout />
            <p>Sair</p>
          </button>
        </div>
      </UserMenuPopup>
    );
  };

  return (
    <HeaderStyle>
      <Link
        to={tokenIsValid ? '/dashboard' : '/'}
        onClick={(e) => handleNavigation(e, tokenIsValid ? '/dashboard' : '/')}
      >
        <Logo className="logo" />
      </Link>

      <>
        {showOptions &&
          (children || (
            <>
              <nav className="nav-bar">
                <ul>
                  {menuOptions
                    .filter((option) => option.enabled)
                    .map(({ label, path, options = [] }) => (
                      <li
                        key={label}
                        onMouseLeave={() => setLeave(path)}
                        onMouseEnter={() =>
                          setTimeout(() => {
                            setLeave('');
                          }, 300)
                        }
                      >
                        <Link
                          to={path}
                          onClick={(e) => handleNavigation(e, path)}
                        >
                          {label}
                        </Link>
                        {options.length > 0 && (
                          <ul
                            id={leave === path ? 'leave' : ''}
                            className="sub-menu"
                          >
                            {options
                              .filter((option) => option.enabled)
                              .map((sub) => (
                                <li key={sub.label}>
                                  <Link
                                    to={sub.path}
                                    onClick={(e) =>
                                      handleNavigation(e, sub.path)
                                    }
                                  >
                                    {sub.label}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        )}
                      </li>
                    ))}
                </ul>
              </nav>
              <div className="actions">
                <Notifications />
                <div className="divider" />
                <Popover Element={UserMenu}>
                  <div className={`user-info${open ? ' open' : ''}`}>
                    <AccountCircle />

                    <span>
                      Olá,{' '}
                      {activeOffice?.name.split(' ')[0] ||
                        user?.name.split(' ')[0]}
                    </span>

                    <KeyboardArrowDown id="arrow" />
                  </div>
                </Popover>
              </div>
            </>
          ))}
      </>
    </HeaderStyle>
  );
};

export default Header;
