import { IAttachment } from 'entities';
import { IDeleteAttachment, InsertAttachment } from 'props';
import { IAtaListViewModel } from 'viewModels';
import api from '../services/api/axios';

const getById = (id: string) =>
  new Promise<IAttachment>((resolve, reject) => {
    api
      .get(`attachment`, { params: { id } })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const insert = ({
  formData,
  token,
  onProgress,
  cancelToken,
}: InsertAttachment) =>
  new Promise<{ id: string }>((resolve, reject) => {
    api
      .post('attachment/insert', formData, {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          onProgress(percentage);
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelToken.token,
      })
      .then(({ data }) => resolve(data.data))
      .catch(reject);
  });

const get = (params?: {
  page: number;
  take: number;
  filter: 'oldAttachments' | 'alphabeticalOrder' | 'notAttached' | undefined;
}) =>
  new Promise<IAttachment[]>((resolve, reject) => {
    api
      .get('user/get-attachments', { params })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const download = (id: string) => {
  return api.get(`attachment/download/${id}`, {
    responseType: 'blob',
  });
};

const remove = async ({ ...data }: IDeleteAttachment, token: string) => {
  await api.post(`attachment/delete`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const detach = (input: { ataId: string; attachmentId: string }) =>
  new Promise<void>((resolve, reject) => {
    api
      .put('attachment/detach', input)
      .then(() => resolve())
      .catch(reject);
  });

const getAttachmentAtas = (id: string) =>
  new Promise<IAtaListViewModel[]>((resolve, reject) => {
    api
      .get(`ata/get-atas-to-attachment/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const AttachmentController = {
  getById,
  insert,
  get,
  download,
  remove,
  detach,
  getAttachmentAtas,
};
