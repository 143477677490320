import styled from 'styled-components';

export const InputContainer = styled.div`
  padding: 10px;
  border-top: 1px solid #ccc;
  bottom: 1px;
`;

export const MessageContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  .emptyContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }

  .emptyAvatar {
    width: 100px;
    height: 100px;
  }

  .emptyTitle {
    margin: 10px 0 0 0;
  }

  .emptySubTitle {
    font-size: 14px;
    margin: 0;
    color: gray;
  }
  .dayMarker {
    position: sticky;
    top: 0;
    align-self: center;
    padding: 0 3px 0 3px;
    margin: 2px 0 0 0;
    background-color: #e8ebea;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    border-radius: 11px;
    font-family: Arial, sans-serif;
    font-size: 10px;
    z-index: 1;
  }

  .userTitle {
    font-weight: bold;
    margin-left: auto;
    font-size: 12px;
    margin-right: 20px;
  }
  .userMessage {
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: left;
    background-color: #5dd9f0;
    padding: 8px;
    padding-right: 10px;
    border-radius: 15px 15px 0 15px;
    word-wrap: break-word;
    max-width: 80%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

    .messageText {
      display: flex;
      flex-direction: column;
    }

    .timestamp {
      font-size: 11px;
      color: gray;
      margin-right: auto;
    }
  }

  .assistantTitle {
    font-weight: bold;
    margin-right: auto;
    font-size: 12px;
    margin-left: 20px;
  }
  .assistantMessage {
    text-align: left;
    background-color: #61ede6;
    padding: 8px;

    border-radius: 15px 15px 15px 0;
    margin: 0 0 10px 10px;
    margin-right: auto;
    word-wrap: break-word;
    max-width: 80%;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    .messageText {
      display: flex;
      flex-direction: column;
    }

    .timestamp {
      font-size: 11px;
      color: gray;
      margin-left: auto;
    }
  }
`;
export const Avatar = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
`;

export const ChatContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isOpen }) => (isOpen ? '20vw' : '60px')};
  min-width: ${({ isOpen }) => (isOpen ? '320px' : '60px')};
  min-height: ${({ isOpen }) => (isOpen ? '500px' : '60px')};
  height: ${({ isOpen }) => (isOpen ? '60vh' : '60px')};
  border-radius: ${({ isOpen }) => (isOpen ? '10px' : '50%')};
  background-color: ${({ isOpen }) => (isOpen ? '#f5f5f5' : '#3CDBCD')};
  position: fixed;
  bottom: 20px;
  right: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease;
  z-index: 999;

  &:hover {
    background-color: ${({ isOpen }) => (isOpen ? '#f5f5f5' : '#27ADC5')};
    transform: ${({ isOpen }) => (isOpen ? '' : 'scale(1.1)')};
  }
  & > *:not(button) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  }
  .containerHeaderChat {
    border-radius: 10px 10px 0 0;
    background-color: #27adc5;
    justify-content: space-between;
    color: white;
    align-items: center;
    gap: 0.5rem;

    .avatarContainer {
      margin: 0.5rem 0 0 0.5rem;
    }

    .closeButton {
      color: #f1f1f1;
      font-size: x-large;
      margin-right: 10px;
      &:hover {
        transform: scale(1.2);
      }
    }

    .deleteButton {
      color: #f1f1f1;
      font-size: x-large;
      margin-left: auto;
      &:hover {
        transform: scale(1.2);
        color: #cf5955;
      }
    }
  }
  .dividerChat {
    height: 1px;
    display: flex;
  }
`;

export const Button = styled.button`
  place-self: center;
  position: static;
  background-color: initial;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  .iconChatButton {
    font-size: xx-large;
    color: #f1f1f1;
    margin: 12px 0 0 0;
  }
`;
