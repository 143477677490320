import { IContactWithAtas } from 'entities';
import React, { useCallback, useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { IAtaListViewModel } from 'viewModels';
import loadingGif from '../../assets/gif/loading.gif';
import { useActions } from '../../context';
import { AtaController } from '../../controllers';
import { ContactStatus } from '../../types/enums';
import Ata from '../Ata';
import ContactComponent from '../Contact';
import { Container } from './styles';

interface UserAtasProps extends Omit<IContactWithAtas, 'atas'> {
  refId: string;
  focus?: boolean;
  trashed?: boolean;
  showTrashedMenu?: boolean;
  officeId?: string;
  atas?: IAtaListViewModel[];
  showNameFrom?: 'user' | 'owner';
  onGetAtas: (atas: IAtaListViewModel[]) => void;
}

const ContactWithAtas: React.FC<UserAtasProps> = ({
  refId,
  focus,
  trashed,
  officeId,
  showNameFrom,
  atas: initialAtas,
  showTrashedMenu = false,
  onGetAtas,
  ...contact
}) => {
  //const { online } = useApp();
  const { subscribe } = useActions();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [atas, setAtas] = useState<IAtaListViewModel[]>(initialAtas || []);

  const handleGetAtas = useCallback(
    (isOpen: boolean) => {
      if ((loaded && atas.length > 0) || !isOpen) return;
      setLoading(true);

      AtaController.getByUserAndOffice(
        contact.companyId ? contact.owner.id : contact.user.id,
        officeId!
      )
        .then((atasRes: any) => {
          setLoaded(true);
          setAtas(atasRes);
          onGetAtas(atasRes);
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [atas.length, contact.companyId, contact.id, loaded, trashed]
  );

  // useEffect(() => {
  //   setAtas(initialAtas || []);
  // }, [initialAtas]);

  useEffect(() => {
    const unsubscribeAtaTransfer = subscribe('ATA_TRANSFER', ({ ownerId }) => {
      if (ownerId === contact?.companyId || ownerId === contact.owner.id) {
        handleGetAtas(true);
      }
    });

    return () => unsubscribeAtaTransfer();
  }, [contact?.companyId, contact.owner.id, handleGetAtas, subscribe]);

  return (
    <Droppable
      droppableId={`${refId}/${contact.id}`}
      key={`${refId}/${contact.id}`}
      isCombineEnabled
      isDropDisabled //={!online || contact.status !== ContactStatus.RELEASED}
    >
      {(provided, { isDraggingOver }) => {
        return (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <ContactComponent
              {...contact}
              focus={focus}
              onOpen={handleGetAtas}
              showSeeAtas={false}
              officeId={officeId}
              showNameFrom={showNameFrom}
              isDraggingOver={isDraggingOver}
              deleteButton={false}
            >
              {contact.status === ContactStatus.RELEASED && (
                <Container>
                  {loading ? (
                    <div className="loading-atas">
                      <img src={loadingGif} alt="Legal Control Atas Loading" />
                      <p>Carregando atas...</p>
                    </div>
                  ) : atas.length > 0 ? (
                    atas.map((ata, indx) => {
                      return (
                        <Draggable
                          key={ata.id}
                          draggableId={`${refId}/${contact.id}/${ata.id}`}
                          index={indx}
                          isDragDisabled /*={
                            !online ||
                            contact.status !== ContactStatus.RELEASED ||
                            trashed
                          }*/
                        >
                          {(pro) => {
                            return (
                              <div
                                ref={pro.innerRef}
                                {...pro.draggableProps}
                                {...pro.dragHandleProps}
                              >
                                <Ata
                                  {...ata}
                                  showTrashedMenu={showTrashedMenu}
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })
                  ) : (
                    !isDraggingOver && (
                      <p className="empty-atas">Nenhuma ata encontrada.</p>
                    )
                  )}
                  {provided.placeholder}
                </Container>
              )}
            </ContactComponent>
          </div>
        );
      }}
    </Droppable>
  );
};

export default ContactWithAtas;
