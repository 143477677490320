import styled from 'styled-components';

export const SearchContainer = styled.div`
  border-radius: 10px;
  padding: 5px 10px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  position: relative;

  .input {
    display: flex;
    input {
      width: 100%;
      border: none;
      outline: none;
      font-size: 18px;
      font-weight: 200;
      color: ${({ theme }) => theme.colors.text4};
    }
  }

  &.with-results {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-color: ${({ theme }) => theme.colors.text};

    .results {
      animation: show 0.3s ease-in-out forwards;
    }
  }

  #loading {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 5px 0;

    .MuiCircularProgress-indeterminate {
      width: 25px !important;
      height: 25px !important;
    }

    p {
      margin-left: 10px;
      color: ${({ theme }) => theme.colors.text4};
      font-style: italic;
      font-weight: 200;
    }
  }

  .results {
    top: calc(100% + 1px);
    max-height: 410px;
    overflow-y: auto;
    left: -2px;
    right: -2px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.background};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-top: none;
    z-index: 20;
    animation: hide 0.3s ease-in-out forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
