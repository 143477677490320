import { ModalProps } from '@mui/material';
import Modal from '../Modal';
import Button from '../../Button';

import { Container } from './styles';

interface Props extends Omit<ModalProps, 'children'> {
  close: () => void;
}

const ModalShareAta = ({ close, ...rest }: Props) => {
  return (
    <Modal
      onClose={close}
      {...rest}
      boxStyle={{
        p: 2,
      }}
    >
      <Container>
        <h2 className="title">Aceite da política de privacidade</h2>
        <p className="terms">
          {`Pode ser utilizado por qualquer indivíduo que possua contato direto ou indireto com a empresa.\nA empresa encoraja a todos sua utilização, ressaltando que não são coletadas informações acerca da identificação de qualquer usuário.\nToda informação aposta no site é sigilosa, sendo vedada a sua divulgação ou sua utilização para qualquer finalidade escusa ou antiética.\nO usuário fica ciente, contudo que o fornecimento intencional de informações falsas não será tolerado.\nA solicitação poderá ser anônima ou não, podendo o usuário fornecer qualquer informação que lhe permita identificar.\nQualquer acesso não autorizado a áreas privativas do site, assim como tentativas ou consumação de acesso ao código-fonte, serão consideradas atos de violação, sendo devidamente tratadas de acordo com a especificação penal vigente à época.\nAs informações são protegidas e o armazenamento seguro das mesmas é de responsabilidade do Legal  Ética.\nOs prazos para apuração, retorno e tratamento de solicitações, elogios ou reports são informados quando da realização do ato, sendo possível ao usuário consultar periodicamente o andamento de sua atividade.\nAconselha-se que o protocolo informado pelo Legal Ética quando da utilização do canal seja armazenado pelo usuário para consultas posteriores.\nQualquer informação adicional ou falha sistêmica, deverá ser contatado o suporte técnico pelo canal 0800-400-3333.\nO uso responsável dessa ferramenta contribui com um ambiente mais ético para todos.`}
        </p>
        <Button shape="outline" onClick={close}>
          Sair
        </Button>
      </Container>
    </Modal>
  );
};

export default ModalShareAta;
