/* eslint-disable react/prop-types */
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
} from 'react';
import { AlertProps } from 'props';
import { Alert } from '../components';

interface IAlertContext {
  show: <T = boolean>(props: AlertProps) => Promise<T>;
}

const AlertContext = createContext({} as IAlertContext);

export const AlertProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [showProps, setShowProps] = useState<AlertProps>({} as AlertProps);

  const resolver = useRef<(value?: any | PromiseLike<any>) => void>(() => null);
  const handleClose = (value?: any) => {
    setOpen(false);
    resolver.current(value || false);
  };

  const handleShow = <T,>(props: AlertProps) =>
    new Promise<T>((resolve) => {
      resolver.current = resolve;
      setShowProps({ ...props });
      setOpen(true);
      if (props.type !== 'confirm' && props.timer)
        setTimeout(() => {
          setOpen(false);
          resolver.current(true);
        }, props.timer);
    });

  const show = useCallback(handleShow, []);

  const initialValues = {
    show,
  };

  return (
    <AlertContext.Provider value={initialValues}>
      <>
        <Alert open={open} close={handleClose} {...showProps} />
        {children}
      </>
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
