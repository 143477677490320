import styled from 'styled-components';
import { Screen } from '../../../../styles/global';

export const Container = styled(Screen)`
  padding: 0px 60px 24px;
  padding-top: 100px;

  h1 {
    font-family: 'Futura Condensed PT' !important;
    font-size: 36px;
    color: ${({ theme }) => theme.colors.accent};
  }

  h2 {
    font-family: 'Futura PT';
    font-size: 18px;
    font-weight: 100;
    color: ${({ theme }) => theme.colors.primary};
  }

  div {
    height: min-content;
  }
`;

export const Content = styled.main`
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  flex: 1;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 20px;
`;
