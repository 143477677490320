import { Search } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Paper from '../../assets/img/error-paper.png';
import { Header, Input, ModalOffline } from '../../components';
import { useApp } from '../../context';
import { Container } from './styles';

interface ErrorPageProps {
  onReset?: () => void;
  statusCode?: number;
  message?: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  statusCode,
  message,
  onReset,
}) => {
  const { online } = useApp();
  const [open, setOpen] = useState(!online);

  const { pathname } = useLocation();

  let status = Math.abs(Number(pathname.split('/')[2]));
  status =
    Number.isNaN(status) || status <= 300 || status >= 600
      ? statusCode ?? 404
      : status;

  useEffect(() => {
    setOpen(!online);
  }, [online]);

  return (
    <Container>
      <Header showOptions />
      <div className="error">
        <strong>Erro</strong>
        <h1>{status}</h1>
        <img src={Paper} alt="Error" />
      </div>
      <div className="help">
        <strong>
          {message ?? 'A página solicitada parece que está fora do ar.'}
        </strong>
        <strong>
          Use a nossa pesquisa ou volte para{' '}
          <Link onClick={onReset} to="/dashboard">
            home
          </Link>
          .
        </strong>

        <Input
          label="Procurar"
          placeholder="Pesquisar alguma coisa..."
          endAdornment={
            <IconButton onClick={() => {}}>
              <Search />
            </IconButton>
          }
        />
      </div>
      <ModalOffline open={open} onClose={() => setOpen(false)} />
    </Container>
  );
};

export default ErrorPage;
