import styled from 'styled-components';

interface ProgressProps {
  error?: boolean;
}

export const Progress = styled.div<ProgressProps>`
  width: 100%;
  box-shadow: none;
  border: 2px solid
    ${({ theme, error }) => (error ? theme.colors.error : theme.colors.primary)};
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 25px;
  margin-bottom: 20px;

  &.progress-xs {
    height: 5px;
    margin-top: 5px;
  }
  &.progress-sm {
    height: 10px;
    margin-top: 5px;
  }
  &.progress-lg {
    height: 25px;
  }
  &.vertical {
    position: relative;
    width: 20px;
    height: 200px;
    display: inline-block;
    margin-right: 10px;
    > .progress-bar {
      width: 100% !important;
      position: absolute;
      bottom: 0;
    }
    &.progress-xs {
      width: 5px;
      margin-top: 5px;
    }
    &.progress-sm {
      width: 10px;
      margin-top: 5px;
    }
    &.progress-lg {
      width: 30px;
    }
  }

  .progress-bar {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50px;
    box-shadow: none;
    height: 100%;
    &.text-left {
      text-align: left;
      span {
        margin-left: 10px;
      }
    }
    &.text-right {
      text-align: right;
      span {
        margin-right: 10px;
      }
    }
  }

  .progress-bar.fail {
    background-color: ${({ theme }) => theme.colors.error};
  }

  @-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 40px 0;
    }
    to {
      background-position: 0 0;
    }
  }

  // Spec and IE10+
  @keyframes progress-bar-stripes {
    from {
      background-position: 40px 0;
    }
    to {
      background-position: 0 0;
    }
  }

  &.active .progress-bar {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
  }
  &.progress-striped .progress-bar {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 40px 40px;
  }

  #infos {
    display: flex;
    align-items: flex-start;
    justify-content: space-between !important;

    #label {
      color: ${({ theme }) => theme.colors.text} !important;
      font-size: 12px !important;
      margin: 0 !important;
      margin-top: 5px !important;
      min-width: 100px !important;
      cursor: default;
    }

    #actions {
      display: flex;
      justify-content: flex-end;

      p {
        margin-top: 5px;
        color: ${({ theme }) => theme.colors.primary} !important;
        font-size: 12px !important;
        cursor: pointer;
        margin-left: 15px;
      }

      p:hover {
        text-decoration: underline;
      }

      #error {
        color: ${({ theme }) => theme.colors.error};
        min-width: 120px;
      }
    }
  }
`;
