import { useLiveQuery } from 'dexie-react-hooks';
import { IAta } from 'entities';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  IAtaListViewModel,
  IAtaMoveToTrashViewModel,
  IAtaReportListViewModel,
  IAtaRestoreViewModel,
} from 'viewModels';
import { AtaController } from '../controllers/AtaController';
import useWebSocket from '../hooks/useWebSocket';
import { ataRepository } from '../services/indexedDB';
import { buildFormData } from '../utils/buildFormData';
import { useActions, useAlert, useApp, useAuth, useContact } from './index';

interface IPropsContext {
  ata: IAta;
  isEmpty: boolean;
  atas: IAtaListViewModel[];
  getAtaName: (_ata: IAta) => [string, string];
  atasOffline: IAtaReportListViewModel[];
  trashedAtas: IAtaListViewModel[] | undefined;
  getTrashedAtas: () => void;
  setAta: Dispatch<SetStateAction<IAta>>;
  availableOffline: (ataId: string) => boolean;
  onlyOffline: (ataId: string) => boolean;
  setOfflineAta: (ataId: string) => boolean;
  handleAtaMenu: (optionMenu: number, ataId?: string) => void;
  setAtas: Dispatch<SetStateAction<IAtaListViewModel[]>>;
}

interface IAtaProviderProps {
  children: ReactNode;
}

const AtaContext = createContext({} as IPropsContext);

export const AtaProvider = ({ children }: IAtaProviderProps) => {
  const appAlert = useAlert();
  const { loading, online, setLoading } = useApp();
  const { linkedContact } = useContact();
  const { subscribe, dispatch, countListeners } = useActions();
  const { tokenIsValid, user, token, activeOffice, profile } = useAuth();

  const atasOffline = useLiveQuery(() => ataRepository.getAll(), []);

  const [trashedAtas, setTrashedAtas] = useState<IAtaListViewModel[]>();
  const [atas, setAtas] = useState<IAtaListViewModel[]>([]);
  const [ata, setAta] = useState<IAta>({} as IAta);

  const isEmpty = useMemo(
    () =>
      !(
        !!ata.processNumber ||
        !!ata.hearingJudge ||
        ata.hearingsLocations?.length ||
        ata.claimants?.some(
          (c) => !!c.name || c.lawyers?.some((l) => !!l.name)
        ) ||
        ata.claimed?.some(
          (c) => !!c.name || c.lawyers?.some((l) => !!l.name)
        ) ||
        ata.hearings?.some(
          (h) =>
            h.date ||
            h.observation ||
            h.lawyers?.some((l) => !!l.name) ||
            h.depositions?.some(
              (d) =>
                !!d.nameDeponent ||
                d.nickNameDeponent ||
                !!d.text ||
                d.contradicted !== undefined ||
                d.typeDeponent !== undefined
            )
        )
      ),
    [ata]
  );

  const getAtas = useCallback(() => {
    if (tokenIsValid) {
      setLoading(true);
      AtaController.get({
        companyId: profile === 'company' ? user?.id : undefined,
        trashed: false,
      })
        .then(setAtas)
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, tokenIsValid, token]);

  const getTrashedAtas = useCallback(() => {
    if (tokenIsValid) {
      setLoading(true);
      AtaController.get({
        trashed: true,
      })
        .then(setTrashedAtas)
        .finally(() => setLoading(false));
    }
  }, [setLoading, tokenIsValid]);

  const availableOffline = useCallback(
    (ataId: string) => atasOffline?.find((a) => a.id === ataId) !== undefined,
    [atasOffline]
  );

  const onlyOffline = useCallback(
    (ataId: string) => atasOffline?.find((a) => a.id === ataId)?.isNew ?? false,
    [atasOffline]
  );

  const setOfflineAta = useCallback(
    (ataId: string) => {
      const match = atasOffline?.find((a) => a.id === ataId);
      if (match) {
        setAta(match);
      }

      return match !== undefined;
    },
    [atasOffline]
  );

  const handleAtaMenu = useCallback(
    async (menuOption: number, ataId?: string) => {
      if (!ataId && !ata.id) return;

      if (!online && menuOption !== 0) {
        appAlert.show({
          type: 'error',
          errors: ['Sem conexão com a internet'],
        });

        return;
      }

      switch (menuOption) {
        case 0: // Open Ata Viewer Modal
          break;
        case 1: // Edit ata
          break;
        case 2: // Open modal to forward ata
          break;
        case 3:
          if (loading) return;

          if (ata?.id && ata.trashed) {
            appAlert.show({
              type: 'error',
              errors: ['Ata já foi excluída'],
            });
            return;
          }

          setLoading(true);
          await AtaController.moveToTrash(ataId ?? ata.id)
            .then(() => {
              if (ata.id) setAta((prev) => ({ ...prev, trashed: true }));
            })
            .catch(() => {
              appAlert.show({
                type: 'error',
                errors: ['Erro ao excluir ata'],
              });
            })
            .finally(() => setLoading(false));
          break;
        case 4:
          if (loading) return;

          if (ata?.id && !ata.trashed) {
            appAlert.show({
              type: 'error',
              errors: ['Ata não excluída'],
            });
            return;
          }

          setLoading(true);
          await AtaController.restore(ataId ?? ata.id)
            .then(() => {
              if (ata.id) setAta((prev) => ({ ...prev, trashed: false }));
            })
            .catch(() => {
              appAlert.show({
                type: 'error',
                errors: ['Erro ao restaurar ata'],
              });
            })
            .finally(() => setLoading(false));
          break;
        case 5:
          if (loading) return;
          setLoading(true);
          await AtaController.remove(ataId ?? ata.id).finally(() =>
            setLoading(false)
          );
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ata.id, ata.trashed, loading, online, setLoading]
  );

  const getAtaName = (_ata: IAta): [string, string] => {
    if (_ata?.claimants?.length > 0) {
      const index = _ata.claimants.findIndex((c) => c.name.trim() !== '');
      if (index !== -1) {
        return ['Reclamante', _ata.claimants[index].name];
      }
    }
    if (_ata.processNumber) {
      return ['Nº Processo', _ata.processNumber];
    }
    if (_ata.hearingJudge) {
      return ['Juiz', _ata.hearingJudge];
    }
    if (_ata?.hearingsLocations?.length > 0) {
      const index = _ata.hearingsLocations.findIndex((l) => l.trim() !== '');
      if (index !== -1) {
        return ['Local', _ata.hearingsLocations[index]];
      }
    }
    if (_ata?.claimants?.length > 0) {
      const index = _ata.claimants.findIndex((c) =>
        c.lawyers.some((l) => l.name.trim() !== '')
      );
      if (index !== 1) {
        const indexLawyer = _ata.claimants[index].lawyers.findIndex(
          (l) => l.name.trim() !== ''
        );
        if (index !== -1) {
          return [
            'Adv.Reclamante',
            _ata.claimants[index].lawyers[indexLawyer].name,
          ];
        }
      }
    }
    if (_ata?.claimed?.length > 0) {
      let index = _ata.claimed.findIndex((c) => c.name.trim() !== '');
      if (index !== -1) {
        return ['Reclamante', _ata.claimed[index].name];
      }
      index = _ata.claimed.findIndex((c) =>
        c.lawyers.some((l) => l.name.trim() !== '')
      );
      if (index !== 1) {
        const indexLawyer = _ata.claimed[index].lawyers.findIndex(
          (l) => l.name.trim() !== ''
        );
        if (index !== -1) {
          return [
            'Adv.Reclamado',
            _ata.claimed[index].lawyers[indexLawyer].name,
          ];
        }
      }
    }
    if (_ata?.hearings?.length > 0) {
      const { hearings } = _ata;
      let index = hearings.findIndex((h) => h.observation.trim() !== '');
      if (index !== -1) {
        return ['Obs. Audiência', hearings[index].observation];
      }
      index = hearings.findIndex((h) =>
        h.lawyers.some((l) => l.name.trim() !== '')
      );
      if (index !== -1) {
        const indexLawyer = hearings[index].lawyers.findIndex(
          (l) => l.name.trim() !== ''
        );
        if (indexLawyer !== -1) {
          return ['Adv. Audiência', hearings[index].lawyers[indexLawyer].name];
        }
      }
      index = hearings.findIndex((h) =>
        h.depositions.some((d) => d.nickNameDeponent.trim() !== '')
      );
      if (index !== -1) {
        const indexDeposition = hearings[index].depositions.findIndex(
          (l) => l.nickNameDeponent.trim() !== ''
        );
        if (indexDeposition !== -1) {
          return [
            'Apelido',
            hearings[index].depositions[indexDeposition].nickNameDeponent,
          ];
        }
      }
      index = hearings.findIndex((h) =>
        h.depositions.some((d) => d.nameDeponent.trim() !== '')
      );
      if (index !== -1) {
        const indexDeposition = hearings[index].depositions.findIndex(
          (l) => l.nameDeponent.trim() !== ''
        );
        if (index !== -1) {
          return [
            'Depoente',
            hearings[index].depositions[indexDeposition].nameDeponent,
          ];
        }
      }
      index = hearings.findIndex((h) =>
        h.depositions.some((d) => d.text.trim() !== '')
      );
      if (index !== -1) {
        const indexDeposition = hearings[index].depositions.findIndex(
          (l) => l.text.trim() !== ''
        );
        if (index !== -1) {
          return [
            'Depoimento',
            hearings[index].depositions[indexDeposition].text,
          ];
        }
      }
      index = hearings.findIndex((h) => !!h.date);
      if (index !== -1) {
        return [
          'Data da audiência',
          Intl.DateTimeFormat('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          }).format(new Date(hearings[index].date!)),
        ];
      }
    }
    return ['Nome', 'Não informado'];
  };

  useEffect(() => {
    const callback = () => ataRepository.clearAll();
    const unsubscribeChangeAccount = subscribe('CHANGE_ACCOUNT', callback);
    const unsubscribeLogout = subscribe('LOGOUT', callback);

    return () => {
      unsubscribeChangeAccount();
      unsubscribeLogout();
    };
  }, [linkedContact, subscribe]);

  useEffect(() => {
    getAtas();
  }, [getAtas]);

  useEffect(() => {
    const atasToSync = (atasOffline || []).filter((a) => a.isNew);
    if (online && atasToSync.length) {
      const promises = atasToSync.map((a) => {
        const formData = new FormData();
        buildFormData(formData, {
          processNumber: a.processNumber,
          placeHearing: '', //a.placeHearing,
          hearingJudge: a.hearingJudge,
          claimants: a.claimants,
          claimed: a.claimed,
          depositions: [], //a.depositions,
          attachmentsId: a.attachmentsId || ([] as any),
        });

        formData.append('userId', user?.id || '');

        return AtaController.create(formData, '');
      });

      Promise.all(promises).then(() =>
        ataRepository.removeWhere((a) => a.isNew === true)
      );
    }
  }, [atasOffline, online, user?.id]);

  useEffect(() => {
    if (!tokenIsValid) {
      setAtas([]);
      setTrashedAtas([]);
      setAta({} as IAta);
    }
  }, [tokenIsValid]);

  useWebSocket<string>({
    url: `ata/${user?.id}`,
    onMessage: async (id) => {
      try {
        const newAta = await AtaController.getById(id);
        setAtas((prevAtas) => [newAta, ...prevAtas]);
        dispatch('ATA_CREATED', newAta);
      } catch {
        //...
      }
    },
  });

  useWebSocket<{ ataId: string; contactId: string; companyId?: string }>({
    url: `ata/${user?.id || activeOffice?.id}/from-contact`,
    onMessage: async ({ ataId, contactId, companyId }) => {
      try {
        if (countListeners('NEW_CONTACT_ATA') === 0) return;
        const addedAta = await AtaController.getById(ataId);
        dispatch('NEW_CONTACT_ATA', { contactId, companyId, ...addedAta });
      } catch {
        //...
      }
    },
  });

  useWebSocket<string>({
    url: `ata/${user?.id || activeOffice?.id}/update`,
    onMessage: async (id) => {
      try {
        const updatedAta = await AtaController.getById(id);
        const ataIndex = atas.findIndex((a) => a.id === id);
        if (ataIndex > -1) {
          setAtas((prev) => [
            ...prev.slice(0, ataIndex),
            updatedAta,
            ...prev.slice(ataIndex + 1),
          ]);
        }
        const trashedAtaIndex =
          trashedAtas?.findIndex((a) => a.id === id) ?? -1;
        if (trashedAtaIndex > -1) {
          setTrashedAtas((prev) => [
            ...(prev?.slice(0, trashedAtaIndex) || []),
            updatedAta,
            ...(prev?.slice(trashedAtaIndex + 1) || []),
          ]);
        }

        const ataOfflineIndex = atasOffline?.findIndex((a) => a.id === id);
        if (ataOfflineIndex !== -1) {
          const ataUpdateInfos = await AtaController.getAllInfosById(id);
          ataRepository.update(ataUpdateInfos);
        }

        dispatch('ATA_UPDATED', updatedAta);
      } catch {
        //...
      }
    },
  });

  useWebSocket<string>({
    url: `ata/${user?.id || activeOffice?.id}/disabled`,
    onMessage: (id) => {
      setAtas((prev) => prev.filter((a) => a.id !== id));
      setTrashedAtas((prev) => prev?.filter((a) => a.id !== id));

      const ataOfflineIndex = atasOffline?.findIndex((a) => a.id === id);
      if (ataOfflineIndex !== -1) {
        ataRepository.remove(id);
      }
      dispatch('ATA_DISABLED', id);
    },
  });

  useWebSocket<{ ataId: string; linkedToId: string; linkedTo: string }>({
    url: `ata/${user?.id}/link-to`,
    onMessage: async (info) => {
      let ataIndex = atas.findIndex((a) => a.id === info.ataId);
      if (ataIndex > -1) {
        setAtas((prev) => [
          ...prev.slice(0, ataIndex),
          { ...prev[ataIndex], linkedTo: info.linkedTo },
          ...prev.slice(ataIndex + 1),
        ]);
      }

      ataIndex = trashedAtas?.findIndex((a) => a.id === info.ataId) ?? -1;
      if (ataIndex > -1) {
        setTrashedAtas((prev) => [
          ...(prev?.slice(0, ataIndex) || []),
          { ...prev?.[ataIndex]!, linkedTo: info.linkedTo },
          ...(prev?.slice(ataIndex + 1) || []),
        ]);
      }

      ataIndex = atasOffline?.findIndex((a) => a.id === info.ataId) ?? -1;

      if (ataIndex > -1) {
        ataRepository.update({
          ...atasOffline?.[ataIndex]!,
          linkedToId: info.linkedToId,
        });
      }

      dispatch('ATA_LINK_TO', info);
    },
  });

  useWebSocket<{ ownerId: string }>({
    url: `ata/${user?.id}/transfer`,
    onMessage: async ({ ownerId }) => {
      if (user?.id === ownerId) getAtas();
      dispatch('ATA_TRANSFER', { ownerId });
    },
  });

  useWebSocket<IAtaMoveToTrashViewModel>({
    url: `ata/${user?.id || activeOffice?.id}/trashed`,
    onMessage: async ({ id, companyId, contactId }) => {
      const trashed = await AtaController.getById(id);
      if (trashed) {
        setAtas((prev) => prev.filter((a) => a.id !== id));

        if (trashed.userId === user?.id) {
          setTrashedAtas((prev) => [
            {
              ...trashed,
              trashed: true,
            },
            ...(prev || []).filter((a) => a.id !== id),
          ]);
        }

        const ataOffline = atasOffline?.find((a) => a.id === id);

        if (ataOffline) {
          ataRepository.update({ ...ataOffline, trashed: true });
        }
        dispatch('TRASHED_ATA', { companyId, contactId, ...trashed });
      }
    },
  });

  useWebSocket<IAtaRestoreViewModel>({
    url: `ata/${user?.id || activeOffice?.id}/restore`,
    onMessage: async ({ id, contactId, companyId }) => {
      try {
        const restoredAta = await AtaController.getById(id);
        setTrashedAtas((prev) => prev?.filter((a) => a.id !== id));
        if (restoredAta.userId === user?.id)
          setAtas((prev) => [
            restoredAta,
            ...(prev || []).filter((a) => a.id !== id),
          ]);

        const ataOffline = atasOffline?.find((a) => a.id === id);
        if (ataOffline) {
          ataRepository.update({ ...ataOffline, trashed: false });
        }
        dispatch('ATA_RESTORED', { companyId, contactId, ...restoredAta });
      } catch {
        //...
      }
    },
  });

  return (
    <AtaContext.Provider
      value={{
        ata,
        atas,
        setAta,
        setAtas,
        isEmpty,
        getAtaName,
        onlyOffline,
        trashedAtas,
        handleAtaMenu,
        setOfflineAta,
        getTrashedAtas,
        availableOffline,
        atasOffline:
          atasOffline?.map((a) => ({
            ...a,
            claimed: a.claimed?.[0]?.name,
            placeHearing: a.hearingsLocations?.[0],
            depositions: a.hearings?.[0]?.depositions?.[0]?.nameDeponent,
            claimant: a.claimants?.[0]?.name,
            claimantLawyer: a.claimants?.[0]?.lawyers?.[0]?.name,
            claimedLawyer: a.claimed?.[0]?.lawyers?.[0]?.name,
            sharedWith: [],
            userId: a.userId,
            disabled: !!a.trashed,
            createdAt: a.createdAt || new Date().toUTCString(),
          })) || [],
      }}
    >
      {children}
    </AtaContext.Provider>
  );
};

export const useAta = () => useContext(AtaContext);
