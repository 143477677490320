import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { Container } from './styles';

export type OptionsProps = { key: any; label: any; value?: any; };

type Props = {
  label: string;
  onChange: (value: string | number | undefined) => void;
  options: OptionsProps[];
  defaultValue?: any;
};

export const Select = ({ label, onChange, options, defaultValue }: Props) => {
  const [value, setValue] = useState('');
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue);
      forceUpdate();
    }
  }, [defaultValue, forceUpdate]);

  return (
    <Container>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
        <MUISelect
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={value}
          onChange={(e) => {
            const changeValue = e.target.value;
            setValue(e.target.value);
            onChange(changeValue !== 'none' ? changeValue : undefined);
          }}
          label={label}
        >
          <MenuItem value="none">Selecione</MenuItem>
          {options.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          ))}
        </MUISelect>
      </FormControl>
    </Container>
  );
};
