import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 45vw;
  height: 70vh;
  padding: 3;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon-close {
    ${({ theme: { colors } }) => css`
      color: ${colors.blue};
    `};
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  ${({ theme: { colors } }) => css`
    color: ${colors.primary};
  `};

  p {
    font-size: 20px;
    font-weight: 400;
    padding-left: 4px;
  }

  svg {
    width: 28px;
    height: 28px;
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 32px);
  flex: 1;
`;

export const Attachment = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0 8px;

  svg {
    height: 32;
    margin-right: 8px;
  }

  .content-attachment {
    h1 {
      font-size: 16px;
      font-weight: 400;
      ${({ theme: { colors } }) => css`
        color: ${colors.text2};
      `};
    }
    p {
      font-size: 10px;

      ${({ theme: { colors } }) => css`
        color: ${colors.text};
      `};
      font-weight: 200;
    }
  }
`;

export const ListAtas = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;

  overflow-y: scroll;
  padding-top: 8px;

  & > p {
    color: ${({ theme }) => theme.colors.text};
  }
`;
