import { CircularProgress } from '@mui/material';
import mime from 'mime';
import React, { useCallback, useEffect, useState } from 'react';
import { FaPaperclip } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { setTimeout } from 'timers';
import { v4 as uuid } from 'uuid';

import { log } from 'console';
import { useAlert, useApp, useAta } from '../../../context';
import { useReadTextFile } from '../../../hooks/useReadTextFile';
import Button from '../../Button';
import ItemFile from '../../ItemFile';
import { Container, WrapperFiles, WrapperOcr } from './styles';

const RegisterAtaOcr: React.FC = () => {
  const alert = useAlert();
  const { online } = useApp();
  const navigate = useNavigate();
  const { colors, shadows } = useTheme();
  const { ata, setAta } = useAta();
  const { readTextImage, readTextPdf } = useReadTextFile();

  const [readingFile, setReadingFile] = useState(false);
  const [formHeight, setFormHeight] = useState(0);
  const [filesHeight, setFilesHeight] = useState(0);
  const [selectedFilesIds, setSelectedFilesIds] = useState<string[]>([
    !!ata.files && ata.files?.length > 0 ? ata.files[0].file.id : '',
  ]);
  const [textOcr, setTextOcr] = useState(
    !!ata.files && ata.files?.length > 0 ? ata.files[0].text : ''
  );

  useEffect(() => {
    const formObserver = new ResizeObserver((entries) =>
      setFormHeight(entries[0].contentRect.height)
    );
    formObserver.observe(document.getElementById('register-ata-form')!);

    const ocrObserver = new ResizeObserver((entries) =>
      setFilesHeight(entries[0].contentRect.height)
    );
    ocrObserver.observe(document.getElementById('ocr-files')!);

    return () => {
      formObserver.disconnect();
      ocrObserver.disconnect();
    };
  }, []);

  const handleOnFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files!);

    if (files) {
      setAta((prevState) => ({
        ...prevState,
        files: [
          ...(prevState.files || []),
          ...files.map((f) => ({
            file: Object.assign(f, { id: uuid() }),
          })),
        ],
      }));
    }
  };

  const handleSetSelectedFile = useCallback(
    async (id: string, checked: boolean, index: number) => {
      setSelectedFilesIds((prevState) => {
        return checked ? [...prevState, id] : prevState.filter((p) => p !== id);
      });

      if (!ata.files) return;
      const newFiles = [...ata.files];

      if (checked) {
        const currentFileSelected = newFiles.find((f) => f.file.id === id);

        if (!currentFileSelected?.text) {
          setReadingFile(true);
          const isPdf = mime.getExtension(newFiles[index].file.type) === 'pdf';
          const { file } = newFiles[index];
          const promise = isPdf ? readTextPdf(file) : readTextImage(file);

          promise
            .then((text) => {
              newFiles[index].text = text;
              setAta((prevState) => ({ ...prevState, files: newFiles }));
              setTextOcr(
                newFiles.map((f) => (f.text ? f.text : '')).join('\n')
              );
            })
            .finally(() => setReadingFile(false));
        }
      } else {
        setAta((prevState) => ({ ...prevState, files: newFiles }));
        setTextOcr(newFiles.map((f) => (f.text ? f.text : '')).join('\n'));
        newFiles[index].text = '';
      }
    },
    [ata.files, readTextImage, readTextPdf, setAta]
  );

  const handleSelectionText = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const x =
      e.pageX -
      document.getElementById('container-register-ata-ocr')?.offsetLeft! +
      window.scrollX;

    const textSelected = window.getSelection()?.toString();
    if (!textSelected) return;

    navigator.clipboard.writeText(textSelected);

    const oRect = window.getSelection()?.getRangeAt(0).getBoundingClientRect();

    const element = document.createElement('div');
    element.style.width = '78px';
    element.style.height = '22px';
    element.style.backgroundColor = colors.background;
    // eslint-disable-next-line prefer-destructuring
    element.style.boxShadow = shadows[2];
    element.style.borderRadius = '15px';
    element.style.position = 'absolute';
    element.style.left = `${x - 39}px`;
    element.style.top = `${oRect?.y! + oRect?.height!}px`;
    element.style.display = 'flex';
    element.style.justifyContent = 'center';
    element.style.alignItems = 'center';
    element.style.userSelect = 'none';

    const text = document.createElement('p');
    text.style.color = colors.primary;
    text.style.fontSize = '12px';
    text.innerText = 'Copiado';

    element.appendChild(text);

    const container = document.getElementById('container-register-ata-ocr');
    const textArea = document.getElementById('ocr-text-container');
    container?.appendChild(element);

    function handleClickOutside(event: MouseEvent) {
      if (event.target) {
        try {
          container?.removeChild(element);
        } catch {
          //...
        }
        document.removeEventListener('mousedown', handleClickOutside);
      }
    }
    function handleScroll() {
      try {
        container?.removeChild(element);
      } catch {
        //...
      }
      textArea?.removeEventListener('scroll', handleScroll);
    }

    document.addEventListener('mousedown', handleClickOutside);
    textArea?.addEventListener('scroll', handleScroll);

    setTimeout(() => {
      container?.removeChild(element);
      document?.removeEventListener('mousedown', handleClickOutside);
    }, 10000);
  };

  const handleRemoveFile = async (id: string) => {
    if (!!ata.files && ata.files?.length <= 1) {
      const res = await alert.show({
        type: 'confirm',
        title: 'Confirmar exclusão?',
        message:
          'É necessário ter pelo menos um arquivo selecionado para realizar o cadastro.\nSe continuar, você será redirecionado para tela anterior.\nProsseguir exclusão?',
      });
      if (res) navigate('/atas/new');
      return;
    }

    const filesFiltered = ata.files!.filter((f) => f.file.id !== id);

    setAta((prevState) => ({
      ...prevState,
      files: filesFiltered,
    }));
    setSelectedFilesIds((prevState) => prevState.filter((p) => p !== id));
    setTextOcr(() =>
      filesFiltered.map((f) => (f.text ? f.text : '')).join('\n')
    );
  };

  useEffect(() => {
    if (ata.files?.length === 1) {
      handleSetSelectedFile(ata?.files[0]?.file?.id, true, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ata.files?.length]);

  return (
    <Container>
      {!!ata.files?.length && (
        <p>Selecione a parte do texto para preencher o formulario</p>
      )}
      <div id="ocr-files">
        <div id="ocr-files-input">
          <Button
            shape="text"
            size="large"
            type="file"
            disabled={!online || readingFile}
            onFilesChange={handleOnFilesChange}
          >
            <FaPaperclip size={24} />
            <p>Adicionar arquivo</p>
          </Button>
          {readingFile && (
            <div className="file-reading-loading">
              <CircularProgress size={15} />
              <p>Lendo arquivos...</p>
            </div>
          )}
        </div>
        <WrapperFiles id="ocr-files">
          {ata.files?.map(({ file }, index) => (
            <ItemFile
              selectedFilesIds={selectedFilesIds}
              isFirstElement={index === 0}
              onChecked={(checked) =>
                handleSetSelectedFile(file.id, checked, index)
              }
              key={file.id}
              file={file}
              onDelete={() => handleRemoveFile(file.id)}
            />
          ))}
        </WrapperFiles>
      </div>
      {!!textOcr?.trim()?.length && (
        <WrapperOcr
          id="ocr-text-container"
          maxHeight={formHeight - filesHeight}
          onMouseUp={(e) => handleSelectionText(e)}
        >
          <p>{textOcr}</p>
        </WrapperOcr>
      )}
    </Container>
  );
};

export default RegisterAtaOcr;
