import { IContact, IOffice, IUserAuth } from 'entities';
import api from '../services/api/axios';
import { OfficeDeleteAction } from '../types/enums';

const create = (name: string, token: string) =>
  new Promise<string>((resolve, reject) => {
    api
      .post('office', JSON.stringify(name), {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const login = (id: string) =>
  new Promise<IUserAuth>((resolve, reject) => {
    api
      .post(`office/login/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const setManager = (input: {
  officeId: string;
  code?: string;
  email?: string;
}) =>
  new Promise<IContact>((resolve, reject) => {
    api
      .put('office', input)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const removeManager = (id: string, token: string) =>
  new Promise<void>((resolve, reject) => {
    api
      .put(`office/${id}/remove-manager`, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => resolve())
      .catch(reject);
  });

const deleteById = (
  id: string,
  action: OfficeDeleteAction,
  receiverOfficeId?: string
) =>
  new Promise<void>((resolve, reject) => {
    api
      .delete('office', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          id,
          action,
          receiverOfficeId,
        },
      })
      .then(() => resolve())
      .catch(reject);
  });

const get = (id: string) =>
  new Promise<IOffice>((resolve, reject) => {
    api
      .get(`office/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const listByOwnerId = (owner?: string) =>
  new Promise<IOffice[]>((resolve, reject) => {
    if (!owner) resolve([]);
    api
      .get(`office/list/${owner}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getByManagerId = (managerId: string) =>
  new Promise<IOffice[]>((resolve, reject) => {
    api
      .get(`office/get-by-manager/${managerId}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getContactsByOfficeId = (officeId: string) =>
  new Promise<IContact[]>((resolve, reject) => {
    api
      .get(`office/${officeId}/contacts`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const OfficeController = {
  get,
  login,
  create,
  deleteById,
  setManager,
  listByOwnerId,
  removeManager,
  getByManagerId,
  getContactsByOfficeId,
};
