import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import ProgressBar from '../ProgressBar';

import Button from '../Button';
import { ItemFile } from './ItemFile';

import { useAlert } from '../../context/alert';
import { Container, ListFiles, TextDefault } from './styles';

type IFile = {
  id: string;
} & File;

interface Props {
  onLoadFiles: (files: IFile[]) => void;
  onCancel: () => void;
  progress: number;
}

const Dropzone = ({ onLoadFiles, progress, onCancel }: Props) => {
  const alert = useAlert();
  const [files, setFiles] = useState<IFile[]>([]);
  const [isStartUpload, setIsStartUpload] = useState(false);
  const totalSize = files.reduce((acc, item) => acc + item.size, 0);
  const MAX_UPLOAD_SIZE_FILES = 200000000;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const arqs = acceptedFiles.map((arq) => Object.assign(arq, { id: uuid() }));
    setFiles((state) => state.concat(arqs));
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    onDrop,

    accept:
      '.csv, .doc, .docx, .pdf, .xls, .xlsx, .png, .jpg, .jpeg, .bin, audio/*',
    multiple: true,
    noClick: true,
  });

  const handleOnDelete = (index: number) => {
    setFiles((state) => state.filter((_, idx) => idx !== index));
  };

  const handleOnLoadFiles = () => {
    if (totalSize > MAX_UPLOAD_SIZE_FILES) {
      alert.show({
        type: 'info',
        message: 'Só é possível fazer upload de 200mb por vez.',
      });
    } else {
      setFiles([]);
      setIsStartUpload(true);
      onLoadFiles(files);
    }
  };

  useEffect(() => {
    if (progress === 0) setIsStartUpload(false);
  }, [progress]);

  const handleOnCancel = () => {
    setIsStartUpload(false);
    onCancel();
  };

  return (
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      <TextDefault>Arraste os arquivos para cá ou</TextDefault>
      <Button
        onClick={() => open()}
        shape="outline"
        size="large"
        style={{ width: '60%' }}
      >
        Selecionar o arquivo
      </Button>
      {files.length > 0 && (
        <>
          <ListFiles>
            {files.map(({ id, name }, index) => (
              <ItemFile
                key={id}
                name={name}
                onDelete={() => handleOnDelete(index)}
              />
            ))}
          </ListFiles>
          <Button
            onClick={handleOnLoadFiles}
            shape="outline"
            size="medium"
            style={{ width: '40%', alignSelf: 'flex-end', marginBottom: 8 }}
          >
            Carregar
          </Button>
        </>
      )}

      {isStartUpload && (
        <>
          <div style={{ height: 16 }} />
          <ProgressBar
            active
            label={
              progress === 0 ? 'Processando...' : `Carregando ${progress}%`
            }
            id="123"
            value={progress}
            onCancel={handleOnCancel}
          />
          <div style={{ height: 8 }} />
        </>
      )}
    </Container>
  );
};

export default Dropzone;
