import styled from 'styled-components';

interface OfflineProps {
  isOffline: boolean;
}

export const Container = styled.div<OfflineProps>`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${({ isOffline }) => (isOffline ? '30px' : '0')};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.background};
  transition: height 0.5s;
  overflow: hidden;

  strong {
    margin: 0 10px;
  }
`;
