import Dexie from 'dexie';
import {
  applyEncryptionMiddleware,
  clearEncryptedTables,
  NON_INDEXED_FIELDS,
} from 'dexie-encrypted';
import { Ata, Attachment } from './models';

export class Database extends Dexie {
  public atas!: Dexie.Table<Ata, string>;

  public attachments!: Dexie.Table<Attachment, string>;

  constructor() {
    super('lc-atas');
    const db = this;

    //
    // Define tables and indexes
    //
    db.version(1).stores({
      atas: '&id',
      attachments: '&id',
    });

    db.atas.mapToClass(Ata);
    db.attachments.mapToClass(Attachment);
  }
}

const db = new Database();

applyEncryptionMiddleware(
  db,
  new TextEncoder().encode(process.env.REACT_APP_ENCRYPTION_KEY),
  {
    atas: NON_INDEXED_FIELDS,
    attachments: NON_INDEXED_FIELDS,
  },
  clearEncryptedTables
);

/**
 * Abstraction of indexedDB database.
 * @extends {Dexie}
 */
export default db;
