import { IUser } from 'entities';
import axios from '../axios';

export const login = (email: string, password: string) =>
  new Promise<{ token?: string; user?: IUser; status?: number }>(
    (resolve, reject) => {
      axios
        .post('/user/login', {
          email,
          password,
        })
        .then(({ data }) => resolve(data))
        .catch(reject);
    }
  );
