/* eslint-disable no-param-reassign */
import axios, { CancelTokenSource } from 'axios';
import { IUserAuth } from 'entities';

export const { CancelToken, isCancel } = axios;
export type { CancelTokenSource };

let onError: (error: any) => void;
export const setOnError = (cb: (error: any) => void) => {
  onError = cb;
};

let onRefreshToken: (data: IUserAuth) => void;
export const setOnRefreshToken = (cb: (data: IUserAuth) => void) => {
  onRefreshToken = cb;
};

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/${
    process.env.REACT_APP_API_VERSION || 'v1'
  }`,
});

export const refreshToken = (error?: any) =>
  new Promise((resolve, reject) => {
    try {
      const rToken = localStorage.getItem('@atas_control_jwt_refresh_token');
      if (!rToken) return reject(error);
      const refresh = JSON.parse(rToken);

      return api
        .post('user/refresh-token', JSON.stringify(refresh), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          onRefreshToken(res.data);
          return resolve(res);
        })
        .catch((err) => {
          onError?.(err);
          return reject(error);
        });
    } catch (err) {
      onError?.(err);
      return reject(err);
    }
  });

api.interceptors.request.use((config) => {
  try {
    const token = JSON.parse(
      localStorage.getItem('@atas_control_jwt_token') || ''
    );
    if (token && config?.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    //...
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  // eslint-disable-next-line consistent-return
  (error) => {
    if (error?.response?.status === 401) {
      return new Promise((resolve, reject) => {
        refreshToken(error)
          .then((res) => {
            error.config.headers.Authorization = `Bearer ${
              (res as any).data.token
            }`;
            resolve(api.request(error.config));
          })
          .catch(reject);
      });
    }
    return Promise.reject(error);
  }
);

export default api;
