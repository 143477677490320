import styled from 'styled-components';

interface Props {
  shown?: boolean;
}

export const Container = styled.div<Props>`
  box-shadow: ${({ theme }) => theme.shadows[1]};
  border-radius: 15px;
  margin-bottom: 10px;
  overflow: hidden;

  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.f2} !important;
  }

  .MuiAccordionDetails-root {
    padding: 0px 16px 5px 16px;
  }
  #user-summary {
    max-height: 44px !important;
    min-height: 44px !important;
  }
  .user-info {
    width: 100%;
    min-width: 200px;
    display: flex;
    font-size: 17px;
    font-weight: 200;
    margin-right: 10px;
    align-items: center;
    justify-content: space-between;

    #name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      margin-right: auto;
      padding-right: 10px;
      color: ${({ theme }) => theme.colors.accent};
    }

    #status {
      white-space: nowrap;
      color: ${({ theme }) => theme.colors.success};
      font-style: italic;
    }

    #status.pending {
      color: ${({ theme }) => theme.colors.warning2};
    }

    #status.blocked {
      color: ${({ theme }) => theme.colors.error};
    }

    svg {
      margin-left: 10px;
      width: 30px;
      height: 30px;
      transform: ${({ shown }) => (shown ? 'rotate(180deg)' : 'rotate(0deg)')};
      transition: transform 0.3s ease-in-out;
    }
  }

  .empty-action-text {
    font-weight: 200 !important;
    color: ${({ theme }) => theme.colors.text} !important;
    font-style: italic;
    padding-bottom: 8px;
  }

  .user-actions {
    order: 2;
    display: flex;
    font-size: 17px;
    font-weight: 200;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.primary};

    button {
      margin-left: -10px;
      border-radius: 60px;
      padding: 5px 15px 5px 10px;

      p {
        font-weight: 200;
        white-space: nowrap;
        font-size: 16px !important;
        text-transform: none !important;
      }

      svg {
        margin-right: 5px;
      }
    }
  }

  .contact-rules {
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    font-style: italic;
    padding-bottom: 8px;
  }
`;

export const BlockAlert = styled.div`
  max-width: 400px;
  padding: 5px 15px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;

  .MuiFormGroup-root {
    width: 100%;
  }

  &,
  .MuiTypography-root {
    font-weight: 200 !important;
    color: ${({ theme }) => theme.colors.text} !important;
  }
  & > p {
    margin-bottom: 10px;
    cursor: default;
    span {
      color: ${({ theme }) => theme.colors.error};
    }
  }

  button {
    border-radius: 60px;
    padding: 0px 30px;
    margin-top: 10px;

    p {
      font-weight: 200;
      white-space: nowrap;
      text-transform: none !important;
    }
  }
`;

export const DeleteAlert = styled.div`
  max-width: 350px;
  padding: 5px 15px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;

  .MuiFormGroup-root {
    width: 100%;
  }

  &,
  .MuiTypography-root {
    font-weight: 200 !important;
    color: ${({ theme }) => theme.colors.text} !important;
  }
  & > p {
    margin-bottom: 10px;
    cursor: default;
    span {
      color: ${({ theme }) => theme.colors.error};
    }
  }

  button {
    border-radius: 60px;
    padding: 0px 30px;
    margin-top: 10px;

    p {
      font-weight: 200;
      white-space: nowrap;
      text-transform: none !important;
    }
  }
`;
