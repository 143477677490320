import styled, { css } from 'styled-components';

export const Container = styled.div`
  .btn-close {
    color: ${({ theme }) => theme.colors.blue};
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;
export const Title = styled.p`
  text-align: center;
  margin-top: 8px;
`;

export const Attachment = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0 8px;

  svg {
    height: 32;
    margin-right: 8px;
  }

  .content-attachment {
    max-width: 250px;
    h1 {
      font-size: 16px;
      font-weight: 400;
      ${({ theme: { colors } }) => css`
        color: ${colors.text2};
      `};

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-wrap: break-word;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      max-width: 230px;
    }
    p {
      font-size: 10px;

      ${({ theme: { colors } }) => css`
        color: ${colors.text};
      `};
      font-weight: 200;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  padding-top: 12px;
`;
