import styled, { css } from 'styled-components';
import { ButtonBase } from '@mui/material';

interface ButtonProps {
  isLoading?: boolean;
  size?: 'small' | 'medium' | 'large';
  shape?: 'outline' | 'solid' | 'gradient' | 'text';
  color?: 'primary' | 'accent' | 'red' | 'warning';
}

export const ButtonStyle = styled(ButtonBase)<ButtonProps>`
  background: ${({ theme, shape, color }) =>
    shape !== 'text' && shape === 'solid'
      ? theme.colors[color || 'primary']
      : shape === 'gradient'
      ? theme.gradients[color || 'primary']
      : theme.colors.background} !important;
  color: ${({ theme, shape, color }) =>
    shape === 'outline' || shape === 'text'
      ? theme.colors[color || 'primary']
      : theme.colors.background} !important;
  border: ${({ theme, shape, color }) =>
    shape !== 'text' && shape === 'outline'
      ? `${theme.colors[color || 'primary']} solid 1px`
      : 'none'} !important;

  padding: ${({ shape }) => shape !== 'text' && '10px 20px'}!important;
  flex: 1;
  max-height: ${({ size }) =>
    size === 'small' ? '24px' : size === 'large' ? '42px' : '32px'};
  border-radius: 150px !important;
  font-size: ${({ size }) =>
    size === 'small' ? '12px' : size === 'large' ? '16px' : '14px'};
  transition: all 0.2s ease-in-out;

  cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'pointer')} !important;

  &:hover {
    ${({ shape, theme, color }) =>
      shape !== 'text' &&
      css`
        transition: all 0.2s ease-in-out;
        background: ${shape === 'gradient'
          ? theme.gradients[color || 'primary']
          : theme.colors[color || 'primary']} !important;

        filter: ${shape === 'outline' ? 'none' : 'brightness(0.85)'};

        &,
        p,
        svg {
          color: ${theme.colors.background} !important;
          transition: all 0.2s ease-in-out;
        }
      `}
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${({ shape }) =>
    shape === 'text' &&
    css`
      margin-top: 16px !important;
    `}

  .btn-loading {
    max-height: ${({ size }) =>
      size === 'small' ? '13px' : size === 'large' ? '25px' : '20px'};
    max-width: ${({ size }) =>
      size === 'small' ? '13px' : size === 'large' ? '25px' : '20px'};
    transition: all 0.2s ease-in-out;
    color: ${({ theme, shape, color }) =>
      shape === 'outline' || shape === 'text'
        ? theme.colors[color || 'primary']
        : theme.colors.background} !important;
  }
`;
