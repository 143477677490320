import {
  Delete,
  DeleteOutline,
  Menu,
  SettingsBackupRestore,
  Visibility,
} from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { FaEdit, FaEye, FaPaperclip } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { IAtaListViewModel } from 'viewModels';
import { useApp, useAta, useAuth } from '../../context';
import MenuPopup from '../MenuPopup';
import { ModalAttachFile, ModalViewAta } from '../Modals';
import { Container, WrapperIconTrashed } from './styles';

interface AtaProps extends IAtaListViewModel {
  showMenu?: boolean;
  readOnly?: boolean;
  onClick?: () => void;
  showTrashedMenu?: boolean;
}

const Ata: React.FC<AtaProps> = ({
  id,
  text,
  label,
  userId,
  //linkedTo,
  createdAt,
  //sharedWith,
  showMenu = true,
  readOnly = false,
  trashed = false,
  showTrashedMenu = false,
  onClick,
}) => {
  const { online } = useApp();
  const { handleAtaMenu, setAta, ata } = useAta();
  const { user, profile } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  //const [showModalLinkTo, setShowModalLinkTo] = useState(false);
  const [showModalViewAta, setShowModalViewAta] = useState(false);
  //const [showForwardModal, setShowForwardModal] = useState(false);
  //const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [displayModalAttachFile, setDisplayModalAttachFile] = useState(false);

  return (
    <Container
      disabled={trashed}
      isClickable={!!onClick}
      onClick={onClick}
      style={{ height: '100%' }}
    >
      <div>
        <p className="ata-name">
          {label || 'Nome'}: <span>{text || 'Não consta'}</span>
        </p>
        <p>
          Data:{' '}
          <span>
            {new Date(createdAt)?.toLocaleDateString('pt-BR') || '--/--/----'}
          </span>
        </p>
        {/* {linkedTo && (
          <p>
            Vinculo: <span>{linkedTo || 'Não consta'}</span>
          </p>
        )} */}
      </div>
      {showMenu &&
        (loading ? (
          <CircularProgress size={20} />
        ) : !online || readOnly ? (
          <IconButton color="primary" onClick={() => setShowModalViewAta(true)}>
            <FaEye />
          </IconButton>
        ) : !trashed ? (
          <MenuPopup
            options={
              [
                { id: 0, label: 'Visualizar', icon: <FaEye /> },
                //profile !== 'office' ?

                profile !== 'office' && {
                  id: 1,
                  label: 'Editar',
                  icon: <FaEdit />,
                },
                // : undefined
                {
                  id: 6,
                  label: 'Anexar',
                  icon: <FaPaperclip />,
                },
                /*{
                  id: 2,
                  label: 'Encaminhar',
                  icon: <SettingsBackupRestore />,
                },
                linkedTo || profile !== 'common' || user?.id !== userId
                  ? undefined
                  : {
                      id: 7,
                      label: 'Vincular',
                      icon: <Share />,
                    },
                !linkedTo &&
                  profile === 'common' &&
                  userId === user?.id && {
                    id: 8,
                    label: 'Permissões',
                    icon: <PermissionIcon />,
                  },*/
                {
                  id: 3,
                  label: 'Excluir',
                  icon: <DeleteOutline />,
                },
              ].filter((o) => !!o) as any
            }
            onSelect={async (option) => {
              setLoading(true);
              try {
                handleAtaMenu(option, id);
              } catch {
                //...
              }
              setLoading(false);
              if (option === 1)
                navigate('/atas/edit', { state: { ataId: id } });
              setShowModalViewAta(option === 0);
              //setShowForwardModal(option === 2);
              if (option === 6) setAta({ ...ata, id });
              setDisplayModalAttachFile(option === 6);

              //setShowModalLinkTo(option === 7);

              //setShowPermissionModal(option === 8);
            }}
          >
            <IconButton>
              <Menu />
            </IconButton>
          </MenuPopup>
        ) : showTrashedMenu ? (
          <MenuPopup
            options={
              [
                { id: 0, label: 'Visualizar', icon: <Visibility /> },
                {
                  id: 4,
                  label: 'Restaurar',
                  icon: <SettingsBackupRestore />,
                },

                userId === user?.id && {
                  id: 5,
                  label: 'Excluir',
                  icon: <Delete />,
                },
              ].filter((o) => !!o) as any
            }
            onSelect={(option) => {
              setLoading(true);
              try {
                handleAtaMenu(option, id);
              } catch {
                //...
              }
              setLoading(false);
              setShowModalViewAta(option === 0);
            }}
          >
            <IconButton>
              <Menu />
            </IconButton>
          </MenuPopup>
        ) : (
          <WrapperIconTrashed>
            {/* <IconArchived /> */}
            <p>Excluída</p>
          </WrapperIconTrashed>
        ))}
      <ModalViewAta
        ataId={showModalViewAta ? id : undefined}
        open={showModalViewAta}
        onClose={() => setShowModalViewAta(false)}
      />
      {/* <ModalForwardAta
        ataId={id}
        open={showForwardModal}
        close={() => setShowForwardModal(false)}
      /> */}
      {/* <ModalLinkTo
        ataId={showModalLinkTo ? id : ''}
        close={() => setShowModalLinkTo(false)}
        open={id !== undefined && showModalLinkTo}
        onClose={() => setShowModalLinkTo(false)}
      /> */}
      {/* <ModalPermissions
        ataId={showPermissionModal ? id : ''}
        userId={userId}
        sharedWith={sharedWith}
        close={() => setShowPermissionModal(false)}
        open={id !== undefined && showPermissionModal}
        onClose={() => setShowPermissionModal(false)}
      /> */}
      <ModalAttachFile
        close={() => setDisplayModalAttachFile(false)}
        open={displayModalAttachFile}
        onClose={() => setDisplayModalAttachFile(false)}
      />
    </Container>
  );
};

export default Ata;
