import styled from 'styled-components';
import { Screen } from '../../styles/global';

export const Container = styled(Screen)`
  padding: 0px 60px;
  padding-top: 100px;

  h1 {
    font-family: 'Futura Condensed PT' !important;
    font-size: 36px;
    color: ${({ theme }) => theme.colors.accent};
  }

  .content {
    display: flex;
    flex: 1;
    padding-bottom: 25px;
    color: ${({ theme }) => theme.colors.primary};

    h2 {
      font-family: 'Futura Condensed PT' !important;
      font-size: 36px;
      position: relative;
      overflow: hidden;
      padding-left: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 10px;
    }

    h2:before {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: -7.5px;
      width: 15px;
      height: 15px;
      grid-area: title;
      transform: rotate(45deg);
      background-color: ${({ theme }) => theme.colors.primary};
    }

    .list-empty {
      color: ${({ theme }) => theme.colors.text};
      font-style: italic;
    }
    .company {
      width: 320px;

      #company-atas {
        min-height: calc(33vh);
        max-height: calc(33vh);
        overflow: auto;
        grid-area: company;
      }

      #contacts {
        border-top: 1px solid ${({ theme }) => `${theme.colors.border}55`};
        padding-top: 10px;
        min-height: calc(30vh);
        max-height: calc(30vh);
        overflow: auto;
        grid-area: contacts;
      }

      &.as-user {
        width: 100%;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 320px 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
          'title contacts'
          'company user';

        #company-atas {
          max-height: calc(100vh - 240px);
        }

        #contacts {
          border: none;
          max-height: none;
          align-content: flex-start;
          display: flex;
          flex-wrap: wrap;

          column-gap: 10px;
        }
      }

      &.as-office {
        width: 100%;

        #contacts {
          border: none;
          max-height: none;
          align-content: flex-start;
          display: flex;
          flex-wrap: wrap;

          column-gap: 10px;
        }
      }
    }

    .offices {
      display: flex;
      overflow-x: auto;

      .office {
        min-width: 320px;
        border-right: 1px solid ${({ theme }) => `${theme.colors.text}88`};
        padding: 0px;

        .header {
          display: flex;
          align-items: center;
          position: relative;
          overflow: hidden;
          padding-left: 20px;
          margin: 0 30px;
          margin-bottom: 10px;

          h2 {
            font-size: 22px;
          }

          svg {
            width: 35px;
            height: 35px;
            margin-right: 10px;
            fill: ${({ theme }) => theme.colors.primary};
          }

          :before {
            content: ' ';
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: -7.5px;
            width: 15px;
            height: 15px;
            transform: rotate(45deg);
            background-color: ${({ theme }) => theme.colors.primary};
          }
        }

        .office-atas {
          padding: 0px 30px;
          min-height: calc(62vh);
          max-height: calc(62vh);
          overflow: auto;
        }
      }
    }

    .empty {
      .empty-container {
        display: flex;
        min-width: 680px;
        flex-direction: column;

        p {
          color: ${({ theme }) => theme.colors.text};
        }

        .empty-title {
          display: flex;

          & > p {
            min-width: 300px;
            white-space: pre-line;
          }
        }

        .empty-option {
          height: 100px;
          display: flex;
          padding-left: 30px;
          align-items: center;
          background-color: ${({ theme }) => theme.colors.f1};
          border-radius: 21px;
          margin-top: 20px;

          & > div {
            display: flex;
            width: 250px;
            align-items: center;

            .MuiInputBase-root {
              width: 300px;
            }

            & > svg {
              min-width: 35px;
              min-height: 35px;
              margin-right: 15px;
            }
          }

          button {
            max-width: 250px;
            margin: 0 auto;
          }
        }
      }
    }

    .row-atas-cards {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }

    .colunm-atas-cards {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex-wrap: wrap;
    }

    .ata-card {
      flex: 1 0 auto;
      min-width: 20rem;
      max-width: 25%;
    }

    .header-name {
      margin-right: 10px;
    }
  }
`;
