import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  p {
    font-family: 'Futura PT';
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text2};
  }

  div {
    flex: 1;
    height: 0.5px;
    background-color: black;
    margin: 4px 16px 0;
    background-color: ${({ theme }) => theme.colors.text};
  }
  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.red};
  }
`;
