import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

type Context = {
  isFormDirty: boolean;
  setIsFormDirty: Dispatch<SetStateAction<boolean>>;
};

type Provider = {
  children: ReactNode;
};

const FormDirtyContext = createContext({} as Context);

export const FormDirtyProvider = ({ children }: Provider) => {
  const [isFormDirty, setIsFormDirty] = useState(false);

  return (
    <FormDirtyContext.Provider value={{ isFormDirty, setIsFormDirty }}>
      {children}
    </FormDirtyContext.Provider>
  );
};

export const useFormDirty = () => useContext(FormDirtyContext);
