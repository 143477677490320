export const ATTACHMENT_TYPE_DESCRIPTIONS = [
  'Anexo',
  'Substabelecimento',
  'Protocolo',
  'Autorização',
  'Petição',
  'Carta de preposição',
  'Contestação',
  'Defesa',
  'Guia',
  'Comprovante',
  'Contrato',
  'Substabelecimento ou Autorização',
];
export const CODE_ERROR_OPENAI: { [key: string]: string } = {
  context_length_exceeded:
    'A ata excede o tamanho permitido; por favor, faça uma versão mais concisa.',
};

export const DEFAULT_PROMPT = `Analisar o documento de ata de audiência fornecido. Extrair e listar em formato JSON as seguintes informações: 
  \n1. Nome de todosdos reclamantes.
  \n2. Nome de todos advogados inclusive os da audiência.
  \n3. Nome de todos dos reclamados e nome de seus respectivos advogados.
  \n4. Identificação das partes envolvidas e nome de seus respectivos advogados.
  \n5. Detalhes da audiência incluindo:
   \n   a. Data da audiência.
   \n   b. Tipo de audiência.
   \n6. Informações detalhadas de todos os depoimentos, incluindo tipo de depoente e conteúdo dos depoimentos.
   \nAssegurar que a resposta esteja organizada e clara, facilitando a compreensão e o uso das informações extraídas conforme o modelo abaixo.
  interface IAtaOpenAI {
    numberProcess: string;
    hearingJudge: string;
    hearingLocals: string[];
    parts: 
      {
        claimants: IPartsAta[];
        claimed: IPartsAta[];
      };
    hearings: IHearingOpenAI[];
  }
  Abaixo segue os modelos de interfaces que são utilizados no modelo de retorno acima:
  interface IPartsAta {
    name: string;
    lawyersName: string[];
  }

   interface IHearingOpenAI {
    dateHearing?: Date;
    ataHearing: string;
    lawyersHearing: string[];
    depositionsHearing: IDepositionsOpenAI[];
  }
  interface IDepositionsOpenAI {
    textDeposition: string;
    nameDeponent: string;
    nickNameDeponent: string;
    contradicted?: boolean;
    typeDeponent?:
      | 'AUTHOR'
      | 'DEFENDANT'
      | 'AUTHOR_WITNESS'
      | 'AUTHOR_DEFENDANT';
  }
  `;
