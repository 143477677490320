import React, { useEffect, useRef, useState } from 'react';
import loadingGif from '../../assets/gif/loading.gif';
import { ActionLog, Header, Search } from '../../components';
import {
  useAta,
  useAttachments,
  useAuth,
  useNotification,
} from '../../context';
import { NotificationController, UserController } from '../../controllers';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import useSizeChange from '../../hooks/useSizeChange';
import { Container } from './styles';
import PieChart from '../../components/PieChart';
import { TermsConditions, PrivacyPolicyModal } from '../../components/Modals';

interface ChartData {
  type: string;
  value: number;
}

const Dashboard: React.FC = () => {
  const lastActionsRef = useRef<HTMLDivElement>(null);
  const pendingActionsRef = useRef<HTMLDivElement>(null);
  const { height: lastActionsHeight } = useSizeChange(lastActionsRef);
  const { height: pendingActionsHeight } = useSizeChange(pendingActionsRef);

  const { profile, user, setUser, activeOffice } = useAuth();
  const { actionLogs, setActionLogs, pendingActions, setPendingActions } =
    useNotification();
  const [isConfirmTermsConditions, setIsConfirmTermsConditions] =
    useState<boolean>(false);
  const [isOpenTermsConditions, setIsOpenTermsConditions] =
    useState<boolean>(false);
  const [isOpenPrivacyPolicyModal, setIsOpenPrivacyPolicyModal] =
    useState<boolean>(false);
  const [isConfirmPrivacyPolicyModal, setIsConfirmPrivacyPolicyModal] =
    useState<boolean>(false);

  const { atas } = useAta();
  const { attachments } = useAttachments();

  const [chartAtasData, setChartAtasData] = useState<ChartData[]>([]);
  const [chartAttachmentsData, setChartAttachmentsData] = useState<ChartData[]>(
    []
  );

  const { isFetching: isFetchingLastActions } = useInfiniteScroll({
    ref: lastActionsRef,
    initialData: actionLogs,
    query: NotificationController.getByUserId,
    take: Math.floor(lastActionsHeight / 40),

    params: {
      userId: user?.id || activeOffice?.id || '',
      filter: 'actionLogs',
    },
    onFetch: (data) =>
      setActionLogs((prev) => [
        ...prev,
        ...(data?.filter((i) => !prev.find((j) => j.id === i.id)) ?? []),
      ]),
  });

  const { isFetching: isFetchingPendingActions } = useInfiniteScroll({
    ref: pendingActionsRef,
    initialData: pendingActions,
    query: NotificationController.getByUserId,
    take: Math.floor(pendingActionsHeight / 40),
    params: {
      userId: user?.id || activeOffice?.id || '',
      filter: 'pending',
    },
    onFetch: (data) =>
      setPendingActions((prev) => [
        ...prev,
        ...(data?.filter((i) => !prev.find((j) => j.id === i.id)) ?? []),
      ]),
  });

  const handleConfirmationConditions = () => {
    setIsConfirmTermsConditions(true);
    setUser((prev) => ({ ...prev!, conditionsAccepted: true }));
    UserController.updateConditionsAccepted(user?.id!, true).catch((e) => {
      console.log(e);
    });
  };

  useEffect(() => {
    if (user?.conditionsAccepted !== true) {
      setIsOpenPrivacyPolicyModal(true);
      if (isConfirmPrivacyPolicyModal) {
        setIsOpenPrivacyPolicyModal(false);
        setIsOpenTermsConditions(true);
      }
      if (isConfirmTermsConditions) {
        setIsOpenTermsConditions(false);
      }
    }
  }, [isConfirmPrivacyPolicyModal, isConfirmTermsConditions]);

  useEffect(() => {
    const atasData: ChartData[] = [];
    const attachmentsData: ChartData[] = [];

    // Anexos que estao anexados a alguma Ata.
    const linkedAttachments = attachments.filter(
      (attachment) => attachment.hasLinkedAtas
    );

    // Atas criadas no ultimo mes.
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const recentAtas = atas.filter(
      (ata) => new Date(ata.createdAt) >= sevenDaysAgo
    );

    // Dados das Atas.
    atasData.push({ type: 'Recentes', value: recentAtas.length });
    atasData.push({ type: 'Total', value: atas.length });

    // Dados dos Anexos.
    if (linkedAttachments.length > 0) {
      attachmentsData.push({
        type: 'Anexados',
        value: linkedAttachments.length,
      });
    }

    if (attachments.length > 0) {
      attachmentsData.push({
        type: 'Não anexados',
        value: attachments.length - linkedAttachments.length,
      });
    }

    if (attachments.length > 0) {
      attachmentsData.push({
        type: 'Total',
        value: attachments.length,
      });
    }

    // Configura os novos dados aos seus respectivos States.
    setChartAtasData(atasData);
    setChartAttachmentsData(attachmentsData);
  }, [atas, attachments]);

  return (
    <Container>
      <Header showOptions />
      <div className="content">
        <Search />
        <div className="graph-dashboard">
          <div className="container">
            <h2>Dashboard</h2>
            {atas.length > 0 || attachments.length > 0 ? (
              <div className="dash-content">
                <div className="dash-chart-atas">
                  <PieChart data={chartAtasData} name="Atas" />
                </div>
                <div className="dash-chart-attachments">
                  <PieChart data={chartAttachmentsData} name="Anexos" />
                </div>
              </div>
            ) : (
              <div>
                <p className="empty-logs">Nenhuma Ata ou Anexo encontrado.</p>
              </div>
            )}
          </div>
        </div>
        <div className="dashboard">
          <div className="container">
            <h2>Ultimas atualizações</h2>
            <div className="items" ref={lastActionsRef}>
              {actionLogs.map((notification) => (
                <ActionLog key={notification.id} {...notification} />
              ))}
              {isFetchingLastActions ? (
                <div className="loading-action-logs">
                  <img src={loadingGif} alt="Legal Control Atas Loading" />
                  <p>Carregando logs...</p>
                </div>
              ) : (
                actionLogs.length === 0 && (
                  <p className="empty-logs">Nenhuma ação para mostrar.</p>
                )
              )}
            </div>
          </div>
          <div className="container">
            <h2>Ações pendentes</h2>
            <div className="items" ref={pendingActionsRef}>
              {pendingActions.map((notification) => (
                <ActionLog key={notification.id} {...notification} />
              ))}
              {isFetchingPendingActions ? (
                <div className="loading-action-logs">
                  <img src={loadingGif} alt="Legal Control Atas Loading" />
                  <p>Carregando logs...</p>
                </div>
              ) : (
                pendingActions.length === 0 && (
                  <p className="empty-logs">
                    Nenhuma ação pendente para mostrar.
                  </p>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <TermsConditions
        isOpen={isOpenTermsConditions}
        onClose={() => setIsOpenTermsConditions(false)}
        onConfirm={() => handleConfirmationConditions()}
      />
      <PrivacyPolicyModal
        isOpen={isOpenPrivacyPolicyModal}
        onClose={() => setIsOpenPrivacyPolicyModal(false)}
        onConfirm={() => setIsConfirmPrivacyPolicyModal(true)}
      />
    </Container>
  );
};

export default Dashboard;
