/* eslint-disable no-restricted-globals */
import { AddCircleOutline } from '@mui/icons-material';
import { Checkbox, IconButton } from '@mui/material';
import { IClaimed, IComplainant, IHearing } from 'entities';
import { v4 as uuid } from 'uuid';

import React, { useEffect, useMemo, useState } from 'react';
import { FaPaperclip, FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as IconUserPlus } from '../../../assets/svg/icon-user-plus.svg';
import { useAlert, useApp, useAta } from '../../../context';
import { useForceUpdate } from '../../../hooks/useForceUpdate';
import useStateSync from '../../../hooks/useStateSync';
import { TypeDeponent } from '../../../types/enums';
import { typesDeponent } from '../../../utils/typesDeponent';
import Button from '../../Button';
import Input from '../../Input';
import ItemFile from '../../ItemFile';
import { ModalAttachFile, ModalConfirm } from '../../Modals';
import { OptionsProps, Select } from '../../Select';
import {
  ButtonAdd,
  ButtonAttachFile,
  ButtonPlus,
  ContainerLawyers,
  Group,
  Lawyers,
  Line,
  WrapperButtons,
  WrapperContradicted,
  WrapperDeposition,
  WrapperFiles,
  WrapperHearing,
} from './styles';
import { getOpenAIResponse } from '../../../services/api/openai';
import { CODE_ERROR_OPENAI, DEFAULT_PROMPT } from '../../../constants';
import { useFormDirty } from '../../../context/formDirty';

type IChangeContradicted = {
  isContradicted?: boolean;
  hearingIdx: number;
  depositionIdx: number;
  currentHearing: IHearing;
};

interface IRegisterAtaProps {
  isEdit?: boolean;
}

const RegisterAta: React.FC<IRegisterAtaProps> = ({ isEdit = false }) => {
  const { online, setShowPhrases, setProcessingAta, processingAta } = useApp();
  const alert = useAlert();
  const navigate = useNavigate();
  const { ata, setAta } = useAta();
  const forceUpdate = useForceUpdate();
  const { isFormDirty, setIsFormDirty } = useFormDirty();
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  const [textOcr, setTextOcr] = useState<string>();
  const [alreadyProcessed, setAlreadyProcessed] = useState(false);
  const [claimantsChange, setClaimantsChange] = useState<IComplainant[]>(
    ata.claimants?.length > 0 ? ata.claimants : []
  );
  const [claimedChange, setClaimedChange] = useState<IClaimed[]>(
    ata.claimed?.length > 0 ? ata.claimed : []
  );

  const [displayModalAttachFile, setDisplayModalAttachFile] = useState(false);

  const [{ current: processNumber }, setProcessNumber] = useStateSync(
    ata.processNumber || ''
  );

  const [{ current: hearingJudge }, setHearingJudge] = useStateSync(
    ata.hearingJudge || ''
  );
  const [{ current: hearingsLocations }, setHearingsLocations] = useStateSync<
    { id: string; location: string }[]
  >(
    ata.hearingsLocations?.length
      ? ata.hearingsLocations?.map((location) => ({ id: uuid(), location }))
      : [
          {
            id: uuid(),
            location: '',
          },
        ]
  );

  const [{ current: claimants }, setClaimants] = useStateSync<IComplainant[]>(
    ata.claimants?.length
      ? ata.claimants?.map((c) => ({
          ...c,
          lawyers: c.lawyers?.length
            ? c.lawyers
            : [
                {
                  id: uuid(),
                  name: '',
                },
              ],
        }))
      : [
          {
            id: uuid(),
            name: '',
            lawyers: [
              {
                id: uuid(),
                name: '',
              },
            ],
          },
        ]
  );

  const [{ current: claimed }, setClaimed] = useStateSync<IClaimed[]>(
    ata.claimed?.length
      ? ata.claimed?.map((c) => ({
          ...c,
          lawyers: c.lawyers?.length ? c.lawyers : [{ id: uuid(), name: '' }],
        }))
      : [
          {
            id: uuid(),
            name: '',
            lawyers: [
              {
                id: uuid(),
                name: '',
              },
            ],
          },
        ]
  );

  const [{ current: hearings }, setHearings] = useStateSync<IHearing[]>(
    ata.hearings?.length
      ? ata.hearings?.map((h) => ({
          ...h,
          date: h.date ? new Date(h.date) : ('' as unknown as Date),
          lawyers: h.lawyers?.length ? h.lawyers : [{ id: uuid(), name: '' }],

          depositions: h.depositions?.length
            ? h.depositions
            : [
                {
                  id: uuid(),
                  nameDeponent: '',
                  nickNameDeponent: '',
                  text: '',
                  contradicted: undefined,
                  typeDeponent: undefined,
                },
              ],
        }))
      : [
          {
            id: uuid(),
            date: '' as unknown as Date,
            depositions: [
              {
                id: uuid(),
                nameDeponent: '',
                nickNameDeponent: '',
                text: '',
                contradicted: undefined,
                typeDeponent: undefined,
              },
            ],
            lawyers: [
              {
                id: uuid(),
                name: '',
              },
            ],
            observation: '',
          },
        ]
  );

  const handleAdd = (type: 'claimants' | 'claimed') => {
    if (type === 'claimants') {
      setClaimants([
        ...claimants,
        {
          id: uuid(),
          name: '',
          lawyers: [
            {
              id: uuid(),
              name: '',
            },
          ],
        },
      ]);
    } else if (type === 'claimed') {
      setClaimed([
        ...claimed,
        {
          id: uuid(),
          name: '',
          lawyers: [
            {
              id: uuid(),
              name: '',
            },
          ],
        },
      ]);
    }
    forceUpdate();
  };

  const handleDelete = (id: string, type: 'claimants' | 'claimed') => {
    let newData = type === 'claimants' ? [...claimants] : [...claimed];
    newData = newData.filter((i) => i.id !== id);
    if (type === 'claimants') setClaimants([...newData]);
    else setClaimed([...newData]);
    forceUpdate();
  };

  const handleOnChangeAttorney = (
    index: number,
    indexAttorney: number,
    text: string,
    type: 'claimants' | 'claimed'
  ) => {
    setIsFormDirty(true);
    const newData = type === 'claimants' ? [...claimants] : [...claimed];
    newData[index].lawyers[indexAttorney].name = text;
    if (type === 'claimants') {
      setClaimants([...newData]);
      setClaimantsChange([...newData]);
    } else {
      setClaimed([...newData]);
      setClaimedChange([...newData]);
    }
  };

  const handleDeleteAttorney = (
    index: number,
    id: string,
    type: 'claimants' | 'claimed'
  ) => {
    const newData = type === 'claimants' ? [...claimants] : [...claimed];
    newData[index].lawyers = newData[index].lawyers.filter((l) => l.id !== id);
    if (type === 'claimants') {
      setClaimants([...newData]);
      setClaimantsChange([...newData]);
    } else {
      setClaimed([...newData]);
      setClaimedChange([...newData]);
    }
    forceUpdate();
  };

  const handleDeleteDeposition = (id: string, hearingIdx: number) => {
    setHearings(
      hearings.map((h, index) => {
        if (index === hearingIdx) {
          return {
            ...h,
            depositions: h.depositions.filter((d) => d.id !== id),
          };
        }
        return h;
      })
    );
    forceUpdate();
  };

  const removeLawyersEmpty = (data: IComplainant[] | IClaimed[]) => {
    return data.map((c) => ({
      ...c,
      lawyers:
        c.lawyers.length > 0
          ? c.lawyers.filter((l: any) => !!l.name)
          : c.lawyers,
    }));
  };

  const handleReduce = (data: IComplainant[] | IClaimed[]) => {
    if (data.length > 0) {
      const filteredData = data.filter(
        (c) => !!c.name || c.lawyers?.some((l) => !!l.name)
      );
      return removeLawyersEmpty(filteredData);
    }
    return removeLawyersEmpty(data);
  };

  const handleReduceHearings = () => {
    return hearings.length > 0
      ? hearings
          .map((h) => ({
            ...h,
            lawyers: h.lawyers?.filter((l) => !!l.name),

            depositions: h.depositions.filter(
              (d) =>
                !!d.nameDeponent ||
                !!d.nickNameDeponent ||
                !!d.text ||
                d.contradicted !== undefined ||
                d.typeDeponent !== undefined
            ),
          }))
          .filter(
            (h) =>
              // eslint-disable-next-line no-restricted-globals
              (h.date instanceof Date && !isNaN(h.date.valueOf())) ||
              !!h.observation ||
              !!h.lawyers?.length ||
              !!h.depositions?.length
          )
      : hearings;
  };

  const handleSave = () => {
    setIsFormDirty(false);
    const newAta = {
      ...ata,
      hearingJudge: hearingJudge.trim(),
      processNumber: processNumber.trim(),
      hearingsLocations: hearingsLocations
        ?.map(({ location }) => location.trim())
        ?.filter((l) => !!l),
      claimants: handleReduce(claimants),
      claimed: handleReduce(claimed),
      hearings: handleReduceHearings(),
    };

    setAta({
      ...newAta,
    });

    navigate('/atas/register/finish', {
      state: {
        isEdit,
      },
    });
  };

  const handleAddAttorneyClaimants = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    const newClaimants = [...claimants];
    newClaimants[index].lawyers.push({
      id: uuid(),
      name: '',
    });
    setClaimants([...newClaimants]);
    setClaimantsChange([...newClaimants]);
    e.stopPropagation();
    forceUpdate();
  };

  const handleAddAttorneyClaimed = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    const newClaimed = [...claimed];
    newClaimed[index].lawyers.push({
      id: uuid(),
      name: '',
    });
    setClaimed([...newClaimed]);
    setClaimedChange([...newClaimed]);
    e.stopPropagation();
    forceUpdate();
  };

  const handleChangeContradicted = ({
    isContradicted,
    hearingIdx,
    depositionIdx,
    currentHearing,
  }: IChangeContradicted) => {
    setIsFormDirty(true);
    setHearings([
      ...hearings.slice(0, hearingIdx),
      {
        ...currentHearing,
        depositions: [
          ...currentHearing.depositions.slice(0, depositionIdx),
          {
            ...currentHearing.depositions[depositionIdx],
            contradicted: isContradicted,
          },
          ...currentHearing.depositions.slice(depositionIdx + 1),
        ],
      },
      ...hearings.slice(hearingIdx + 1),
    ]);
    forceUpdate();
  };

  const lawyers = useMemo(() => {
    const data: OptionsProps[] = [];

    claimantsChange?.forEach((c, index) =>
      c.lawyers
        .filter((l) => !!l.name && l.name !== '')
        .forEach((cl) => {
          data.push({
            key: cl.id,
            label: `${c.name ? `${cl.name} - ` : cl.name} ${
              c.name
            } / Reclamante ${index + 1}`,
            value: cl.name,
          });
        })
    );

    claimedChange?.forEach((c, index) =>
      c.lawyers
        .filter((l) => !!l.name && l.name !== '')
        .forEach((cl) => {
          data.push({
            key: cl.id,
            label: `${c.name ? `${cl.name} - ` : cl.name} ${
              c.name
            } / Reclamado ${index + 1}`,
            value: cl.name,
          });
        })
    );

    setHearings([
      ...hearings.map((hearing) => ({
        ...hearing,
        lawyers: hearing.lawyers.map((lawyer) => {
          let { name } = lawyer;
          const hearingIdx = hearings.findIndex((h) =>
            h.lawyers.some((l) => l.id === lawyer.id)
          );

          if (hearingIdx >= 0) {
            name =
              hearings[hearingIdx].lawyers.find((l) => l.id === lawyer.id)
                ?.name ?? lawyer.name;
          }

          return {
            ...lawyer,
            name,
          };
        }),
      })),
    ]);

    return data;
  }, [claimantsChange, claimedChange, hearings, setHearings]);

  const handleAutoFillForm = async () => {
    if (textOcr) {
      setIsFormDirty(true);
      setProcessingAta(true);
      setShowPhrases(true);
      getOpenAIResponse(`${DEFAULT_PROMPT} ${textOcr}`)
        .then((res) => {
          if (res && res.data) {
            setProcessNumber(res.data.numberProcess);
            setHearingJudge(res.data.hearingJudge);
            setHearingsLocations(
              res.data.hearingLocals.map((location) => ({
                id: uuid(),
                location,
              }))
            );

            setClaimants(
              res.data.parts.claimants.map((claimant) => ({
                id: uuid(),
                name: claimant.name,
                lawyers: claimant.lawyersName.length
                  ? claimant.lawyersName.map((lawyerName) => ({
                      id: uuid(),
                      name: lawyerName ?? '',
                    }))
                  : [{ id: uuid(), name: '' }],
              }))
            );

            setClaimantsChange(
              res.data.parts.claimants.map((claimant) => ({
                id: uuid(),
                name: claimant.name,
                lawyers: claimant.lawyersName.map((lawyerName) => ({
                  id: uuid(),
                  name: lawyerName ?? '',
                })),
              }))
            );

            setClaimed(
              res.data.parts.claimed.map((c) => ({
                id: uuid(),
                name: c.name,
                lawyers: c.lawyersName.length
                  ? c.lawyersName.map((lawyerName) => ({
                      id: uuid(),
                      name: lawyerName ?? '',
                    }))
                  : [{ id: uuid(), name: '' }],
              }))
            );

            setClaimedChange(
              res.data.parts.claimed.map((c) => ({
                id: uuid(),
                name: c.name,
                lawyers: c.lawyersName.map((lawyerName) => ({
                  id: uuid(),
                  name: lawyerName ?? '',
                })),
              }))
            );

            setHearings(
              res.data.hearings.map((hearing) => ({
                id: uuid(),
                date: hearing.dateHearing
                  ? new Date(hearing.dateHearing)
                  : ('' as unknown as Date),
                observation: hearing.ataHearing,
                lawyers: hearing.lawyersHearing.map((lawyerName) => ({
                  id: uuid(),
                  name: lawyerName ?? '',
                })),
                depositions: hearing.depositionsHearing.map((deposition) => ({
                  id: uuid(),
                  nameDeponent: deposition.nameDeponent,
                  nickNameDeponent: deposition.nickNameDeponent,
                  text: deposition.textDeposition,
                  contradicted: deposition.contradicted,
                  typeDeponent:
                    deposition.typeDeponent === 'AUTHOR'
                      ? TypeDeponent.AUTHOR
                      : deposition.typeDeponent === 'DEFENDANT'
                      ? TypeDeponent.DEFENDANT
                      : deposition.typeDeponent === 'AUTHOR_WITNESS'
                      ? TypeDeponent.AUTHOR_WITNESS
                      : deposition.typeDeponent === 'AUTHOR_DEFENDANT'
                      ? TypeDeponent.AUTHOR_DEFENDANT
                      : undefined,
                })),
              }))
            );

            setAlreadyProcessed(true);
            alert.show({
              type: 'sucess',
              message: `Solicitamos uma revisão cuidadosa para garantir a exatidão de todos os dados. Certifique-se que todos os campos estão preenchidos e preencha manualmente os que faltarem.`,
            });
            forceUpdate();
          } else {
            const messageError = res?.errorCode
              ? CODE_ERROR_OPENAI[res.errorCode]
              : undefined;
            alert.show({
              type: 'error',
              title: 'Não foi possível processar a Ata!',
              errors: messageError ? [messageError] : [],
            });
          }
        })
        .catch(() => {
          alert.show({
            type: 'error',
            errors: [
              'Não foi possível processar a Ata! Tente novamente mais tarde',
            ],
          });
        })
        .finally(() => {
          setProcessingAta(false);
          setShowPhrases(false);
        });
    }
  };

  const checkFormDirty = () => {
    const hasValue =
      processNumber.trim() !== '' ||
      hearingJudge.trim() !== '' ||
      hearingsLocations.some((loc) => loc.location.trim() !== '') ||
      claimants.some(
        (cl) =>
          cl.name.trim() !== '' ||
          cl.lawyers.some((law) => law.name.trim() !== '')
      ) ||
      claimed.some(
        (cl) =>
          cl.name.trim() !== '' ||
          cl.lawyers.some((law) => law.name.trim() !== '')
      ) ||
      hearings.some((h) => {
        return (
          h.observation.trim() !== '' ||
          h.lawyers.some((law) => law.name.trim() !== '') ||
          h.depositions.some(
            (dep) =>
              dep.nameDeponent.trim() !== '' ||
              dep.nickNameDeponent.trim() !== '' ||
              dep.text.trim() !== ''
          )
        );
      });

    setIsFormDirty(hasValue);
  };

  const handleOnChangeInput = () => {
    setTimeout(checkFormDirty, 0);
  };

  useEffect(() => {
    checkFormDirty();
  }, [
    processNumber,
    hearingJudge,
    hearingsLocations,
    claimants,
    claimed,
    hearings,
  ]);

  useEffect(() => {
    setTextOcr(() => ata.files?.map((f) => (f.text ? f.text : '')).join('\n'));
  }, [ata.files]);

  useEffect(() => {
    if (!isFormDirty) return;

    const handleOnBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      // eslint-disable-next-line no-return-assign
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleOnBeforeUnload, {
      capture: true,
    });

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload, {
        capture: true,
      });
    };
  }, [isFormDirty]);

  return (
    <div id="register-ata-form">
      {!isEdit && textOcr && !alreadyProcessed && (
        <Button
          onClick={() => setIsOpenModalConfirm(true)}
          disabled={
            !online ||
            !textOcr ||
            alreadyProcessed ||
            isOpenModalConfirm ||
            processingAta
          }
          shape="outline"
          size="medium"
          style={{
            maxWidth: 250,
            marginTop: 16,
          }}
        >
          Automatizar preenchimento
        </Button>
      )}
      <Group>
        <Line>
          <Input
            value={processNumber || ata.processNumber || ''}
            label="Número do processo"
            maxLength={200}
            onChange={(e) => {
              handleOnChangeInput();
              setProcessNumber(e.target.value);
              forceUpdate();
              e.stopPropagation();
            }}
          />
          <Input
            value={hearingJudge || ata.hearingJudge || ''}
            label="Juiz da audiência"
            maxLength={200}
            onChange={(e) => {
              handleOnChangeInput();
              setHearingJudge(e.target.value);
              forceUpdate();
              e.stopPropagation();
            }}
          />
        </Line>
        {hearingsLocations.map(({ id, location }, index) => (
          <Line key={id}>
            <Input
              defaultValue={location}
              label={`Local da audiência ${index + 1}`}
              maxLength={1000}
              onChange={(e) => {
                handleOnChangeInput();
                setHearingsLocations([
                  ...(hearingsLocations || []).slice(0, index),
                  {
                    ...hearingsLocations[index],
                    location: e.target.value,
                  },
                  ...(hearingsLocations || []).slice(index + 1),
                ]);
              }}
              onBlur={() => forceUpdate()}
              endAdornment={
                hearingsLocations.length > 1 && (
                  <IconButton
                    style={{ marginBottom: 4 }}
                    onClick={(e) => {
                      setHearingsLocations([
                        ...(hearingsLocations || []).slice(0, index),
                        ...(hearingsLocations || []).slice(index + 1),
                      ]);
                      forceUpdate();
                      e.stopPropagation();
                    }}
                  >
                    <FaTrashAlt className="icon-delete" />
                  </IconButton>
                )
              }
            />
          </Line>
        ))}
        <ButtonPlus
          onClick={() => {
            setHearingsLocations([
              ...(hearingsLocations || []),
              {
                id: uuid(),
                location: '',
              },
            ]);
            forceUpdate();
          }}
        >
          <AddCircleOutline />
          Adicionar local
        </ButtonPlus>
      </Group>
      <h2>Reclamantes</h2>
      <Group>
        {claimants.map((c, idx) => (
          <Line key={c.id}>
            <Input
              defaultValue={c.name}
              label={`Reclamante ${idx + 1}`}
              maxLength={200}
              onChange={(e) => {
                handleOnChangeInput();
                const newClaimants = [...claimants];
                newClaimants[idx].name = e.target.value;
                setClaimants([...newClaimants]);
                setClaimantsChange([...newClaimants]);
              }}
              endAdornment={
                claimants.length > 1 && (
                  <IconButton
                    style={{ marginBottom: 4 }}
                    onClick={(e) => {
                      handleDelete(c.id, 'claimants');
                      e.stopPropagation();
                    }}
                  >
                    <FaTrashAlt className="icon-delete" />
                  </IconButton>
                )
              }
            />
            <ContainerLawyers>
              {c.lawyers.map(({ id, name }, idxAttorney) => (
                <Lawyers key={id}>
                  <Input
                    defaultValue={name}
                    maxLength={200}
                    label={`Advogado ${idxAttorney + 1} do reclamante ${
                      idx + 1
                    }`}
                    onChange={(e) => {
                      handleOnChangeAttorney(
                        idx,
                        idxAttorney,
                        e.target.value,
                        'claimants'
                      );
                    }}
                  />

                  <WrapperButtons>
                    {idxAttorney === c.lawyers.length - 1 && (
                      <ButtonPlus
                        onClick={(e) => {
                          handleAddAttorneyClaimants(e, idx);
                        }}
                      >
                        <AddCircleOutline />
                        <p>Adicionar advogado</p>
                      </ButtonPlus>
                    )}
                    {c.lawyers.length > 1 && (
                      <IconButton
                        onClick={(e) => {
                          handleDeleteAttorney(idx, id, 'claimants');
                          e.stopPropagation();
                        }}
                      >
                        <FaTrashAlt className="icon-delete" />
                      </IconButton>
                    )}
                  </WrapperButtons>
                </Lawyers>
              ))}
            </ContainerLawyers>
          </Line>
        ))}
        <Line>
          <ButtonAdd onClick={() => handleAdd('claimants')}>
            <IconUserPlus />
            Adicionar reclamante
          </ButtonAdd>
        </Line>
      </Group>
      <h2>Reclamados</h2>
      <Group>
        {claimed.map((c, idx) => (
          <Line key={c.id}>
            <Input
              defaultValue={c.name}
              label={`Reclamado ${idx + 1}`}
              maxLength={200}
              onChange={(e) => {
                handleOnChangeInput();
                const newClaimed = [...claimed];
                newClaimed[idx].name = e.target.value;
                setClaimed([...newClaimed]);
              }}
              endAdornment={
                claimed.length > 1 && (
                  <IconButton
                    style={{ marginBottom: 4 }}
                    onClick={(e) => {
                      handleDelete(c.id, 'claimed');
                      e.stopPropagation();
                    }}
                  >
                    <FaTrashAlt className="icon-delete" />
                  </IconButton>
                )
              }
            />
            <ContainerLawyers>
              {c.lawyers.map(({ id, name }, idxAttorney) => (
                <Lawyers key={id}>
                  <Input
                    defaultValue={name}
                    maxLength={200}
                    label={`Advogado ${idxAttorney + 1} do reclamado ${
                      idx + 1
                    }`}
                    onChange={(e) => {
                      handleOnChangeInput();
                      handleOnChangeAttorney(
                        idx,
                        idxAttorney,
                        e.target.value,
                        'claimed'
                      );
                    }}
                  />
                  <WrapperButtons>
                    {idxAttorney === c.lawyers.length - 1 && (
                      <ButtonPlus
                        onClick={(e) => handleAddAttorneyClaimed(e, idx)}
                      >
                        <AddCircleOutline />
                        <p>Adicionar advogado</p>
                      </ButtonPlus>
                    )}
                    {c.lawyers.length > 1 && (
                      <IconButton
                        onClick={(e) => {
                          handleDeleteAttorney(idx, id, 'claimed');
                          e.stopPropagation();
                        }}
                      >
                        <FaTrashAlt className="icon-delete" />
                      </IconButton>
                    )}
                  </WrapperButtons>
                </Lawyers>
              ))}
            </ContainerLawyers>
          </Line>
        ))}
        <Line>
          <ButtonAdd onClick={() => handleAdd('claimed')}>
            <IconUserPlus />
            Adicionar reclamado
          </ButtonAdd>
        </Line>
      </Group>
      <h2>Audiências</h2>
      <Group>
        {hearings.map((hearing, hearingIdx) => (
          <WrapperHearing key={hearing.id}>
            <Line>
              <div className="title">
                <h3>
                  Audiência {hearingIdx + 1} {}
                </h3>
                {hearings.length > 1 && (
                  <IconButton
                    onClick={(e) => {
                      setHearings(
                        (hearings || []).filter((h) => h.id !== hearing.id)
                      );
                      forceUpdate();
                      e.stopPropagation();
                    }}
                  >
                    <FaTrashAlt className="icon-delete" />
                  </IconButton>
                )}
              </div>
              <Input
                id="hearing-date"
                type="date"
                defaultValue={
                  hearing.date instanceof Date && !isNaN(hearing.date.valueOf())
                    ? new Date(hearing.date)?.toISOString()?.split('T')?.[0]
                    : ''
                }
                onChange={(e) => {
                  setHearings([
                    ...hearings.slice(0, hearingIdx),
                    {
                      ...hearing,
                      date: new Date(e.target.value),
                    },
                    ...hearings.slice(hearingIdx + 1),
                  ]);
                  forceUpdate();
                }}
              />
            </Line>
            <div style={{ height: 16 }} />
            <Line>
              <Input
                defaultValue={hearing.observation}
                label="Observações"
                maxLength={512 * 1024}
                multiline
                rows={3}
                resize="vertical"
                variant="outlined"
                onChange={(e) => {
                  handleOnChangeInput();
                  setHearings([
                    ...(hearings || []).slice(0, hearingIdx),
                    { ...hearing, observation: e.target.value },
                    ...(hearings || []).slice(hearingIdx + 1),
                  ]);
                  forceUpdate();
                }}
              />
            </Line>
            <div style={{ height: 16 }} />
            <h4>Advogados</h4>
            <div className="hearing-items">
              {hearing.lawyers.map(({ id, name }, lawyerIndex) => {
                return (
                  <Lawyers key={id}>
                    <Select
                      defaultValue={
                        lawyers.find((l) => l.label === name)?.key ??
                        lawyers.find((l) => l.value === name)?.key ??
                        'none'
                      }
                      label="Selecione"
                      onChange={(value) => {
                        handleOnChangeInput();
                        if (value && typeof value === 'string') {
                          const lawyerName = lawyers.find(
                            (l) => l.key === value
                          )?.label as string;
                          const newHearings = [...hearings];
                          newHearings[hearingIdx].lawyers[lawyerIndex].name =
                            lawyerName;
                          setHearings([...newHearings]);
                          forceUpdate();
                        }
                      }}
                      options={lawyers}
                    />

                    <WrapperButtons>
                      {lawyerIndex === hearing.lawyers.length - 1 && (
                        <ButtonPlus
                          onClick={(e) => {
                            setHearings([
                              ...(hearings || []).slice(0, hearingIdx),
                              {
                                ...hearing,
                                lawyers: [
                                  ...hearing.lawyers,
                                  { id: uuid(), name: '' },
                                ],
                              },
                              ...(hearings || []).slice(hearingIdx + 1),
                            ]);
                            forceUpdate();
                            e.stopPropagation();
                          }}
                        >
                          <AddCircleOutline />
                          <p>Adicionar advogado</p>
                        </ButtonPlus>
                      )}
                      {hearing.lawyers.length > 1 && (
                        <IconButton
                          onClick={(e) => {
                            setHearings([
                              ...(hearings || []).slice(0, hearingIdx),
                              {
                                ...hearing,
                                lawyers: hearing.lawyers.filter(
                                  (_, lidx) => lawyerIndex !== lidx
                                ),
                              },
                              ...(hearings || []).slice(hearingIdx + 1),
                            ]);

                            forceUpdate();
                            e.stopPropagation();
                          }}
                        >
                          <FaTrashAlt className="icon-delete" />
                        </IconButton>
                      )}
                    </WrapperButtons>
                  </Lawyers>
                );
              })}
            </div>
            <div style={{ height: 16 }} />

            <h4>Depoimentos</h4>
            {hearing.depositions.map(
              (
                {
                  id,
                  nameDeponent,
                  nickNameDeponent,
                  text,
                  contradicted,
                  typeDeponent,
                },
                depositionIdx
              ) => (
                <WrapperDeposition key={id}>
                  <Line>
                    <Input
                      defaultValue={nameDeponent}
                      label={`Nome do depoente ${
                        depositionIdx + 1
                      } da audiência ${hearingIdx + 1}`}
                      maxLength={200}
                      onChange={(e) => {
                        handleOnChangeInput();
                        setHearings([
                          ...(hearings || []).slice(0, hearingIdx),
                          {
                            ...hearing,
                            depositions: [
                              ...hearing.depositions.slice(0, depositionIdx),
                              {
                                ...hearing.depositions[depositionIdx],
                                nameDeponent: e.target.value,
                              },
                              ...hearing.depositions.slice(depositionIdx + 1),
                            ],
                          },
                          ...(hearings || []).slice(hearingIdx + 1),
                        ]);
                        forceUpdate();
                        e.stopPropagation();
                      }}
                    />
                  </Line>
                  <Line>
                    <Input
                      defaultValue={nickNameDeponent}
                      label={`Apelido do depoente ${
                        depositionIdx + 1
                      } da audiência ${hearingIdx + 1}`}
                      maxLength={200}
                      onChange={(e) => {
                        handleOnChangeInput();
                        setHearings([
                          ...(hearings || []).slice(0, hearingIdx),
                          {
                            ...hearing,
                            depositions: [
                              ...hearing.depositions.slice(0, depositionIdx),
                              {
                                ...hearing.depositions[depositionIdx],
                                nickNameDeponent: e.target.value,
                              },
                              ...hearing.depositions.slice(depositionIdx + 1),
                            ],
                          },
                          ...(hearings || []).slice(hearingIdx + 1),
                        ]);
                        forceUpdate();
                        e.stopPropagation();
                      }}
                    />
                    <Select
                      defaultValue={
                        typesDeponent.find((f) => f.key === typeDeponent)?.key
                      }
                      label="Classificação do depoente"
                      onChange={(value) => {
                        handleOnChangeInput();
                        setHearings([
                          ...(hearings || []).slice(0, hearingIdx),
                          {
                            ...hearing,
                            depositions: [
                              ...hearing.depositions.slice(0, depositionIdx),
                              {
                                ...hearing.depositions[depositionIdx],
                                typeDeponent: value as TypeDeponent,
                              },
                              ...hearing.depositions.slice(depositionIdx + 1),
                            ],
                          },
                          ...(hearings || []).slice(hearingIdx + 1),
                        ]);
                        forceUpdate();
                      }}
                      options={typesDeponent}
                    />
                  </Line>
                  <div style={{ height: 16 }} />
                  <Line>
                    <Input
                      defaultValue={text}
                      label={`Depoimento ${depositionIdx + 1} da audiência ${
                        hearingIdx + 1
                      }`}
                      maxLength={512 * 1024}
                      multiline
                      rows={3}
                      resize="vertical"
                      variant="outlined"
                      onChange={(e) => {
                        handleOnChangeInput();
                        setHearings([
                          ...(hearings || []).slice(0, hearingIdx),
                          {
                            ...hearing,
                            depositions: [
                              ...hearing.depositions.slice(0, depositionIdx),
                              {
                                ...hearing.depositions[depositionIdx],
                                text: e.target.value,
                              },
                              ...hearing.depositions.slice(depositionIdx + 1),
                            ],
                          },
                          ...(hearings || []).slice(hearingIdx + 1),
                        ]);
                        forceUpdate();
                        e.stopPropagation();
                      }}
                    />
                  </Line>

                  <WrapperContradicted>
                    <h2>Contraditas</h2>
                    <div className="checkbox-wrapper">
                      <Checkbox
                        checked={contradicted === true}
                        onChange={() =>
                          handleChangeContradicted({
                            isContradicted:
                              contradicted === true ? undefined : true,
                            hearingIdx,
                            depositionIdx,
                            currentHearing: hearing,
                          })
                        }
                      />
                      <p>Deferida</p>
                    </div>
                    <div className="checkbox-wrapper">
                      <Checkbox
                        checked={contradicted === false}
                        onChange={() =>
                          handleChangeContradicted({
                            isContradicted:
                              contradicted === false ? undefined : false,
                            hearingIdx,
                            depositionIdx,
                            currentHearing: hearing,
                          })
                        }
                      />
                      <p>Indeferida</p>
                    </div>
                  </WrapperContradicted>

                  <WrapperButtons>
                    {depositionIdx === hearing.depositions.length - 1 && (
                      <ButtonPlus
                        onClick={(e) => {
                          setHearings([
                            ...(hearings || []).slice(0, hearingIdx),
                            {
                              ...hearing,
                              depositions: [
                                ...hearing.depositions,
                                {
                                  id: uuid(),
                                  nameDeponent: '',
                                  nickNameDeponent: '',
                                  text: '',
                                  contradicted: undefined,
                                },
                              ],
                            },
                            ...(hearings || []).slice(hearingIdx + 1),
                          ]);
                          forceUpdate();
                          e.stopPropagation();
                        }}
                      >
                        <AddCircleOutline />
                        <p>Adicionar depoimento</p>
                      </ButtonPlus>
                    )}
                    {hearing.depositions.length > 1 && (
                      <IconButton
                        className="btn-delete-deposition"
                        onClick={(e) => {
                          handleDeleteDeposition(id, hearingIdx);
                          forceUpdate();
                          e.stopPropagation();
                        }}
                      >
                        <FaTrashAlt className="icon-delete" />
                      </IconButton>
                    )}
                  </WrapperButtons>
                  <div style={{ height: 16 }} />
                </WrapperDeposition>
              )
            )}
          </WrapperHearing>
        ))}

        <Line>
          <ButtonAdd
            onClick={() => {
              setHearings([
                ...(hearings || []),
                {
                  id: uuid(),
                  date: '' as unknown as Date,
                  observation: '',
                  lawyers: [{ id: uuid(), name: '' }],
                  depositions: [
                    {
                      id: uuid(),
                      nameDeponent: '',
                      nickNameDeponent: '',
                      text: '',
                    },
                  ],
                },
              ]);
              forceUpdate();
            }}
          >
            <IconUserPlus />
            Nova audiência
          </ButtonAdd>
        </Line>
      </Group>
      <WrapperFiles>
        {!!ata.attachments?.length &&
          ata.attachments?.map((att) => <ItemFile key={att.id} att={att} />)}
      </WrapperFiles>

      <ButtonAttachFile
        onClick={() => setDisplayModalAttachFile(true)}
        disabled={!online}
      >
        <FaPaperclip size={24} />
        Anexar um arquivo
      </ButtonAttachFile>
      <div style={{ height: 16 }} />

      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button
          onClick={handleSave}
          shape="outline"
          size="large"
          style={{
            maxWidth: 250,
            marginTop: 16,
          }}
        >
          {isEdit ? 'Atualizar ata' : 'Salvar ata'}
        </Button>
      </div>

      <ModalAttachFile
        close={() => setDisplayModalAttachFile(false)}
        open={displayModalAttachFile}
        onClose={() => setDisplayModalAttachFile(false)}
      />
      <ModalConfirm
        title="Este procedimento de processamento da Ata pode levar alguns minutos. Deseja continuar?"
        onClose={() => setIsOpenModalConfirm(false)}
        open={isOpenModalConfirm}
        onConfirm={handleAutoFillForm}
        onCancel={() => setIsOpenModalConfirm(false)}
      />
    </div>
  );
};

export default RegisterAta;
