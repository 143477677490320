import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  & > p {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.text};
  }

  #ocr-files-input {
    width: 100%;
    display: flex;
    align-items: center;

    button {
      padding: 5px 10px;
      margin-left: -10px;
      max-width: fit-content;
    }

    .file-reading-loading {
      margin-top: 16px;
      margin-left: auto;
      display: flex;
      align-items: center;

      p {
        font-size: 12px;
        margin-left: 10px;
        color: ${({ theme }) => theme.colors.text};
      }
    }
  }
`;

export const ButtonAttachFile = styled.button`
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  font-family: 'Futura PT';
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  text-decoration: underline;
  width: max-content;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const WrapperFiles = styled.div`
  margin: 15px 0;
  max-height: 320px;
  overflow-y: auto;
`;

interface OcrProps {
  maxHeight?: number;
}
export const WrapperOcr = styled.div<OcrProps>`
  border: 1px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.text};
  word-wrap: break-word;
  max-height: ${({ maxHeight }) =>
    maxHeight ? `${maxHeight - 30}px` : 'none'};
  overflow-y: auto;

  p {
    padding: 10px;
    font-size: 16px;
    font-weight: 200;
  }
`;
