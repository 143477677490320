import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  .MuiCheckbox-root {
    padding-left: 0;
  }
  .icon-file {
    min-width: 24px;
    height: auto;
  }
  .icon-view {
    color: ${({ theme }) => theme.colors.primary};
    width: 24px;
    height: 24px;
  }

  .icon-delete {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.red};
  }

  button {
    transition: all 0.2s ease-in-out;

    &:disabled {
      opacity: 0.5;
      cursor: default;
      transition: all 0.2s ease-in-out;
    }
  }
`;

export const Line = styled.div`
  flex: 1;
  ${({ theme: { colors } }) => css`
    background: ${colors.background};
    border: 0.5px dashed ${colors.text};
  `}
  margin: 4px 16px 0;
`;

export const Filename = styled.p`
  width: max-content;
  max-width: 150px;
  font-family: 'Futura PT';
  font-weight: 200;
  font-size: 16px;
  margin-left: 8px;
  ${({ theme: { colors } }) => css`
    color: ${colors.text4};
  `}

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
