import React, { createContext, useCallback, useContext, useState } from 'react';
import {
  IAtaListViewModel,
  IAtaMoveToTrashViewModel,
  IAtaRestoreViewModel,
} from 'viewModels';

type ActionsTypes = {
  LOGOUT: undefined;
  ATA_DISABLED: string;
  DISABLE_ACCOUNT: string;
  RESTORE_ACCOUNT: string;
  CHANGE_ACCOUNT: undefined;
  ATA_CREATED: IAtaListViewModel;
  ATA_UPDATED: IAtaListViewModel;
  ATA_TRANSFER: { ownerId: string };
  ATA_RESTORED: IAtaListViewModel & IAtaRestoreViewModel;
  TRASHED_ATA: IAtaListViewModel & IAtaMoveToTrashViewModel;
  CONTACT_NEW_OWNER: { newOwnerId: string };
  ATA_LINK_TO: { ataId: string; linkedToId: string; linkedTo: string };
  NEW_CONTACT_ATA: IAtaListViewModel & {
    contactId: string;
    companyId?: string;
  };
};

type ActionKey = keyof ActionsTypes;
type Payload<K extends ActionKey> = ActionsTypes[K];
type CallbackFn<K extends ActionKey> = (payload: Payload<K>) => void;
type SubscribeFn = <K extends ActionKey>(
  action: K,
  callback: CallbackFn<K>
) => () => void;

type DispatchFn = <K extends ActionKey>(action: K, payload: Payload<K>) => void;

type ActionsContextProps = {
  subscribe: SubscribeFn;
  dispatch: DispatchFn;
  countListeners: (key: ActionKey) => number;
};

const ActionsContext = createContext<ActionsContextProps>(
  {} as ActionsContextProps
);

const Actions: React.FC = ({ children }) => {
  const [subscriptions, setSubscriptions] = useState<{
    [key: string]: any[];
  }>({});

  const subscribe = useCallback(
    <K extends ActionKey>(action: K, callback: CallbackFn<K>) => {
      setSubscriptions((prev) => ({
        ...prev,
        [action]: [...(prev[action] || []), callback],
      }));

      return () => {
        setSubscriptions((prev) => ({
          ...prev,
          [action]: (prev[action] || []).filter((cb) => cb !== callback),
        }));
      };
    },
    []
  );

  const dispatch = useCallback(
    <K extends ActionKey>(action: K, payload: Payload<K>) =>
      subscriptions[action]?.forEach((cb) => cb(payload)),
    [subscriptions]
  );

  const countListeners = useCallback(
    (key: ActionKey) => subscriptions[key]?.length || 0,
    [subscriptions]
  );

  return (
    <ActionsContext.Provider
      value={{
        dispatch,
        subscribe,
        countListeners,
      }}
    >
      {children}
    </ActionsContext.Provider>
  );
};

export const useActions: () => ActionsContextProps = () =>
  useContext(ActionsContext);

export default Actions;
