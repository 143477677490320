import { IUserNotificationSelectInputModel } from 'inputModels';
import {
  NotificationViewModel,
  NotificationPerTypeViewModel,
} from 'viewModels';
import api from '../services/api/axios';
import { NotificationType } from '../types/enums';

const getByUserId = ({
  take = 0,
  page = 0,
  filter,
  userId,
}: IUserNotificationSelectInputModel) =>
  new Promise<NotificationViewModel[]>((resolve, reject) => {
    api
      .get('notification/get-by-user', {
        params: {
          take,
          page,
          userId,
          filter,
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getById = (id: string = '') =>
  new Promise<NotificationViewModel>((resolve, reject) => {
    api
      .get(`notification/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const markAllAsRead = (userId: string = '') =>
  new Promise<void>((resolve, reject) => {
    api
      .put(`notification/mark-all-as-read/${userId}`)
      .then(() => resolve())
      .catch(reject);
  });

const markAsReadByType = (userId: string = '', type: NotificationType) =>
  new Promise<void>((resolve, reject) => {
    api
      .put(`notification/mark-as-read-by-type/${userId}`, undefined, {
        params: {
          type,
        },
      })
      .then(() => resolve())
      .catch(reject);
  });

const selectPerType = (userId: string = '') =>
  new Promise<NotificationPerTypeViewModel[]>((resolve, reject) => {
    api
      .get(`notification/select-per-type/${userId}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const NotificationController = {
  getById,
  getByUserId,
  markAllAsRead,
  selectPerType,
  markAsReadByType,
};
