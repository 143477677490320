import styled from 'styled-components';

export const Container = styled.div`
  max-width: 200px;
  .btn-close {
    color: ${({ theme }) => theme.colors.blue};
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: ${({ theme }) => theme.colors.text};
      font-size: 30px;
    }

    p {
      margin-left: 10px;
      font-size: 20px;
      color: ${({ theme }) => theme.colors.error};
    }
  }

  & > p {
    text-align: center;
    margin: 10px 0;
    color: ${({ theme }) => theme.colors.text};
  }

  #go-back {
    margin-top: 10px;
    width: 100%;
  }
`;
