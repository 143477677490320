import { IContact, IOffice } from 'entities';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { OfficeController } from '../controllers';
import useWebSocket from '../hooks/useWebSocket';
import { useActions, useApp, useAuth } from './index';

type OfficeContextProps = {
  offices: IOffice[];
  managedOffices: IOffice[];
};

const OfficeContext = createContext<OfficeContextProps>(
  {} as OfficeContextProps
);

const Office: React.FC = ({ children }) => {
  const { subscribe } = useActions();
  const { user, ownerAccount, tokenIsValid, activeOffice, changeAccount } =
    useAuth();
  const { setLoading } = useApp();
  const [offices, setOffices] = useState<IOffice[]>([]);
  const [managedOffices, setManagedOffices] = useState<IOffice[]>([]);

  useEffect(() => {
    if (tokenIsValid) {
      setLoading(true);
      Promise.all([
        OfficeController.listByOwnerId(user?.id),
        OfficeController.getByManagerId(
          ownerAccount?.user?.id || user?.id || ''
        ),
      ])
        .then(([officesRes, managedOfficesRes]) => {
          setOffices(officesRes);
          setManagedOffices(managedOfficesRes);
        })
        .finally(() => setLoading(false));
    }
  }, [ownerAccount?.user?.id, setLoading, tokenIsValid, user?.id]);

  useEffect(() => {
    const unsubscribeDisableAccount = subscribe('DISABLE_ACCOUNT', (uid) => {
      let disabledOffices: IOffice[] = [];

      setOffices((prev) => prev.filter((office) => office.createdById !== uid));
      setManagedOffices((prev) => {
        disabledOffices = prev.filter((o) => o.createdById === uid);
        return (prev || []).filter((o) => o.createdById !== uid);
      });

      if (disabledOffices.find((o) => o.id === activeOffice?.id))
        changeAccount();
    });

    const unsubscribeRestoreAccount = subscribe('RESTORE_ACCOUNT', () => {
      OfficeController.getByManagerId(
        ownerAccount?.user?.id || user?.id || ''
      ).then(setManagedOffices);
    });

    return () => {
      unsubscribeRestoreAccount();
      unsubscribeDisableAccount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOffice?.id, ownerAccount?.user?.id, subscribe, user?.id]);

  useEffect(() => {
    if (!tokenIsValid) {
      setOffices([]);
      setManagedOffices([]);
    }
  }, [tokenIsValid]);

  useWebSocket<IOffice>({
    url: `office/${user?.id}`,
    onMessage: (newOffice) => {
      setOffices((prev) => [...prev, newOffice]);
    },
  });

  useWebSocket<string>({
    url: `office/${user?.id || activeOffice?.id}/delete`,
    onMessage: (officeId) => {
      setOffices((prev) => prev.filter((office) => office.id !== officeId));
      setManagedOffices((prev) =>
        prev.filter((office) => office.id !== officeId)
      );
      if (activeOffice?.id === officeId) changeAccount();
    },
  });

  useWebSocket<{ id: string; manager: IContact }>({
    url: `office/${user?.id || activeOffice?.id}/set-manager`,
    onMessage: async ({ id, manager }) => {
      setOffices((prev) =>
        prev.map((o) => (o.id === id ? { ...o, manager } : o))
      );
      setManagedOffices((prev) =>
        prev.filter(
          (o) => o.id !== id && o.manager?.user?.id !== manager.user?.id
        )
      );

      if (
        manager?.user?.id === user?.id ||
        manager?.user?.id === ownerAccount?.user?.id
      ) {
        const prevIndex = managedOffices.findIndex((o) => o.id === id);
        if (prevIndex > -1) return;
        const manageOffice = await OfficeController.get(id);
        setManagedOffices((prev) => [...prev, manageOffice]);
      }

      if (
        activeOffice?.id === id &&
        ownerAccount?.user?.id !== manager.user?.id
      )
        changeAccount();
    },
  });

  useWebSocket<string>({
    url: `office/${user?.id || activeOffice?.id}/remove-manager`,
    onMessage: (id) => {
      setOffices((prev) =>
        prev.map((o) => (o.id === id ? { ...o, manager: undefined } : o))
      );
      setManagedOffices((prev) => prev.filter((o) => o.id !== id));

      if (activeOffice?.id === id) changeAccount();
    },
  });

  return (
    <OfficeContext.Provider
      value={{
        offices,
        managedOffices,
      }}
    >
      {children}
    </OfficeContext.Provider>
  );
};

export const useOffice: () => OfficeContextProps = () =>
  useContext(OfficeContext);

export default Office;
