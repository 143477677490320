import { IconButton, ModalProps } from '@mui/material';
import { useState } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { useAlert, useApp, useOffice } from '../../../context';
import { OfficeController } from '../../../controllers';
import { OfficeDeleteAction } from '../../../types/enums';
import Button from '../../Button';
import { Select } from '../../Select';
import Modal from '../Modal';
import { Container } from './styles';

interface Props extends Omit<ModalProps, 'children'> {
  onClose: () => void;
  onConfirm: () => void;
  officeId: string;
}

const ModalSwitchOffice = ({
  onClose,
  onConfirm,
  officeId,
  ...rest
}: Props) => {
  const alert = useAlert();
  const { online } = useApp();
  const { offices } = useOffice();
  const [selectedOffice, setSelectedOffice] = useState<string>();
  const [loading, setLoading] = useState(false);

  const handleOfficeChange = () => {
    if (loading) return;

    setLoading(true);
    OfficeController.deleteById(
      officeId,
      OfficeDeleteAction.TRANSFER_TO_OFFICE,
      selectedOffice
    )
      .then(onConfirm)
      .catch(() => {
        alert.show({
          type: 'error',
          errors: ['Não foi possível remover o escritório'],
        });
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Modal onClose={onClose} {...rest}>
      <Container>
        <IconButton onClick={onClose} className="btn-close">
          <FaTimesCircle size={20} className="icon-close" />
        </IconButton>
        <strong>Selecione a opção que você quer passar</strong>
        <Select
          defaultValue={selectedOffice}
          label="Selecione"
          onChange={(value) => {
            setSelectedOffice(value as string);
          }}
          options={[
            ...offices
              .filter((o) => o.id !== officeId)
              .map((office) => ({ key: office.id, label: office.name })),
          ]}
        />
        <div className="actions">
          <Button
            shape="outline"
            disabled={loading}
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </Button>
          <Button
            shape="outline"
            disabled={!online || loading}
            onClick={handleOfficeChange}
          >
            Avançar
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

export default ModalSwitchOffice;
