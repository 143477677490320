import { Search as SearchIcon } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { ISearchResult } from 'entities';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useApp, useAta, useAttachments } from '../../context';
import { AttachmentController, UserController } from '../../controllers';
import useClickOutside from '../../hooks/useClickOutside';
import {
  ComeFrom,
  SearchResultType,
  TypeResultSearch,
} from '../../types/enums';
import { ModalViewAta } from '../Modals';
import SearchItem from '../SearchItem';
import { SearchContainer } from './styles';

interface ISearchProps {
  type?: TypeResultSearch;
}

const Search: React.FC<ISearchProps> = ({ type }) => {
  const { online } = useApp();
  const { setCurrentAttachment, download } = useAttachments();
  const { atasOffline } = useAta();
  const [term, setTerm] = useState('');
  const [shownModalViewAta, setShownModalViewAta] = useState(false);
  const [selectedResultRef, setSelectedResultRef] = useState<string>();
  const ref = useRef<HTMLDivElement>(null);
  const [shown, setShown] = useState(false);
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState<ISearchResult[]>([]);

  const handleSearch = useCallback(() => {
    if (online) {
      setSearching(true);
      UserController.search(term, type)
        .then(setResults)
        .finally(() => setSearching(false));
    } else {
      setResults(
        atasOffline
          ?.filter(
            (a) =>
              a.text?.toLowerCase().includes(term.toLowerCase()) ||
              a.hearingJudge.toLowerCase().includes(term.toLowerCase()) ||
              a.hearingsLocations.some((l) =>
                l.toLowerCase().includes(term.toLowerCase())
              ) ||
              a.processNumber.toLowerCase().includes(term.toLowerCase())
          )
          .map((a) => {
            const comeFrom = a.text?.toLowerCase().includes(term.toLowerCase())
              ? ComeFrom.Unknown
              : a.hearingJudge.toLowerCase().includes(term.toLowerCase())
              ? ComeFrom.HearingJudge
              : a.hearingsLocations.some((l) =>
                  l.toLowerCase().includes(term.toLowerCase())
                )
              ? ComeFrom.PlaceHearing
              : a.processNumber.toLowerCase().includes(term.toLowerCase())
              ? ComeFrom.ProcessNumber
              : ComeFrom.Unknown;
            const text =
              comeFrom === ComeFrom.HearingJudge
                ? a.hearingJudge
                : comeFrom === ComeFrom.PlaceHearing
                ? a.hearingsLocations.join(', ')
                : comeFrom === ComeFrom.ProcessNumber
                ? a.processNumber
                : a.text ?? '';

            return {
              text,
              comeFrom,
              ref: a.id,
              type: SearchResultType.ATA,
            };
          })
      );
    }
  }, [atasOffline, online, term, type]);

  useClickOutside(() => setShown(false), ref);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (term.trim().length > 0) {
        handleSearch();
      } else setResults([]);
    }, 500);
    return () => clearTimeout(timer);
  }, [handleSearch, term]);

  return (
    <SearchContainer
      ref={ref}
      className={`${shown && results.length > 0 ? ' with-results' : ''}`}
    >
      <div className="input">
        <input
          type="text"
          onFocus={() => setShown(true)}
          placeholder="O que você está procurando?"
          onChange={(e) => setTerm(e.target.value)}
        />
        {searching ? (
          <div id="loading">
            <CircularProgress size="small" />
            <p>Buscando...</p>
          </div>
        ) : (
          <IconButton color="primary" onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        )}
      </div>
      <ul className="results">
        {results.map((result) => (
          <SearchItem
            key={result.ref}
            result={result}
            term={term}
            onClick={async () => {
              setSelectedResultRef(result.ref);
              if (result.type === 0) setShownModalViewAta(true);
              else {
                const att = await AttachmentController.getById(result.ref);
                setCurrentAttachment(att);
                download(false);
              }
            }}
          />
        ))}
      </ul>

      <ModalViewAta
        ataId={shownModalViewAta ? selectedResultRef : undefined}
        open={shownModalViewAta}
        onClose={() => {
          setShownModalViewAta(false);
          setSelectedResultRef(undefined);
        }}
      />
    </SearchContainer>
  );
};

export default Search;
