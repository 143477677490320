import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 0;
  min-height: 10px;
  max-height: 370px;
  overflow: auto;

  .loading-atas {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.text};

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }

  .empty-atas {
    color: ${({ theme }) => theme.colors.text};
  }
`;
