import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { useApp } from '../../context';
import loadingAta from '../../assets/gif/loading-atas.gif';

interface LoadingProps {
  shown: boolean;
  phrases?: string[];
  showPhrases?: boolean;
}

const LoadingAtaProcessing: React.FC<LoadingProps> = ({ shown, phrases, showPhrases }) => {
  const { setProcessingAta, setShowPhrases } = useApp();
  const [showCancellation, setShowCancellation] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!phrases) return undefined;

    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [phrases]);

  useEffect(() => {
    setShowCancellation(false);
    const timer = setTimeout(() => {
      setShowCancellation(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, [shown]);

  return (
    <Container shown={shown}>
      <img src={loadingAta} alt="Legal Control Atas Loading" />
      {showPhrases && phrases && (
        <h1 className="loading-phrase">{phrases[index]}</h1>
      )}
      <p
        className={`${showCancellation ? 'shown' : ''}`}
        aria-hidden
        onClick={() => { setProcessingAta(false); setShowPhrases(false); }}
      >
        Cancelar
      </p>
    </Container>
  );
};

export default LoadingAtaProcessing;
