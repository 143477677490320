import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  NotificationPerTypeViewModel,
  NotificationViewModel,
} from 'viewModels';
import { NotificationController } from '../controllers';
import useWebSocket from '../hooks/useWebSocket';
import { NotificationType } from '../types/enums';
import { useApp, useAuth } from './index';

type NotificationContextProps = {
  notifications: NotificationPerTypeViewModel[];
  setNotifications: React.Dispatch<
    React.SetStateAction<NotificationPerTypeViewModel[]>
  >;
  actionLogs: NotificationViewModel[];
  setActionLogs: React.Dispatch<React.SetStateAction<NotificationViewModel[]>>;
  pendingActions: NotificationViewModel[];
  setPendingActions: React.Dispatch<
    React.SetStateAction<NotificationViewModel[]>
  >;
};

const NotificationContext = createContext<NotificationContextProps>(
  {} as NotificationContextProps
);

const Notification: React.FC = ({ children }) => {
  const { activeOffice, user, tokenIsValid } = useAuth();
  const { setLoading } = useApp();
  const [notifications, setNotifications] = useState<
    NotificationPerTypeViewModel[]
  >([]);

  const [actionLogs, setActionLogs] = useState<NotificationViewModel[]>([]);

  const [pendingActions, setPendingActions] = useState<NotificationViewModel[]>(
    []
  );

  useEffect(() => {
    if (tokenIsValid && (user?.id || activeOffice?.id)) {
      setLoading(true);
      NotificationController.selectPerType(user?.id || activeOffice?.id)
        .then(setNotifications)
        .finally(() => setLoading(false));
    }
  }, [activeOffice?.id, setLoading, tokenIsValid, user?.id]);

  useEffect(() => {
    setNotifications([]);
    setActionLogs([]);
    setPendingActions([]);
  }, [activeOffice?.id, user?.id, tokenIsValid]);

  useWebSocket<NotificationViewModel>({
    url: `notification/${user?.id || activeOffice?.id}`,
    onMessage: (notification) => {
      setNotifications((prev) => {
        const newNotifications = [...prev];
        const index = newNotifications.findIndex(
          (n) => n.type === notification.type
        );
        if (index > -1) {
          newNotifications[index].count += 1;
        } else {
          newNotifications.push({
            type: notification.type,
            count: 1,
            title: notification.title,
          });
        }
        return newNotifications;
      });

      if (NotificationType[notification.type].includes('PENDING'))
        setPendingActions((prevPendingActions) => [
          notification,
          ...prevPendingActions.filter((n) => n.id !== notification.id),
        ]);
      else
        setActionLogs((prevActionLogs) => [
          notification,
          ...prevActionLogs.filter((n) => n.id !== notification.id),
        ]);
    },
  });

  useWebSocket<void>({
    url: `notification/${user?.id || activeOffice?.id}/markAllAsRead`,
    onMessage: () => {
      setNotifications([]);
    },
  });

  useWebSocket<NotificationType>({
    url: `notification/${user?.id || activeOffice?.id}/markAsReadByType`,
    onMessage: (type) => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.type !== type)
      );
    },
  });

  useWebSocket<string>({
    url: `notification/${user?.id || activeOffice?.id}/delete`,
    onMessage: (id) => {
      const type = pendingActions.find((n) => n.id === id)?.type;

      setPendingActions((prev) =>
        prev.filter((notification) => notification.id !== id)
      );

      if (type) {
        setNotifications((prev) => {
          const newNotifications = [...prev];
          const index = newNotifications.findIndex((n) => n.type === type);
          if (index > -1) {
            newNotifications[index].count =
              newNotifications[index].count >= 1
                ? newNotifications[index].count - 1
                : 0;
          }
          return newNotifications;
        });
      }
    },
  });

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        actionLogs,
        setActionLogs,
        pendingActions,
        setPendingActions,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification: () => NotificationContextProps = () =>
  useContext(NotificationContext);

export default Notification;
