import styled from 'styled-components';

export const HeaderStyle = styled.header`
  min-height: 70px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.gradients.primary};

  .logo {
    height: 40px;
  }

  .nav-bar {
    max-width: 40%;
    padding: 0 30px;
    margin-top: 10px;
    margin-right: auto;
    &,
    & > ul {
      width: 100%;
      display: flex;
      padding-left: 30px;

      & > li {
        height: 30px;
        display: flex;
        min-width: 12vw;
        list-style: none;
        position: relative;
        margin-bottom: -10px;

        & > a {
          color: ${({ theme }) => theme.colors.background};
          text-decoration: none;
          font-size: 18px;

          &:hover {
            text-decoration: underline;
          }
        }

        &:hover .sub-menu {
          animation: show 0.3s 1 alternate ease-out forwards;
        }
      }
    }

    .sub-menu {
      top: 100%;
      left: 0;
      transform: scaleY(0);
      opacity: 0;
      min-width: 200px;
      position: absolute;
      border-radius: 10px;
      background: ${({ theme }) => theme.colors.secondary};
      box-shadow: ${({ theme }) => theme.shadows[0]};
      padding-bottom: 10px;
      overflow: hidden;

      &#leave {
        animation: hide 0.3s 1 alternate ease-out forwards;
      }

      li {
        padding: 5px 10px;
        width: 100%;
        background: ${({ theme }) => theme.colors.f1};

        &:first-child {
          padding-top: 10px;
        }

        &:last-child {
          padding-bottom: 10px;
        }
      }

      a {
        font-weight: 300;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
        &,
        &:visited {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .divider {
      width: 1px;
      height: 25px;
      background: ${({ theme }) => theme.colors.background};
      margin: 0 20px;
    }

    .user-info {
      color: ${({ theme }) => theme.colors.background};
      display: flex;
      text-decoration: none;
      align-items: center;
      cursor: pointer;
      svg {
        width: 25px;
        height: 25px;
        transition: transform 0.3s ease-out;
      }

      &.open #arrow {
        transform: rotate(180deg);
        transition: transform 0.3s ease-out;
      }

      span {
        margin: 0 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        max-width: 150px;
      }
    }
  }

  @media (max-width: 350px) {
    padding: 0 10px;
    .logo {
      width: 120px;
      height: auto;
    }
  }
`;

export const UserMenuPopup = styled.div`
  & > p {
    padding: 5px 15px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.text2};
    font-weight: 300;
    cursor: default;

    &:first-of-type {
      padding-top: 0;
    }
    &:not(:first-of-type) {
      border-top: 1px solid ${({ theme }) => theme.colors.f2};
    }
  }

  & > div {
    padding: 0 15px;
  }

  #offices-list {
    max-height: 200px;
    overflow-y: auto;

    & > :last-child {
      padding-bottom: 7px;
    }
  }

  button {
    border: none;
    background: none;
  }

  #with-divider {
    padding-top: 5px;
    border-top: 1px solid ${({ theme }) => theme.colors.f2};
  }

  a,
  button {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    font-weight: 200;
    height: 35px;
    cursor: pointer;
    font-size: 16px;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      max-width: 120px;
    }

    svg {
      margin-right: 10px;
      width: 25px;
      height: 25px;
    }
  }
`;
