import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 90vw;
  height: 70vh;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  ${({ theme: { colors } }) => css`
    color: ${colors.primary};
  `};

  p {
    font-size: 20px;
    font-weight: 400;
    padding-left: 8px;
  }
`;

export const Content = styled.div`
  display: flex;
  height: 95%;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const ContainerDropzone = styled.div`
  min-width: 40%;
`;

export const ContainerListAttachments = styled.div`
  min-width: 60%;
  height: 100%;
  padding: 0 16px;
`;

export const HeaderList = styled.div`
  margin-bottom: 16px;

  #search {
    width: 300px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const List = styled.div`
  max-height: calc(100% - 72px);
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
`;

export const Center = styled.div`
  display: flex;
  height: 80%;
  justify-content: center;
  align-items: center;

  p {
    color: ${({ theme }) => theme.colors.border};
    font-size: 20px;
  }
`;
