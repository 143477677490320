import React, { useState } from 'react';
import { TextField, BaseTextFieldProps, InputAdornment } from '@mui/material';
import InputMask from 'react-input-mask';

import { Container } from './styles';

export interface InputProps extends BaseTextFieldProps {
  mask?: string;
  maxLength?: number;
  fullWidth?: boolean;
  endAdornment?: React.ReactNode;
  variant?: 'standard' | 'outlined' | 'filled';
  resize?: 'none' | 'both' | 'horizontal' | 'vertical';
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const Input: React.FC<InputProps> = ({
  mask,
  id,
  value,
  resize,
  onChange,
  maxLength,
  endAdornment,
  fullWidth = true,
  variant = 'standard',
  helperText,
  rows,
  ...rest
}) => {
  const [reachMaxLength, setReachMaxLength] = useState(false);

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const reachedMaxLength =
      maxLength !== undefined && event.target.value.length >= maxLength;
    setReachMaxLength(reachedMaxLength);
    if (!reachedMaxLength) onChange?.(event);
    // eslint-disable-next-line no-param-reassign
    else event.target.value = event.target.value.substring(0, maxLength);
  };

  return (
    <Container resize={resize} rows={Number(rows)}>
      {!mask ? (
        <TextField
          id={id}
          value={value}
          variant={variant}
          onChange={handleOnChange}
          fullWidth={fullWidth}
          helperText={
            helperText ||
            (reachMaxLength
              ? `Você atingiu o limite de ${maxLength} caracteres`
              : null)
          }
          rows={rows}
          {...rest}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }}
        />
      ) : (
        <InputMask
          id={id}
          mask={mask}
          maskPlaceholder={' '}
          value={value as any}
          onChange={handleOnChange}
        >
          <TextField
            variant={variant}
            fullWidth={fullWidth}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ),
            }}
            rows={rows}
            helperText={
              helperText ||
              (reachMaxLength
                ? `Você atingiu o limite de ${maxLength} caracteres`
                : null)
            }
            {...rest}
          />
        </InputMask>
      )}
    </Container>
  );
};

export default Input;
