import styled from 'styled-components';
import { Screen } from '../../styles/global';
import Banner from '../../assets/img/home_banner.jpg';
import AdvantagesBg from '../../assets/img/advantages-banner.jpg';
import WhoWeAre from '../../assets/img/who-we-are-banner.jpg';

export const Container = styled(Screen)`
  section {
    display: flex;
    background-color: ${({ theme }) => theme.colors.f1};
  }

  .home {
    min-height: 100vh;
    background-size: cover;
    background-position: bottom;
    background-image: url(${Banner});

    .info {
      width: 32%;
      font-size: 24px;
      font-weight: 200;
      margin-top: 60px;
      margin-left: 60px;
      color: ${({ theme }) => theme.colors.background};

      P {
        padding-right: 10%;
        text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
      }
    }

    .form-login {
      width: 25%;
      padding: 30px;
      overflow-y: auto;
      margin-left: auto;
      border-radius: 20px;
      height: min-content;
      margin-right: 100px;
      margin-top: calc(60px + 6%);
      max-height: calc(100vh - 120px);
      color: ${({ theme }) => theme.colors.primary};
      box-shadow: ${({ theme }) => theme.shadows[0]};
      background-color: ${({ theme }) => theme.colors.background};

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        h1 {
          font-size: 16px;
          margin-left: 5px;
        }
        svg {
          width: 27px;
          height: 27px;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        & > div {
          margin-bottom: 10px;
        }
        a {
          text-decoration: none;
          margin: 10px 0 20px auto;
          color: ${({ theme }) => theme.colors.accent};

          &:hover {
            text-decoration: underline;
          }

          &:visited {
            color: ${({ theme }) => theme.colors.accent};
          }
        }
        .actions {
          display: flex;
          margin-bottom: 0px;
          justify-content: space-between;

          button:first-of-type {
            margin-right: 10px;
          }
          button:last-of-type {
            margin-left: 10px;
          }
        }
      }
    }

    .whatsapp-btn {
      z-index: 10;
      right: 70px;
      width: 75px;
      height: 75px;
      bottom: 20px;
      position: absolute;
    }
  }

  .about {
    position: relative;
    padding: 10px 0px 30px 40px;
    .bg {
      left: -60px;
      width: 105vw;
      margin-top: -130px;
      position: absolute;
    }

    #smartphone {
      z-index: 3;
      position: absolute;
      width: 55vw;
      right: 30px;
      top: -30px;
    }

    #infos {
      width: 55%;
      min-height: 80vh;
      z-index: 3;
      padding: 20px;
      background-color: ${({ theme }) => `${theme.colors.f1}CC`};

      h2 {
        font-size: 24px;
        color: ${({ theme }) => theme.colors.accent};
      }

      p {
        font-size: 21px;
        margin-bottom: 40px;
        color: ${({ theme }) => theme.colors.text};

        &:last-of-type {
          margin-bottom: 0px;
        }
      }

      #stores {
        display: flex;
        margin-left: -14px;
        position: relative;

        a {
          display: flex;
          flex-direction: column;
          align-items: center;

          #store {
            height: 100px !important;
          }

          #qr {
            height: 140px;
            width: 140px;
            padding: 10px;
            border-radius: 5px;
            background-color: ${({ theme }) => theme.colors.background};
          }
        }
      }
    }
  }

  .advantages {
    background-image: url(${AdvantagesBg});
    background-size: cover;

    .container {
      padding-top: 20px;
      margin-left: auto;
      margin-right: 160px;
      right: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-size: 50px;
        color: ${({ theme }) => theme.colors.accent};
      }

      .advantages-list {
        width: 50%;
        margin-top: 10px;

        .item {
          display: flex;
          align-items: flex-start;
          color: ${({ theme }) => theme.colors.text};
          font-size: 22px;
          font-weight: 300;
          margin-bottom: 10px;

          p {
            line-height: 27px;
          }

          svg {
            width: 27px;
            height: 27px;
            margin-right: 10px;
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }
  }

  .contact {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 30px 60px;

    h2 {
      font-size: 26px;
      color: ${({ theme }) => theme.colors.accent};
    }

    & > p {
      font-size: 21px;
      color: ${({ theme }) => theme.colors.text};
      margin-bottom: 20px;
    }

    #contact-banner {
      width: 60vw;
      position: absolute;
      margin: auto;
      right: 0;
      bottom: 0;
    }
  }

  .who-we-are {
    background-image: url(${WhoWeAre});
    background-size: cover;
    background-position: center;
    color: ${({ theme }) => theme.colors.background};
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    position: relative;

    .container {
      z-index: 3;

      h2 {
        font-size: 39px;
        margin-bottom: 20px;
      }

      p {
        font-size: 22px;
        margin-bottom: 20px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      pointer-events: none;
    }
  }

  #footer {
    .container {
      height: 200px;
      background-color: ${({ theme }) => theme.colors.primary};
      padding: 40px 60px;

      svg {
        height: 50px;
      }
    }

    .all-rights {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.text};
        font-weight: 200;
      }
    }
  }
`;
