import axios, { AxiosResponse } from 'axios';
import { IAtaOpenAI } from 'entities';

const BASE_URL = 'https://api.openai.com/v1/chat/completions';

interface OpenAIMessage {
  role: string;
  content: string;
}
interface ResponseDataOpenAI {
  id?: string;
  object?: string;
  created?: number;
  model?: string;
  choices: [
    {
      message: OpenAIMessage;
      index: number;
      finishReason: string;
    }
  ];
}
interface ResponseOpenAI {
  data?: IAtaOpenAI;
  status?: number;
  errorCode?: string;
  messageError?: string;
}

export const getOpenAIResponse = async (message: string) => {
  try {
    const response: AxiosResponse<ResponseDataOpenAI> = await axios.post(
      BASE_URL,
      {
        messages: [{ role: 'system', content: message }],
        model: 'gpt-3.5-turbo-1106',
        response_format: { type: 'json_object' },
        max_tokens: 2700,
        temperature: 0.1,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
      }
    );

    const responseMessage = response.data.choices[0].message.content.replace(
      /\\(\s*\/\s*)/g,
      '$1'
    );
    const parsedResponse: IAtaOpenAI = JSON.parse(responseMessage || '');
    const responseData: ResponseOpenAI = {
      data: parsedResponse,
      status: response.status,
    };
    return responseData;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorResponse: ResponseOpenAI = {
        status: error.response?.status,
        messageError: error.response?.data.error.message,
        errorCode: error.response?.data.error.code,
      };
      return errorResponse;
    }
    return undefined;
  }
};
