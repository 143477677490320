import { IBaseUser, IUser, IUserAuth, ProfileSlug } from 'entities';
import jwtDecode from 'jwt-decode';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import usePersistedState from '../hooks/usePersistedState';
import useWebSocket from '../hooks/useWebSocket';
import Api from '../services/api';
import { useActions } from './index';

interface IAuthContext {
  user?: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  token?: string;
  profile: ProfileSlug;
  refreshToken?: string;
  tokenIsValid: boolean;
  ownerAccount?: IUserAuth;
  activeOffice?: IBaseUser;
  login: (userAuth: IUserAuth) => void;
  changeAccount: (userAuth?: IUserAuth) => void;
  logout: () => void;
}

const AuthContext = createContext({} as IAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const { dispatch } = useActions();

  const [token, setToken] = usePersistedState<string | undefined>(
    '@atas_control_jwt_token',
    undefined
  );
  const [refreshToken, setRefreshToken] = usePersistedState<string | undefined>(
    '@atas_control_jwt_refresh_token',
    undefined
  );
  const [user, setUser] = usePersistedState<IUser | undefined>(
    '@atas_control_user',
    undefined,
    true
  );

  const [activeOffice, setActiveOffice] = usePersistedState<
    IBaseUser | undefined
  >('@atas_control_active_office', undefined, true);

  const [ownerAccount, setOwnerAccount] = usePersistedState<
    IUserAuth | undefined
  >('@atas_control_owner_account', undefined, true);

  const tokenIsValid = useMemo(
    () => (token ? (jwtDecode(token) as any).exp > Date.now() / 1000 : false),
    [token]
  );

  const profile: ProfileSlug = useMemo(
    () => token && (jwtDecode(token) as any).role,
    [token]
  );

  const login = useCallback(
    (userAuth: IUserAuth) => {
      setUser(userAuth.user);
      setToken(userAuth.token);
      setActiveOffice(userAuth.office);
      setRefreshToken(userAuth.refreshToken);
    },
    [setActiveOffice, setRefreshToken, setToken, setUser]
  );

  const logout = useCallback(() => {
    setUser(undefined);
    setToken(undefined);
    setRefreshToken(undefined);
    setOwnerAccount(undefined);
    setActiveOffice(undefined);
    dispatch('LOGOUT', undefined);
  }, [
    dispatch,
    setActiveOffice,
    setOwnerAccount,
    setRefreshToken,
    setToken,
    setUser,
  ]);

  const changeAccount = useCallback(
    (userAuth?: IUserAuth) => {
      if (!ownerAccount && token && refreshToken && user)
        setOwnerAccount({
          token,
          refreshToken,
          user,
        });

      let action = 'CHANGE_ACCOUNT';

      if (userAuth) login(userAuth);
      else if (ownerAccount) {
        login(ownerAccount);
        setOwnerAccount(undefined);
      } else {
        logout();
        action = 'LOGOUT';
      }

      if (action === 'CHANGE_ACCOUNT') dispatch('CHANGE_ACCOUNT', undefined);
      else dispatch('LOGOUT', undefined);
    },
    [
      dispatch,
      login,
      logout,
      ownerAccount,
      refreshToken,
      setOwnerAccount,
      token,
      user,
    ]
  );

  useEffect(() => {
    Api.Defaults.setOnError(logout);
    Api.Defaults.setOnRefreshToken((data) => {
      login(data);
    });
  }, [login, logout]);

  useWebSocket<string>({
    url: `user/${ownerAccount?.user?.id || user?.id}/disable`,
    onMessage: (uid) => dispatch('DISABLE_ACCOUNT', uid),
  });

  useWebSocket<string>({
    url: `user/${ownerAccount?.user?.id || user?.id}/restore`,
    onMessage: (uid) => dispatch('RESTORE_ACCOUNT', uid),
  });

  const propsContext = {
    user,
    setUser,
    token,
    login,
    logout,
    profile,
    refreshToken,
    tokenIsValid,
    activeOffice,
    ownerAccount,
    changeAccount,
  };

  return (
    <AuthContext.Provider value={propsContext}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
