import React from 'react';
import { Header, Forms } from '../../../../components';
import { Container, Content } from './styles';

const RegisterAtaOcr: React.FC = () => {
  return (
    <Container id="container-register-ata-ocr">
      <Header showOptions />
      <h1>Nova Ata</h1>
      <Content>
        <Forms.RegisterAtaOcr />
        <Forms.RegisterAta />
      </Content>
    </Container>
  );
};

export default RegisterAtaOcr;
