/* eslint-disable no-shadow */
export enum ContactStatus {
  BLOCKED,
  RELEASED,
  PENDING,
}

export enum ComeFrom {
  ProcessNumber,
  PlaceHearing,
  HearingJudge,
  Complainant,
  Claimed,
  Attorney,
  NameDeponent,
  NickNameDeponent,
  Text,
  AttName,
  AttDescription,
  Unknown,
}

export enum SearchResultType {
  ATA,
  ATTACHMENT,
}

export enum TypeResultSearch {
  ATAS,
  ATTACHMENTS,
}

export enum ContactDeleteAction {
  NONE,
  DELETE,
  TRANSFER,
}

export enum OfficeDeleteAction {
  NONE,
  KEEP_ATAS,
  TRANSFER,
  DELETE_ATAS,
  TRANSFER_TO_OFFICE,
}

export enum ShareDirection {
  USER_TO_OWNER,
  OWNER_TO_USER,
}

export enum NotificationType {
  ALERT,
  ADD_CONTACT,
  REMOVE_CONTACT,
  CHANGE_CONTACT_STATUS,
  CHANGE_CONTACT_PERMISSIONS,
  ADD_OFFICE,
  REMOVE_OFFICE,
  ADD_OFFICE_MANAGER,
  CHANGE_OFFICE_MANAGER,
  REMOVE_OFFICE_MANAGER,
  ADD_ATA,
  CHANGE_ATA,
  REMOVE_ATA,
  SHARE_ATA,
  TRASHED_ATA,
  RESTORE_ATA,
  ADD_ATTACHMENT,
  REMOVE_ATTACHMENT,
  CHANGE_ATTACHMENT,
  LINK_ATTACHMENT,
  PENDING_CHANGE_CONTACT_STATUS,
  PENDING_ADD_OFFICE,
  PENDING_ADD_OFFICE_MANAGER,
  PENDING_LINK_ATTACHMENT,
  REMOVE_LINK_ATTACHMENT,
  DUPLICATE_ATA,
  LINK_ATA_TO_USER,
}

export enum TypeDeponent {
  AUTHOR,
  DEFENDANT,
  AUTHOR_WITNESS,
  AUTHOR_DEFENDANT,
}

export enum Role {
  ASSISTANT,
  USER,
  SYSTEM,
}
