import styled, { css } from 'styled-components';

export const Container = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;
  width: 100%;
  max-width: calc((100% / 5) - 8px);
  margin: 0 8px 8px 0;
  height: 80px;
  ${({ theme: { colors, shadows } }) => css`
    background-color: ${colors.background};
    box-shadow: ${shadows[2]};
  `};
  border-radius: 12px;
  padding: 8px;
  display: flex;
  align-items: center;

  .content-attach {
    width: 66%;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .icon-bars {
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme.colors.primary};
    margin-left: 8px;
  }

  .hasAta {
    color: #39d128;
    font-size: 12px;
    font-weight: 400;
  }

  .hasNoAta {
    color: #fc3f2d;
    font-size: 12px;
    font-weight: 400;
  }

  font-family: 'Futura PT';
`;

export const Filename = styled.p`
  color: ${({ theme }) => theme.colors.text2};
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: start;
`;

export const CreatedAt = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-size: 10px;
  font-weight: 200;
`;

export const WrapperOptions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  padding-left: 12px;

  .icon-delete {
    color: ${({ theme }) => theme.colors.red};
  }
  .icon-view {
    color: ${({ theme }) => theme.colors.primary};
  }

  .MuiIconButton-root {
    padding: 4px;
  }
`;
