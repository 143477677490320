import styled from 'styled-components';

export const SearchItemContainer = styled.li`
  width: 100%;
  padding: 5px 10px;
  list-style: none;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text4};

  p {
    font-weight: 200;
    margin-left: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};

    p,
    svg {
      color: ${({ theme }) => theme.colors.background};
    }
  }

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }

  .item-search-loading {
    width: 20px;
    height: 20px;
  }
  .come-from {
    color: ${({ theme }) => theme.colors.accent};
    font-weight: 200;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    min-width: max-content;
  }
`;
