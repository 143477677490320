import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

::-webkit-scrollbar {
  width: 14px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Futura PT', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection { /* Code for Firefox */
  color: ${({ theme }) => theme.colors.background};
  background: ${({ theme }) => theme.colors.primary};
}

::selection {
  color: ${({ theme }) => theme.colors.background};
  background: ${({ theme }) => theme.colors.primary};
}

 @keyframes hide {
    0% {
      opacity: 1;
      transform: scaleY(1);
    }
    99%{
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
      opacity: 0;
      z-index: -1;
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
      z-index: 5;
    }
  }

`;
