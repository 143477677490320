import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context';
import { FloatingChat } from '../components/FloatingChat';

const AuthRoute: ({ children }: { children: React.ReactNode }) => any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { tokenIsValid } = useAuth();
  const location = useLocation();

  if (!tokenIsValid) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return (
    <div>
      {children}
      <FloatingChat />
    </div>
  );
};

export default AuthRoute;
