import { IAttachment } from 'entities';
import BaseModel from './BaseModel';

export default class Attachment extends BaseModel implements IAttachment {
  id!: string;

  name!: string;

  createdAt!: Date;

  totalBytes!: number;

  contentType!: string;

  description!: string;

  hasLinkedAtas!: boolean;

  originalName!: string;
}
