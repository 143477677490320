import React from 'react';
import Box from '@mui/material/Box';
import { Modal as ModalUI, SxProps, Theme, ModalProps } from '@mui/material';

const style: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  outline: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: 8,
};

interface Props extends ModalProps {
  boxStyle?: SxProps<Theme>;
}

const Modal = ({ children, boxStyle, ...rest }: Props) => {
  return (
    <ModalUI
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...rest}
    >
      <Box sx={Object.assign(style, boxStyle)}>{children}</Box>
    </ModalUI>
  );
};

export default Modal;
