import React from 'react';
import { Link } from 'react-router-dom';
import { Help, SupportAgent } from '@mui/icons-material';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { Container } from './styles';

interface FooterProps {
  type?: 'default' | 'help';
}

const Footer: React.FC<FooterProps> = ({ type = 'default' }) => {
  return (
    <Container>
      <div className="footer-content">
        <Logo />
        <div className="footer-info">
          {type === 'default' ? (
            <>
              <Help />
              <div>
                <strong>Tem mais alguma duvida? </strong>
                <p>
                  Mande um e-mail para{' '}
                  <a href="mailto:suporte@atascontrol.com.br">
                    suporte@atascontrol.com.br
                  </a>
                </p>
              </div>
            </>
          ) : (
            <>
              <SupportAgent />
              <strong>Ajuda? </strong>
              <Link to="/help">Clique aqui</Link>
            </>
          )}
        </div>
      </div>
      <div className="all-rights">
        <p>
          Todos os direitos reservados - © {new Date().getFullYear()} por Legal
          Control
        </p>
      </div>
    </Container>
  );
};

export default Footer;
