import styled from 'styled-components';
import { Screen } from '../../styles/global';

export const Container = styled(Screen)`
  padding: 0px 60px;
  padding-top: 100px;

  .content {
    flex: 1;
    margin: 0px 70px;
    padding: 30px 0px;
    margin-bottom: 0px;

    .blocks {
      display: flex;
      column-count: 4;
      column-gap: 20px;
      margin: 20px 0;
      justify-content: space-between;

      .block {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.f2};
        box-shadow: ${({ theme }) => theme.shadows[0]};
        border-radius: 16px;
        padding: 10px 20px;

        & > div {
          display: flex;
          align-items: flex-start;
          font-weight: 200;
          font-size: 12px;
          margin-bottom: 10px;
          color: ${({ theme }) => theme.colors.text};

          span {
            color: ${({ theme }) => theme.colors.primary};
            font-size: 20px;
          }

          svg {
            fill: ${({ theme }) => theme.colors.primary};
            min-width: 50px;
            max-width: 50px;
            min-height: 50px;
            max-height: 50px;
            margin-right: 10px;
          }

          #crown {
            fill: ${({ theme }) => theme.colors.warning};
          }
        }
      }

      button {
        width: 100%;
        margin-top: auto;
        bottom: 0;
      }

      button[shape='outline']:not(:hover) {
        background-color: transparent !important;
      }
    }

    .graph-dashboard {
      display: flex;
      padding: 10px 0 0 0;

      .container {
        flex: 1;
        box-shadow: ${({ theme }) => theme.shadows[2]};
        overflow: hidden;
        border-radius: 16px;
        margin-bottom: 10px;
        border: 1px solid ${({ theme }) => theme.colors.f2};

        h2 {
          font-size: 20px;
          font-weight: 200;
          color: ${({ theme }) => theme.colors.primary};
          padding: 10px 20px;
          width: 100%;
          border-bottom: 1px solid ${({ theme }) => theme.colors.f2};
          cursor: default;
        }

        .dash-content {
          display: flex;
        }

        .dash-chart-atas {
          height: 15rem;
          width: 48%;
        }

        .dash-chart-attachments {
          height: 15rem;
          width: 52%;
        }

        .empty-logs {
          color: ${({ theme }) => theme.colors.text};
          font-weight: bold;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
        }
      }
    }

    .dashboard {
      display: flex;
      height: calc(100vh - 25em);
      grid-gap: 20px;

      .container {
        flex: 1;
        box-shadow: ${({ theme }) => theme.shadows[2]};
        overflow: hidden;
        border-radius: 16px;
        margin-bottom: 10px;
        border: 1px solid ${({ theme }) => theme.colors.f2};

        h2 {
          font-size: 20px;
          font-weight: 200;
          color: ${({ theme }) => theme.colors.primary};
          padding: 10px 20px;
          width: 100%;
          border-bottom: 1px solid ${({ theme }) => theme.colors.f2};
          cursor: default;
        }

        .items {
          flex: 1;
          height: calc(100% - 47.5px);
          //height: calc(100vh - 25em);
          overflow: hidden;
          overflow-y: auto;
          display: flex;
          flex-direction: column;

          .loading-action-logs {
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${({ theme }) => theme.colors.text};

            img {
              width: 30px;
              height: 30px;
              margin-right: 10px;
            }
          }

          .empty-logs {
            color: ${({ theme }) => theme.colors.text};
            font-weight: bold;
            font-size: 14px;
            margin: auto;
          }
        }
      }
    }
  }
`;
