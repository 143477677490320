export default {
  title: 'light',
  colors: {
    accent: '#093C5F',
    primary: '#21B0B1',
    secondary: '#26AAC4',
    background: '#fff',
    f1: '#F1F1F1',
    f2: '#F2F2F2',
    d1: '#D1D1D1',
    text: '#707070',
    text2: '#373737',
    text3: '#5B5B5B',
    text4: '#8D8D8D',
    blue: '#003960',
    border: '#959595',
    red: '#C80000',
    pink: '#DC5F5F',
    success: '#099B04',
    error: '#E52214',
    warning2: '#969F0F',
    warning: '#ECC030',
  },
  shadows: [
    '0px 4px 5px #0000002E',
    '0px 2px 2px #00000029',
    '0px 3px 6px #00000029',
  ],
  gradients: {
    primary:
      'transparent linear-gradient(273deg, #26AAC4 0%, #3EDECE 100%) 0% 0% no-repeat padding-box',
    warning:
      'transparent linear-gradient(97deg, #ECC030 0%, #D8A500 100%) 0% 0% no-repeat padding-box',
  },
};
