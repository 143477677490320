import styled from 'styled-components';

interface Props {
  selected?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.f1 : 'transparent'};
  flex-direction: column;
  width: 200px;
  max-width: 250px;
  margin: 0 10px;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? 'transparent' : `${theme.colors.d1}`};
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  padding: 0 20px;
  user-select: none;
  height: min-content;
  cursor: default;

  h3 {
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primary};
    border-bottom: none;
    padding-top: 20px;
  }

  .detail {
    display: flex;
    align-items: center;
    height: 80px;

    svg {
      width: 40px;
      height: 35px;
      margin-right: auto;
    }

    p {
      width: calc(100% - 20px);
      margin-left: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 36px;
      color: ${({ theme }) => theme.colors.text};
      span {
        color: ${({ theme }) => `${theme.colors.text}AA`};
        margin-top: 11px;
        margin-left: 5px;
        font-size: 14px;
      }
    }
  }

  .price {
    border-top: ${({ theme }) => `1px solid ${theme.colors.d1}`};
    padding-top: 10px;
    margin-bottom: 5px;

    p,
    strong {
      color: ${({ theme }) => theme.colors.primary};
    }

    p {
      font-size: 12px;
    }

    strong {
      font-size: 43px;
      line-height: 40px;
      font-weight: 200;

      span {
        font-size: 14px;
      }
    }
  }

  button {
    width: 100%;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
  }

  a {
    text-decoration: none;
    padding-bottom: 10px;
    margin: 0 auto;

    &:hover {
      text-decoration: underline;
    }

    &,
    &:visited {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &:not(:hover) {
    button {
      opacity: 0;
      margin-bottom: 0px;
      padding: 0 20px !important;
      transition: all 0.3s ease-in-out;
    }
  }

  &:hover {
    border-color: transparent;
    background: ${({ theme }) => theme.colors.f1};
    transition: all 0.3s ease-in-out;
  }
`;
