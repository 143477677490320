import React, {
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { v4 as uuid } from 'uuid';
import { IPlan } from 'entities';

type RegisterProps = {
  document: string;
  name: string;
  email: string;
  password: string;
  planId: string;
};

type Context = {
  userRegister: RegisterProps;
  setUserRegister: Dispatch<SetStateAction<RegisterProps>>;
};

type Provider = {
  children: ReactNode;
};

export const Plans: IPlan[] = [
  {
    id: uuid(),
    name: 'Básico',
    offices: 1,
    storage: 1,
    atas: 5,
    price: 10,
    color: '#21B0B1',
  },
  {
    id: uuid(),
    name: 'Prata',
    offices: 3,
    storage: 2,
    atas: 10,
    price: 25,
    color: '#A7A7A7',
  },
  {
    id: uuid(),
    name: 'Ouro',
    offices: 5,
    storage: 5,
    atas: 25,
    price: 50,
    color: '#FFC919',
  },
  {
    id: uuid(),
    name: 'Platina',
    offices: 10,
    storage: 10,
    atas: 50,
    price: 90,
    color: '#42ADFF',
  },
];

const SignUpContext = createContext({} as Context);

export const SignUpProvider = ({ children }: Provider) => {
  const [userRegister, setUserRegister] = useState({} as RegisterProps);

  const initialValues: Context = {
    userRegister,
    setUserRegister,
  };

  return (
    <SignUpContext.Provider value={{ ...initialValues }}>
      {children}
    </SignUpContext.Provider>
  );
};

export const useUserRegister = () => useContext(SignUpContext);
