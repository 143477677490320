import styled from 'styled-components';

export const Content = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  color: ${({ theme }) => theme.colors.text};
  transition: all 0.3s ease;
  cursor: default;

  p:not(:first-of-type) {
    margin-left: 3px;
  }

  svg {
    color: ${({ theme }) => theme.colors.primary};

    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    margin-right: 10px;
  }
`;

interface TextProps {
  bold?: boolean;
  color?: string;
  italic?: boolean;
}
export const Text = styled.p<TextProps>`
  font-size: 12px;
  min-width: ${({ bold }) => (bold ? 'unset' : 'fit-content')};
  max-width: 225px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  color: ${({ color, theme }) => color || theme.colors.text};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;
