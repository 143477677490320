/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { CircularProgress } from '@mui/material';
import { ButtonStyle } from './styles';

interface Props
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  isLoading?: boolean;
  size?: 'small' | 'medium' | 'large';
  type?: 'file' | 'button' | 'submit' | 'reset';
  shape?: 'outline' | 'solid' | 'gradient' | 'text';
  color?: 'primary' | 'accent' | 'red' | 'warning';
  onFilesChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      size,
      type,
      onClick,
      children,
      isLoading,
      onFilesChange,
      shape = 'solid',
      color = 'primary',
      ...rest
    },
    ref
  ) => {
    const MAX_FILE_SIZE = 200;
    const inputFile = useRef<any>();

    const handleClick = (e: any) => {
      e.preventDefault();
      if (type === 'file') inputFile?.current?.click();
      onClick?.(e);
    };

    return (
      <>
        <input
          type="file"
          accept=".pdf, .png, .jpg, .jpeg"
          id="file"
          multiple
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={(e) => {
            if (
              !(
                Array.from(e?.target?.files || []).reduce(
                  (acc, file) => acc + file.size,
                  0
                ) >
                MAX_FILE_SIZE * 1024 * 1024
              )
            )
              onFilesChange?.(e);

            e.target.value = '';
          }}
        />
        <ButtonStyle
          ref={ref}
          size={size}
          shape={shape}
          color={color}
          isLoading={isLoading}
          onClick={handleClick}
          {...rest}
        >
          {isLoading ? <CircularProgress className="btn-loading" /> : children}
        </ButtonStyle>
      </>
    );
  }
);

export default Button;
