import { ModalProps, IconButton } from '@mui/material';
import { FaTimesCircle } from 'react-icons/fa';
import { Container } from './styles';
import Button from '../../Button';
import Modal from '../Modal';

interface Props extends Omit<ModalProps, 'children'> {
  title: string;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
  optionTexts?: [string, string];
}

const ModalConfirm = ({
  title,
  onClose,
  onCancel,
  onConfirm,
  optionTexts,
  ...rest
}: Props) => {
  return (
    <Modal onClose={onClose} {...rest}>
      <Container>
        <IconButton onClick={onCancel} className="btn-close">
          <FaTimesCircle size={20} className="icon-close" />
        </IconButton>
        <p className="title">{title}</p>
        <div className="actions">
          <Button
            shape="outline"
            onClick={() => {
              onClose();
              onCancel?.();
            }}
          >
            {optionTexts?.[0] ?? 'Não'}
          </Button>
          <Button
            shape="outline"
            onClick={() => {
              onClose();
              onConfirm?.();
            }}
          >
            {optionTexts?.[1] ?? 'Sim'}
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

export default ModalConfirm;
