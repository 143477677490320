import styled, { css } from 'styled-components';
import { Screen } from '../../../../styles/global';

export const Container = styled(Screen)`
  display: flex;
  flex-direction: column;
  padding: 0px 60px;
  padding-top: 100px;

  h1 {
    font-family: 'Futura Condensed PT' !important;
    font-size: 36px;
    color: ${({ theme }) => theme.colors.accent};
  }

  #ata-infos {
    width: 80%;
    margin: 16px 0;
    padding: 16px;
    ${({ theme: { colors, shadows } }) => css`
      background-color: ${colors.background};
      box-shadow: ${shadows[2]};
      border: 0.5px solid ${colors.border};
    `};
    border-radius: 17px;
  }

  h4 {
    padding-bottom: 16px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  width: 80%;
  align-self: center;
  justify-content: space-around;
  padding-bottom: 32px;

  #btn-finish-register-ata svg {
    color: ${({ theme }) => theme.colors.background};
  }
`;
