import React from 'react';
import { Container, Content } from './styles';
import { Header, Forms } from '../../../components';

const RegisterAta: React.FC = () => {
  return (
    <Container>
      <Header showOptions />
      <h1>Nova Ata</h1>
      <Content>
        <Forms.RegisterAta />
      </Content>
    </Container>
  );
};

export default RegisterAta;
