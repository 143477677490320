import { AlternateEmail } from '@mui/icons-material';
import { ModalProps } from '@mui/material';
import React, { useEffect, useCallback, useState } from 'react';
import { EmailController } from '../../../controllers';
import loadingGif from '../../../assets/gif/loading.gif';
import { Button, InputCode } from '../../index';
import { useAlert } from '../../../context';
import { Container } from './styles';
import Modal from '../Modal';
import { decryptCode } from '../../../utils/crypto';

interface Props extends Omit<ModalProps, 'children'> {
  email: string;
  onClose: () => void;
  onConfirm?: () => void;
}

const ModalVerificationCode = ({
  email,
  onClose,
  onConfirm,
  open,
  ...rest
}: Props) => {
  const appAlert = useAlert();
  const [code, setCode] = useState('');
  const [expectedCode, setExpectedCode] = useState('');
  const [sendingCode, setSendingCode] = useState(false);

  const sendCode = useCallback(() => {
    if (sendingCode || !email) return;
    setSendingCode(true);
    EmailController.sendCodeEmail({
      email,
      subject: 'Atas Control - Autenticação de email',
      title: 'Autenticação',
      description: 'Prezado(a) Use o código para validar seu cadastro.',
      titleCode: 'Código de verificação',
    })
      .then((resCode) => {
        setExpectedCode(decryptCode(resCode));
        appAlert.show({
          type: 'sucess',
          message: 'Código enviado. Verifique seu e-mail',
          timer: 3000,
        });
      })
      .catch(() => {
        appAlert.show({
          type: 'error',
          title: 'Falha ao enviar código!',
          errors: ['Houve um erro inesperado, tente novamente.'],
        });
      })
      .finally(() => setSendingCode(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, sendingCode]);

  const handleVerifyCode = () => {
    if (!code || code !== expectedCode) {
      appAlert.show({
        type: 'error',
        title: 'Código inválido!',
        errors: ['Verifique se o código está correto.'],
      });
      return;
    }
    onConfirm?.();
  };

  useEffect(() => {
    if (open) sendCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal onClose={onClose} {...rest} open={open}>
      <Container>
        {sendingCode ? (
          <div className="loading">
            <img src={loadingGif} alt="Legal Control Atas Loading" />
            <p>
              Enviando código de verificação
              <br />
              para <b>{email}</b>
            </p>
          </div>
        ) : (
          <>
            <div className="title">
              <AlternateEmail />
              <h3>Veja seu e-mail</h3>
              <p>Insira o código de verificação</p>
            </div>
            <div className="form">
              <InputCode
                id="code"
                enabled={!!expectedCode}
                length={4}
                onComplete={(c) => setCode(c)}
              />
              <Button disabled={!code} onClick={handleVerifyCode}>
                VERIFICAR
              </Button>
            </div>
          </>
        )}
      </Container>
    </Modal>
  );
};

export default ModalVerificationCode;
