import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import {
  IAtaListViewModel,
  IAtaMoveToTrashViewModel,
  IAtaRestoreViewModel,
} from 'viewModels';
import { ReactComponent as OfficeIcon } from '../../../assets/svg/icon-office.svg';
import { Ata, Button, ContactWithAtas, Header } from '../../../components';
import {
  useActions,
  useApp,
  useAta,
  useAuth,
  useContact,
  useOffice,
} from '../../../context';
import useHorizontalScroll from '../../../hooks/useHorizontalScroll';
import { Container } from './styles';

const AtasTrash: React.FC = () => {
  const { subscribe } = useActions();
  const { online } = useApp();
  const navigate = useNavigate();
  const { atasOffline, trashedAtas, getTrashedAtas } = useAta();
  const { profile, user, activeOffice, ownerAccount } = useAuth();
  const { offices } = useOffice();
  const {
    contacts,
    ownerContacts,
    officeContacts /*subscribeContactTransfer*/,
  } = useContact();

  const allContacts = useMemo(
    () =>
      contacts
        .concat(Object.values(officeContacts).flat())
        .concat(ownerContacts)
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        ),
    [contacts, ownerContacts, officeContacts]
  );

  const [contactAtas, setContactAtas] = useState<{
    [key: string]: IAtaListViewModel[];
  }>({});
  const officesRef = useHorizontalScroll(['office-atas', 'user-info']);

  useEffect(() => {
    if (!trashedAtas) getTrashedAtas();
  }, [trashedAtas, getTrashedAtas]);

  useEffect(() => {
    const unsubscribeAtaUpdate = subscribe(
      'ATA_UPDATED',
      (ataUpdated: IAtaListViewModel) => {
        Object.entries(contactAtas).forEach(([key, atasContact]) => {
          const ataContactIndex = atasContact.findIndex(
            (a) => a.id === ataUpdated.id
          );

          if (ataContactIndex > -1) {
            setContactAtas((prev) => ({
              ...prev,
              [key]: [
                ...atasContact.slice(0, ataContactIndex),
                ataUpdated,
                ...atasContact.slice(ataContactIndex + 1),
              ],
            }));
          }
        });
      }
    );

    const unsubscribeAtaDelete = subscribe(
      'ATA_DISABLED',
      (ataDeletedId: string) => {
        setContactAtas((prev) =>
          Object.entries(prev).reduce(
            (acc, [key, atasContact]) => ({
              ...acc,
              [key]: atasContact.filter((a) => a.id !== ataDeletedId),
            }),
            {}
          )
        );
      }
    );

    const unsubscribeAtaTrashed = subscribe(
      'TRASHED_ATA',
      ({
        contactId,
        companyId,
        ...ataTrashed
      }: IAtaListViewModel & IAtaMoveToTrashViewModel) => {
        const contact = allContacts.find((c) =>
          contactId ? c.id === contactId : c.companyId === companyId
        );

        if (contact) {
          const idToUpdate = contact.companyId ?? contact.id;
          setContactAtas((prev) => ({
            ...prev,
            [idToUpdate]: [ataTrashed, ...(prev[idToUpdate] || [])],
          }));
        }
      }
    );

    const unsubscribeAtaRestore = subscribe(
      'ATA_RESTORED',
      ({ id }: IAtaRestoreViewModel) => {
        setContactAtas((prev) =>
          Object.entries(prev).reduce(
            (acc, [key, atasContact]) => ({
              ...acc,
              [key]: atasContact.filter((a) => a.id !== id),
            }),
            {}
          )
        );
      }
    );

    return () => {
      unsubscribeAtaUpdate();
      unsubscribeAtaDelete();
      unsubscribeAtaTrashed();
      unsubscribeAtaRestore();
    };
  }, [allContacts, contactAtas, subscribe]);

  /*useEffect(() => {
    const unsubscribeContactTransfer = subscribeContactTransfer(
      (contactId: string, newOwnerId: string, mergedContactId?: string) => {
        if (mergedContactId && newOwnerId === user?.id) {
          setContactAtas((prev) =>
            Object.keys(prev).reduce(
              (acc, key) => {
                if (key !== mergedContactId) {
                  if (key === contactId) {
                    acc[key] = [...prev[key], ...prev[mergedContactId]];
                  } else acc[key] = prev[key];
                }
                return acc;
              },
              {} as {
                [key: string]: IAtaListViewModel[];
              }
            )
          );
        }
      }
    );

    return () => unsubscribeContactTransfer();
  }, [subscribeContactTransfer, user?.id]);*/

  const handleDragEnd = () => {};

  const companyAtas = useMemo(
    () =>
      online ? [...(trashedAtas || [])] : atasOffline.filter((a) => a.trashed),
    [trashedAtas, atasOffline, online]
  );

  return (
    <Container>
      <Header showOptions />
      <h1>Lixeira</h1>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="content">
          {!companyAtas.length &&
          !contacts.length &&
          (profile === 'office' ||
            !offices.length ||
            Object.values(officeContacts).every((oc) => !oc?.length)) ? (
            <div className="empty">
              <h2>Tudo certo por aqui...</h2>
              <div className="empty-container">
                <div className="empty-title">
                  <p>Você não possui Atas excluídas até o momento!</p>
                </div>
                <div className="empty-option">
                  <div>
                    <p>Você pode optar por:</p>
                  </div>
                  <Button
                    shape="outline"
                    size="large"
                    disabled={!online}
                    onClick={() => navigate('/atas', { replace: true })}
                  >
                    Ver todas as atas
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className={`company${
                  profile === 'common'
                    ? ' as-user'
                    : profile === 'office'
                    ? ' as-office'
                    : ''
                }`}
              >
                <div>
                  <h2>
                    {user?.name ||
                      activeOffice?.name ||
                      ownerAccount?.user?.name ||
                      'Empresa'}
                  </h2>
                  {profile !== 'office' && (
                    <Droppable
                      droppableId="company"
                      key="company"
                      isDropDisabled
                    >
                      {(provided) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            id="company-atas"
                          >
                            {!companyAtas.length ? (
                              <p className="list-empty">
                                Nenhuma ata sua na lixeira...
                              </p>
                            ) : (
                              companyAtas.map((item, i) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={`company/${item.id}`}
                                    index={i}
                                    isDragDisabled
                                  >
                                    {(pro) => {
                                      return (
                                        <div
                                          ref={pro.innerRef}
                                          {...pro.draggableProps}
                                          {...pro.dragHandleProps}
                                        >
                                          <Ata {...item} showTrashedMenu />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })
                            )}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  )}
                </div>
                <div id="contacts">
                  {!contacts.length && !ownerContacts.length && (
                    <p className="list-empty">Nenhum contato encontrado...</p>
                  )}
                  {profile !== 'common' &&
                    profile !== 'office' &&
                    contacts.map((contact) => (
                      <ContactWithAtas
                        refId="contact"
                        key={contact.id}
                        {...contact}
                        trashed
                        showTrashedMenu
                        atas={contactAtas[contact.id]}
                        onGetAtas={(atasRes) => {
                          setContactAtas((prev) => ({
                            ...prev,
                            [contact.id]: atasRes,
                          }));
                        }}
                      />
                    ))}
                  {ownerContacts.map((contact) => (
                    <ContactWithAtas
                      refId="contact"
                      key={contact.id}
                      {...contact}
                      trashed
                      showTrashedMenu
                      showNameFrom="user"
                      atas={contactAtas[contact?.companyId ?? contact.id]}
                      onGetAtas={(atasRes) => {
                        setContactAtas((prev) => ({
                          ...prev,
                          [contact?.companyId ?? contact.id]: atasRes,
                        }));
                      }}
                    />
                  ))}
                </div>
              </div>
              {profile !== 'common' && profile !== 'office' && (
                <div ref={officesRef} className="offices">
                  {offices?.map((office) => (
                    <div className="office" key={office.id}>
                      <div className="header">
                        <OfficeIcon />
                        <h2>{office.name}</h2>
                      </div>
                      <div className="office-atas">
                        {!officeContacts[office.id]?.length ? (
                          <p className="list-empty">
                            Este escritório não possui contatos...
                          </p>
                        ) : (
                          officeContacts[office.id]?.map((c) => (
                            <ContactWithAtas
                              refId={`office/${office.id}`}
                              {...c}
                              atas={contactAtas[c.id]}
                              trashed
                              showTrashedMenu
                              officeId={office.id}
                              onGetAtas={(atasRes) => {
                                setContactAtas((prev) => ({
                                  ...prev,
                                  [c.id]: atasRes,
                                }));
                              }}
                            />
                          ))
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </DragDropContext>
    </Container>
  );
};

export default AtasTrash;
