import api from '../services/api/axios';

export const error = (_error: Error) =>
  new Promise<void>((resolve) =>
    api
      .post('/logger/error', {
        message: _error?.message,
        stack: _error?.stack,
      })
      .finally(resolve)
  );

export const warning = (_warning: string) =>
  new Promise<void>((resolve) =>
    api
      .post('/logger/warning', {
        message: _warning,
      })
      .finally(resolve)
  );

export const info = (_info: string) =>
  new Promise<void>((resolve) =>
    api
      .post('/logger/info', {
        message: _info,
      })
      .finally(resolve)
  );

export const LoggerController = { error, warning, info };
