import {
  isCancel,
  CancelToken,
  CancelTokenSource,
  refreshToken,
  setOnError,
  setOnRefreshToken,
} from './axios';
import * as Auth from './auth';

const Cancel = {
  token: CancelToken,
  isCancel,
};

const Defaults = {
  setOnError,
  refreshToken,
  setOnRefreshToken,
};

const api = {
  Defaults,
  Cancel,
  Auth,
};

export type { CancelTokenSource };

export default api;
