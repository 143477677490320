import {
  ComputerVisionClient,
  ComputerVisionModels,
} from '@azure/cognitiveservices-computervision';
import { ApiKeyCredentials } from '@azure/ms-rest-js';
import mime from 'mime';

import { useAlert, useAuth } from '../context';
import { AtaController } from '../controllers';

const key = process.env.REACT_APP_AZURE_COMPUTER_VISION_KEY;
const endpoint = process.env.REACT_APP_AZURE_COMPUTER_VISION_ENDPOINT;

type StatusError = 'SIZE_LARGED' | 'FORMAT_INVALID';

type ResponseValidImage = {
  isValid: boolean;
  statusError?: StatusError;
};

export const useReadTextFile = () => {
  const auth = useAuth();
  const alert = useAlert();
  const MAX_SIZE_IMAGE = 4194304;
  const extensionsValids = ['png', 'jpg', 'jpeg'];

  const msgErrors: { [keyObj in StatusError]: string } = {
    SIZE_LARGED: 'O arquivo pode ter no máximo 4 megabytes',
    FORMAT_INVALID: 'Só são permitidos arquivos .jpg, .jpeg, .png ou .pdf',
  };

  const validImage = (file: File): ResponseValidImage => {
    if (file.size >= MAX_SIZE_IMAGE)
      return { isValid: false, statusError: 'SIZE_LARGED' };

    const extension = mime.getExtension(file.type);
    if (!extension || !extensionsValids.includes(extension))
      return { isValid: false, statusError: 'FORMAT_INVALID' };
    return { isValid: true };
  };

  const readTextImage = (file: File) =>
    new Promise<string | undefined>((resolve, reject) => {
      const { isValid, statusError } = validImage(file);

      if (!isValid) {
        alert.show({ type: 'error', errors: [msgErrors[statusError!]] });
        reject(msgErrors[statusError!]);
      }

      if (endpoint) {
        const computerVisionClient = new ComputerVisionClient(
          new ApiKeyCredentials({
            inHeader: {
              'Ocp-Apim-Subscription-Key': key,
              'Content-Type': 'application/octet-stream',
            },
          }),
          endpoint
        );

        computerVisionClient
          .analyzeImageInStream(file, { language: 'pt' })
          .then((res) => {
            const text = (res as ComputerVisionModels.OcrResult).regions
              ?.map((region) =>
                region.lines?.map((line) =>
                  line.words?.map((word) => word.text)
                )
              )[0]
              ?.map((word) => word?.join(' '))
              .join('\n');

            resolve(text);
          })
          .catch(reject);
      }
    });

  const readTextPdf = (file: File) =>
    new Promise<string | undefined>((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);

      AtaController.readPdf(formData, auth?.token || '')
        .then((res) => resolve((res.data as string[]).join('\n')))
        .catch((err) => {
          alert.show({
            type: 'error',
            errors: ['Houve um erro ao ler o pdf, tente novamente.'],
          });
          reject(err);
        });
    });

  return { readTextImage, readTextPdf };
};
