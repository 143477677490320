import styled from 'styled-components';

export const Container = styled.div`
  width: 80vw;
  height: min-content;
  max-height: 80vh;
  overflow-y: auto;

  .title {
    font-size: 23px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }

  .terms {
    width: 100%;
    color: ${({ theme }) => theme.colors.text};
    white-space: pre-line;
    padding: 10px 0;
    line-height: 30px;
    vertical-align: middle;
  }

  button {
    width: 150px;
  }
`;
