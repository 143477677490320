import { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loading } from '../components';
import { useApp, useAuth } from '../context';
import Atas from './Atas';
import EditAta from './Atas/Edit';
import ListAtas from './Atas/List';
import NewAta from './Atas/New';
import RegisterAta from './Atas/Register';
import FinishRegisterAta from './Atas/Register/Finish';
import RegisterAtaOcr from './Atas/Register/Ocr';
import AtasTrash from './Atas/Trash';
import AuthRoute from './AuthRoute';
import Dashboard from './Dashboard';
import Error from './Error';
import MultiTabError from './Error/MultiTab';
import Home from './Home';

const SignUp = lazy(() => import('./SignUp'));
const Dossier = lazy(() => import('./Dossier'));
const Profile = lazy(() => import('./Profile'));
const Support = lazy(() => import('./Support'));
const Plan = lazy(() => import('./SignUp/Plan'));
const Recovery = lazy(() => import('./Recovery'));
const Contacts = lazy(() => import('./Contacts'));
const Payment = lazy(() => import('./SignUp/Payment'));
const Attachments = lazy(() => import('./Attachments'));
const Administrative = lazy(() => import('./Administrative'));
const AdministrativeOffice = lazy(() => import('./Administrative/Office'));

const Router = () => {
  const { profile } = useAuth();
  const { online } = useApp();

  const [anotherTab, setAnotherTab] = useState(false);

  useEffect(() => {
    const channel = new BroadcastChannel('tab');
    channel.postMessage('another-tab');
    channel.addEventListener('message', (msg) => {
      if (msg.data === 'another-tab') {
        setAnotherTab(true);
      }
    });
  }, []);

  return (
    <>
      {anotherTab ? (
        <MultiTabError />
      ) : (
        <BrowserRouter>
          <Suspense fallback={<Loading shown />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/recovery" element={<Recovery />} />
              <Route path="/sign-up">
                <Route index element={<SignUp />} />
                <Route path="payment" element={<Payment />} />
                <Route path="plan" element={<Plan />} />
              </Route>
              <Route
                path="/dashboard"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              />
              {online && (
                <Route
                  path="/profile"
                  element={
                    <AuthRoute>
                      <Profile />
                    </AuthRoute>
                  }
                />
              )}
              <Route path="/atas">
                <Route
                  index
                  element={
                    <AuthRoute>
                      <Atas />
                    </AuthRoute>
                  }
                />
                {profile !== 'office' && (
                  <>
                    <Route
                      path="new"
                      element={
                        <AuthRoute>
                          <NewAta />
                        </AuthRoute>
                      }
                    />
                    <Route
                      path="edit"
                      element={
                        <AuthRoute>
                          <EditAta />
                        </AuthRoute>
                      }
                    />
                    <Route path="register">
                      <Route
                        index
                        element={
                          <AuthRoute>
                            <RegisterAta />
                          </AuthRoute>
                        }
                      />
                      <Route
                        path="ocr"
                        element={
                          <AuthRoute>
                            <RegisterAtaOcr />
                          </AuthRoute>
                        }
                      />
                      <Route
                        path="finish"
                        element={
                          <AuthRoute>
                            <FinishRegisterAta />
                          </AuthRoute>
                        }
                      />
                    </Route>
                  </>
                )}
                <Route
                  path="trash"
                  element={
                    <AuthRoute>
                      <AtasTrash />
                    </AuthRoute>
                  }
                />
                <Route
                  path="list"
                  element={
                    <AuthRoute>
                      <ListAtas />
                    </AuthRoute>
                  }
                />
              </Route>
              {online && profile !== 'office' && (
                <Route
                  path="/attachments"
                  element={
                    <AuthRoute>
                      <Attachments />
                    </AuthRoute>
                  }
                />
              )}
              {online && (
                <Route
                  path="/contacts"
                  element={
                    <AuthRoute>
                      <Contacts />
                    </AuthRoute>
                  }
                />
              )}
              {online && (
                <Route
                  path="/dossier"
                  element={
                    <AuthRoute>
                      <Dossier />
                    </AuthRoute>
                  }
                />
              )}
              {online && profile !== 'common' && (
                <Route path="/administrative">
                  <Route
                    index
                    element={
                      <AuthRoute>
                        <Administrative />
                      </AuthRoute>
                    }
                  />
                  {profile === 'office' && (
                    <Route
                      path="office"
                      element={
                        <AuthRoute>
                          <AdministrativeOffice />
                        </AuthRoute>
                      }
                    />
                  )}
                </Route>
              )}
              {(!process.env.NODE_ENV ||
                process.env.NODE_ENV === 'development')}
              <Route path="/help" element={<Support />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      )}
    </>
  );
};
export default Router;
