import { IAta } from 'entities';
import React, { useEffect } from 'react';
import { PermContactCalendar } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import mime from 'mime';
import { Button, Header } from '../../../components';
import {
  Container,
  Content,
  Footer,
  OptionCreateAta,
  TextButton,
} from './styles';
import { ReactComponent as OfficeIcon } from '../../../assets/svg/icon-office.svg';
import { ReactComponent as IconPaperClip } from '../../../assets/svg/icon-paper-clip.svg';
import { ReactComponent as IconSearchPlus } from '../../../assets/svg/icon-search-plus.svg';
import { ReactComponent as IconWriteAta } from '../../../assets/svg/icon-write-ata.svg';
import { ReactComponent as IconDocument } from '../../../assets/svg/icon-document.svg';
import { ButtonCard } from '../../../components/ButtonCard';
import { useAuth, useAlert, useApp, useAta } from '../../../context';
import { useReadTextFile } from '../../../hooks/useReadTextFile';

const NewAta = () => {
  const { setAta } = useAta();
  const { profile } = useAuth();
  const navigate = useNavigate();
  const { setLoading, online } = useApp();
  const { readTextImage, readTextPdf } = useReadTextFile();
  const alert = useAlert();

  useEffect(() => {
    setAta({} as IAta);
  }, [setAta]);

  const handleOnFilesChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    setLoading(true);
    const files = Array.from(e.target?.files!);

    if (!files || files?.length === 0) {
      alert.show({
        type: 'error',
        errors: ['É necessário selecionar um arquivo para continuar'],
      });
      setLoading(false);
      return;
    }

    const isPdf = mime.getExtension(files[0].type) === 'pdf';

    const promise = isPdf ? readTextPdf(files[0]) : readTextImage(files[0]);

    promise
      .then((text) => {
        if (!text) {
          alert.show({
            type: 'error',
            errors: [
              'Não identificamos nenhum elemento para preenchimento automático, por gentileza insira outro documento válido ou insira as informações manualmente.',
            ],
          });
        } else {
          setAta((prevState) => ({
            ...prevState,
            files: files.map((f) => ({
              file: Object.assign(f, {
                id: uuid(),
              }),
              text,
            })),
          }));
          navigate('/atas/register/ocr');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      <Header showOptions />
      <h1>Nova Ata</h1>
      <Content>
        <OptionCreateAta>
          <IconSearchPlus />
          <p>Busque um documento base para o preenchimento da Ata</p>
          <Button
            shape="outline"
            size="large"
            type="file"
            disabled={!online}
            onFilesChange={handleOnFilesChange}
          >
            <TextButton>Começar</TextButton>
          </Button>
        </OptionCreateAta>
        <OptionCreateAta>
          <IconWriteAta />
          <p>Redija para preencher a Ata</p>
          <Button
            shape="outline"
            size="large"
            onClick={() => navigate('/atas/register')}
          >
            <TextButton>Começar</TextButton>
          </Button>
        </OptionCreateAta>
      </Content>
      {/* <Footer>
        <ButtonCard
          title="Atas"
          disabled={!online}
          icon={() => <IconDocument />}
          onClick={() => navigate('/atas')}
          text="Visualize todas as atas que você cadastrou, ou que foram compartilhadas com você."
        />
        {profile !== 'common' && (
          <ButtonCard
            disabled={!online}
            title="Administrar"
            icon={() => <OfficeIcon />}
            onClick={() => navigate('/administrative')}
            text="Crie os níveis de perfil e acesso de cada usuário, e empresa."
          />
        )}
        <ButtonCard
          title="Contatos"
          disabled={!online}
          icon={() => <PermContactCalendar />}
          onClick={() => navigate('/contacts')}
          text="Visualize e cadastre contatos."
        />
        <ButtonCard
          title="Anexos"
          disabled={!online}
          icon={() => <IconPaperClip />}
          onClick={() => navigate('/attachments')}
          text="Inclusão e visualização de anexos."
        />
      </Footer> */}
    </Container>
  );
};

export default NewAta;
