import { HighlightOff, KeyboardArrowDown } from '@mui/icons-material';
import { Checkbox, IconButton } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaCogs, FaFileDownload } from 'react-icons/fa';
import { IAtaReportListViewModel } from 'viewModels';
import loadingGif from '../../../assets/gif/loading.gif';
import { ReactComponent as ManyReportDownloadIcon } from '../../../assets/svg/icon-many-reports.svg';
import { ReactComponent as OneReportDownloadIcon } from '../../../assets/svg/icon-report.svg';
import {
  Button,
  Header,
  MenuPopup,
  ModalViewAta,
  Search,
} from '../../../components';
import { useActions, useAlert, useApp, useAta } from '../../../context';
import { AtaController } from '../../../controllers';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import useSizeChange from '../../../hooks/useSizeChange';
import { TypeResultSearch } from '../../../types/enums';
import { Container, DownloadContainer } from './styles';

const ListAtas: React.FC = () => {
  const appAlert = useAlert();
  const { online } = useApp();
  const { atasOffline } = useAta();
  const { subscribe } = useActions();
  const listRef = useRef<HTMLUListElement>(null);
  const { height: listHeight } = useSizeChange(listRef);
  const [viewAtaId, setViewAtaId] = useState<string>();
  const [downloading, setDownloading] = useState(false);
  const [enableSelect, setEnableSelect] = useState(false);
  const [selectedAtas, setSelectedAtas] = useState<string[]>([]);
  const [atas, setAtas] = useState<IAtaReportListViewModel[]>([]);
  const [orderedOfflineAtas, setOrderedOfflineAta] =
    useState<IAtaReportListViewModel[]>(atasOffline);
  const [orderBy, setOrderBy] = useState<string[]>([]);
  const [columns, setColumns] = useState([
    'text',
    'createdBy',
    'hearingJudge',
    'hearingsLocations',
  ]);

  useEffect(() => {
    setOrderBy([]);
  }, [online]);

  useEffect(() => {
    const unsubscribeAtaDelete = subscribe(
      'ATA_DISABLED',
      (ataDeletedId: string) => {
        setAtas((prev) =>
          (prev || []).filter((ata) => ata.id !== ataDeletedId)
        );
      }
    );

    return () => {
      unsubscribeAtaDelete();
    };
  }, [subscribe]);

  const { isFetching } = useInfiniteScroll({
    ref: listRef,
    initialData: atas,
    query: AtaController.getReportList,
    params: {
      fullList: true,
      filter: 'multiple',
      fields: orderBy.join(','),
    },
    take: Math.floor(listHeight / 30),
    enabled: online,
    onFetch: (data) =>
      setAtas((prev) => [
        ...prev,
        ...(data?.filter((i) => !prev.find((j) => j.id === i.id)) ?? []),
      ]),
    onReFetch: () => setAtas([]),
  });

  const defaultColumns = [
    { id: 'text', label: 'Ata', orderBy: 'text' },
    { id: 'createdBy', label: 'Criado por', orderBy: 'createdBy' },
    { id: 'hearingJudge', label: 'Juiz', orderBy: 'hearingJudge' },
    {
      id: 'hearingsLocations',
      label: 'Local audiência',
      orderBy: 'hearingsLocations',
    },
    { id: 'processNumber', label: 'N° do processo', orderBy: 'processNumber' },
    { id: 'claimants', label: 'Reclamante', orderBy: 'claimant' },
    {
      id: 'claimantLawyer',
      label: 'Adv. reclamante',
      orderBy: 'claimantLawyer',
    },
    {
      id: 'claimedLawyer',
      label: 'Adv. reclamado',
      orderBy: 'claimedLawyer',
    },
    { id: 'nameDeponent', label: 'Depoente', orderBy: 'nameDeponent' },
  ];

  const handleOrderBy = (column: string) => {
    setOrderBy((prev) =>
      [
        ...prev.filter((i) => i !== column),
        prev.includes(column) ? '' : column,
      ].filter((i) => i !== '')
    );
  };

  const comparers: any = {
    text: (a: IAtaReportListViewModel, b: IAtaReportListViewModel) =>
      a.text?.localeCompare(b?.text ?? '') || 0,
    createdBy: (a: IAtaReportListViewModel, b: IAtaReportListViewModel) =>
      a.createdBy?.localeCompare(b?.createdBy ?? '') || 0,
    hearingJudge: (a: IAtaReportListViewModel, b: IAtaReportListViewModel) =>
      a.hearingJudge?.localeCompare(b?.hearingJudge ?? '') || 0,
    hearingsLocations: (
      a: IAtaReportListViewModel,
      b: IAtaReportListViewModel
    ) =>
      (a.hearingsLocations?.[0] ?? '').localeCompare(
        b?.hearingsLocations?.[0] ?? ''
      ) || 0,
    processNumber: (a: IAtaReportListViewModel, b: IAtaReportListViewModel) =>
      a.processNumber?.localeCompare(b?.processNumber ?? '') || 0,
    claimants: (a: IAtaReportListViewModel, b: IAtaReportListViewModel) =>
      a.claimant?.localeCompare(b?.claimant ?? '') || 0,
    claimantLawyer: (a: IAtaReportListViewModel, b: IAtaReportListViewModel) =>
      a.claimantLawyer?.localeCompare(b?.claimantLawyer ?? '') || 0,
    claimedLawyer: (a: IAtaReportListViewModel, b: IAtaReportListViewModel) =>
      a.claimedLawyer?.localeCompare(b?.claimedLawyer ?? '') || 0,
    nameDeponent: (a: IAtaReportListViewModel, b: IAtaReportListViewModel) =>
      a.nameDeponent?.localeCompare(b?.nameDeponent ?? '') || 0,
  };

  const handleOfflineOrderBy = useCallback(
    (list: IAtaReportListViewModel[]) => {
      const orderedList = list.sort((a, b) => {
        const res = orderBy.reduce((acc, i) => {
          if (i) {
            const comparer = (comparers as any)[i];
            if (comparer) {
              const result = comparer(a, b);
              return result;
            }
          }
          return acc;
        }, 0);

        return res === 0 ? a.id.localeCompare(b.id) : res;
      });
      setOrderedOfflineAta(orderedList);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderBy]
  );

  useEffect(() => {
    handleOfflineOrderBy(atasOffline);
  }, [atasOffline, handleOfflineOrderBy, orderBy]);

  const handleSelectAll = () => {
    setEnableSelect(true);
    setSelectedAtas((prev) =>
      (prev || []).length === (atas || []).length
        ? []
        : (atas || []).map((a) => a.id)
    );
  };

  const handleDownloadReports = (allInOneFile: boolean) => {
    const mimes: any = {
      pdf: 'application/pdf',
      zip: 'application/zip',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };
    setDownloading(true);
    AtaController.exportDownload(selectedAtas, allInOneFile)
      .then((res) => {
        const blob = new Blob([res], {
          type: allInOneFile ? mimes.pdf : mimes.zip,
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Relatório - ${Date.now()}.${allInOneFile ? 'pdf' : 'zip'}`
        );
        link.click();

        setEnableSelect(false);
        setSelectedAtas([]);
      })
      .finally(() => setDownloading(false));
  };

  const formatField = (field?: string) => {
    const text = field?.trim();
    return text?.length ? text : 'NÃO CONSTA';
  };

  return (
    <Container selectEnabled={enableSelect} columns={columns.length}>
      <Header showOptions />
      <div className="list-title">
        <h1>Listagem</h1>
        <Search type={TypeResultSearch.ATAS} />
      </div>
      <div className="actions">
        <Button
          shape="text"
          onClick={() =>
            setEnableSelect((prev) => {
              if (prev) setSelectedAtas([]);
              return !prev;
            })
          }
        >
          <div id="select-btn">
            {enableSelect ? <HighlightOff /> : <FaFileDownload />}
            <p>{enableSelect ? 'Cancelar' : 'Clique aqui para baixar atas'}</p>
          </div>
        </Button>
      </div>
      <div className="content">
        <div className="list-header">
          {enableSelect && (
            <IconButton size="small" color="primary" onClick={handleSelectAll}>
              <FaFileDownload />
            </IconButton>
          )}
          {columns.map((column) => (
            <div
              key={column}
              className={`column-name${
                orderBy.includes(column) ? ' rotate' : ''
              }`}
            >
              <p>{defaultColumns.find((c) => c.id === column)?.label ?? ''}</p>
              {(!online || column !== 'text') && (
                <IconButton size="small" onClick={() => handleOrderBy(column)}>
                  <KeyboardArrowDown />
                </IconButton>
              )}
            </div>
          ))}
          <MenuPopup
            options={defaultColumns}
            multiple
            onSelect={(selected) => {
              if (selected.length) {
                setColumns(selected);
                setOrderBy((prev) => [
                  ...prev.filter((i) => selected.includes(i)),
                ]);
              } else {
                appAlert.show({
                  type: 'info',
                  title: 'Atenção',
                  message: 'Selecione pelo menos uma coluna para exibir',
                });
              }
            }}
            value={columns}
          >
            <IconButton color="primary">
              <FaCogs />
            </IconButton>
          </MenuPopup>
        </div>
        <ul className="list-items" ref={listRef}>
          {!isFetching && (online ? atas : atasOffline).length === 0 && (
            <div className="empty-list">
              <p>Nenhuma ata encontrada{!online ? ' offline' : ''}...</p>
            </div>
          )}
          {(online ? atas : orderedOfflineAtas).map((ata) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
              key={ata.id}
              className="item"
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') setViewAtaId(ata.id);
              }}
              onClick={() => setViewAtaId(ata.id)}
            >
              {enableSelect && (
                <Checkbox
                  id="select-ata"
                  size="small"
                  checked={selectedAtas.includes(ata.id)}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => {
                    if (selectedAtas.includes(ata.id)) {
                      setSelectedAtas((prev) =>
                        prev.filter((i) => i !== ata.id)
                      );
                    } else {
                      setSelectedAtas((prev) => [...prev, ata.id]);
                    }
                  }}
                />
              )}
              {columns?.map((key) => {
                const columnName =
                  defaultColumns.find((c) => c.id === key)?.label ?? '';
                const text =
                  key === 'text'
                    ? formatField(ata?.text)
                    : key === 'createdBy'
                    ? formatField(ata?.createdBy)
                    : key === 'hearingJudge'
                    ? formatField(ata.hearingJudge)
                    : key === 'hearingsLocations'
                    ? formatField(ata.hearingsLocations?.[0])
                    : key === 'processNumber'
                    ? formatField(ata.processNumber)
                    : key === 'claimants'
                    ? formatField(ata?.claimant)
                    : key === 'claimantLawyer'
                    ? formatField(ata?.claimantLawyer)
                    : key === 'claimedLawyer'
                    ? formatField(ata?.claimedLawyer)
                    : key === 'nameDeponent'
                    ? formatField(ata.nameDeponent)
                    : '-';

                return (
                  <p key={key} title={`${columnName}: ${text}`}>
                    {text}
                  </p>
                );
              })}
            </li>
          ))}
          {isFetching && (
            <div className="loading-atas-list">
              <img src={loadingGif} alt="Legal Control Atas Loading" />
              <p>Carregando atas...</p>
            </div>
          )}
        </ul>
      </div>
      <DownloadContainer open={enableSelect}>
        {downloading ? (
          <div className="download-loading">
            <img src={loadingGif} alt="Legal Control Atas Loading" />
            <p>Baixando arquivo...</p>
          </div>
        ) : (
          <>
            <div className="downloader-header">
              <div id="header">
                <FaFileDownload />
                <p>
                  {selectedAtas.length} ata
                  {selectedAtas.length !== 1 ? 's' : ''} selecionada
                  {selectedAtas.length !== 1 ? 's' : ''}
                </p>
              </div>
              <Button
                shape="text"
                onClick={() => {
                  setEnableSelect(false);
                  setSelectedAtas([]);
                }}
              >
                <div id="select-btn">
                  <HighlightOff />
                  <p>Cancelar</p>
                </div>
              </Button>
            </div>
            <div className="downloader-actions">
              <Button
                shape="text"
                disabled={!online || !selectedAtas.length}
                onClick={() => handleDownloadReports(true)}
              >
                <div id="action-btn-text">
                  <OneReportDownloadIcon />
                  <p>Baixar um pdf único</p>
                </div>
              </Button>
              <Button
                shape="text"
                disabled={!online || selectedAtas.length < 2}
                onClick={() => handleDownloadReports(false)}
              >
                <div id="action-btn-text">
                  <ManyReportDownloadIcon />
                  <p>Baixar pdf de cada ata</p>
                </div>
              </Button>
            </div>
          </>
        )}
      </DownloadContainer>
      <ModalViewAta
        ataId={viewAtaId}
        open={viewAtaId !== undefined}
        onClose={() => setViewAtaId(undefined)}
      />
    </Container>
  );
};

export default ListAtas;
