import { IAta, IHearing } from 'entities';
import { serialize } from 'object-to-formdata';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { IAtaInputModel, IClaimedInputModel } from 'inputModels';
import { IAtaListViewModel } from 'viewModels';
import { AtaViewer, Button, Header } from '../../../../components';
import {
  useAlert,
  useApp,
  useAta,
  useAuth,
  useContact,
  useOffice,
} from '../../../../context';
import { AtaController } from '../../../../controllers';
import { ataRepository } from '../../../../services/indexedDB';
import { Container, Footer } from './styles';

const FinishRegisterAta: React.FC = () => {
  const auth = useAuth();
  const { online } = useApp();
  const appAlert = useAlert();
  const { setAta, ata, isEmpty, getAtaName } = useAta();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const state = useLocation().state as { isEdit: boolean };

  const { offices } = useOffice();
  const { contacts } = useContact();

  const [sharingOption, setSharingOption] = useState<string | null | undefined>(
    undefined
  );

  const handleRegister = useCallback(async () => {
    if (isLoading) return;

    if (isEmpty) {
      const res = await appAlert.show({
        type: 'confirm',
        title: 'Atenção',
        message: 'Nenhum dado foi informado, deseja continuar mesmo assim?',
      });
      if (!res) return;
    }

    if (online) {
      setIsLoading(true);
      const claimed: IClaimedInputModel[] = ata.claimed.map((c) => ({
        name: c.name,
        lawyers: c.lawyers || [],
      }));

      const hearings: IHearing[] = ata.hearings.map((h) => ({
        date: h.date,
        observation: h.observation,
        lawyers: h.lawyers || [],
        depositions: h.depositions || [],
      }));
      const newAta: IAtaInputModel = {
        hearingJudge: ata.hearingJudge,
        processNumber: ata.processNumber,
        claimed,
        claimants: ata.claimants || ([] as any),
        attachmentsId: ata.attachmentsId || ([] as any),
        hearingsLocations: ata.hearingsLocations || ([] as any),
        hearings,
        officeId: sharingOption ?? undefined,
      };
      const formData = serialize(newAta, {
        indices: true,
      });
      formData.append('userId', auth.user?.id || '');

      AtaController.create(formData, auth?.token || '')
        .then(async () => {
          await appAlert.show({
            type: 'sucess',
            message: 'Ata salva com sucesso',
            timer: 1500,
          });
          setAta({} as IAta);
          navigate('/atas', { replace: true });
        })
        .catch(() => {
          appAlert.show({
            type: 'error',
            errors: [
              'Houve um erro inesperado ao salvar a ata.\nTente novamente ou entre em contato conosco.',
            ],
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      const [label, text] = getAtaName(ata);

      ata.label = label;
      ata.text = text;

      ataRepository
        .add({ ...ata, id: v4(), isNew: true })
        .then(async () => {
          await appAlert.show({
            type: 'sucess',
            message: 'Ata salva com sucesso',
            timer: 1500,
          });
          navigate('/atas');
        })
        .catch(() => {
          appAlert.show({
            type: 'error',
            errors: ['Houve um erro inesperado ao salvar a ata offline.'],
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [
    isLoading,
    isEmpty,
    online,
    appAlert,
    ata,
    sharingOption,
    auth.user?.id,
    auth?.token,
    setAta,
    navigate,
    getAtaName,
  ]);

  const handleUpdate = async () => {
    if (isLoading) return;
    setIsLoading(true);

    if (isEmpty) {
      const res = await appAlert.show({
        type: 'confirm',
        title: 'Atenção',
        message: 'Nenhum dado foi informado, deseja continuar mesmo assim?',
      });
      if (!res) return;
    }

    AtaController.update({
      id: ata.id,
      claimed: ata.claimed,
      claimants: ata.claimants,
      hearingJudge: ata.hearingJudge,
      processNumber: ata.processNumber,
      hearingsLocations: ata.hearingsLocations,
      attachmentsId: ata.attachmentsId || ([] as any),
      hearings:
        ata.hearings?.map((h) => ({
          ...h,
          date:
            // eslint-disable-next-line no-restricted-globals
            h.date instanceof Date && !isNaN(h.date.valueOf())
              ? new Date(
                  `${new Date(h.date)?.toISOString()?.replace('Z', '')}Z`
                )
              : undefined,
          lawyers: h.lawyers || ([] as any),
          depositions: h.depositions || ([] as any),
        })) || ([] as any),
      officeId: ata.officeId,
    })
      .then(async () => {
        await appAlert.show({
          type: 'sucess',
          message: 'Ata atualizada com sucesso',
          timer: 1500,
        });
        setAta({} as IAta);
        navigate('/atas');
      })
      .catch(() => {
        appAlert.show({
          type: 'error',
          errors: [
            'Houve um erro inesperado ao atualizar a ata. Tente novamente.',
          ],
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Container>
      <Header showOptions />
      <h1>Nova ata</h1>
      <AtaViewer />
      <h4>Com quem deseja compartilhar a ata?</h4>
      {auth.profile === 'company' && (
        <div className="sharing-buttons">
          <Button
            onClick={() => {
              setSharingOption(null);
              setAta({ ...ata, officeId: undefined });
            }}
            shape={ata.officeId ? 'outline' : 'solid'}
            size="medium"
            style={{ margin: '10px' }}
          >
            Manter Privada
          </Button>
          {offices.map((office) => (
            <Button
              key={office.id}
              onClick={() => {
                setSharingOption(office.id);
                setAta({ ...ata, officeId: office.id });
              }}
              shape={ata.officeId === office.id ? 'solid' : 'outline'}
              size="medium"
              style={{ margin: '10px' }}
            >
              {office.name}
            </Button>
          ))}
        </div>
      )}
      {auth.profile === 'common' && (
        <div>
          <Button
            onClick={() => {
              setSharingOption(null);
              setAta({ ...ata, officeId: undefined });
            }}
            shape={ata.officeId ? 'outline' : 'solid'}
            size="medium"
            style={{ margin: '10px' }}
          >
            Manter Privada
          </Button>
          {contacts.map((contact) => (
            <Button
              key={contact.owner.id}
              onClick={() => {
                setSharingOption(contact.owner.id);
                setAta({ ...ata, officeId: contact.owner.id });
              }}
              shape={ata.officeId !== contact.owner.id ? 'outline' : 'solid'}
              size="medium"
              style={{ margin: '10px' }}
            >
              {contact.owner.name}
            </Button>
          ))}
        </div>
      )}

      <Footer>
        <Button
          onClick={() => navigate(-1)}
          shape="outline"
          size="large"
          disabled={isLoading}
          style={{
            maxWidth: 300,
            alignSelf: 'flex-end',
            marginTop: 16,
          }}
        >
          Voltar
        </Button>
        <Button
          onClick={() => (state?.isEdit ? handleUpdate() : handleRegister())}
          shape="solid"
          size="large"
          id="btn-finish-register-ata"
          style={{
            maxWidth: 300,
            alignSelf: 'flex-end',
            marginTop: 16,
          }}
          isLoading={isLoading}
        >
          Finalizar
        </Button>
      </Footer>
    </Container>
  );
};

export default FinishRegisterAta;
