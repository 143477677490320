import { Search } from '@mui/icons-material';
import { CircularProgress, IconButton, ModalProps } from '@mui/material';
import { IAttachment } from 'entities';
import { useEffect, useRef, useState } from 'react';
import { FaPaperclip, FaTimesCircle } from 'react-icons/fa';
import { useAlert, useApp, useAta, useAuth } from '../../../context';
import { CancelTokenSource } from '../../../services/api';
import Attachment from '../../Attachment';
import Dropzone from '../../Dropzone';
import Input from '../../Input';
import InputFilter from '../../InputFilter';
import Modal from '../Modal';
import ModalDeleteAttachment from '../ModalDeleteAttachment';

import {
  IFilterAttachments,
  useAttachments,
} from '../../../context/attachments';
import { AtaController, AttachmentController } from '../../../controllers';
import {
  Center,
  Container,
  ContainerDropzone,
  ContainerListAttachments,
  Content,
  Header,
  HeaderList,
  List,
  Title,
} from './styles';

interface Props extends Omit<ModalProps, 'children'> {
  close: () => void;
}

type IFilters = {
  [key in IFilterAttachments]: string;
};

type IAttachments = {
  id: string;
} & File;

const ModalAttachFile = ({ close, ...props }: Props) => {
  const auth = useAuth();
  const appAlert = useAlert();
  const { online } = useApp();
  const { ata, setAta } = useAta();
  const [displayModalDeleteAttachment, setDisplayModalDeleteAttachment] =
    useState(false);

  const {
    attachments,
    filteredAttachments,
    loading,
    download,
    filterAttachments,
    insertAttachments,
    setKeyFilter,
    progressUpload,
    currentAttachment,
    setProgressUpload,
    setCurrentAttachment,
    deleteAttachment,
  } = useAttachments();

  const [textEmpty, setTextEmpty] = useState('');

  const filters: IFilters = {
    oldAttachments: 'Mais antigos',
    order: 'Ordem alfabética',
    notAttached: 'Não anexados',
  };

  const cancelToken = useRef<CancelTokenSource>({} as CancelTokenSource);

  useEffect(() => {
    if (!attachments.length) setTextEmpty('Você não possui anexos');
    else if (!filteredAttachments.length)
      setTextEmpty('Não foram encontrados resultados.');
  }, [attachments.length, filteredAttachments.length]);

  useEffect(() => {
    if (!online) close();
  }, [close, online]);

  const handleDeleteAttachment = async () => {
    deleteAttachment().then(() => {
      setDisplayModalDeleteAttachment(false);
    });
  };

  const handleOptions = (option: number | string) => {
    switch (option) {
      case 0:
        handleDeleteAttachment();
        break;
      case 1:
        download(false);
        break;
      default:
        break;
    }
  };

  const handleOnFilter = (key: IFilterAttachments) => {
    setKeyFilter(key);
  };

  const handleAttachFile = (att: IAttachment) => {
    const prevIndex = (ata?.attachments || []).findIndex(
      (attachment) => attachment.id === att.id
    );

    const prevIdsIndex = (ata?.attachmentsId || []).findIndex(
      (attId) => attId === att.id
    );

    if (prevIndex !== -1 || prevIdsIndex !== -1) {
      appAlert.show({
        type: 'error',
        errors: ['Este anexo já está vinculado a ata.'],
      });
      return;
    }

    setAta((prevState) => ({
      ...prevState,
      attachments: prevState.attachments?.length
        ? [...prevState.attachments, att]
        : [att],
      attachmentsId: prevState.attachmentsId?.length
        ? [...prevState.attachmentsId, att.id]
        : [att.id],
    }));

    if (ata.id) {
      const data = new FormData();
      data.append('ataId', ata.id);
      data.append('attachmentId', att.id);

      AtaController.attach(data)
        .then(() => close())
        .catch(() => {
          appAlert.show({
            type: 'error',
            errors: ['Erro ao anexar arquivo'],
          });
        });
    } else close();
  };

  const handleInsertAttachments = async (
    attachmentsToUpload: IAttachments[]
  ) => {
    const formData = new FormData();
    formData.append('id', auth.user?.id || '');
    attachmentsToUpload.forEach((att) => {
      formData.append('attachments', att);
    });

    const attId = await insertAttachments({
      formData,
      cancelToken: cancelToken.current,
    });
    if (attId) {
      const att = await AttachmentController.getById(attId);
      await handleAttachFile(att);
    }
  };

  return (
    <Modal {...props}>
      <Container>
        <Header>
          <Title>
            <FaPaperclip size={24} />
            <p>Anexos</p>
          </Title>
          <IconButton onClick={close}>
            <FaTimesCircle size={20} />
          </IconButton>
        </Header>
        <Content>
          <ContainerDropzone>
            <Dropzone
              onLoadFiles={handleInsertAttachments}
              progress={progressUpload}
              onCancel={() => {
                cancelToken.current?.cancel();
                setProgressUpload(0);
              }}
            />
          </ContainerDropzone>
          <ContainerListAttachments>
            <HeaderList>
              <Input
                id="search"
                label="Procurar"
                placeholder="Procurar usuário"
                size="medium"
                onFocus={() => setKeyFilter('search')}
                fullWidth={false}
                endAdornment={
                  <IconButton onClick={() => {}}>
                    <Search />
                  </IconButton>
                }
                onChange={(e) => filterAttachments(e.target.value)}
              />
              <InputFilter filters={filters} onFilter={handleOnFilter} />
            </HeaderList>
            {filteredAttachments.length > 0 ? (
              <List>
                {filteredAttachments.map((att) => (
                  <Attachment
                    onSelect={(option) => {
                      setCurrentAttachment(att);
                      handleOptions(option);
                    }}
                    onClick={() => handleAttachFile(att)}
                    key={att.id}
                    data={att}
                  />
                ))}
              </List>
            ) : (
              <Center>
                {(!attachments.length || !!textEmpty) && !loading && (
                  <p>{textEmpty}</p>
                )}
                {loading && <CircularProgress size={30} />}
              </Center>
            )}
          </ContainerListAttachments>
        </Content>
        {displayModalDeleteAttachment &&
          Object.keys(currentAttachment.current).length > 0 && (
            <ModalDeleteAttachment
              data={{
                createdAt: currentAttachment.current.createdAt,
                contentType: currentAttachment.current.contentType,
                originalName: currentAttachment.current.originalName,
              }}
              open={displayModalDeleteAttachment}
              close={() => setDisplayModalDeleteAttachment(false)}
              onDelete={() => handleDeleteAttachment()}
            />
          )}
      </Container>
    </Modal>
  );
};

export default ModalAttachFile;
