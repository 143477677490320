import React from 'react';
import Menu from '@mui/material/Menu';
import Checkbox from '@mui/material/Checkbox';
import { Check } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { MenuContent, Option } from './styles';

type IOption = {
  id: string | number;
  label: string;
  icon?: any;
};

interface MenuPopupProps {
  id?: string;
  children: React.ReactNode;
  multiple?: boolean;
  options: IOption[];
  disabled?: boolean;
  value?: any | any[];
  // eslint-disable-next-line no-unused-vars
  onSelect?: (option: any) => void;
}

const MenuPopup: React.FC<MenuPopupProps> = ({
  children,
  options,
  id,
  multiple,
  onSelect,
  value,
  disabled,
}) => {
  const handleChange = (option: any) => {
    let res = option;
    if (multiple) {
      if (value && value.includes(option)) {
        res = value.filter(
          (item: any) => item?.id !== option && item !== option
        );
      } else res = [...(value || []), option];
    }

    if (onSelect) {
      onSelect(res);
    }
  };

  return (
    <PopupState variant="popover" popupId="popup-menu">
      {(popupState) => (
        <>
          <MenuContent id={id} disabled={disabled} {...bindTrigger(popupState)}>
            {children}
          </MenuContent>
          {!disabled && (
            <Menu {...bindMenu(popupState)}>
              {options.map((option, index) =>
                multiple ? (
                  <MenuItem
                    key={option?.id || index}
                    onClick={() => handleChange(option?.id || index)}
                    selected={value && value.includes(option?.id || index)}
                  >
                    <Option hasIcon={!!option.icon}>
                      <Checkbox
                        checked={value && value.includes(option?.id || index)}
                      />
                      <p>{option?.label || option}</p>
                    </Option>
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={option?.id || index}
                    value={option?.id || index}
                    selected={
                      value !== undefined &&
                      (option?.id === value || index === value)
                    }
                    onClick={() => {
                      popupState.close();
                      handleChange(option?.id || index);
                    }}
                  >
                    <Option hasIcon={!!option.icon}>
                      {!!option.icon && option.icon}
                      <p>{option?.label || option}</p>
                      {value !== undefined &&
                      (option?.id === value || index === value) ? (
                        <Check id="check" />
                      ) : null}
                    </Option>
                  </MenuItem>
                )
              )}
            </Menu>
          )}
        </>
      )}
    </PopupState>
  );
};

export default MenuPopup;
