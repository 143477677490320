/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Menu from '@mui/material/Menu';
import { PopupProps } from 'utils';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

interface MenuPopupProps {
  id?: string;
  children: React.ReactNode;
  Element: React.FC<PopupProps>;
  disabled?: boolean;
}

const MenuPopup: React.FC<MenuPopupProps> = ({
  children,
  Element,
  id,
  disabled,
}) => {
  return (
    <PopupState variant="popover" popupId="popover">
      {(popupState) => (
        <>
          <div
            id={id}
            {...{
              ...bindTrigger(popupState),
              onClick: (event) => {
                event.stopPropagation();
                bindTrigger(popupState).onClick(event);
              },
            }}
          >
            {children}
          </div>
          {!disabled && (
            <Menu
              {...bindMenu(popupState)}
              onClick={(e) => e.stopPropagation()}
            >
              <Element onClose={popupState.close} isOpen={popupState.isOpen} />
            </Menu>
          )}
        </>
      )}
    </PopupState>
  );
};

export default MenuPopup;
