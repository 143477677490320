/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Progress } from './styles';

interface ProgressBarProps {
  id?: string;
  value?: number;
  size?: 'small' | 'medium' | 'large';
  active?: boolean;
  label?: string;
  errorLabel?: string;
  failed?: boolean;
  onCancel?: () => void;
  onRetry?: () => void;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  id,
  value,
  size,
  active = true,
  label,
  errorLabel,
  failed,
  onCancel,
  onRetry,
}) => {

  // useEffect(() => {
  //   if (value === 100) {
  //     onCancel?.();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [value]);

  return (
    <Progress
      id={id}
      className={`progress-striped progress-${size === 'small' ? 'xs' : size === 'large' ? 'lg' : 'sm'
        }${active && !failed ? ' active' : ''}`}
      error={failed}
    >
      <div
        style={{ width: `${value || 0}%` }}
        className={`progress-bar${failed ? ' fail' : ''}`}
      />
      <div id="infos">
        {label && <p id="label">{!failed ? label : errorLabel}</p>}
        {value ? (
          <div id="actions">
            <p onClick={onCancel}>Cancelar</p>
            {failed && (
              <p id="error" onClick={onRetry}>
                Tentar novamente
              </p>
            )}
          </div>
        ) : null}
      </div>
    </Progress>
  );
};

export default ProgressBar;
