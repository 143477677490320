import styled, { css } from 'styled-components';

interface IFilters {
  isVisible: boolean;
}

export const Container = styled.div`
  position: relative;
  div {
    display: flex;
    align-items: center;
    width: max-content;
  }
`;

export const TextFilter = styled.p`
  font-family: 'Futura PT';
  font-weight: 200;
  ${({ theme: { colors } }) => css`
    color: ${colors.text};
  `}
  margin-right: 4px;
  white-space: nowrap;
`;

export const Filters = styled.div<IFilters>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 171px;
  height: auto;

  ${({ theme: { colors, shadows } }) => css`
    background: ${colors.background};
    box-shadow: ${shadows[2]};
    border: 1px solid #ebebeb;
  `}

  position: absolute;
  top: 100%;
  right: 0;
  z-index: 5000;

  transform: scaleY(0);
  transform-origin: top center;
  transition: all 0.5s ease;
  opacity: 0;

  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
      transform: scaleY(1);
      transition: all 0.5s ease;
    `}
`;

export const ButtonOpenFilters = styled.button`
  background-color: transparent;
  outline: 0;
  border-color: transparent;
  padding: 4px;
  svg {
    ${({ theme: { colors } }) => css`
      color: ${colors.text};
    `}
  }
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ButtonFilter = styled.button`
  background-color: transparent;
  outline: 0;
  border-color: transparent;

  ${({ theme: { colors } }) => css`
    color: ${colors.border};
  `}
  font-size: 14px;
  font-weight: 200;
  line-height: 18px;
`;
