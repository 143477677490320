import { ISearchResult, IUser, IUserUpdate } from 'entities';
import api from '../services/api/axios';
import { TypeResultSearch } from '../types/enums';

interface ILogin {
  document: string;
  password: string;
}

interface ICreate {
  document: string;
  name: string;
  email: string;
  password: string;
}

const create = (user: ICreate) => {
  return api.post('user', user);
};

const update = (user: IUserUpdate, token: string) => {
  return api.put('user', user, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updatePassword = (
  oldPassword: string = '',
  newPassword: string = '',
  token: string
) => {
  return api.put(
    'user/update-password',
    {
      oldPassword,
      newPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const login = ({ ...data }: ILogin) => {
  return api.post('user/login', { ...data });
};

const resetPassword = (email: string, newPassword: string) =>
  new Promise<void>((resolve, reject) => {
    api
      .put('user/reset-password', {
        email,
        newPassword,
      })
      .then(() => resolve())
      .catch(reject);
  });

const getAuthority = () =>
  new Promise<boolean>((resolve, reject) => {
    api
      .get('user/authority')
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

/*const setAuthority = (authority: boolean) =>
  new Promise<void>((resolve, reject) => {
    api
      .put('user/authority', undefined, {
        params: { authority },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => resolve())
      .catch(reject);
  });*/

const findByEmail = (email: string) =>
  new Promise<{ sucess: boolean; data?: IUser; status?: string }>((resolve) => {
    api
      .get(`user/find-by-email/${email}`)
      .then(({ data }) => resolve(data))
      .catch(({ response: { data } }) => resolve(data));
  });

const findByDocument = (document: string) =>
  new Promise<{ sucess: boolean; data?: IUser; status?: string }>((resolve) => {
    api
      .get(`user/find-by-document/${document}`)
      .then(({ data }) => resolve(data))
      .catch(({ response: { data } }) => resolve(data));
  });

const search = (term: string, typeResults?: TypeResultSearch) =>
  new Promise<ISearchResult[]>((resolve, reject) => {
    api
      .get('user/search', {
        params: {
          term,
          typeResults,
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const disableAccount = (uid: string) =>
  new Promise<void>((resolve, reject) => {
    api
      .delete(`user/${uid}`)
      .then(() => resolve())
      .catch(reject);
  });

const updateConditionsAccepted = (id: string, status: boolean) =>
  new Promise<boolean>((resolve, reject) => {
    api
      .put(`user/accept-conditions/${id}`, status, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const UserController = {
  create,
  update,
  login,
  search,
  findByEmail,
  getAuthority,
  //setAuthority,
  resetPassword,
  findByDocument,
  disableAccount,
  updatePassword,
  updateConditionsAccepted,
};
