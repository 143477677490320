import { useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { IFilterAttachments } from '../../context/attachments';
import useClickOutside from '../../hooks/useClickOutside';

import {
  ButtonFilter,
  ButtonOpenFilters,
  Container,
  Filters,
  TextFilter,
} from './styles';

interface Props {
  filters: object;
  onFilter: (key: IFilterAttachments) => void;
}

const InputFilter = ({ filters, onFilter }: Props) => {
  const filterRef = useRef<HTMLDivElement>(null);
  const [textFilter, setTextFilter] = useState('Filtrar');
  const [displayFilters, setDisplayFilters] = useState(false);

  useClickOutside(() => setDisplayFilters(false), filterRef);

  const handleOptionFilter = (key: IFilterAttachments, text: string) => {
    setTextFilter(text);
    onFilter(key);
    setDisplayFilters(false);
  };
  return (
    <Container ref={filterRef}>
      <ButtonOpenFilters onClick={() => setDisplayFilters(!displayFilters)}>
        <TextFilter>{textFilter}</TextFilter>
        <FaChevronDown />
      </ButtonOpenFilters>

      <Filters isVisible={displayFilters}>
        {Object.entries(filters).map(([key, value]) => (
          <ButtonFilter
            key={key}
            onClick={() => handleOptionFilter(key as IFilterAttachments, value)}
          >
            {value}
          </ButtonFilter>
        ))}
      </Filters>
    </Container>
  );
};

export default InputFilter;
