import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  input {
    margin-right: 10px;
    font-size: 40px;
    font-weight: 800;
    text-align: center;
    width: 60px;
    height: 60px;
    border: 1px solid ${({ theme }) => theme.colors.text};
    color: ${({ theme }) => theme.colors.primary};
    border-radius: 15px;
    text-transform: uppercase;
    outline: none;
  }

  input:not(:read-only):focus {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  input:last-child {
    margin-right: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .with-error {
    border: 1px solid ${({ theme }) => theme.colors.error};
  }
`;
