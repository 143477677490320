import { IContact } from 'entities';
import api from '../services/api/axios';
import { ContactDeleteAction, ContactStatus } from '../types/enums';

const add = ({
  code,
  email,
  uid,
}: {
  code?: string;
  email?: string;
  uid?: string;
}) =>
  new Promise<string>((resolve, reject) => {
    api
      .post('contact', {
        email,
        code,
        uid,
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const get = (ownerId?: string) =>
  new Promise<IContact[]>((resolve, reject) => {
    api
      .get('contact', { params: { ownerId } })
      .then(({ data }) => resolve(data || []))
      .catch(reject);
  });

const getById = (id: string) =>
  new Promise<IContact>((resolve, reject) => {
    api
      .get(`contact/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getOwnerContacts = (contactId: string) =>
  new Promise<IContact[]>((resolve, reject) => {
    api
      .get(`contact/get-owner-contacts/${contactId}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const update = (
  id: string,
  status: ContactStatus,
  action: ContactDeleteAction | undefined,
  token: string
) =>
  new Promise<void>((resolve, reject) => {
    api
      .put(
        'contact',
        {
          id,
          status,
          action,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(() => resolve())
      .catch(reject);
  });

/*const setRules = (id: string, rules: string[]) =>
  new Promise<void>((resolve, reject) => {
    api
      .put(`contact/${id}`, rules, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => resolve())
      .catch(reject);
  });*/

const remove = (
  id: string,
  action: ContactDeleteAction | undefined,
  token: string
) =>
  new Promise<void>((resolve, reject) => {
    api
      .delete(`contact/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          action,
        },
      })
      .then(() => resolve())
      .catch(reject);
  });

export const ContactController = {
  add,
  get,
  update,
  remove,
  getById,
  getOwnerContacts,
};
