import styled from 'styled-components';

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 36%;
  padding: 10px 30px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.background};

  & > div {
    margin: 5px 0;
  }

  #msg {
    min-height: 175px;
  }

  #send-form {
    width: 100px;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    right: 0;
  }
`;
