import styled from 'styled-components';

interface MenuContentProps {
  disabled?: boolean;
}
export const MenuContent = styled.div<MenuContentProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

interface IOption {
  hasIcon: boolean;
}

export const Option = styled.div<IOption>`
  display: flex;
  align-items: center;
  width: calc(100% + 20px);
  margin-left: -10px;
  justify-content: ${({ hasIcon }) =>
    hasIcon ? 'flex-start' : 'space-between'};
  color: ${({ theme }) => theme.colors.primary};

  #check {
    margin-left: 10px;
  }

  svg {
    min-width: 22px;
    min-height: 22px;
    max-width: 22px;
    max-height: 22px;
  }

  p {
    width: 100%;
    margin-left: 10px;
    padding-right: 10px;
  }

  .MuiCheckbox-root {
    margin-right: -5px;
  }

  /*input {
    margin-left: -10px !important;
    margin-right: 10px !important;
  }

  span {
    margin-right: 15px;
  }*/

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }

  & > svg {
    min-width: 20px;
    min-height: 20px;
    margin-left: 10px;
  }
`;
