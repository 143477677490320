import Dexie from 'dexie';
import db from './db';
import { Ata, Attachment, BaseModel } from './models';

/**
 * Implementations of CRUD operations for indexedDB database.
 * @class Repository class for models.
 * @template T The model type.
 */
class Repository<T extends BaseModel> {
  private table: Dexie.Table<T, string>;

  constructor(tableName: string) {
    this.table = db.tables.find(
      (t) => t.name === tableName.toLowerCase()
    ) as Dexie.Table<T, string>;
  }

  /**
   * Delete the entire database
   * @returns {Promise<void>}
   * @memberof BaseRepository
   * @example
   * await repository.clear();
   */
  drop = async (): Promise<void> => db.delete();

  /**
   * Open a database
   * @returns {Promise<Dexie>}
   * @memberof BaseRepository
   * @example
   * const db = await repository.open();
   */
  open = async (): Promise<Dexie> => db.open();

  /**
   * Clear all tables
   * @returns {Promise<void[]>}
   * @memberof BaseRepository
   * @example
   * await repository.clearAll();
   */
  clearAll = async (): Promise<void[]> =>
    Promise.all(db.tables.map(async (table) => table.clear()));

  /**
   * Get item from the database by id.
   * @param id  The id of the item to get.
   * @returns {Promise<T | undefined>}
   * @memberof Repository
   * @example
   * const item = await repository.get(id);
   */
  get = (id: string): Promise<T | undefined> => this.table.get(id);

  /**
   * Get all match items from the database.
   * @param where The where clause to filter the items to get.
   * @returns {Promise<T | undefined>}
   * @memberof Repository
   * @example
   * const item = await repository.get(id);
   */
  getWhere = (query: (item: T) => boolean): Promise<T[] | undefined> =>
    this.table.toCollection().and(query).toArray();

  /**
   * Get all items from the database.
   * @returns {Promise<T[]>}
   * @memberof Repository
   * @example
   * const items = await repository.getAll();
   */
  getAll = (): Promise<T[]> => this.table.toArray();

  /**
   * Add item to the database.
   * @param item  The item to add.
   * @returns {Promise<string>}
   * @memberof Repository
   * @example
   * const id = await repository.add(item);
   */
  add = (item: T): Promise<string> => this.table.add(item);

  /**
   * Update item in the database.
   * @param item The item to update.
   * @returns {Promise<number>}
   * @memberof Repository
   * @example
   * const count = await repository.update(item);
   */
  update = (item: T): Promise<number> => this.table.update(item.id!, item);

  /**
   *  Delete item from the database.
   * @param id
   * @returns {Promise<void>}
   * @memberof Repository
   * @example
   * await repository.delete(id);
   */
  remove = (id: string): Promise<void> => this.table.delete(id);

  /**
   * Delete all match items from the database.
   * @param where The where clause to filter the items to delete.
   * @returns {Promise<number>}
   * @memberof Repository
   * @example
   * await repository.removeWhere(where);
   */
  removeWhere = async (query: (item: T) => boolean): Promise<number> => {
    const results = await this.getWhere(query);
    if (!results) return 0;
    const deletions = results
      ?.map((item) => (item.id ? this.remove(item.id) : undefined))
      .filter((p) => !!p);

    return Promise.all(deletions).then(() => 0);
  };
  //this.table.toCollection().and(query).delete();

  /**
   * Delete all items from the database.
   * @returns {Promise<void>}
   * @memberof Repository
   * @example
   * await repository.clear();
   */
  removeAll = (): Promise<void> => this.table.clear();
}

// Instantiate the repositories

/**
 * Repository for Ata models.
 */
const ataRepository = new Repository<Ata>('atas');

/**
 * Repository for Attachment models.
 */
const attachmentRepository = new Repository<Attachment>('attachments');

export { ataRepository, attachmentRepository };
