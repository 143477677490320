import React, { useState, useLayoutEffect } from 'react';

const useSizeChange = (ref: React.RefObject<HTMLElement>) => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  useLayoutEffect(() => {
    const currentRef = ref.current;
    if (!currentRef) return () => {};

    const observer = new ResizeObserver((entries) => {
      const rect = entries[0].contentRect;
      setSize(rect);
    });

    observer.observe(currentRef as Element);

    return () => observer.disconnect();
  }, [ref]);
  return size;
};

export default useSizeChange;
