import { Checkbox } from '@mui/material';
import React, { useState } from 'react';
import { FaPaperclip } from 'react-icons/fa';
import { useApp, useAta } from '../../context';
import { typesDeponent } from '../../utils/typesDeponent';
import ItemFile from '../ItemFile';
import ModalAttachFile from '../Modals/ModalAttachFile';
import {
  ButtonAttachFile,
  Content,
  Group,
  Item,
  Lawyers,
  Line,
  WrapperContradicted,
  WrapperFiles,
  WrapperInfos,
} from './styles';

const AtaViewer: React.FC = () => {
  const { ata, isEmpty } = useAta();
  const { online } = useApp();
  const [displayModalAttachFile, setDisplayModalAttachFile] = useState(false);

  return (
    <Content>
      <WrapperInfos id="ata-infos">
        {isEmpty && (
          <Line>
            <Item>
              <p>Ata em branco</p>
              <p>Nenhum dado informado...</p>
            </Item>
          </Line>
        )}
        {(!!ata?.processNumber || !!ata?.hearingJudge) && (
          <Line>
            {!!ata?.processNumber && (
              <Item>
                <p>Número do processo</p>
                <p>{ata?.processNumber}</p>
              </Item>
            )}
            {!!ata?.hearingJudge && (
              <Item>
                <p>Juiz da audiência</p>
                <p>{ata?.hearingJudge}</p>
              </Item>
            )}
          </Line>
        )}
        {ata.hearingsLocations?.map((location, index) => (
          <Line key={location}>
            <Item>
              <p>Local da audiência {index + 1}</p>
              <p>{location}</p>
            </Item>
          </Line>
        ))}

        {ata?.claimants?.map((c, index) => (
          <Line key={c.id}>
            {!!c.name && (
              <Item>
                <p>Reclamante {index + 1}</p>
                <p>{c.name}</p>
              </Item>
            )}
            <Lawyers>
              {c.lawyers?.map(
                (l) =>
                  !!l.name && (
                    <Item>
                      <p>Advogado do reclamante {index + 1}</p>
                      <p>{l.name}</p>
                    </Item>
                  )
              )}
            </Lawyers>
          </Line>
        ))}
        {ata?.claimed?.map((c, index) => (
          <Line key={c.id}>
            {!!c.name && (
              <Item>
                <p>Reclamado {index + 1}</p>
                <p>{c.name}</p>
              </Item>
            )}
            <Lawyers>
              {c.lawyers?.map(
                (l) =>
                  !!l.name && (
                    <Item>
                      <p>Advogado do reclamado {index + 1}</p>
                      <p>{l.name}</p>
                    </Item>
                  )
              )}
            </Lawyers>
          </Line>
        ))}
        {!!ata.hearings?.length && (
          <>
            {ata?.hearings?.map((h, idx) => (
              <>
                <div style={{ height: 16 }} />
                <Group key={h.id}>
                  <Line id="title">
                    <h3>Audiência {idx + 1}</h3>
                    {!!h.date && (
                      <Item>
                        <p>Data</p>
                        <p>
                          {h.date
                            ? new Date(
                              new Date(h.date)?.getTime() +
                              new Date(h.date)?.getTimezoneOffset() * 60000
                            ).toLocaleDateString('pt-BR')
                            : '--/--/----'}
                        </p>
                      </Item>
                    )}
                  </Line>
                  {!!h.observation && (
                    <Line>
                      <Item>
                        <p>Observações</p>
                        <p>{h.observation}</p>
                      </Item>
                    </Line>
                  )}
                  {!!h.lawyers?.length && (
                    <>
                      <h4>Advogados</h4>
                      {h.lawyers?.map((l, lidx) => (
                        <div key={l.id} className="hearing-items">
                          <Line>
                            <Item>
                              <p>Advogado {lidx + 1}</p>
                              <p>{l.name}</p>
                            </Item>
                          </Line>
                        </div>
                      ))}
                    </>
                  )}

                  {!!h.depositions?.length && (
                    <>
                      <div style={{ height: 16 }} />
                      <h4>Depoimentos</h4>
                      {h?.depositions?.sort((a, b) => Number(a.typeDeponent) - Number(b.typeDeponent)).map((d, didx) => (
                        <div key={d.id}>
                          {(!!d.nameDeponent || !!d.nickNameDeponent) && (
                            <>
                              <Line>
                                {!!d.nameDeponent && (
                                  <Item>
                                    <p>Nome do depoente {didx + 1}</p>
                                    <p>{d.nameDeponent}</p>
                                  </Item>
                                )}
                              </Line>
                            </>
                          )}
                          {(d.typeDeponent !== undefined ||
                            d.nickNameDeponent) && (
                              <Line>
                                {!!d.nickNameDeponent && (
                                  <Item>
                                    <p>Apelido do depoente {didx + 1}</p>
                                    <p>{d.nickNameDeponent}</p>
                                  </Item>
                                )}
                                {d.typeDeponent !== undefined && (
                                  <Item>
                                    <p>Classificação do depoente {didx + 1}</p>
                                    <p>
                                      {
                                        typesDeponent.find(
                                          (t) => t.key === d.typeDeponent
                                        )?.label
                                      }
                                    </p>
                                  </Item>
                                )}
                              </Line>
                            )}
                          {!!d.text && (
                            <Line>
                              <Item>
                                <p>Depoimento {didx + 1}</p>
                                <p>{d.text}</p>
                              </Item>
                            </Line>
                          )}
                          {d.contradicted !== undefined && (
                            <div className="contradicted">
                              <h2>Contraditas</h2>
                              <WrapperContradicted>
                                <div className="checkbox-wrapper">
                                  <Checkbox
                                    checked={d.contradicted === true}
                                    disabled
                                  />
                                  <p>Deferida</p>
                                </div>
                                <div className="checkbox-wrapper">
                                  <Checkbox
                                    checked={d.contradicted === false}
                                    disabled
                                  />
                                  <p>Indeferida</p>
                                </div>
                              </WrapperContradicted>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </Group>
              </>
            ))}
          </>
        )}
        {!!ata?.attachments?.length && (
          <WrapperFiles>
            {ata?.attachments?.map((att) => (
              <ItemFile key={att.id} att={att} />
            ))}
          </WrapperFiles>
        )}

        {!ata?.trashed && (
          <ButtonAttachFile
            disabled={!online}
            onClick={() => setDisplayModalAttachFile(true)}
          >
            <FaPaperclip size={24} />
            Anexar arquivo
          </ButtonAttachFile>
        )}
      </WrapperInfos>
      <ModalAttachFile
        close={() => setDisplayModalAttachFile(false)}
        open={displayModalAttachFile}
        onClose={() => setDisplayModalAttachFile(false)}
      />
    </Content>
  );
};

export default AtaViewer;
