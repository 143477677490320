import React, { useEffect, useRef, useState } from 'react';
import { Pie } from '@ant-design/plots';

interface PieChartData {
  type: string;
  value: number;
}

interface PieChartProps {
  data: PieChartData[];
  name: string;
}

const PieChart: React.FC<PieChartProps> = ({ ...props }) => {
  const [chartData, setChartData] = useState<PieChartData[]>(props.data);
  const [key, setKey] = useState<number>(0);
  const [fontSize, setFontSize] = useState<number>(20);
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setChartData(props.data);
    // Atualiza a chave para forçar a remontagem do componente e a atualização dos rótulos
    setKey((prevKey) => prevKey + 1);
  }, [props.data]);

  useEffect(() => {
    function updateFontSize() {
      if (chartRef.current) {
        const width = chartRef.current.offsetWidth;
        // Calcula dinamicamente o tamanho da fonte com base no tamanho do componente
        const newFontSize = Math.min(width * 0.05, 20);
        setFontSize(newFontSize);
      }
    }

    updateFontSize();
    window.addEventListener('resize', updateFontSize);
    return () => {
      window.removeEventListener('resize', updateFontSize);
    };
  }, []);

  const chartConfig = {
    key,
    data: chartData,
    angleField: 'value',
    colorField: 'type',
    autoFit: true,
    label: {
      text: 'value',
      style: {
        fontWeight: 'bold',
      },
    },
    innerRadius: 0.6,
    legend: {
      color: {
        title: false,
        position: 'right',
        rowPadding: 5,
      },
    },
    annotations: [
      {
        type: 'text',
        style: {
          text: props.name,
          x: '50%',
          y: '50%',
          textAlign: 'center',
          fontSize,
          fontStyle: 'bold',
        },
      },
    ],
  };

  return (
    <div ref={chartRef} style={{ width: '100%', height: '100%' }}>
      <Pie {...chartConfig} />
    </div>
  );
};

export default PieChart;
