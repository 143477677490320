import React, { useEffect } from 'react';

function useClickOutside(
  callback: () => void,
  ref: React.RefObject<HTMLElement>
) {
  useEffect(() => {
    let timer: NodeJS.Timeout;

    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        timer = setTimeout(() => {
          callback();
        }, 150);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (timer) clearTimeout(timer);
    };
  }, [ref, callback]);
}

export default useClickOutside;
