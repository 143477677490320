import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { useApp } from '../../context';
import loading from '../../assets/gif/loading.gif';

interface LoadingProps {
  shown: boolean;
}

const Loading: React.FC<LoadingProps> = ({ shown }) => {
  const { setLoading } = useApp();
  const [showCancellation, setShowCancellation] = useState(false);

  useEffect(() => {
    setShowCancellation(false);
    const timer = setTimeout(() => {
      setShowCancellation(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, [shown]);

  return (
    <Container shown={shown}>
      <img src={loading} alt="Legal Control Atas Loading" />
      <p
        className={`${showCancellation ? 'shown' : ''}`}
        aria-hidden
        onClick={() => { setLoading(false); }}
      >
        Cancelar
      </p>
    </Container>
  );
};

export default Loading;
