import {
  CloudOff,
  CloudOutlined,
  Delete,
  SettingsBackupRestore,
} from '@mui/icons-material';
import {
  ButtonBase,
  CircularProgress,
  IconButton,
  ModalProps,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FaEdit, FaPaperclip, FaTimesCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  IAtaListViewModel,
  IAtaMoveToTrashViewModel,
  IAtaRestoreViewModel,
} from 'viewModels';
import {
  useActions,
  useAlert,
  useApp,
  useAta,
  useAuth,
} from '../../../context';
import { AtaController, AttachmentController } from '../../../controllers';
import useWebSocket from '../../../hooks/useWebSocket';
import { ataRepository } from '../../../services/indexedDB';
import AtaViewer from '../../AtaViewer';
import { ModalAttachFile } from '../index';
import Modal from '../Modal';
import { Container } from './styles';

interface Props extends Omit<ModalProps, 'children'> {
  ataId?: string;
  onClose: () => void;
}

const ModalViewAta = ({ onClose, ataId, ...rest }: Props) => {
  const { online } = useApp();
  const appAlert = useAlert();
  const navigate = useNavigate();
  const { subscribe } = useActions();
  const { tokenIsValid, user, profile } = useAuth();
  /*const [showModalLinkTo, setShowModalLinkTo] = useState(false);
  const [showForwardModal, setShowForwardModal] = useState(false);
  const [unshareContactId, setUnshareContactId] = useState<string>();*/
  const {
    ata,
    setAta,
    onlyOffline,
    handleAtaMenu,
    setOfflineAta,
    availableOffline,
  } = useAta();
  const [loading, setLoading] = useState(true);
  const [displayModalAttachFile, setDisplayModalAttachFile] = useState(false);

  useEffect(() => {
    if (tokenIsValid && ataId) {
      setLoading(true);
      if (online) {
        AtaController.getAllInfosById(ataId)
          .then(setAta)
          .finally(() => setLoading(false));
      } else {
        const success = setOfflineAta(ataId);
        setLoading(false);
        if (!success) onClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ataId, online, setAta, setOfflineAta, tokenIsValid]);

  useEffect(() => {
    const unsubscribeAtaUpdate = subscribe(
      'ATA_UPDATED',
      async (ataUpdated: IAtaListViewModel) => {
        if (ataId === ataUpdated.id) {
          AtaController.getAllInfosById(ataUpdated.id).then(setAta);
        }
      }
    );

    const unsubscribeAtaDelete = subscribe(
      'ATA_DISABLED',
      (ataDeletedId: string) => {
        if (ataId === ataDeletedId) onClose();
      }
    );

    const unsubscribeAtaLinkTo = subscribe('ATA_LINK_TO', (info) => {
      if (ataId === info.ataId) {
        setAta((prev) => ({ ...prev, linkedToId: info.linkedToId }));
      }
    });

    const unsubscribeAtaTrashed = subscribe(
      'TRASHED_ATA',
      ({ id }: IAtaMoveToTrashViewModel) => {
        if (ataId === id) {
          setAta((prev) => ({ ...prev, trashed: true }));
        }
      }
    );

    const unsubscribeAtaRestore = subscribe(
      'ATA_RESTORED',
      ({ id }: IAtaRestoreViewModel) => {
        if (id === ataId) {
          setAta((prev) => ({ ...prev, trashed: false }));
        }
      }
    );

    return () => {
      unsubscribeAtaUpdate();
      unsubscribeAtaDelete();
      unsubscribeAtaLinkTo();
      unsubscribeAtaTrashed();
      unsubscribeAtaRestore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribe, ataId, setAta]);

  useWebSocket<string>({
    url: `attachment/${ataId}/attach-to-ata`,
    onMessage: async (attId) => {
      try {
        const att = await AttachmentController.getById(attId);
        setAta((prev) => ({
          ...prev,
          attachments: [
            ...(prev.attachments || []).filter((a) => a.id !== attId),
            att,
          ],
        }));
      } catch {
        //...
      }
    },
  });

  useWebSocket<string>({
    url: `attachment/${ataId}/detach`,
    onMessage: async (attId) => {
      try {
        setAta((prev) => ({
          ...prev,
          attachments: [
            ...(prev.attachments || []).filter((a) => a.id !== attId),
          ],
        }));
      } catch {
        //...
      }
    },
  });

  /*useWebSocket<IAtaDuplicationViewModel>({
    url: `ata/${ataId}/duplicated`,
    onMessage: async (duplication) => {
      try {
        setAta((prev) => ({
          ...prev,
          duplications: [...(prev?.duplications || []), duplication],
        }));
      } catch {
        //...
      }
    },
  });

  useWebSocket<IShareListViewModel>({
    url: `share/${ataId}/ata`,
    onMessage: async (share) => {
      try {
        setAta((prev) => ({
          ...prev,
          shares: [...(prev?.shares || []), share],
        }));
      } catch {
        //...
      }
    },
  });*/

  const isAvailableOffline = useMemo(
    () => availableOffline(ata.id),
    [ata.id, availableOffline]
  );
  const isOnlyOffline = useMemo(
    () => onlyOffline(ata.id),
    [ata.id, onlyOffline]
  );

  const handleKeepOffline = async () => {
    if (!ataId) return;

    if (isAvailableOffline) {
      let confirm = true;
      if (!online) {
        confirm = await appAlert.show({
          type: 'confirm',
          title: 'Remover do offline',
          message:
            'Ao prosseguir não será possível visualizar a ata novamente, até que você volte a ficar online.\nDeseja continuar?',
        });
      }

      if (confirm) {
        ataRepository.remove(ataId);
        if (!online) onClose();
      }
    } else {
      ataRepository.add(ata);
    }
  };

  return (
    <Modal onClose={onClose} {...rest}>
      <Container>
        {loading ? (
          <div className="ata-view-loading">
            <CircularProgress size={20} />
            <p>Carregando...</p>
          </div>
        ) : (
          <div className="content">
            <IconButton onClick={onClose} className="btn-close">
              <FaTimesCircle size={20} className="icon-close" />
            </IconButton>
            <AtaViewer />

            {/* <div className="shares-and-duplications">
              <div className="shares">
                <h4>Compartilhada com</h4>
                <div className="shares-list">
                  {!!ata?.shares?.length && (
                    <div className="share">
                      <p>
                        {ata.userId === user?.id
                          ? 'Você'
                          : ata.sharedBy?.split(' ')?.[0]}
                      </p>
                    </div>
                  )}
                  {ata?.shares?.length ? (
                    ata.shares.map((share) => (
                      <div key={share.contactId} className="share">
                        <p>
                          {share.userId === user?.id
                            ? 'Você'
                            : share.userName?.split(' ')?.[0]}
                        </p>
                        {isDev() &&
                          (ata.userId === user?.id ||
                            share.userId === user?.id) && (
                            <IconButton
                              color="error"
                              size="small"
                              onClick={async () => {
                                setUnshareContactId(share.contactId);
                              }}
                            >
                              <FaTimesCircle size={20} />
                            </IconButton>
                          )}
                      </div>
                    ))
                  ) : (
                    <p>Esta ata não foi compartilhada com ninguém.</p>
                  )}
                </div>
              </div>

              <div className="duplications">
                <h4>Duplicada para</h4>
                <div className="duplications-list">
                  {ata?.duplications?.length ? (
                    ata.duplications.map(({ userId, userName }) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <p key={userId} className="duplication">
                        {userId === user?.id
                          ? 'Você'
                          : userName?.split(' ')?.[0]}
                      </p>
                    ))
                  ) : (
                    <p>Esta ata não foi duplicada.</p>
                  )}
                </div>
              </div>
            </div> */}
            <div className="actions">
              {!ata?.trashed ? (
                <>
                  {/*{!(
                    ata?.linkedToId ||
                    profile !== 'common' ||
                    user?.id !== ata?.userId
                  ) && (
                    <ButtonBase
                      disabled={!online}
                      onClick={() => {
                        handleAtaMenu(7);
                        setShowModalLinkTo(true);
                      }}
                    >
                      <Share />
                      <p>Vincular</p>
                    </ButtonBase>
                  )}
                  <ButtonBase
                    disabled={!online}
                    onClick={() => {
                      handleAtaMenu(2);
                      setShowForwardModal(true);
                    }}
                  >
                    <SettingsBackupRestore />
                    <p>Encaminhar</p>
                  </ButtonBase>*/}
                  {profile !== 'office' && (
                    <ButtonBase
                      disabled={!online}
                      onClick={() => {
                        handleAtaMenu(1);
                        navigate('/atas/edit', { state: { ataId } });
                      }}
                    >
                      <FaEdit />
                      <p>Editar</p>
                    </ButtonBase>
                  )}
                  <ButtonBase
                    disabled={!online}
                    onClick={() => setDisplayModalAttachFile(true)}
                  >
                    <FaPaperclip />
                    <p>Anexar</p>
                  </ButtonBase>
                  {(!ata?.trashed || user?.id === ata?.userId) && (
                    <ButtonBase
                      disabled={!online}
                      onClick={() => handleAtaMenu(3)}
                    >
                      <Delete />
                      <p>Excluir</p>
                    </ButtonBase>
                  )}
                </>
              ) : (
                <>
                  <ButtonBase
                    disabled={!online}
                    onClick={() => handleAtaMenu(4)}
                  >
                    <SettingsBackupRestore />
                    <p>Restaurar</p>
                  </ButtonBase>
                  {(!ata?.trashed || user?.id === ata?.userId) && (
                    <ButtonBase
                      disabled={!online}
                      onClick={() => handleAtaMenu(5)}
                    >
                      <Delete />
                      <p>Excluir</p>
                    </ButtonBase>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {/* <ModalForwardAta
          ataId={ataId ?? ''}
          open={showForwardModal}
          close={() => setShowForwardModal(false)}
        /> */}
        {/* <ModalLinkTo
          ataId={showModalLinkTo ? ataId || '' : ''}
          close={() => setShowModalLinkTo(false)}
          open={ataId !== undefined && showModalLinkTo}
          onClose={() => setShowModalLinkTo(false)}
        /> */}
        {/* <ModalUnshare
          ataId={ataId || ''}
          open={unshareContactId !== undefined}
          contactId={unshareContactId || ''}
          close={() => setUnshareContactId(undefined)}
          label={ata?.label}
          text={ata?.text}
          createdAt={ata?.createdAt}
          userName={
            ata.shares?.find((s) => s.contactId === unshareContactId)?.userName
          }
        /> */}
        <ModalAttachFile
          close={() => setDisplayModalAttachFile(false)}
          open={displayModalAttachFile}
          onClose={() => setDisplayModalAttachFile(false)}
        />
      </Container>
    </Modal>
  );
};

export default ModalViewAta;
