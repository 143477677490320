import {
  ContentCopy,
  DeleteOutline,
  ExpandMore,
  Key,
  PersonSearch,
  Search,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button as MuiButton,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import { IOffice } from 'entities';
import React, { useMemo, useState } from 'react';
import { FaCogs } from 'react-icons/fa';
import { PopupProps } from 'utils';
import { ReactComponent as OfficeIcon } from '../../assets/svg/icon-office.svg';
import {
  useAlert,
  useApp,
  useAuth,
  useContact,
  useOffice,
} from '../../context';
import { ContactController, OfficeController } from '../../controllers';
import { OfficeDeleteAction } from '../../types/enums';
import Button from '../Button';
import Contact from '../Contact';
import Input from '../Input';
import ModalSwitchOffice from '../Modals/ModalSwitchOffice';
import Popover from '../Popover';
import { Container, DeleteAlert, OfficeManagerPopover } from './styles';

interface OfficeProps extends IOffice {
  contactsCount?: number;
  onDelete?: (id: string) => void;
  canDelete?: boolean;
  asMain?: boolean;
}

const Office: React.FC<OfficeProps> = ({
  id,
  name,
  code,
  asMain,
  manager,
  onDelete,
  contactsCount,
  canDelete = true,
}) => {
  const alert = useAlert();
  const auth = useAuth();
  const { offices } = useOffice();
  const { officeContacts } = useContact();
  const contacts = useMemo(
    () => officeContacts[id] || [],
    [officeContacts, id]
  );
  const { setLoading, loading } = useApp();

  const [managerCode, setManagerCode] = useState<string>('');
  const [contactCode, setContactCode] = useState<string>('');
  const [showSwitchOffice, setShowSwitchOffice] = useState(false);
  const [search, setSearch] = useState<string>('');

  const filteredContacts = useMemo(() => {
    return contacts?.filter(
      (c) => !search || c.user.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [contacts, search]);

  const handleAddContact = (contactParam: string = contactCode) => {
    setLoading(true);

    const isEmail = contactParam.includes('@');
    ContactController.add({
      code: isEmail ? undefined : contactParam,
      email: isEmail ? contactParam : undefined,
      uid: id,
    })
      .then(() => setContactCode(''))
      .catch(() => {
        alert.show({
          type: 'error',
          errors: ['Não foi possível vincular usuário'],
        });
      })
      .finally(() => setLoading(false));
  };

  const handleManager = (
    newManagerCode: string = managerCode,
    onClose?: () => void
  ) => {
    setLoading(true);
    const isEmail = newManagerCode.includes('@');
    OfficeController.setManager({
      officeId: id,
      code: isEmail ? undefined : newManagerCode,
      email: isEmail ? newManagerCode : undefined,
    })
      .then(() => {
        handleAddContact(newManagerCode);
        setManagerCode('');
        onClose?.();
      })
      .catch(() => {
        alert.show({
          type: 'error',
          errors: ['Não foi possível vincular repensável'],
        });
      })
      .finally(() => setLoading(false));
  };

  const handleRemoveManager = (onClose?: () => void) => {
    setLoading(true);
    OfficeController.removeManager(id, auth?.token || '')
      .then(() => onClose?.())
      .catch(() => {
        alert.show({
          type: 'error',
          errors: ['Não foi possível remover o vínculo'],
        });
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = (action: OfficeDeleteAction) => {
    if (loading) return;

    if (action === OfficeDeleteAction.TRANSFER_TO_OFFICE) {
      setShowSwitchOffice(true);
      return;
    }

    setLoading(true);
    OfficeController.deleteById(id, action)
      .then(() => onDelete?.(id))
      .catch(() => {
        alert.show({
          type: 'error',
          errors: ['Não foi possível remover o escritório'],
        });
      })
      .finally(() => setLoading(false));
  };

  const EditManager = React.memo(({ onClose }: PopupProps) => {
    const [newManager, setNewManager] = useState<string>('');

    return (
      <OfficeManagerPopover>
        <p>Trocar responsável</p>
        <Input
          id="search"
          label="Responsável"
          placeholder="Insira a chave ou email"
          size="small"
          fullWidth={false}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => setNewManager(e.target.value)}
          endAdornment={
            <IconButton
              disabled={!newManager || loading}
              onClick={(e) => {
                e.stopPropagation();
                handleManager(newManager, onClose);
              }}
            >
              <PersonSearch />
            </IconButton>
          }
        />
        <Button
          id="remove-manager"
          shape="text"
          color="red"
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveManager(onClose);
          }}
        >
          Excluir o responsável
        </Button>
      </OfficeManagerPopover>
    );
  });

  const deleteAlert = () => {
    let option: number = OfficeDeleteAction.TRANSFER;

    return (
      <DeleteAlert>
        <p>
          <span>Atenção:</span> Ao excluir o escritório, você irá excluir todas
          as pessoas vinculadas e suas atas.
        </p>
        <RadioGroup
          aria-labelledby="rbd-block"
          defaultValue={option}
          name="rbd-block"
          onChange={(e) => {
            option = Number(e.target.value);
          }}
        >
          <FormControlLabel
            value={OfficeDeleteAction.DELETE_ATAS}
            control={<Radio />}
            label="Excluir contatos e deletar todas as atas"
          />
          <FormControlLabel
            value={OfficeDeleteAction.KEEP_ATAS}
            control={<Radio />}
            label="Excluir contatos e passar atas para empresa"
          />
          {!!offices.filter((o) => o.id !== id).length && (
            <FormControlLabel
              value={OfficeDeleteAction.TRANSFER_TO_OFFICE}
              control={<Radio />}
              label="Passar contatos e atas para outro escritório"
            />
          )}
          <FormControlLabel
            value={OfficeDeleteAction.TRANSFER}
            control={<Radio />}
            label="Passar contatos e atas para empresa"
          />
        </RadioGroup>
        <MuiButton
          color="error"
          variant="contained"
          title="Confirmar bloqueio do usuário"
          onClick={() => handleDelete(option)}
        >
          <p>Excluir</p>
        </MuiButton>
      </DeleteAlert>
    );
  };

  return (
    <Container>
      <Accordion>
        <AccordionSummary
          id="office-summary"
          expandIcon={<ExpandMore id="arrow" color="primary" />}
        >
          <div className={`office-info${asMain ? ' main' : ''}`}>
            <OfficeIcon id="icon" />
            <h3>{name || 'Escritório sem nome'}</h3>
            <div className="code">
              <div>
                <p>Número</p>
                <p id="code">{code}</p>
              </div>
              <IconButton
                aria-label="Copiar código"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(code);
                }}
              >
                <ContentCopy />
              </IconButton>
            </div>
            <div className="manager">
              {manager ? (
                <div className="manager-container">
                  <div id="name">
                    <p>Responsável</p>
                    <p>
                      {manager.id === auth?.user?.id
                        ? 'Você'
                        : manager.user.name}
                    </p>
                  </div>
                  <Popover Element={EditManager}>
                    <Button id="edit-manager" shape="text">
                      <p>Editar</p>
                      <FaCogs />
                    </Button>
                  </Popover>
                </div>
              ) : (
                <Input
                  id="search"
                  label="Responsável"
                  placeholder="Insira a chave ou email para vincular"
                  size="small"
                  fullWidth={false}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => setManagerCode(e.target.value)}
                  endAdornment={
                    <IconButton
                      disabled={!managerCode || loading}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleManager();
                      }}
                    >
                      <PersonSearch />
                    </IconButton>
                  }
                />
              )}
            </div>
            {canDelete && (
              <>
                {contactsCount || contacts.length ? (
                  <Popover Element={deleteAlert}>
                    <IconButton aria-label="Deletar escritório" color="error">
                      <DeleteOutline />
                    </IconButton>
                  </Popover>
                ) : (
                  <IconButton
                    aria-label="Deletar escritório"
                    color="error"
                    onClick={() => handleDelete(OfficeDeleteAction.NONE)}
                  >
                    <DeleteOutline />
                  </IconButton>
                )}
              </>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="office-contacts">
            <div id="inputs">
              <Input
                id="key"
                label="Chave"
                placeholder="Insira a chave ou email para vincular"
                size="small"
                variant="outlined"
                onChange={(e) => setContactCode(e.target.value)}
                value={contactCode}
                endAdornment={
                  <IconButton
                    onClick={() => {
                      handleAddContact();
                    }}
                  >
                    <Key />
                  </IconButton>
                }
              />
              {contacts?.length > 0 ? (
                <Input
                  id="search"
                  label="Procurar"
                  placeholder="Procurar usuário"
                  size="small"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  endAdornment={
                    <IconButton onClick={() => {}}>
                      <Search />
                    </IconButton>
                  }
                />
              ) : (
                <div />
              )}
            </div>
            <div id="contacts">
              {!filteredContacts?.length ? (
                <div id="empty">
                  <p>Nenhum contato encontrado...</p>
                </div>
              ) : (
                <>
                  <div>
                    {filteredContacts

                      ?.slice(
                        0,
                        filteredContacts.length / 2 +
                          (filteredContacts.length % 2)
                      )
                      .map((contact) => (
                        <Contact
                          key={contact.id}
                          {...contact}
                          officeId={id}
                          showNameFrom="user"
                          deleteButton
                        />
                      ))}
                  </div>
                  <div>
                    {filteredContacts
                      ?.slice(
                        filteredContacts.length / 2 +
                          (+filteredContacts.length % 2),
                        filteredContacts.length
                      )
                      .map((contact) => (
                        <Contact
                          key={contact.id}
                          {...contact}
                          officeId={id}
                          showNameFrom="user"
                          deleteButton
                        />
                      ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <ModalSwitchOffice
        officeId={id}
        open={showSwitchOffice}
        onConfirm={() => onDelete?.(id)}
        onClose={() => setShowSwitchOffice(false)}
      />
    </Container>
  );
};

export default Office;
