import { IconButton, ModalProps } from '@mui/material';
import { IAttachment } from 'entities';
import mime from 'mime';
import React, { useEffect, useState } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { IAtaListViewModel } from 'viewModels';
import { useAlert, useAuth } from '../../../context';
import { AtaController, AttachmentController } from '../../../controllers';
import useWebSocket from '../../../hooks/useWebSocket';
import Ata from '../../Ata';

import { ReactComponent as IconExcel } from '../../../assets/svg/icon-excel.svg';
import { ReactComponent as IconImage } from '../../../assets/svg/icon-image.svg';
import { ReactComponent as IconPaperClip } from '../../../assets/svg/icon-paper-clip.svg';
import { ReactComponent as IconPdf } from '../../../assets/svg/icon-pdf.svg';
import { ReactComponent as IconWord } from '../../../assets/svg/icon-word.svg';

import Modal from '../Modal';

import {
  Attachment,
  Container,
  Content,
  Header,
  ListAtas,
  Title,
} from './styles';

interface Props extends Omit<ModalProps, 'children'> {
  close: () => void;
  data: React.MutableRefObject<IAttachment>;
}

const ModalAttachedAtas = ({ data, close, ...rest }: Props) => {
  const { tokenIsValid, user } = useAuth();
  const appAlert = useAlert();
  const [atas, setAtas] = useState<IAtaListViewModel[]>([]);
  const extension = mime.getExtension(data.current.contentType);

  useEffect(() => {
    if (tokenIsValid) {
      AttachmentController.getAttachmentAtas(data.current.id).then(setAtas);
    }
  }, [data, tokenIsValid]);

  useWebSocket<string>({
    url: `attachment/${data.current.id}/attach-to-ata`,
    onMessage: async (ataId) => {
      try {
        const ata = await AtaController.getById(ataId);
        setAtas((prev) => [ata, ...prev]);
      } catch {
        //...
      }
    },
  });

  useWebSocket<string>({
    url: `attachment/${data.current.id}/detach`,
    onMessage: async (ataId) => {
      try {
        setAtas((prev) => [...(prev || []).filter((ata) => ata.id !== ataId)]);
      } catch {
        //...
      }
    },
  });

  useWebSocket<string>({
    url: `attachment/${user?.id}/deleted`,
    onMessage: (id) => {
      if (id === data.current.id) {
        close();
        appAlert.show({
          title: 'Este anexo foi excluído.',
          errors: ['Você não pode mais visualizar este anexo.'],
          type: 'error',
        });
      }
    },
  });

  useWebSocket<string>({
    url: `ata/${user?.id}/trashed`,
    onMessage: (id) => {
      setAtas((prev) => {
        let newList = [...(prev || [])];
        const index = newList.findIndex((a) => a.id === id);
        if (index >= 0) {
          newList = [
            ...newList.slice(0, index),
            {
              ...newList[index],
              trashed: true,
            },
            ...newList.slice(index + 1),
          ];
        }

        return newList;
      });
    },
  });

  useWebSocket<{ ataId: string }>({
    url: `ata/${user?.id}/restore`,
    onMessage: ({ ataId }) => {
      setAtas((prev) => {
        let newList = [...(prev || [])];
        const index = newList.findIndex((a) => a.id === ataId);
        if (index >= 0) {
          newList = [
            ...newList.slice(0, index),
            {
              ...newList[index],
              trashed: false,
            },
            ...newList.slice(index + 1),
          ];
        }

        return newList;
      });
    },
  });

  useWebSocket<string>({
    url: `ata/${user?.id}/update`,
    onMessage: async (id) => {
      const ata = await AtaController.getById(id);
      setAtas((prev) => {
        let newList = [...(prev || [])];
        const index = newList.findIndex((a) => a.id === id);
        if (index >= 0) {
          newList = [
            ...newList.slice(0, index),
            {
              ...(ata ?? newList[index]),
            },
            ...newList.slice(index + 1),
          ];
        }

        return newList;
      });
    },
  });

  useWebSocket<string>({
    url: `ata/${user?.id}/disabled`,
    onMessage: (id) => {
      setAtas((prev) => prev.filter((a) => a.id !== id));
    },
  });

  const getIcon = () => {
    if (extension === 'pdf') {
      return <IconPdf />;
    }
    if (extension === 'doc' || extension === 'docx') {
      return <IconWord />;
    }
    if (extension === 'csv' || extension === 'xls' || extension === 'xlsx') {
      return <IconExcel />;
    }
    if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
      return <IconImage />;
    }
    return null;
  };

  return (
    <Modal onClose={close} {...rest}>
      <Container>
        <Header>
          <Title>
            <IconPaperClip />
            <p>Atas anexadas</p>
          </Title>
          <IconButton onClick={close}>
            <FaTimesCircle size={20} className="icon-close" />
          </IconButton>
        </Header>

        <Content>
          <Attachment>
            {getIcon()}
            <div className="content-attachment">
              <h1>{data.current.originalName}</h1>
              <p>
                {new Intl.DateTimeFormat('pt-BR').format(
                  new Date(data.current.createdAt)
                )}
              </p>
            </div>
          </Attachment>
          <ListAtas>
            {!atas?.length ? (
              <p>Nenhuma ata para mostrar...</p>
            ) : (
              atas.map((ata) => <Ata key={ata.id} {...ata} />)
            )}
          </ListAtas>
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalAttachedAtas;
