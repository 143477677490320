import { InsertDriveFile, Person } from '@mui/icons-material';
import React from 'react';
import { FaBuilding } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import { NotificationViewModel } from 'viewModels';
import { ReactComponent as IconPaperClip } from '../../assets/svg/icon-paper-clip.svg';
import { useAuth } from '../../context';
import { ContactStatus, NotificationType } from '../../types/enums';
import { Content, Text } from './styles';

const ActionLog: React.FC<NotificationViewModel> = ({
  type,
  body,
  userId,
  metadata,
}) => {
  const theme = useTheme();
  const { user, activeOffice } = useAuth();

  const icons = [
    {
      icon: <Person />,
      types: [
        NotificationType.ADD_CONTACT,
        NotificationType.REMOVE_CONTACT,
        NotificationType.CHANGE_CONTACT_STATUS,
        NotificationType.CHANGE_CONTACT_PERMISSIONS,
        NotificationType.PENDING_CHANGE_CONTACT_STATUS,
      ],
    },
    {
      icon: <FaBuilding />,
      types: [
        NotificationType.ADD_OFFICE,
        NotificationType.REMOVE_OFFICE,
        NotificationType.ADD_OFFICE_MANAGER,
        NotificationType.CHANGE_OFFICE_MANAGER,
        NotificationType.REMOVE_OFFICE_MANAGER,
        NotificationType.PENDING_ADD_OFFICE,
        NotificationType.PENDING_ADD_OFFICE_MANAGER,
      ],
    },
    {
      icon: <InsertDriveFile />,
      types: [
        NotificationType.ADD_ATA,
        NotificationType.CHANGE_ATA,
        NotificationType.REMOVE_ATA,
        NotificationType.SHARE_ATA,
        NotificationType.TRASHED_ATA,
        NotificationType.RESTORE_ATA,
        NotificationType.DUPLICATE_ATA,
        NotificationType.LINK_ATA_TO_USER,
      ],
    },
    {
      icon: <IconPaperClip />,
      types: [
        NotificationType.ADD_ATTACHMENT,
        NotificationType.REMOVE_ATTACHMENT,
        NotificationType.CHANGE_ATTACHMENT,
        NotificationType.LINK_ATTACHMENT,
        NotificationType.REMOVE_LINK_ATTACHMENT,
        NotificationType.PENDING_LINK_ATTACHMENT,
      ],
    },
  ];

  const getColorByContactStatus = (status?: ContactStatus) => {
    switch (status) {
      case ContactStatus.PENDING:
        return theme.colors.warning2;
      case ContactStatus.RELEASED:
        return theme.colors.success;
      case ContactStatus.BLOCKED:
        return theme.colors.error;
      default:
        return theme.colors.text;
    }
  };

  const getNameToShow = (replace: string = '') => {
    return (
      activeOffice
        ? replace === activeOffice?.name && userId === activeOffice?.id
        : replace === user?.name && userId === user?.id
    )
      ? 'Você'
      : replace;
  };

  return (
    <Content>
      {icons.find((icon) => icon.types.includes(type))?.icon}
      {body.map((line) => {
        const styleCode = line.includes('|') ? line.split('|')[0] : '';

        const text =
          !line.includes('{') && !line.includes('}')
            ? line?.trim()
            : getNameToShow(
                metadata?.replaces?.[
                  Number(line.replace(/[^0-9.]/g, ''))
                ]?.trim()
              ) ?? null;

        const color = !styleCode.includes('C')
          ? undefined
          : getColorByContactStatus(metadata?.contact?.status);

        return (
          text && (
            <Text
              key={line}
              bold={styleCode.includes('B')}
              italic={styleCode.includes('I')}
              color={color}
            >
              {text}
            </Text>
          )
        );
      })}
    </Content>
  );
};

export default ActionLog;
