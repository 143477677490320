import { Audiotrack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import mime from 'mime';
import { FaBars, FaEye, FaTrashAlt } from 'react-icons/fa';

import { IAttachment } from 'entities';
import { Container, CreatedAt, Filename, WrapperOptions } from './styles';

import { ReactComponent as IconExcel } from '../../assets/svg/icon-excel.svg';
import { ReactComponent as IconImage } from '../../assets/svg/icon-image.svg';
import { ReactComponent as IconPdf } from '../../assets/svg/icon-pdf.svg';
import { ReactComponent as IconWord } from '../../assets/svg/icon-word.svg';

import { useAlert, useApp } from '../../context';

import MenuPopup from '../MenuPopup';

type IOption = {
  id: string | number;
  label: string;
  icon?: any;
};

interface Props {
  onClick: () => void;
  onSelect?: (option: string | number) => void;
  data: IAttachment;
  options?: IOption[];
}

const Attachment = ({ onClick, onSelect, data, options }: Props) => {
  const { online } = useApp();
  const appAlert = useAlert();
  const { contentType, originalName, createdAt, hasLinkedAtas } = data;
  const extension = mime.getExtension(contentType);

  const getIcon = () => {
    if (extension === 'pdf') {
      return <IconPdf />;
    }
    if (extension === 'doc' || extension === 'docx') {
      return <IconWord />;
    }
    if (extension === 'csv' || extension === 'xls' || extension === 'xlsx') {
      return <IconExcel />;
    }
    if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
      return <IconImage />;
    }
    if (
      extension === 'mp3' ||
      extension === 'wav' ||
      extension === 'ogg' ||
      extension === 'aac' ||
      extension === 'flac' ||
      extension === 'wma' ||
      extension === 'mpga'
    ) {
      return (
        <div>
          <Audiotrack color="info" />
        </div>
      );
    }
    return null;
  };

  const handleMenu = (option: string | number) => {
    if (!online) {
      appAlert.show({
        type: 'error',
        errors: ['Sem conexão com a internet'],
      });

      return;
    }

    onSelect?.(option);
  };

  return (
    <Container onClick={onClick}>
      {getIcon()}
      <div className="content-attach">
        <Filename>
          {originalName.substring(
            0,
            originalName.includes('.')
              ? originalName.lastIndexOf('.')
              : originalName.length
          )}
        </Filename>
        <CreatedAt>
          Criado {new Intl.DateTimeFormat('pt-BR').format(new Date(createdAt))}
        </CreatedAt>
        {hasLinkedAtas ? (
          <span className="hasAta">Anexado a uma Ata</span>
        ) : (
          <span className="hasNoAta">Não anexado a uma Ata</span>
        )}
      </div>
      {options?.length ? (
        <MenuPopup options={options || []} onSelect={handleMenu} value="">
          <FaBars className="icon-bars" />
        </MenuPopup>
      ) : (
        <WrapperOptions>
          <IconButton
            disabled={!online}
            onClick={(e) => {
              e.stopPropagation();
              onSelect?.(0);
            }}
          >
            <FaTrashAlt size={16} className="icon-delete" />
          </IconButton>
          <IconButton
            disabled={!online}
            onClick={(e) => {
              e.stopPropagation();
              onSelect?.(1);
            }}
          >
            <FaEye size={20} className="icon-view" />
          </IconButton>
        </WrapperOptions>
      )}
    </Container>
  );
};

export default Attachment;
