import { Backdrop, Box, Modal, Fade, SxProps, Theme } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import { AlertProps, AlertTypes, AlertExtractProps } from 'props';
import { FaCheck, FaQuestion } from 'react-icons/fa';

import Button from '../Button';
import { Content, Header } from './styles';

const style: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#fff',
  outline: 'none',
  boxShadow: 24,
  p: 2,
  borderRadius: 4,
};

interface AlertComponentProps {
  open: boolean;
  close: (value?: any) => void;
}

const Alert = ({
  open,
  close,
  type,
  ...props
}: AlertComponentProps & AlertProps) => {
  const AlertInfo = () => {
    const info = props as AlertExtractProps<'confirm'>;
    return (
      <>
        <Header titleColor="primary" iconColor="primary">
          <InfoIcon />
          <p>Aviso</p>
        </Header>
        <main>
          {info.title && <strong>{info.title}</strong>}
          <p>{info.message}</p>
        </main>
        <footer>
          <Button
            size="small"
            style={{ maxWidth: 100 }}
            onClick={() => close(false)}
          >
            Fechar
          </Button>
        </footer>
      </>
    );
  };
  const AlertError = () => {
    const info = props as AlertExtractProps<'error'>;

    return (
      <>
        <Header titleColor="pink" iconColor="red">
          <ErrorOutlineIcon />
          <p>Erro</p>
        </Header>
        <main>
          <p
            style={{
              // eslint-disable-next-line react/prop-types
              textAlign: props?.titleAlign || 'center',
            }}
          >
            {info.title || 'As alterações não foram salvas pelo(s) motivo(s)'}
          </p>
          <div className="errors">
            {(info.errors || []).map((err) => (
              <p>- {err}</p>
            ))}
          </div>
        </main>
        <footer>
          <Button
            size="small"
            style={{ maxWidth: 100 }}
            onClick={() => close(false)}
          >
            Fechar
          </Button>
        </footer>
      </>
    );
  };

  const AlertSucess = () => {
    const info = props as AlertExtractProps<'sucess'>;
    return (
      <>
        <Header titleColor="primary" iconColor="primary">
          <FaCheck />
          <p>Sucesso</p>
        </Header>
        <main>
          <p>{info.message}</p>
        </main>
      </>
    );
  };

  const AlertConfirm = () => {
    const info = props as AlertExtractProps<'confirm'>;
    return (
      <>
        <Header titleColor="primary" iconColor="primary">
          <FaQuestion />
          <p>{info.title}</p>
        </Header>
        <main>
          <p>{info.message}</p>
        </main>
        <footer>
          <Button
            size="small"
            color="red"
            style={{ maxWidth: 100 }}
            onClick={() => close(false)}
          >
            Não
          </Button>
          <Button
            size="small"
            style={{ maxWidth: 100 }}
            onClick={() => close(true)}
          >
            Sim
          </Button>
        </footer>
      </>
    );
  };

  const getAlert = (key: AlertTypes) =>
    ({
      confirm: <AlertConfirm />,
      sucess: <AlertSucess />,
      error: <AlertError />,
      info: <AlertInfo />,
    }[key]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => close(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Content>{getAlert(type)}</Content>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Alert;
