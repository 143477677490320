import styled from 'styled-components';

export const Container = styled.div`
  span {
    color: ${({ theme }) => theme.colors.background};
    fill: ${({ theme }) => theme.colors.background};
  }

  .title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};

    h3 {
      width: calc(100% - 50px);
      font-size: 20px;
      font-weight: 400;
    }

    p {
      margin-left: 50px;
      margin-bottom: 10px;
      width: calc(100% - 50px);
      font-size: 20px;
      font-weight: 200;
      color: ${({ theme }) => theme.colors.text};
    }

    svg {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .form {
    display: flex;
    align-items: center;
  }

  #code {
    margin-left: 50px;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: ${({ theme }) => theme.colors.text};

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }

  button {
    max-width: 150px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
`;
