import { v4 } from 'uuid';

/**
 * Base model for indexedDB database.
 * @abstract
 */
export default abstract class BaseModel {
  public id?: string;

  constructor(id?: string) {
    // eslint-disable-next-line no-unused-expressions
    id ? (this.id = id) : (this.id = v4());
  }

  //equals = (other: BaseModel) => this.id === other.id;
}
