import { IAttachment } from 'entities';
import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AttachmentController } from '../controllers';
import useStateSync from '../hooks/useStateSync';
import useWebSocket from '../hooks/useWebSocket';
import { CancelTokenSource } from '../services/api';
import { useAlert, useAta, useAuth } from './index';

type IFiltersFunctions = 'search' | IFilterAttachments | null;

export type IFilterAttachments = 'oldAttachments' | 'order' | 'notAttached';

type IStatusErrorInsertAttachment = {
  status: 'EMPTY_ATTACHMENT' | 'USER_NOT_EXISTS';
};

interface IInsertAttachment {
  formData: FormData;
  cancelToken: CancelTokenSource;
}

type Context = {
  attachments: IAttachment[];
  filteredAttachments: IAttachment[];
  setFilteredAttachments: Dispatch<SetStateAction<IAttachment[]>>;
  insertAttachments: ({
    formData,
  }: IInsertAttachment) => Promise<string | undefined>;
  updateAttachments: () => void;
  deleteAttachment: () => Promise<void>;
  filterAttachments: (textSearch?: string) => void;
  download: (save?: boolean) => Promise<void>;
  loading: boolean;
  progressUpload: number;
  setProgressUpload: Dispatch<SetStateAction<number>>;
  setKeyFilter: Dispatch<SetStateAction<IFiltersFunctions>>;
  currentAttachment: MutableRefObject<IAttachment>;
  setCurrentAttachment: (newState: IAttachment) => void;
};

type Provider = {
  children: ReactNode;
};

const AttachmentContext = createContext({} as Context);

export const AttachmentsProvider = ({ children }: Provider) => {
  const appAlert = useAlert();
  const { setAta } = useAta();
  const { tokenIsValid, user } = useAuth();
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const [filteredAttachments, setFilteredAttachments] = useState<IAttachment[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [progressUpload, setProgressUpload] = useState(0);
  const [keyFilter, setKeyFilter] = useState<IFiltersFunctions>(null);
  const [currentAttachment, setCurrentAttachment] = useStateSync<IAttachment>(
    {} as IAttachment
  );

  const download = useCallback(
    async (save: boolean = true) => {
      const { id, contentType, originalName } = currentAttachment.current;

      AttachmentController.download(id).then((response) => {
        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        if (save) link.setAttribute('download', originalName);
        else link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.referrerPolicy = 'no-referrer';

        link.click();
      });
    },
    [currentAttachment]
  );

  const filterAttachments = useCallback(
    (textSearch?: string) => {
      let result: IAttachment[] = [];
      switch (keyFilter) {
        case 'oldAttachments':
          result = [
            ...attachments.sort((a, b) =>
              new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1
            ),
          ];
          break;
        case 'order':
          result = [
            ...attachments.sort((a, b) =>
              a.originalName > b.originalName ? 1 : -1
            ),
          ];
          break;
        case 'notAttached':
          result = [...attachments.filter((att) => !att.hasLinkedAtas)];
          break;
        case 'search':
          result = textSearch
            ? [
                ...attachments.filter(
                  (a) =>
                    a.originalName
                      .toLowerCase()
                      .substring(0, textSearch?.length) ===
                    textSearch?.toLowerCase()
                ),
              ]
            : [...attachments];
          break;

        default:
          result = [...attachments];
          break;
      }

      setFilteredAttachments(result);
    },
    [attachments, keyFilter]
  );

  const setUserAttachments = useCallback(
    (data: IAttachment[], hasFilter?: boolean) => {
      setAttachments(data);
      if (hasFilter) filterAttachments();
      else setFilteredAttachments(data);
    },
    [filterAttachments]
  );

  const getAttachments = useCallback(() => {
    setLoading(true);
    AttachmentController.get()
      .then((snap) => {
        setUserAttachments(snap);
      })
      .finally(() => setLoading(false));
  }, [setUserAttachments]);

  useEffect(() => {
    if (tokenIsValid) getAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenIsValid]);

  useWebSocket<void>({
    url: `attachment/${user?.id}/new`,
    onMessage: async () => {
      try {
        getAttachments();
      } catch {
        //..
      }
    },
  });

  useWebSocket<{ id: string }>({
    url: `attachment/${user?.id}/edit`,
    onMessage: async ({ id }) => {
      try {
        const att = await AttachmentController.getById(id);

        setAttachments((prev) => {
          const index = prev.findIndex((a) => a.id === att.id);
          const newAttachments = [...prev];
          if (index !== -1) newAttachments[index] = att;
          return newAttachments;
        });

        if (currentAttachment.current.id === att.id) {
          setCurrentAttachment(att);
        }
      } catch {
        //...
      }
    },
  });

  useWebSocket<string>({
    url: `attachment/${user?.id}/disabled`,
    onMessage: async (id) => {
      try {
        setAttachments((prev) => prev.filter((a) => a.id !== id));
        setFilteredAttachments((prev) => prev.filter((a) => a.id !== id));

        setAta((prevState) => ({
          ...prevState,
          attachments: prevState.attachments?.filter(
            (attachment) => attachment.id !== id
          ),
          attachmentsId: prevState.attachmentsId?.filter(
            (attachmentId) => attachmentId !== id
          ),
        }));
      } catch {
        //..
      }
    },
  });

  const errorsInsertAttachment = (key: string) =>
    ({
      EMPTY_ATTACHMENT: 'Não é possível anexar um anexo vazio.',
      USER_NOT_EXISTS: 'Usuário não encontrado',
    }[key]);

  const insertAttachments = useCallback(
    async ({ formData, cancelToken }: IInsertAttachment) => {
      return AttachmentController.insert({
        formData,
        token: '',
        onProgress: setProgressUpload,
        cancelToken,
      })
        .then(({ id }) => {
          setProgressUpload(100);
          appAlert.show({
            type: 'sucess',
            message: 'Anexo salvo com sucesso!',
            timer: 2000,
          });
          setProgressUpload(0);
          return id;
        })
        .catch((err) => {
          if (err?.response) {
            const { status } = err.response
              .data as IStatusErrorInsertAttachment;

            let msg = errorsInsertAttachment(status);
            if (!msg) msg = 'Houve um erro inesperado, tente novamente.';
            appAlert.show({ type: 'error', errors: [msg] });
          }
          return undefined;
        });
    },
    [appAlert]
  );

  // useEffect(() => {
  //   if (tokenIsValid && functionReinvoke) {
  //     (async () => {
  //       await functionReinvoke;
  //       setFunctionReinvoke(null);
  //     })();
  //   }
  // }, [functionReinvoke, tokenIsValid]);

  const updateAttachments = useCallback(() => {}, []);

  const deleteAttachment = useCallback(() => {
    const { id } = currentAttachment.current;
    return new Promise<void>((resolve, reject) => {
      AttachmentController.remove({ attachmentId: id }, '')
        .then(() => {
          appAlert.show({
            type: 'sucess',
            message: 'Anexo deletado com sucesso.',
          });
          //setAttachments((state) => state.filter((a) => a.id !== id));
          //setFilteredAttachments((state) => state.filter((a) => a.id !== id));
          resolve();
        })
        .catch(() => {
          appAlert.show({
            type: 'error',
            errors: ['Erro ao deletar anexo, tente novamente!'],
          });
          reject();
        });
    });
  }, [currentAttachment, appAlert]);

  useEffect(() => {
    if (keyFilter !== 'search') filterAttachments();
  }, [keyFilter, filterAttachments]);

  useEffect(() => {
    if (!tokenIsValid) {
      setAttachments([]);
      setFilteredAttachments([]);
    }
  }, [tokenIsValid]);

  const initialValues: Context = {
    attachments,
    filteredAttachments,
    setFilteredAttachments,
    insertAttachments,
    updateAttachments,
    deleteAttachment,
    filterAttachments,
    loading,
    progressUpload,
    setProgressUpload,
    setKeyFilter,
    currentAttachment,
    setCurrentAttachment,
    download,
  };

  return (
    <AttachmentContext.Provider value={initialValues}>
      {children}
    </AttachmentContext.Provider>
  );
};

export const useAttachments = () => useContext(AttachmentContext);
