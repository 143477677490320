/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-alert */
import {
  AccountCircle,
  CheckCircle,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { IUserAuth } from 'entities';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { Link, useNavigate } from 'react-router-dom';
import ContactBanner from '../../assets/img/contact-banner.png';
import Smartphone from '../../assets/img/smartphone.png';
import { ReactComponent as AppStore } from '../../assets/svg/app-store.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/svg/icon-whatsapp.svg';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { ReactComponent as Particles } from '../../assets/svg/particles.svg';
import { ReactComponent as PlayStore } from '../../assets/svg/play-store.svg';
import { Button, Forms, Input, InputCpfCnpj } from '../../components';
import { useAlert, useApp, useAuth } from '../../context';
import { UserController } from '../../controllers';
import { removeMask } from '../../utils/removeMask';
import { Container } from './styles';

const URL_PLAY_STORE =
  'https://play.google.com/store/apps/details?id=com.atascontrol.app&pli=1';

const URL_APPLE_STORE =
  'https://apps.apple.com/br/app/atas-control/id1631835423';

const Home: React.FC = () => {
  const { login, logout, tokenIsValid } = useAuth();
  const appAlert = useAlert();
  const { online } = useApp();
  const navigate = useNavigate();
  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    if (isLoading) return;
    if (tokenIsValid) {
      const res = await appAlert.show({
        type: 'confirm',
        title: 'Você já está logado',
        message: 'Deseja sair e entrar novamente?',
      });
      if (res) logout();
      else return;
    }

    setIsLoading(true);
    UserController.login({
      document: removeMask(document),
      password,
    })
      .then(async (response) => {
        const data = response.data as IUserAuth;

        login(data);
        navigate('dashboard');
      })
      .catch((error) => {
        const status = error.response?.data?.status;

        if (status === 'INVALID_LOGIN') {
          appAlert.show({
            type: 'error',
            title: 'Não foi possível realizar o login',
            titleAlign: 'left',
            errors: ['Usuário ou senha inválidos'],
          });
        } else
          appAlert.show({
            type: 'error',
            title: 'Não foi possível realizar o login',
            errors: ['Houve um erro inesperado, tente novamente.'],
          });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    (async () => {
      if (online && !isLoading && tokenIsValid) {
        const res = await appAlert.show({
          type: 'confirm',
          title: 'Você já está logado',
          message: 'Deseja ir para o dashboard?',
        });
        if (res) navigate('dashboard');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, isLoading, navigate, tokenIsValid]);

  return (
    <Container>
      <section className="home">
        <div className="info">
          <Logo />
          <p>
            Um sistema inovador e tecnológico que trará à sua empresa total
            controle sobre as informações e documentos referentes às audiências,
            testemunhos, depoimentos, perícias e decisões dos seus processos,
            permitindo, assim, a condução preventiva e o êxito em novas
            demandas.
          </p>
        </div>
        <div className="form-login">
          <div className="header">
            <AccountCircle />
            <h1>Entre com a sua conta</h1>
          </div>
          <form>
            <InputCpfCnpj
              value={document}
              label="CPF ou CNPJ"
              placeholder="Digite o número do documento"
              onChange={(e) => {
                setDocument(e.target.value);
              }}
            />
            <Input
              value={password}
              label="Senha"
              placeholder="Digite aqui"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
              onChange={(e) => setPassword(e.target.value)}
            />
            <Link
              onClick={(e) => {
                if (!online || tokenIsValid || isLoading) {
                  e.preventDefault();
                  appAlert.show({
                    type: 'info',
                    message: !online
                      ? 'Você está offline'
                      : tokenIsValid
                      ? 'Você já está logado'
                      : 'Logando, aguarde...',
                  });
                }
              }}
              to="/recovery"
            >
              <p>Esqueceu sua senha?</p>
            </Link>
            <div className="actions">
              <Button
                shape="outline"
                disabled={!online || isLoading || tokenIsValid}
                onClick={() => navigate('sign-up')}
              >
                Fazer cadastro
              </Button>
              <Button
                shape="outline"
                onClick={handleLogin}
                isLoading={isLoading}
              >
                Entrar
              </Button>
            </div>
          </form>
        </div>
        <IconButton
          className="whatsapp-btn"
          onClick={() =>
            window.open(
              `https://wa.me/${process.env.REACT_APP_CONTACT_PHONE}?text=Quero saber mais sobre o Atas Control Web`,
              '_blank'
            )
          }
        >
          <WhatsappIcon />
        </IconButton>
      </section>
      <section id="about" className="about">
        <Particles className="bg" />
        <div id="infos">
          <h2>Atas Control</h2>
          <p>
            O Atas Control é um portal tecnológico de gestão de atas de
            audiências, perícias e decisões já proferidas nos processos da sua
            área.
          </p>
          <h2>O que é o Atas Control</h2>
          <p>
            É uma ferramenta tecnológica que trará controle e gestão para sua
            empresa. Através de casos anteriores, você poderá monitorar
            depoimentos, testemunhos, resultados de perícias e de decisões dos
            processos.
          </p>
          <h2>Download</h2>
          <p>Baixe agora o aplicativo Atas Control</p>
          <div id="stores">
            <a href={URL_PLAY_STORE} target="_blank" rel="noreferrer">
              <PlayStore id="store" />
              <QRCode size={120} id="qr" value={URL_PLAY_STORE} />
            </a>
            <a href={URL_APPLE_STORE} target="_blank" rel="noreferrer">
              <AppStore id="store" />
              <QRCode size={120} id="qr" value={URL_APPLE_STORE} />
            </a>
          </div>
        </div>
        <img id="smartphone" src={Smartphone} alt="App Atas Control" />
      </section>
      <section id="advantages" className="advantages">
        <div className="container">
          <h2>Vantagens Atas Control</h2>
          <div className="advantages-list">
            <div className="item">
              <CheckCircle />
              <p>Comparativo entre depoimentos conflitantes;</p>
            </div>
            <div className="item">
              <CheckCircle />
              <p>Aumento no número de improcedências;</p>
            </div>
            <div className="item">
              <CheckCircle />
              <p>Gestão detalhada da qualidade das demandas;</p>
            </div>
            <div className="item">
              <CheckCircle />
              <p>Economia;</p>
            </div>
            <div className="item">
              <CheckCircle />
              <p>Dentre outros.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className="contact">
        <h2>Contato</h2>
        <p>Preencha o formulário ou, se preferir, ligue para nós.</p>
        <Forms.ContactUs />
        <img id="contact-banner" src={ContactBanner} alt="App Atas Control" />
      </section>
      <section id="who-we-are" className="who-we-are">
        <div className="container">
          <h2>Quem somos</h2>
          <p>
            O Atas Control é um portal tecnológico de gestão de atas de
            audiências, perícias e decisões já proferidas nos processos da sua
            área.
          </p>
          <p>
            É mais uma inovação tecnológica que trará benefícios econômicos e
            melhores resultados na condução dos processos trabalhistas, com
            informações mais completas e detalhadas.
          </p>
          <p>
            O Atas Control permite que todas as atas de audiências sejam
            indexadas ao sistema com a identificação das testemunhas que foram
            ouvidas em audiências anteriores, possibilitando a unicidade de
            depoimentos e garantindo a efetiva contradita nos processos.
          </p>
          <p>
            Além disso, você conseguirá uma gestão das perícias judiciais, além
            de todas as decisões já proferidas.
          </p>
          <p>
            O sistema lhe permitirá efetiva gestão e controle dos seus processos
            trabalhistas.
          </p>
        </div>
      </section>
      <footer id="footer">
        <div className="container">
          <Logo />
        </div>
        <div className="all-rights">
          <p>
            Todos os direitos reservados - © {new Date().getFullYear()} por
            Legal Control
          </p>
        </div>
      </footer>
    </Container>
  );
};

export default Home;
