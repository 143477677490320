import styled from 'styled-components';

export const Content = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperInfos = styled.div`
  width: 100%;

  .contradicted {
    padding-top: 16px;
    h2 {
      font-size: 20px;
      font-weight: 300;
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;

export const Group = styled.div`
  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.colors.f1};

  #title {
    align-items: center;
    height: 50px;
  }

  h3 {
    min-width: calc(100% - 200px);
    font-size: 28px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.accent};
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.accent};
  }
  .hearing-items {
    margin-left: 16px;
  }
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const Lawyers = styled.div`
  flex: 1;
`;

export const Item = styled.div`
  flex: 1;
  margin: 16px 0;
  overflow-y: auto;
  max-height: 100vh;

  font-family: 'Futura PT';

  p:nth-child(1) {
    font-weight: 200;
    color: ${({ theme }) => theme.colors.text4};
    font-size: 16px;
  }
  p:nth-child(2) {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text2};
    font-size: 18px;
    text-transform: uppercase;
  }
`;

export const WrapperFiles = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 8px;
`;

export const ButtonAttachFile = styled.button`
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  font-family: 'Futura PT';
  font-weight: 500;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  text-decoration: underline;
  width: max-content;

  display: flex;
  align-items: center;
  transition: all 0.2s;

  svg {
    margin-right: 8px;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    transition: all 0.2s;
  }
`;

export const Footer = styled.footer`
  display: flex;
  width: 80%;
  align-self: center;
  justify-content: space-around;
  padding-bottom: 32px;
`;

export const WrapperContradicted = styled.div`
  display: flex;
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-right: 16px;
    p {
      margin-left: 8px;
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
    }

    span,
    svg {
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }
`;
