/* eslint-disable no-param-reassign */
import { useMemo, useState, useEffect } from 'react';
/* eslint-disable no-extend-native */
import { ExpandMore, Search } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  ModalProps,
  Typography,
} from '@mui/material';
import { FaTimesCircle } from 'react-icons/fa';
import {
  IAtaListViewModel,
  IAtaMoveToTrashViewModel,
  IAtaRestoreViewModel,
} from 'viewModels';
import loadingGif from '../../../assets/gif/loading.gif';
import {
  useAlert,
  useApp,
  useAta,
  useAuth,
  useContact,
  useActions,
  useAttachments,
} from '../../../context';
import { AtaController } from '../../../controllers';
import Ata from '../../Ata';
import Input from '../../Input';
import Modal from '../Modal';
import ModalConfirm from '../ModalConfirm';

import { ReactComponent as IconPaperClip } from '../../../assets/svg/icon-paper-clip.svg';
import {
  Container,
  ContainerAccordion,
  Content,
  Header,
  Title,
} from './styles';

interface Props extends Omit<ModalProps, 'children'> {
  close: () => void;
  attachmentId: string;
}

const chunk = <T,>(arr: T[], chunkSize: number): T[][] => {
  const R = [];
  for (let i = 0; i < arr.length; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize));
  return R;
};

const ModalAttachToAta = (props: Props) => {
  const { close, attachmentId, ...rest } = props;
  const { subscribe } = useActions();
  const { atas } = useAta();
  const { user } = useAuth();
  const { contacts, officeContacts, ownerContacts, getContactFullName } =
    useContact();

  const appAlert = useAlert();
  const { setLoading } = useApp();
  const [ataToAttachId, setAtaToAttachId] = useState<string>();
  const { setFilteredAttachments } = useAttachments();
  const [search, setSearch] = useState('');
  const [contactAtas, setContactAtas] = useState<{
    [key: string]: IAtaListViewModel[];
  }>({});

  const allContacts = useMemo(
    () =>
      contacts
        .concat(Object.values(officeContacts).flat())
        .concat(ownerContacts)
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        ),
    [contacts, ownerContacts, officeContacts]
  );

  const chunked = useMemo(
    () =>
      chunk(
        contacts
          .filter((c) => c.user.id !== user?.id)
          .concat(
            (Object.values(officeContacts)?.flat() ?? []).filter(
              (c) => c.user.id !== user?.id
            )
          )
          .concat(ownerContacts)
          .filter(
            (contact, idx, self) =>
              idx === self.findIndex((c) => c.id === contact.id)
          ),
        //.reduce((acc, c) => !acc.find((c) => c.id === c.id) ? acc.push(c) : null, []),
        3
      ),
    [contacts, officeContacts, ownerContacts, user?.id]
  );
  const arr = useMemo(
    () =>
      chunked[0]
        ?.map((_, colIndex) => chunked.map((row) => row[colIndex]))
        ?.map((row) => row.filter((contact) => !!contact)) ?? [],
    [chunked]
  );

  const handleAttachToAta = () => {
    if (!ataToAttachId) return;
    setLoading(true);
    const data = new FormData();
    data.append('ataId', ataToAttachId);
    data.append('attachmentId', attachmentId);

    AtaController.attach(data)
      .then(() => {
        setFilteredAttachments((prev) => {
          const newAttachments = [...prev];
          const idxAttachment = newAttachments.findIndex(
            (a) => a.id === attachmentId
          );
          if (idxAttachment !== -1) {
            newAttachments[idxAttachment].hasLinkedAtas = true;
          }
          return newAttachments;
        });

        appAlert.show({
          type: 'sucess',
          timer: 2000,
          message: 'Documento anexado com sucesso!',
        });
      })
      .catch(() => {
        appAlert.show({
          type: 'error',
          title: 'Falha ao anexar documento',
          errors: ['Não foi possível anexar o documento a esta ata.'],
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const unsubscribeAtaUpdate = subscribe(
      'ATA_UPDATED',
      (ataUpdated: IAtaListViewModel) => {
        Object.entries(contactAtas).forEach(([key, atasContact]) => {
          const ataContactIndex = atasContact.findIndex(
            (a) => a.id === ataUpdated.id
          );

          if (ataContactIndex > -1) {
            setContactAtas((prev) => ({
              ...prev,
              [key]: [
                ...atasContact.slice(0, ataContactIndex),
                ataUpdated,
                ...atasContact.slice(ataContactIndex + 1),
              ],
            }));
          }
        });
      }
    );

    const unsubscribeAtaDelete = subscribe(
      'ATA_DISABLED',
      (ataDeletedId: string) => {
        setContactAtas((prev) =>
          Object.entries(prev).reduce(
            (acc, [key, atasContact]) => ({
              ...acc,
              [key]: atasContact.filter((a) => a.id !== ataDeletedId),
            }),
            {}
          )
        );
      }
    );

    const unsubscribeAtaTrashed = subscribe(
      'TRASHED_ATA',
      ({ id }: IAtaListViewModel & IAtaMoveToTrashViewModel) => {
        setContactAtas((prev) =>
          Object.entries(prev).reduce(
            (acc, [key, atasContact]) => ({
              ...acc,
              [key]: atasContact.filter((a) => a.id !== id),
            }),
            {}
          )
        );
      }
    );

    const unsubscribeAtaRestore = subscribe(
      'ATA_RESTORED',
      ({
        companyId,
        contactId,
        ...restoredAta
      }: IAtaRestoreViewModel & IAtaListViewModel) => {
        const contact = allContacts.find((c) =>
          contactId ? c.id === contactId : c.companyId === companyId
        );

        if (contact) {
          const idToUpdate = contact.companyId ?? contact.id;
          setContactAtas((prev) => ({
            ...prev,
            [idToUpdate]: [restoredAta, ...(prev[idToUpdate] || [])],
          }));
        }
      }
    );

    const unsubscribeAtaShare = subscribe(
      'NEW_CONTACT_ATA',
      ({
        contactId,
        companyId,
        ...addedAta
      }: { contactId: string; companyId?: string } & IAtaListViewModel) => {
        setContactAtas((prev) => ({
          ...prev,
          [companyId ?? contactId]: [
            addedAta,
            ...(prev[companyId ?? contactId] || []),
          ],
        }));
      }
    );

    return () => {
      unsubscribeAtaUpdate();
      unsubscribeAtaDelete();
      unsubscribeAtaTrashed();
      unsubscribeAtaRestore();
      unsubscribeAtaShare();
    };
  }, [allContacts, contactAtas, subscribe, user?.id]);

  return (
    <Modal onClose={close} {...rest}>
      <Container>
        <Header>
          <Title>
            <IconPaperClip />
            <p>Anexos</p>
          </Title>
          <IconButton onClick={close}>
            <FaTimesCircle size={20} className="icon-close" />
          </IconButton>
        </Header>
        <Content>
          <Input
            id="search"
            label="Procurar"
            placeholder="Procurar usuário"
            size="medium"
            fullWidth={false}
            endAdornment={
              <IconButton onClick={() => {}}>
                <Search />
              </IconButton>
            }
            onChange={(e) => setSearch(e.target.value.trim())}
          />
          <div className="user-atas">
            {atas
              .filter(
                (ata) =>
                  !search ||
                  ata.text?.toLowerCase().includes(search.toLowerCase())
              )
              .map((ata) => (
                <Ata
                  key={ata.id}
                  {...ata}
                  showMenu={false}
                  onClick={() => setAtaToAttachId(ata.id)}
                />
              ))}
          </div>
          <div className="contact-atas">
            {arr.map((column, index) => (
              <div>
                {column.map((contact, i) => {
                  const fullName = getContactFullName(contact);

                  return (
                    <ContainerAccordion>
                      <Accordion
                        key={`${i + 1}`}
                        TransitionProps={{ unmountOnExit: true }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls={`panel${index + 1}a-content`}
                          id="accordion-summary"
                          onClick={() => {
                            if (contactAtas[contact.id] !== undefined) return;
                            AtaController.get({
                              contactId: !contact?.companyId
                                ? contact.id
                                : undefined,
                              companyId: contact.companyId,
                              trashed: false,
                            })
                              .then((atasRes) => {
                                setContactAtas((prev) => ({
                                  ...prev,
                                  [contact.id]: atasRes,
                                }));
                              })
                              .finally(() => setLoading(false));
                          }}
                        >
                          <Typography>
                            <strong>{fullName[0]}</strong>
                            {fullName[1] ? ` - ${fullName[1]}` : ''}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {!contactAtas[contact.id] ? (
                            <div className="loading-atas">
                              <img
                                src={loadingGif}
                                alt="Legal Control Atas Loading"
                              />
                              <p>Carregando atas...</p>
                            </div>
                          ) : contactAtas[contact.id]?.filter(
                              (ata) =>
                                !search ||
                                ata.text
                                  ?.toLowerCase()
                                  .includes(search.toLowerCase())
                            ).length === 0 ? (
                            <p className="empty-atas">
                              Nenhuma ata encontrada.
                            </p>
                          ) : (
                            contactAtas[contact.id]
                              ?.filter(
                                (ata) =>
                                  !search ||
                                  ata.text
                                    ?.toLowerCase()
                                    .includes(search.toLowerCase())
                              )
                              ?.map((ata: IAtaListViewModel) => (
                                <Ata
                                  key={ata.id}
                                  {...ata}
                                  showMenu={false}
                                  onClick={() => setAtaToAttachId(ata.id)}
                                />
                              ))
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </ContainerAccordion>
                  );
                })}
              </div>
            ))}
          </div>
          <ModalConfirm
            onConfirm={handleAttachToAta}
            open={ataToAttachId !== undefined}
            title="Anexar o documento nessa ata?"
            onClose={() => setAtaToAttachId(undefined)}
            onCancel={() => setAtaToAttachId(undefined)}
          />
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalAttachToAta;
