import React from 'react';
import { IPlan } from 'entities';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import Button from '../Button';
import { ReactComponent as Atas } from '../../assets/svg/plan-ata.svg';
import { ReactComponent as Office } from '../../assets/svg/icon-office-outline.svg';
import { ReactComponent as Storage } from '../../assets/svg/plan-storage.svg';
import { useUserRegister } from '../../context';
import { removeMask } from '../../utils/removeMask';

interface PlanProps extends IPlan {
  handleContinue?: (id: string) => void;
  selected?: boolean;
}

const Plan: React.FC<PlanProps> = ({
  id,
  name,
  atas,
  price,
  color,
  offices,
  storage,
  selected,
  handleContinue,
}) => {
  const { userRegister } = useUserRegister();
  return (
    <Container selected={selected} className="plan">
      <h3 style={{ color }}>{name}</h3>
      <div className="detail">
        <Storage />
        <p>
          {storage}
          <span>GB</span>
        </p>
      </div>
      <div className="detail">
        <Atas />
        <p>
          {atas} <span>Atas</span>
        </p>
      </div>
      {removeMask(userRegister.document).length === 14 && (
        <div className="detail">
          <Office />
          <p>
            {offices} <span>Escritórios</span>
          </p>
        </div>
      )}
      <div className="price">
        <p>Plano mensal</p>
        <strong>
          {
            price
              .toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
              .split(',')[0]
          }

          <span>
            ,
            {
              price
                .toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                .split(',')[1]
            }
          </span>
        </strong>
      </div>
      {selected ? (
        <Link to="/sign-up/plan">Mudar plano</Link>
      ) : (
        <Button shape="outline" onClick={() => handleContinue?.(id)}>
          <p>Contratar plano</p>
        </Button>
      )}
    </Container>
  );
};

export default Plan;
