import styled from 'styled-components';
import { Screen } from '../../../styles/global';

interface Props {
  columns: number;
  selectEnabled: boolean;
}

export const Container = styled(Screen)<Props>`
  padding: 0px 60px;
  padding-top: 100px;

  .list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-family: 'Futura Condensed PT' !important;
      font-size: 36px;
      color: ${({ theme }) => theme.colors.accent};
    }

    & > :last-child {
      width: 300px;
    }
  }

  .actions {
    margin-left: -10px;
    margin-bottom: 10px;
    #select-btn {
      display: flex;
      margin: 5px 10px;
      align-items: center;

      p {
        margin-left: 10px;
      }

      svg {
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
      }
    }
  }

  .content {
    flex: 1;
    border-radius: 16px;
    max-height: calc(100vh - 230px);
    box-shadow: ${({ theme }) => theme.shadows[0]};
    background-color: ${({ theme }) => theme.colors.background};

    .list-header {
      padding: 0 16px;
      display: grid;
      grid-template-columns: ${({ columns, selectEnabled }) =>
        `${selectEnabled ? '40px' : ''} repeat(${columns}, 1fr) 40px`};
      align-items: center;
      height: 60px;

      & > svg {
        margin: 0 auto;
        color: ${({ theme }) => theme.colors.primary};
      }

      .column-name {
        display: flex;
        align-items: center;

        p {
          color: ${({ theme }) => theme.colors.accent};
          font-size: 14px;
          font-weight: 200;
          cursor: default;
        }

        button {
          margin-left: 5px;

          svg {
            color: ${({ theme }) => theme.colors.accent};
            fill: ${({ theme }) => theme.colors.accent};
            transition: all 0.2s;
          }
        }

        &.rotate svg {
          transform: rotate(-180deg);
          transition: all 0.2s;
        }
      }

      & > :last-child {
        margin-left: auto;
      }
    }

    .list-items {
      height: 100%;
      padding: 0 16px;
      max-height: calc(100vh - 290px);
      overflow-y: auto;

      .item {
        width: 100%;
        height: ${({ selectEnabled }) => (selectEnabled ? '40px' : '30px')};
        display: grid;
        grid-template-columns: ${({ columns, selectEnabled }) =>
          `${selectEnabled ? '40px' : ''} repeat(${columns}, 1fr) 40px`};
        cursor: pointer;
        align-items: center;
        transition: background-color 0.2s;
        border-bottom: 1px solid ${({ theme }) => theme.colors.border};

        transition: height 0.2s ease-in-out;

        #select-ata {
          width: 40px;
        }

        p {
          font-size: 12px;
          font-weight: 600;
          color: ${({ theme }) => theme.colors.text};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          padding: 0 2.5px;
          text-transform: uppercase;
        }

        &:hover {
          background-color: ${({ theme }) => theme.colors.f1};
          transition: background-color 0.2s;
        }
      }

      .loading-atas-list {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0;
        color: ${({ theme }) => theme.colors.text};

        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }

      .empty-list {
        display: flex;

        p {
          color: ${({ theme }) => theme.colors.text};
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
`;

interface DownloadContainerProps {
  open: boolean;
}

export const DownloadContainer = styled.div<DownloadContainerProps>`
  bottom: 0;
  right: 60px;
  width: 400px;
  display: flex;
  position: fixed;
  flex-direction: column;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  height: ${({ open }) => (open ? '120px' : '0')};
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ open }) => (open ? '10px 10px 20px 10px' : '0')};

  .download-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: ${({ theme }) => theme.colors.text};
    }

    img {
      max-width: 30px;
      min-width: 30px;
      max-height: 30px;
      min-height: 30px;
      margin-right: 10px;
    }
  }

  .downloader-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
    }

    #header {
      padding-left: 10px;
      & > svg,
      & > p {
        color: ${({ theme }) => theme.colors.accent};
      }

      p {
        margin-left: 10px;
      }
    }

    button {
      margin: 0 !important;
      max-width: 100px;
      padding: 5px 10px;

      svg {
        margin-right: 5px;
      }
    }
  }

  .downloader-actions {
    display: flex;
    margin-top: auto;
    align-items: center;
    justify-content: space-between;

    button {
      max-width: min-content;
    }

    #action-btn-text {
      display: flex;
      margin: 5px 10px;
      align-items: center;

      p {
        margin-left: 10px;
        white-space: nowrap;
      }

      svg {
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;

        stroke-width: 5px;
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;
