import { ModalProps, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FaTimesCircle } from 'react-icons/fa';
import { SignalWifiStatusbarConnectedNoInternet4 } from '@mui/icons-material';
import { Container } from './styles';
import Button from '../../Button';
import Modal from '../Modal';

interface Props extends Omit<ModalProps, 'children'> {
  onClose: () => void;
}

const ModalOffline = ({ onClose, ...rest }: Props) => {
  const navigate = useNavigate();
  return (
    <Modal onClose={onClose} {...rest}>
      <Container>
        <IconButton onClick={onClose} className="btn-close">
          <FaTimesCircle size={20} className="icon-close" />
        </IconButton>
        <div className="title">
          <SignalWifiStatusbarConnectedNoInternet4 />
          <p>Sem conexão</p>
        </div>
        <p>Você precisa estar conectado para usar essa função</p>
        <Button id="go-back" shape="outline" onClick={() => navigate(-1)}>
          Voltar
        </Button>
      </Container>
    </Modal>
  );
};

export default ModalOffline;
