import styled from 'styled-components';

export const Container = styled.div`
  max-width: 30vw;
  .btn-close {
    color: ${({ theme }) => theme.colors.blue};
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .title {
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
  }

  .actions {
    display: flex;
    margin-top: 20px;
    justify-content: center;

    button {
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
`;
