import styled from 'styled-components';

export const Container = styled.div`
  max-width: 75vw;
  padding: 0 16px;
  max-height: 80vh;
  overflow-y: auto;
  .btn-close {
    color: ${({ theme }) => theme.colors.blue};
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .content {
    width: calc(75vw - 46px);
  }

  .ata-view-loading {
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin-left: 10px;
    }
  }

  .shares-and-duplications {
    display: grid;
    padding: 20px 0;
    grid-gap: 100px;
    grid-template-columns: 1fr 1fr;

    h4 {
      width: 100%;
      font-size: 16px;
      font-weight: 300;
      position: relative;
      margin-bottom: 20px;
      color: ${({ theme }) => theme.colors.primary};

      &:before {
        left: 0;
        right: 0;
        height: 1px;
        content: '';
        bottom: -10px;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.primary};
      }
    }

    .shares-list {
      display: flex;
      grid-gap: 10px;
      flex-wrap: wrap;

      & > p {
        color: ${({ theme }) => theme.colors.text};
      }

      .share {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        border-radius: 10px;
        background-color: ${({ theme }) => theme.colors.f1};
        box-shadow: ${({ theme }) => theme.shadows[1]};

        p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-transform: capitalize;
          color: ${({ theme }) => theme.colors.accent};
        }

        button {
          margin-left: 5px;
          margin-right: -5px;
        }
      }
    }

    .duplications-list {
      display: flex;
      grid-gap: 10px;
      flex-wrap: wrap;

      & > p {
        color: ${({ theme }) => theme.colors.text};
      }

      .duplication {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: ${({ theme }) => theme.colors.accent};

        &:before {
          content: '-';
          margin-right: 5px;
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    margin: 16px 0;

    button {
      width: 90px;
      height: 90px;
      display: flex;
      padding: 10px;
      margin: 0 30px;
      border-radius: 10px;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      transition: all 0.2s;

      p {
        font-size: 20px;
        color: ${({ theme }) => theme.colors.text};
        margin-top: 8px;
      }

      svg {
        width: 45px;
        height: 45px;
        color: ${({ theme }) => theme.colors.primary};
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
        transition: all 0.2s;
      }
    }
  }
`;
