import styled from 'styled-components';

type IHeader = {
  titleColor: 'primary' | 'pink';
  iconColor: 'primary' | 'red';
};

export const Content = styled.div`
  padding: 8px 16px;
  min-width: 300px;

  main {
    display: flex;
    margin-top: 16px;
    flex-direction: column;

    strong {
      cursor: default;
      font-size: 16px;
      text-align: center;
      white-space: pre-line;
      color: ${({ theme }) => theme.colors.border};
    }

    p {
      cursor: default;
      font-size: 16px;
      font-weight: 500px;
      text-align: center;
      white-space: pre-line;
      color: ${({ theme }) => theme.colors.border};
    }

    .errors {
      margin-top: 16px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      p {
        color: ${({ theme }) => theme.colors.pink};
        text-align: start;
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    button {
      margin: 0 8px;
    }
  }
`;

export const Header = styled.header<IHeader>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, titleColor }) => theme.colors[titleColor]};

  svg {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    color: ${({ theme, iconColor }) => theme.colors[iconColor]};
  }

  p {
    cursor: default;
    font-size: 24px;
    font-weight: 500;
  }
`;
