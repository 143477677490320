import { IconButton } from '@mui/material';
import { FaTrashAlt } from 'react-icons/fa';

import { Container } from './styles';

interface Props {
  name: string;
  onDelete: () => void;
}

export const ItemFile = ({ name, onDelete }: Props) => {
  return (
    <Container>
      <p>{name}</p>
      <div />
      <IconButton
        aria-label="Deletar"
        onClick={(e) => {
          onDelete();
          e.stopPropagation();
        }}
        color="error"
      >
        <FaTrashAlt />
      </IconButton>
    </Container>
  );
};
