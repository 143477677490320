import styled, { css } from 'styled-components';
import { Screen } from '../../../styles/global';

export const Container = styled(Screen)`
  padding: 0px 60px;
  padding-top: 100px;

  h1 {
    font-family: 'Futura Condensed PT' !important;
    font-size: 36px;
    color: ${({ theme }) => theme.colors.accent};
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 48px;
`;

export const OptionCreateAta = styled.div`
  width: 314px;
  height: 283px;
  background-color: ${({ theme }) => theme.colors.background};
  margin-right: 95px;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 16px;

  svg {
    align-self: center;
  }

  p {
    text-align: center;
    color: ${({ theme }) => theme.colors.text3};
    font-family: 'Futura PT' !important;
    font-size: 18px;
  }
`;

export const TextButton = styled.p`
  ${({ theme: { colors } }) => css`
    color: ${colors.primary} !important;
  `};
  font-family: 'Futura PT' !important;
`;

export const Footer = styled.footer`
  display: flex;
  margin-top: 36px;
  justify-content: space-around;
`;
